export class CheckFavoriteMessage {

    //#region Constructor

    constructor(isProductDocumentFavorite?: boolean) {
        this.isProductDocumentFavorite = isProductDocumentFavorite;
    }

    //#endregion

    //#region Properties

    /**
     * Specifies if product document is tagged as favorite.
     */
    public isProductDocumentFavorite?: boolean;

    //#endregion
}
