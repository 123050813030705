import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CONSTANTS } from '../../constants';
import { LOCALIZE_CONSTANTS } from '../../localize.constants';
import { Language } from './language';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    //#region Private Fields

    /**
     * Default language
     */
    private _defaultLanguage: Language | undefined;

    /**
     * Selected language;
     */
    private _selectedLanguage: Language | undefined;

    /**
     * Available languages
     * NOTE: This will be fetched from the server, once we implement translation service.
     */
    private _availableLanguages: Array<Language>;


    //#endregion

    //#region Constructor

    /**
     * Initializes new instance of Translation service
     * @param translate Ngx-Translate service
     */
    constructor(public translate: TranslateService) {

        this._availableLanguages = new Array<Language>();

        this.translate.get([LOCALIZE_CONSTANTS.LANGUAGES.ENGLISH]).subscribe(translations=>{

            this._defaultLanguage = new Language(CONSTANTS.LANGUAGE_CODE.ENGLISH, translations[LOCALIZE_CONSTANTS.LANGUAGES.ENGLISH]);

            console.log("Default language: " + this._defaultLanguage.languageName + ", Default language code: " + this._defaultLanguage.languageCode);
            this._selectedLanguage = this._defaultLanguage;
            this._availableLanguages.push(this._defaultLanguage);
            
            if (!translate.getDefaultLang()){
                translate.setDefaultLang(this._defaultLanguage.languageCode);
            }

            this.useLanguage(this._defaultLanguage);
        });
    }

    //#endregion

    /**
     * @description Sets language to use.
     * @param language Language to use.
     */
    useLanguage(language: Language) {
        this.translate.use(language.languageCode);
    }

    /**
     * @description Gets available languages.
     * @returns Array of available languages.
     */
    getAvailableLanguages():Array<Language> {
        return this._availableLanguages;
    }

    /**
     * Gets selected language
     * @returns Selected language
     */
    getCurrentLanguage():Language | undefined{
        return this._selectedLanguage;
    }
}
