import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CONSTANTS } from "../../constants";
import { LOCALIZE_CONSTANTS } from "../../localize.constants";
import { LoggerService } from "../log-service/logger-service";
import { NotificationService } from "../notification-service/notification.service";

@Injectable({
    providedIn: 'root'
})
export class ErrorResponseHandlerService{

    //#region Private fields

    /**
     * Notification service.
     */
    private _notificationService: NotificationService;

    /**
     * Translate service.
     */
    private _translateService: TranslateService;

    /**
     * Logger service.
     */
    private _loggerService: LoggerService;

    //#endregion

    //#region Constructor

    /**
     * Initializes new instance of ErrorResponseHandlerService
     * @param notificationService Notification service.
     * @param translateService Translate service.
     * @param loggerService Logger service.
     */
    constructor(notificationService: NotificationService, translateService: TranslateService, loggerService: LoggerService){
        this._notificationService = notificationService;
        this._translateService = translateService;
        this._loggerService = loggerService;
    }

    //#endregion

    //#region Public methods

    /**
     * Handles HttpErrorResponse and shows appropriate error notification.
     * @param httpErrorResponse Http error response
     */
    public handleHttpErrorResponse(httpErrorResponse: HttpErrorResponse){

        if(httpErrorResponse){

            if(httpErrorResponse.error && httpErrorResponse.error.errorCode){

                this._loggerService.errorFormat("Server error. Error code: {0}", httpErrorResponse.error.errorCode);

                switch(httpErrorResponse.error.errorCode){

                    case CONSTANTS.SERVER_ERROR_CODES.CONFIGURATION_ERROR:
                        this._notificationService.notifyError(LOCALIZE_CONSTANTS.ERROR_MESSAGE.SERVER_CONFIGURATION_ERROR);
                        break;
                    
                    case CONSTANTS.SERVER_ERROR_CODES.UNKNOWN_PRODUCT_NAME:
                        this._notificationService.notifyError(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNKNOWN_PRODUCT_NAME);
                        break;
                    
                    case CONSTANTS.SERVER_ERROR_CODES.INVALID_PRODUCT_RELEASE_STATUS:
                        this._notificationService.notifyError(LOCALIZE_CONSTANTS.ERROR_MESSAGE.INVALID_PRODUCT_RELEASE_STATUS);
                        break;

                    case CONSTANTS.SERVER_ERROR_CODES.EMAIL_SEND_FAILED:
                        this._notificationService.notifyError(LOCALIZE_CONSTANTS.ERROR_MESSAGE.EMAIL_SEND_FAILED);
                        break;
                    
                    case CONSTANTS.SERVER_ERROR_CODES.CORE_DATA_SYNC_IN_PROGRESS:
                        this._notificationService.notifyWarning(LOCALIZE_CONSTANTS.WARNING_MESSAGE.CORE_DATA_SYNC_IN_PROGRESS);
                        break;
                    
                    case CONSTANTS.SERVER_ERROR_CODES.CATEGORY_NOT_ELIGIBLE_FOR_DELETION:
                        this._notificationService.notifyWarning(LOCALIZE_CONSTANTS.WARNING_MESSAGE.CATEGORY_NOT_ELIGIBLE_FOR_DELETION);
                        break;
                    
                    case CONSTANTS.SERVER_ERROR_CODES.DOCS_SYNC_IN_PROGRESS:
                        this._notificationService.notifyWarning(LOCALIZE_CONSTANTS.WARNING_MESSAGE.PRODUCT_DOCUMENTATION_SYNC_IN_PROGRESS);
                        break;
                    
                    case CONSTANTS.SERVER_ERROR_CODES.PUBLICATION_CATEGORY_IS_PREDEFINED:
                        this._notificationService.notifyWarning(LOCALIZE_CONSTANTS.WARNING_MESSAGE.PUBLICATION_CATEGORY_IS_PREDEFINED);
                        break;
                    
                    case CONSTANTS.SERVER_ERROR_CODES.PUBLICATION_CATEGORY_IS_USED:
                        this._notificationService.notifyWarning(LOCALIZE_CONSTANTS.WARNING_MESSAGE.PUBLICATION_CATEGORY_IS_USED);
                        break;
                    
                    case CONSTANTS.SERVER_ERROR_CODES.TAG_NOT_ELIGIBLE_FOR_DELETION:
                        this._notificationService.notifyWarning(LOCALIZE_CONSTANTS.WARNING_MESSAGE.TAG_NOT_ELIGIBLE_FOR_DELETION);
                        break;
                    
                    case CONSTANTS.SERVER_ERROR_CODES.FILE_NOT_ELIGIBLE_FOR_DELETION:
                        this._notificationService.notifyWarning(LOCALIZE_CONSTANTS.WARNING_MESSAGE.FILE_NOT_ELIGIBLE_FOR_DELETION);
                        break;
                    
                    case CONSTANTS.SERVER_ERROR_CODES.DIRECTORY_NOT_ELIGIBLE_FOR_DELETION:
                        this._notificationService.notifyWarning(LOCALIZE_CONSTANTS.WARNING_MESSAGE.DIRECTORY_NOT_ELIGIBLE_FOR_DELETION);
                        break;

                    default:
                        this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNEXPECTED_SERVER_ERROR).subscribe(translation=>{
                            var errorMessage = translation + httpErrorResponse.error.errorCode;
                            this._notificationService.notifyError(errorMessage, true);
                        })
                        break;
                }
            }else{

                this._loggerService.errorFormat("Received HTTP status code: {0}", httpErrorResponse.status);

                switch(httpErrorResponse.status)
                {
                    case 0:
                        this._notificationService.notifyError(LOCALIZE_CONSTANTS.ERROR_MESSAGE.CONNECTION_PROBLEM);
                        break;

                    case 400:
                        this._notificationService.notifyError(LOCALIZE_CONSTANTS.ERROR_MESSAGE.INVALID_REQUEST);
                        break;

                    case 404:
                        this._notificationService.notifyError(LOCALIZE_CONSTANTS.ERROR_MESSAGE.RESOURCE_NOT_FOUND);
                        break;

                    case 401:
                    case 403:
                        this._notificationService.notifyError(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNAUTHORIZED_REQUEST);
                        break;

                    case 409:
                        this._notificationService.notifyError(LOCALIZE_CONSTANTS.ERROR_MESSAGE.RESOURCE_ALREADY_EXIST);
                        break;

                    default:
                        this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNEXPECTED_HTTP_STATUS_CODE).subscribe(translation=>{
                            var errorMessage = translation + httpErrorResponse.status;
                            this._notificationService.notifyError(errorMessage, true);
                        })
                        break;
                }
            }
        }else{
            this._loggerService.error("Connection problem");
            this._notificationService.notifyError(LOCALIZE_CONSTANTS.ERROR_MESSAGE.CONNECTION_PROBLEM);
        }
    }

    //#endregion

}