import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms/";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { TextBoxComponent } from "@progress/kendo-angular-inputs";
import { PasswordPolicy, RegisterUserRequest } from "src/app/api/opal-partner-center/models";
import { RegistrationService } from "src/app/api/opal-partner-center/services";
import { CONSTANTS } from "src/app/shared/constants";
import { LOCALIZE_CONSTANTS } from "src/app/shared/localize.constants";
import { PasswordValidationResult } from "src/app/shared/model/password-validation-result";
import { ErrorResponseHandlerService } from "src/app/shared/services/error-response-handler-service/error-response-handler-service";
import { PasswordValidationService } from "src/app/shared/services/password-validation-service/password-validation.service";
import { SECURITY_ROUTES } from "../security.routing.constants";

@Component({
    selector: "register-user",
    templateUrl: "./register-user.component.html"
})
export class RegisterUserComponent implements OnInit{

  //#region Private Fields

  /**
   * User password.
   */
  private _password: string;

  //#endregion

  //#region Constructor

  constructor(private _registrationService: RegistrationService,
              private _translateService: TranslateService,
              private _router: Router,
              private _passwordValidationService: PasswordValidationService,
              private _errorResponseHandlerService: ErrorResponseHandlerService){

    this.username = "";
    this._password = "";
    this.confirmPassword = "";
    this.isRegistrationInProgress = false;
    this.registerUserErrorMessage = "";
    this.isRegistrationErrorOccured = false;
    this.isRegistrationSent = false;
    this.isPasswordPolicyShown = false;
    this.isPasswordShown = false;
    this.passwordValidationResult = new PasswordValidationResult();
  }

  //#endregion

  //#region NgCore

  public ngOnInit(): void{
    this._registrationService.getApiPasswordPolicy().subscribe({
      next: response=>{
        this.passwordPolicy = response.passwordPolicy;
      },
      error: errorResponse=>{
        this._errorResponseHandlerService.handleHttpErrorResponse(errorResponse);
      }
    });
  }

  public ngAfterViewInit(): void{
    this.passwordInput!.input.nativeElement.type = "password";
    this.confirmPasswordInput!.input.nativeElement.type = "password";
    setTimeout(() => {
      this.usernameInput!.focus();  
    }, 300);
  }

  //#endregion

  //#region Public Properties

  /**
   * Password policy.
   */
  public passwordPolicy?: PasswordPolicy;

  /**
   * Password validation result.
   */
  public passwordValidationResult: PasswordValidationResult;

  /**
   * Username.
   */
  public username: string;

  /**
   * Gets password.
   */
  public get password() : string {
    return this._password;
  }

  /**
   * Sets password and validates passoword againts password policy.
   */
  public set password(password : string) {
    this._password = password;

    this.passwordValidationResult = this._passwordValidationService.validatePassword(this._password, this.passwordPolicy!);
  }

  /**
   * The password confirmation.
   */
  public confirmPassword: string;

  /**
   * Indicates whether the registration is in progress.
   */
  public isRegistrationInProgress: boolean;

  /**
   * The registration error message.
   */
  public registerUserErrorMessage: string;

  /**
   * Indicates whether the registration error occured.
   */
  public isRegistrationErrorOccured: boolean;

  /**
   * User registration form.
   */
  @ViewChild("userRegistrationForm")
  public userRegistrationForm?: NgForm;

  /**
   * Username input.
   */
  @ViewChild("usernameInput")
  public usernameInput?: TextBoxComponent;

  /**
   * Password input.
   */
  @ViewChild("passwordInput")
  public passwordInput?: TextBoxComponent;

  /**
   * Confirm password input.
   */
  @ViewChild("confirmPasswordInput")
  public confirmPasswordInput?: TextBoxComponent;

  /**
   * Determines whether the registration request is successfully sent.
   */
  public isRegistrationSent: boolean;

  /**
   * Indicates whether the password policy is shown.
   */
  public isPasswordPolicyShown: boolean;

  /**
   * Indicates whether the password is shown.
   */
  public isPasswordShown: boolean;

  //#endregion

  //#region Event Handlers

  public onShowPassword(event: any): void{
    this.isPasswordShown = true;
    this.passwordInput!.input.nativeElement.type = "text";
  }

  public onHidePassword(event: any): void{
    this.isPasswordShown = false;
    this.passwordInput!.input.nativeElement.type = "password";
  }

  /**
   * Shows password policy.
   */
  public onShowPasswordPolicy(): void{
    this.isPasswordPolicyShown = true;
  }

  /**
   * Closes password policy.
   */
  public onClosePasswordPolicy():void{
    this.isPasswordPolicyShown = false;
  }

  /**
   * Called when user initiates registration.
   */
  public onRegisterUser(): void {

    if(this.userRegistrationForm?.invalid){
      return;
    }

    this.isRegistrationInProgress = true;
    this.isRegistrationErrorOccured = false;
    this.registerUserErrorMessage = "";

    var registrationRequest = {} as RegisterUserRequest;

    registrationRequest.username = this.username;
    registrationRequest.password = this.password;

    this._registrationService.postApiRegisterUser(registrationRequest).subscribe({

      next: response =>{
        this.isRegistrationInProgress = false;
        this.isRegistrationErrorOccured = false;

        this.password = "";
        this.confirmPassword = "";
        this.username = "";
        this.userRegistrationForm?.resetValidation();
        this.isRegistrationSent = true;

      },
      error: errorResponse =>{

        this.isRegistrationInProgress = false;
        this.handleHttpErrorResponse(errorResponse);
      }
    });
      
  }

  public onCancelRegisterUser(): void{
    
    this._router.navigate([SECURITY_ROUTES.SECURITY_LOGIN]);
  }

  //#endregion

  //#region Private Methods

  /**
   * Handles error response.
   * @param httpErrorResponse Http error response.
   */
  private handleHttpErrorResponse(httpErrorResponse: any): void {
    
    this.isRegistrationErrorOccured = true;

    if(httpErrorResponse){

      if(httpErrorResponse.error && httpErrorResponse.error.errorCode){

        switch(httpErrorResponse.error.errorCode){

          case CONSTANTS.SERVER_ERROR_CODES.UNABLE_TO_REGISTER_USER:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNABLE_TO_REGISTER_USER).subscribe(translation=>{
              this.registerUserErrorMessage = translation;
            })
            break;

          case CONSTANTS.SERVER_ERROR_CODES.EMAIL_SEND_FAILED:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNABLE_TO_SEND_REGISTRATION_CONFIRMATION_EMAIL).subscribe(translation=>{
              this.registerUserErrorMessage = translation;
            })
            break;

          case CONSTANTS.SERVER_ERROR_CODES.USER_ALREADY_REGISTERED:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.USER_ALREADY_REGISTERED).subscribe(translation=>{
              this.registerUserErrorMessage = translation;
            })
            break;

          default:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNEXPECTED_SERVER_ERROR).subscribe(translation=>{
              this.registerUserErrorMessage = translation;
            })

        }

      } else {

        switch(httpErrorResponse.status)
        {
          case 0:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.CONNECTION_PROBLEM).subscribe(translation=>{
              this.registerUserErrorMessage = translation;
            })
            break;

          case 400:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.INVALID_REQUEST).subscribe(translation=>{
              this.registerUserErrorMessage = translation;
            })
            break;

          case 404:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.RESOURCE_NOT_FOUND).subscribe(translation=>{
              this.registerUserErrorMessage = translation;
            })
            break;

          case 401:
          case 403:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNAUTHORIZED_REQUEST).subscribe(translation=>{
              this.registerUserErrorMessage = translation;
            })
            break;

          case 409:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.RESOURCE_ALREADY_EXIST).subscribe(translation=>{
              this.registerUserErrorMessage = translation;
            })
            break;

          default:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNEXPECTED_HTTP_STATUS_CODE).subscribe(translation=>{
              this.registerUserErrorMessage = translation+ httpErrorResponse.status;
            })
            break;
        }
      }

    } else {
      this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.CONNECTION_PROBLEM).subscribe(translation=>{
        this.registerUserErrorMessage = translation;
      })
    }
  }

  //#endregion
}