export class SearchMessage {

    //#region Constructor

    constructor(searchTerm: string) {
        this.searchTerm = searchTerm;
        this.searchFilterOptions = new Map<string, string>();
    }

    //#endregion

    //#region Properties

    /**
     * Search term for product documentation.
     */
    searchTerm: string;

    /**
     * Set of key-value pairs where key represents search filter (like "productName:",
     * "productVersion" e.t.c) and value represents corresponding selected option.
     */
    searchFilterOptions: Map<string, string>;

    //#endregion
}
