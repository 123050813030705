import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SNACK_TYPE } from './snackbar.constants';

/**
 *Snackbar component with custom template for Angular Material Snackbar.
 */
@Component({
    selector: 'snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {


    //#region Constructors

    /**
     * Initializes new instance of SnackbarComponent
     * @param data Snackbar configuration
     */
    constructor(public snackBarRef: MatSnackBarRef<SnackbarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {
        this.snackType = this.data.snackType;
    }

    //#endregion

    //#region Public properties

    /**
    * @description Determines the type snack:
    * - done (Success)
    * - error (Error)
    * - warning (Warn)
    * - info (info)
    */
    public snackType: string;

    //#endregion
}