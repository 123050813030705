import { saveAs } from "@progress/kendo-file-saver";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class FileSaverService{

    /**
     * Saves file in default download location with a given name.
     * @param fileContent File content in binary or base64 string format.
     * @param fileName Name under which file will be saved.
     */
    public saveAs(fileContent: ArrayBuffer | string, fileName: string){

        if(typeof fileContent === "string" && fileContent.startsWith("data:")){
            saveAs(fileContent, fileName);
        }else{
            const dataURI = "data:application/x-binary;base64," +  fileContent;
            saveAs(dataURI, fileName);
        }
    }
}