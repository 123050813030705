import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import { Subscription } from "rxjs";


@Directive({
  selector: '[passwordMatch]',
  providers:[{provide: NG_VALIDATORS, useExisting: PasswordMatchValidatorDirective, multi: true}]
})
export class PasswordMatchValidatorDirective implements Validator{

  //#region Private Fields

  /**
   * The password change subscription.
   */
  private _passwordChangeSubscription?: Subscription;

  //#endregion

  //#region Properties

  /**
   * The id of the password element.
   */
  @Input("passwordMatch")
  public passwordElementId?: string;

  //#endregion

  //#region Validator

  validate(control: AbstractControl): ValidationErrors | null {

    if(this._passwordChangeSubscription){
      this._passwordChangeSubscription.unsubscribe();
    }

    var passwordElement = control.parent?.get(this.passwordElementId as string);

    if(passwordElement){

      this._passwordChangeSubscription = passwordElement.valueChanges.subscribe({
        next: value =>{
          control.updateValueAndValidity();
        }
      });

      if(control.value == passwordElement.value){
        return null;
      }
    } 

    return {"passwordMatch": true}
  }

  //#endregion
 }