import { UserRatingSummary } from "./user-rating-summary";

export class RatingSummaryMessage {

    //#region Constructor
    
    constructor(userRatingSummary: UserRatingSummary | undefined) {
        this.ratingSummary = userRatingSummary;
    }

    //#endregion

    //#region Public Properties

    /**
     * User rating summary.
     */
    public ratingSummary: UserRatingSummary | undefined;

    //#endregion
}
