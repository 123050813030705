import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MdbSidenavModule } from 'mdb-angular-ui-kit/sidenav';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { SharedModule as KendoSharedModule } from '@progress/kendo-angular-grid';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { MatDialogModule } from '@angular/material/dialog';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';
import { SharedModule as AppSharedModule } from '../shared/shared.module';
import { EditorModule } from '@progress/kendo-angular-editor';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { BarcodesModule } from '@progress/kendo-angular-barcodes';
import { LabelModule } from '@progress/kendo-angular-label';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { PopoverModule } from '@progress/kendo-angular-tooltip';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { LayoutComponent } from './layout.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { RouterModule } from '@angular/router';
import { MouseEnterDelayedDirective } from './directives/mouse-enter-delayed-directive';

@NgModule({
  declarations: [
    LayoutComponent,
    MouseEnterDelayedDirective
  ],
  imports: [
    RouterModule,
    BrowserAnimationsModule,
    LayoutRoutingModule,
    MdbSidenavModule,
    KendoSharedModule,
    MatDialogModule,
    MatSnackBarModule, 
    IndicatorsModule,
    TranslateModule,
    DialogsModule,
    ButtonsModule,
    DateInputsModule,
    DropDownsModule,
    FormsModule,
    AppSharedModule,
    EditorModule,
    UploadsModule,
    HttpClientModule,
    MdbCollapseModule,
    InputsModule,
    BarcodesModule,
    InputsModule,
    TooltipsModule,
    PopoverModule,
    LabelModule,
    ChartsModule
  ],

  exports:[RouterModule],
})
export class LayoutModule { }
