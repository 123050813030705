import { HttpClient, HttpHeaders,  HttpParameterCodec,  HttpParams, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { filter, map, Observable } from "rxjs";
import { ODataResponse } from "../common/model/odata-response";

import { FileStorageEntityBase, FileStorageEntityCategoryInfo, FileStorageEntityTagInfo } from "../opal-partner-center/models";
import { StrictHttpResponse } from "../opal-partner-center/strict-http-response";
import { OpalPartnerCenterODataApiConfiguration } from "./opal-partner-center-odata-api-configuration";

@Injectable({
    providedIn: 'root'
})
export class FileStorageOdataService{

  //#region Constructors

  constructor(private _httpClient: HttpClient, private _config: OpalPartnerCenterODataApiConfiguration){

  }

  //#endregion

  //#region API

  /**
   * @return Success
   */
  getApiFileStorageResponse(query: string): Observable<StrictHttpResponse<ODataResponse<FileStorageEntityBase>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this._config.rootUrl + `/api/fileStorage` + query,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this._httpClient.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map((_r) => {
        return _r as StrictHttpResponse<ODataResponse<FileStorageEntityBase>>;
      })
    );
  }
  
  /**
   * @return Success
   */
  getApiFileStorage(query: string): Observable<ODataResponse<FileStorageEntityBase>> {
    return this.getApiFileStorageResponse(query).pipe(
      map(_r => _r.body as ODataResponse<FileStorageEntityBase>)
    );
  }

  /**
   * @return Success
   */
  getApiFileCategoriesResponse(query: string): Observable<StrictHttpResponse<ODataResponse<FileStorageEntityCategoryInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this._config.rootUrl + `/api/fileCategories` + query,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this._httpClient.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map((_r) => {
        return _r as StrictHttpResponse<ODataResponse<FileStorageEntityCategoryInfo>>;
      })
    );
  }
  /**
   * @return Success
   */
  getApiFileCategories(query: string): Observable<ODataResponse<FileStorageEntityCategoryInfo>> {
    return this.getApiFileCategoriesResponse(query).pipe(
      map(_r => _r.body as ODataResponse<FileStorageEntityCategoryInfo>)
    );
  }

  /**
   * @return Success
   */
  getApiFileTagsResponse(query: string): Observable<StrictHttpResponse<ODataResponse<FileStorageEntityTagInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this._config.rootUrl + `/api/fileTags` + query,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this._httpClient.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map((_r) => {
        return _r as StrictHttpResponse<ODataResponse<FileStorageEntityTagInfo>>;
      })
    );
  }

  /**
   * @return Success
   */
  getApiFileTags(query: string): Observable<ODataResponse<FileStorageEntityTagInfo>> {
    return this.getApiFileTagsResponse(query).pipe(
      map(_r => _r.body as ODataResponse<FileStorageEntityTagInfo>)
    );
  }
  
  //#endregion

  //#region Private methods

  protected newParams(): HttpParams {
      return new HttpParams({
        encoder: new ParameterCodec()
      });
  }

  //#endregion
    
}

class ParameterCodec implements HttpParameterCodec {

  //#region HttpParameterCodec 

  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }

  //#region 
}