import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule} from "@angular/common";
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LoggerService } from "./services/log-service/logger-service";
import { TranslationService } from "./services/translation-service/translation-service";
import { BusinessPartnerSelectionComponent } from "./components/business-partner-selection/business-partner-selection.component";
import { FormsModule } from "@angular/forms";
import { ErrorResponseHandlerService } from "./services/error-response-handler-service/error-response-handler-service";
import { SnackbarComponent } from "./components/snackbar/snackbar.component";
import { NotificationService } from "./services/notification-service/notification.service";
import { MainContractSelectionComponent } from "./components/main-contract-selection/main-contract-selection.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { DialogModule, WindowModule } from "@progress/kendo-angular-dialog";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { SharePageComponent } from "./components/share-page-component/share-page.component";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ClipboardModule } from "@angular/cdk/clipboard"
import { MultiEmailValidatorDirective } from "./validators/multi-email-validator.directive";
import { AddUserFavoriteComponent } from "./components/add-user-favorite-component/add-user-favorite.component";
import { ForbiddenNamesValidatorDirective } from "./validators/forbidden-names-validator.directive";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog-component/confirmation-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { TrimDirective } from "./directives/trim.directive";
import { DateRangeValidatorDirective } from "./validators/date-range-validator.directive";
import { UrlValidatorDirective } from "./validators/url-validator.directive";
import { EndDateValidatorDirective } from "./validators/end-date-validator.directive";
import { StartDateValidatorDirective } from "./validators/start-date-validator.directive";
import { MaxVersionValidatorDirective } from "./validators/max-version-validator.directive";
import { MinVersionValidatorDirective } from "./validators/min-version-validator.directive";
import { MigrationStepNumberValidatorDirective } from "./validators/migration-step-number-validator.directive";
import { KendoTexboxDirective } from "./directives/kendo-textbox.directive";
import { ProductDocSearchResultComponent } from "./components/search-results/product-documentation/product-doc-search-result.component";
import { MarketingDocSearchResultComponent } from "./components/search-results/marketing-documentation/marketing-doc-search-result.component";
import { ProductReleaseSearchResultComponent } from "./components/search-results/product-release/product-release-search-result.component";
import { PasswordMatchValidatorDirective } from "./validators/password-match-validator.directive";
import { DataConversionService } from "./services/data-conversion-service/data-conversion-service";
import { PasswordPolicyValidatorDirective } from "./validators/password-policy-validator.directive";
import { VisibleIfDirective } from "./directives/visible-if.directive";
import { EnabledSelectionOptionValidatorDirective } from "./validators/enabled-selection-option-validator.directive";
import { SelectedActivityCategoryValidatorDirective } from "./validators/selected-activity-category-validator.directive";
import { KendoDropdownListAutofocusDirective } from "./directives/kendo-dropdownlist-autofocus.directive";
import { TimeWithoutSecondsPipe } from "./pipes/time-without-seconds.pipe";
import { VideoFileSearchResultComponent } from "./components/search-results/video-file/video-file-search-result.component";
import { VideoPlayerComponent } from "./components/video-player-component/video-player.component";
import { ListViewModule } from "@progress/kendo-angular-listview";
import { FileDownloadComponent } from "./components/file-download-component/file-download-component";
import { JoinTitlesPipe } from "./pipes/join-titles-pipe";
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { UserRatingComponent } from "./components/user-rating-component/user-rating.component";
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { UrlsValidatorDirective } from "./validators/urls-validator.directive";

@NgModule({
    imports:[
        CommonModule,
        FormsModule,
        LabelModule,
        TranslateModule,
        InputsModule,
        DropDownsModule,
        IndicatorsModule,
        MatSnackBarModule,
        DialogModule,
        ButtonsModule,
        MatDialogModule,
        ClipboardModule,
        WindowModule,
        ListViewModule,
        TooltipModule,
        ProgressBarModule
    ],
    declarations:[
        BusinessPartnerSelectionComponent,
        ConfirmationDialogComponent,
        MainContractSelectionComponent,
        SnackbarComponent,
        SharePageComponent,
        MultiEmailValidatorDirective,
        AddUserFavoriteComponent,
        ForbiddenNamesValidatorDirective,
        TrimDirective,
        DateRangeValidatorDirective,
        UrlValidatorDirective,
        UrlsValidatorDirective,
        EndDateValidatorDirective,
        StartDateValidatorDirective,
        MinVersionValidatorDirective,
        MaxVersionValidatorDirective,
        MigrationStepNumberValidatorDirective,
        KendoTexboxDirective,
        ProductDocSearchResultComponent,
        MarketingDocSearchResultComponent,
        ProductReleaseSearchResultComponent,
        PasswordMatchValidatorDirective,
        PasswordPolicyValidatorDirective,
        EnabledSelectionOptionValidatorDirective,
        VisibleIfDirective,
        PasswordMatchValidatorDirective,
        SelectedActivityCategoryValidatorDirective,
        KendoDropdownListAutofocusDirective,
        TimeWithoutSecondsPipe,
        VideoFileSearchResultComponent,
        VideoPlayerComponent,
        FileDownloadComponent,
        JoinTitlesPipe,
        UserRatingComponent
    ],
    providers:[
        LoggerService,
        TranslationService,
        ErrorResponseHandlerService,
        NotificationService,
        DataConversionService
    ],
    exports:[
        BusinessPartnerSelectionComponent,
        ConfirmationDialogComponent,
        MainContractSelectionComponent,
        SharePageComponent,
        AddUserFavoriteComponent,
        ForbiddenNamesValidatorDirective,
        TrimDirective,
        DateRangeValidatorDirective,
        UrlValidatorDirective,
        UrlsValidatorDirective,
        EndDateValidatorDirective,
        StartDateValidatorDirective,
        MinVersionValidatorDirective,
        MaxVersionValidatorDirective,
        MigrationStepNumberValidatorDirective,
        KendoTexboxDirective,
        ProductDocSearchResultComponent,
        MarketingDocSearchResultComponent,
        ProductReleaseSearchResultComponent,
        PasswordMatchValidatorDirective,
        PasswordPolicyValidatorDirective,
        EnabledSelectionOptionValidatorDirective,
        VisibleIfDirective,
        PasswordMatchValidatorDirective,
        SelectedActivityCategoryValidatorDirective,
        KendoDropdownListAutofocusDirective,
        TimeWithoutSecondsPipe,
        VideoFileSearchResultComponent,
        VideoPlayerComponent,
        FileDownloadComponent,
        JoinTitlesPipe,
        TooltipModule,
        UserRatingComponent
    ]
})
export class SharedModule{

}