import { inject } from "@angular/core";
import { ActivatedRoute, CanActivateFn, Router } from "@angular/router";
import { FileSaverService } from "../file-saver-service/file-saver-service";
import { FileStorageService, InfoService, MarketingMaterialService } from "src/app/api/opal-partner-center/services";
import { CONSTANTS } from "../../constants";
import { catchError, firstValueFrom, map } from "rxjs";
import { SECURITY_ROUTES } from "src/app/security/security.routing.constants";
import { LAYOUT_ROUTES } from "src/app/layout/layout.routing.constants";
import { BusyIndicationService } from "../busy-indication-service/busy-indication-service";
import { LOCALIZE_CONSTANTS } from "../../localize.constants";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { ErrorResponseHandlerService } from "../error-response-handler-service/error-response-handler-service";

export const FileDownloadGuard: CanActivateFn = async (route, state) => {

  const _fileSaverService = inject(FileSaverService);
  const _fileStorageService = inject(FileStorageService);
  const _infoService = inject(InfoService);
  const _router = inject(Router);
  const _marketingMaterialService = inject(MarketingMaterialService);
  const _busyIndicationService = inject(BusyIndicationService);
  const _httpClient = inject(HttpClient);
  const _errorResponseHandlerService = inject(ErrorResponseHandlerService);

  var isAuthenticatedResult = _infoService.getApiIsAuthenticated().pipe(
    map((response) => true),
    catchError(() => {
      return [false];
    })
  );

  var isAuthenticated = await firstValueFrom(isAuthenticatedResult);

  if(isAuthenticated){
  
    let fileCategory = route.queryParams[CONSTANTS.QUERY_PARAMS.FILE_CATEGORY];
    let fileGuid = route.queryParams[CONSTANTS.QUERY_PARAMS.FILE_GUID];
    
    if(fileCategory && fileGuid){
  
      try{
  
        if(fileCategory === CONSTANTS.QUERY_PARAM_VALUES.FILE_CATEGORY_GENERAL){
  
          _busyIndicationService.Show(LOCALIZE_CONSTANTS.MESSAGES.DOWNLOADING_FILE)

          let fileDownloadSubscription = _fileStorageService.getApiFiles(fileGuid).subscribe({
            next: async (response) => {

              _fileSaverService.saveAs(response.fileContent as ArrayBuffer, response.fileName as string);
              fileDownloadSubscription.unsubscribe();

              _busyIndicationService.Hide();
              if(_router.url == "/"){
                _router.navigate([LAYOUT_ROUTES.DASHBOARD_WELCOME]);
              }

            },
            error: (error) => {
              fileDownloadSubscription.unsubscribe();
              _busyIndicationService.Hide();
              console.error(error);
            }
          });
        } else if(fileCategory === CONSTANTS.QUERY_PARAM_VALUES.FILE_CATEGORY_MARKETING){

          _busyIndicationService.Show(LOCALIZE_CONSTANTS.MESSAGES.DOWNLOADING_FILE)

          if(environment.deployment == CONSTANTS.DEPLOYMENT_TYPE.CLOUD){

            _marketingMaterialService.getApiMarketingMaterialDownloadLink(fileGuid!).subscribe({
              next: response => {

                  _httpClient.get(response.fileDownloadLink?.downloadLink!, {responseType: 'blob'}).subscribe({
                      next: async blobResponse => {
                  
                          var reader = new FileReader();
                          reader.readAsDataURL(blobResponse as Blob);
                          reader.onloadend = () => {
                              _fileSaverService.saveAs(reader.result as ArrayBuffer, response.fileDownloadLink?.fileName as string);
                          }
                                                          
                          _busyIndicationService.Hide();
                      },
                      error: errorResponse => {
                          _busyIndicationService.Hide();
                          _errorResponseHandlerService.handleHttpErrorResponse(errorResponse);
                      }
                  });
              },
              error: errorResponse => {
                  _busyIndicationService.Hide();
                  _errorResponseHandlerService.handleHttpErrorResponse(errorResponse);
              }
          });

          } else {
            let fileDownloadSubscription = _marketingMaterialService.getApiMarketingMaterialDownload(fileGuid).subscribe({
              next: async (response) => {
  
                _fileSaverService.saveAs(response.fileContent as ArrayBuffer, response.fileName as string);
                fileDownloadSubscription.unsubscribe();
  
                _busyIndicationService.Hide();
  
                if(_router.url == "/"){
                  _router.navigate([LAYOUT_ROUTES.DASHBOARD_WELCOME]);
                }
  
              },
              error: (error) => {
                fileDownloadSubscription.unsubscribe();
                console.error(error);
                _busyIndicationService.Hide();
              }
            });
          }
        }
        
      } catch (e) {
        _busyIndicationService.Hide();
        console.error(e);}
  
    } else {
      console.warn('Missing file category or file guid query parameters.');
    }
  } else {
    _router.navigate([SECURITY_ROUTES.SECURITY_LOGIN], { queryParams: { postLoginRoute: state.url } });
  }

  return false;
}