import { TranslateService } from "@ngx-translate/core";
import { LOCALIZE_CONSTANTS } from "../localize.constants";

export class ProductVersionOption {

    //#region Constructor

    constructor(private _translateService: TranslateService, 
                productVersion: string, 
                isLatestVersion: boolean) {

        this.value  = productVersion;
        this.isLatestVersion = isLatestVersion;
        
        if (isLatestVersion) {
            this._translateService.get(LOCALIZE_CONSTANTS.COMMON.LATEST_RELEASE_INDICATOR).subscribe(translation => {
                this.title = `${translation} (${productVersion})`;
            });

        } else {
            this.title = productVersion;
        }
    }

    //#endregion

    //#region Properties

    /**
     * Product version.
     */
    value: string;

    /**
     * Product version title.
     */
    title?: string

    /**
     * Determines if version is the latest.
     */
    isLatestVersion: boolean;

    //#endregion
}
