/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateDirectoryResponse } from '../models/create-directory-response';
import { CreateDirectoryRequest } from '../models/create-directory-request';
import { BaseResponse } from '../models/base-response';
import { DeleteDirectoryRequest } from '../models/delete-directory-request';
import { UpdateDirectoryRequest } from '../models/update-directory-request';
import { UploadFileResponse } from '../models/upload-file-response';
import { UploadFileRequest } from '../models/upload-file-request';
import { DownloadFileResponse } from '../models/download-file-response';
import { DeleteFileRequest } from '../models/delete-file-request';
import { UpdateFileRequest } from '../models/update-file-request';
import { GetFileLinkResponse } from '../models/get-file-link-response';
import { GetFileStorageEntityCategoriesResponse } from '../models/get-file-storage-entity-categories-response';
import { AddFileStorageEntityCategoryResponse } from '../models/add-file-storage-entity-category-response';
import { AddFileStorageEntityCategoryRequest } from '../models/add-file-storage-entity-category-request';
import { DeleteFileStorageEntityCategoryRequest } from '../models/delete-file-storage-entity-category-request';
import { GetFileStorageEntityDetailsResponse } from '../models/get-file-storage-entity-details-response';
import { GetFileStorageFileTypesResponse } from '../models/get-file-storage-file-types-response';
import { GetProductsInfosResponse } from '../models/get-products-infos-response';
import { GetFileStorageEntityTagsResponse } from '../models/get-file-storage-entity-tags-response';
import { AddFileStorageEntityTagResponse } from '../models/add-file-storage-entity-tag-response';
import { AddFileStorageEntityTagRequest } from '../models/add-file-storage-entity-tag-request';
import { DeleteFileStorageEntityTagRequest } from '../models/delete-file-storage-entity-tag-request';
@Injectable({
  providedIn: 'root',
})
class FileStorageService extends __BaseService {
  static readonly postApiDirectoriesPath = '/api/directories';
  static readonly deleteApiDirectoriesPath = '/api/directories';
  static readonly putApiDirectoriesPath = '/api/directories';
  static readonly postApiFilesPath = '/api/files';
  static readonly getApiFilesPath = '/api/files';
  static readonly deleteApiFilesPath = '/api/files';
  static readonly putApiFilesPath = '/api/files';
  static readonly getApiFileDownloadLinkPath = '/api/fileDownloadLink';
  static readonly getApiFileStorageEntityCategoriesPath = '/api/fileStorageEntityCategories';
  static readonly postApiFileStorageEntityCategoriesPath = '/api/fileStorageEntityCategories';
  static readonly deleteApiFileStorageEntityCategoriesPath = '/api/fileStorageEntityCategories';
  static readonly getApiFileStorageEntityDetailsPath = '/api/fileStorageEntityDetails';
  static readonly getApiFileStorageFileTypesPath = '/api/fileStorageFileTypes';
  static readonly getApiFileStorageProductsPath = '/api/fileStorageProducts';
  static readonly getApiFileStorageEntityTagsPath = '/api/fileStorageEntityTags';
  static readonly postApiFileStorageEntityTagsPath = '/api/fileStorageEntityTags';
  static readonly deleteApiFileStorageEntityTagsPath = '/api/fileStorageEntityTags';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiDirectoriesResponse(body?: CreateDirectoryRequest): __Observable<__StrictHttpResponse<CreateDirectoryResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/directories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateDirectoryResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiDirectories(body?: CreateDirectoryRequest): __Observable<CreateDirectoryResponse> {
    return this.postApiDirectoriesResponse(body).pipe(
      __map(_r => _r.body as CreateDirectoryResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  deleteApiDirectoriesResponse(body?: DeleteDirectoryRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/directories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  deleteApiDirectories(body?: DeleteDirectoryRequest): __Observable<BaseResponse> {
    return this.deleteApiDirectoriesResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  putApiDirectoriesResponse(body?: UpdateDirectoryRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/directories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  putApiDirectories(body?: UpdateDirectoryRequest): __Observable<BaseResponse> {
    return this.putApiDirectoriesResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiFilesResponse(body?: UploadFileRequest): __Observable<__StrictHttpResponse<UploadFileResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadFileResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiFiles(body?: UploadFileRequest): __Observable<UploadFileResponse> {
    return this.postApiFilesResponse(body).pipe(
      __map(_r => _r.body as UploadFileResponse)
    );
  }

  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiFilesResponse(FileGuid: string): __Observable<__StrictHttpResponse<DownloadFileResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (FileGuid != null) __params = __params.set('FileGuid', FileGuid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DownloadFileResponse>;
      })
    );
  }
  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiFiles(FileGuid: string): __Observable<DownloadFileResponse> {
    return this.getApiFilesResponse(FileGuid).pipe(
      __map(_r => _r.body as DownloadFileResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  deleteApiFilesResponse(body?: DeleteFileRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  deleteApiFiles(body?: DeleteFileRequest): __Observable<BaseResponse> {
    return this.deleteApiFilesResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  putApiFilesResponse(body?: UpdateFileRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  putApiFiles(body?: UpdateFileRequest): __Observable<BaseResponse> {
    return this.putApiFilesResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiFileDownloadLinkResponse(FileGuid: string): __Observable<__StrictHttpResponse<GetFileLinkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (FileGuid != null) __params = __params.set('FileGuid', FileGuid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/fileDownloadLink`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetFileLinkResponse>;
      })
    );
  }
  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiFileDownloadLink(FileGuid: string): __Observable<GetFileLinkResponse> {
    return this.getApiFileDownloadLinkResponse(FileGuid).pipe(
      __map(_r => _r.body as GetFileLinkResponse)
    );
  }

  /**
   * @return OK
   */
  getApiFileStorageEntityCategoriesResponse(): __Observable<__StrictHttpResponse<GetFileStorageEntityCategoriesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/fileStorageEntityCategories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetFileStorageEntityCategoriesResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiFileStorageEntityCategories(): __Observable<GetFileStorageEntityCategoriesResponse> {
    return this.getApiFileStorageEntityCategoriesResponse().pipe(
      __map(_r => _r.body as GetFileStorageEntityCategoriesResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiFileStorageEntityCategoriesResponse(body?: AddFileStorageEntityCategoryRequest): __Observable<__StrictHttpResponse<AddFileStorageEntityCategoryResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/fileStorageEntityCategories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddFileStorageEntityCategoryResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiFileStorageEntityCategories(body?: AddFileStorageEntityCategoryRequest): __Observable<AddFileStorageEntityCategoryResponse> {
    return this.postApiFileStorageEntityCategoriesResponse(body).pipe(
      __map(_r => _r.body as AddFileStorageEntityCategoryResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  deleteApiFileStorageEntityCategoriesResponse(body?: DeleteFileStorageEntityCategoryRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/fileStorageEntityCategories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  deleteApiFileStorageEntityCategories(body?: DeleteFileStorageEntityCategoryRequest): __Observable<BaseResponse> {
    return this.deleteApiFileStorageEntityCategoriesResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiFileStorageEntityDetailsResponse(FileGuid: string): __Observable<__StrictHttpResponse<GetFileStorageEntityDetailsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (FileGuid != null) __params = __params.set('FileGuid', FileGuid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/fileStorageEntityDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetFileStorageEntityDetailsResponse>;
      })
    );
  }
  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiFileStorageEntityDetails(FileGuid: string): __Observable<GetFileStorageEntityDetailsResponse> {
    return this.getApiFileStorageEntityDetailsResponse(FileGuid).pipe(
      __map(_r => _r.body as GetFileStorageEntityDetailsResponse)
    );
  }

  /**
   * @return OK
   */
  getApiFileStorageFileTypesResponse(): __Observable<__StrictHttpResponse<GetFileStorageFileTypesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/fileStorageFileTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetFileStorageFileTypesResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiFileStorageFileTypes(): __Observable<GetFileStorageFileTypesResponse> {
    return this.getApiFileStorageFileTypesResponse().pipe(
      __map(_r => _r.body as GetFileStorageFileTypesResponse)
    );
  }

  /**
   * @return OK
   */
  getApiFileStorageProductsResponse(): __Observable<__StrictHttpResponse<GetProductsInfosResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/fileStorageProducts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductsInfosResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiFileStorageProducts(): __Observable<GetProductsInfosResponse> {
    return this.getApiFileStorageProductsResponse().pipe(
      __map(_r => _r.body as GetProductsInfosResponse)
    );
  }

  /**
   * @return OK
   */
  getApiFileStorageEntityTagsResponse(): __Observable<__StrictHttpResponse<GetFileStorageEntityTagsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/fileStorageEntityTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetFileStorageEntityTagsResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiFileStorageEntityTags(): __Observable<GetFileStorageEntityTagsResponse> {
    return this.getApiFileStorageEntityTagsResponse().pipe(
      __map(_r => _r.body as GetFileStorageEntityTagsResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiFileStorageEntityTagsResponse(body?: AddFileStorageEntityTagRequest): __Observable<__StrictHttpResponse<AddFileStorageEntityTagResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/fileStorageEntityTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddFileStorageEntityTagResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiFileStorageEntityTags(body?: AddFileStorageEntityTagRequest): __Observable<AddFileStorageEntityTagResponse> {
    return this.postApiFileStorageEntityTagsResponse(body).pipe(
      __map(_r => _r.body as AddFileStorageEntityTagResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  deleteApiFileStorageEntityTagsResponse(body?: DeleteFileStorageEntityTagRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/fileStorageEntityTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  deleteApiFileStorageEntityTags(body?: DeleteFileStorageEntityTagRequest): __Observable<BaseResponse> {
    return this.deleteApiFileStorageEntityTagsResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }
}

module FileStorageService {
}

export { FileStorageService }
