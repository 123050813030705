export class PageTitleClickedMessage {

    //#region Constructor

    constructor(pageTitleSegment: string) {
        this.pageTitleSegment = pageTitleSegment;
    }

    //#endregion

    //#region Properties

    /**
     * Part of page title text that was clicked.
     */
    pageTitleSegment: string;

    //#endregion
}
