import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/guards/auth.guard';
import { RouteGuard } from '../shared/services/guards/route.guard';
import { LayoutComponent } from './layout.component';
import { LAYOUT_ROUTES } from './layout.routing.constants';
import { FileDownloadGuard } from '../shared/services/guards/file-download-guard';
import { FileDownloadComponent } from '../shared/components/file-download-component/file-download-component';


const routes: Routes = [
  { 
    path: "",
    component: LayoutComponent,
    children:[
    {
      path: LAYOUT_ROUTES.DASHBOARD,
      loadChildren: ()=>import('../dashboard/dashboard.module').then(m=>m.DashboardModule),
      canActivate: [AuthGuard, RouteGuard]
    },
    {
      path: LAYOUT_ROUTES.ACCOUNT,
      loadChildren: ()=>import('../account/account.module').then(m=>m.AccountModule),
      canActivate: [AuthGuard, RouteGuard]
    },
    {
      path: LAYOUT_ROUTES.SOFTWARE,
      loadChildren: ()=>import('../software/software.module').then(m=>m.SoftwareModule),
      canActivate: [AuthGuard, RouteGuard]
    },
    {
      path: LAYOUT_ROUTES.HELP_CENTER,
      loadChildren: ()=>import('../help-center/help-center.module').then(m=>m.HelpCenterModule),
      canActivate: [AuthGuard, RouteGuard]
    },
    {
      path: LAYOUT_ROUTES.ADMINISTRATION,
      loadChildren: ()=>import('../administration/administration.module').then(m=>m.AdmnistrationModule),
      canActivate: [AuthGuard, RouteGuard]
    },
    {
      path: LAYOUT_ROUTES.GENERAL_SEARCH,
      loadChildren: ()=>import('../general-search/general-search.module').then(m=>m.GeneralSearchModule),
      canActivate: [AuthGuard, RouteGuard]
    },
    {
      path: LAYOUT_ROUTES.MARKETING_RESOURCES,
      loadChildren: ()=>import('../marketing-resources/marketing-resources.module').then(m=>m.MarketingModule),
      canActivate: [AuthGuard, RouteGuard]
    },
    {
      path: LAYOUT_ROUTES.AUDIT_TRAIL,
      loadChildren: ()=>import('../audit-trail/audit-trail.module').then(m=>m.AuditTrailModule),
      canActivate: [AuthGuard, RouteGuard]
    },
    {
      path: LAYOUT_ROUTES.ADMINISTRATIVE_NOTIFICATIONS,
      loadChildren: ()=>import('../administrative-notifications/administrative-notifications.module').then(m=>m.AdministrativeNotificationsModule),
      canActivate: [AuthGuard, RouteGuard]
    },
    {
      path: LAYOUT_ROUTES.CUSTOMERS_OVERVIEW,
      loadChildren: ()=>import('../customers-overview/customers-overview.module').then(m=>m.CustomersOverviewModule),
      canActivate: [AuthGuard, RouteGuard]
    },
    {
      path: LAYOUT_ROUTES.USER_GUIDE,
      loadChildren: ()=>import('../user-guide/user-guide.module').then(m=>m.UserGuideModule),
      canActivate: [AuthGuard, RouteGuard]
    },
    {
      path: '',
      redirectTo: LAYOUT_ROUTES.DASHBOARD,
      pathMatch: 'full'
    },
    {
      path: LAYOUT_ROUTES.FILE_DOWNLOAD,
      component: FileDownloadComponent,
      canActivate: [FileDownloadGuard],
      pathMatch: 'full'
    }
  ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
