/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BaseResponse } from '../models/base-response';
import { PermissionsChangeRequest } from '../models/permissions-change-request';
import { RolesChangeRequest } from '../models/roles-change-request';
import { GetNotificationDetailsResponse } from '../models/get-notification-details-response';
@Injectable({
  providedIn: 'root',
})
class AdminNotificationsService extends __BaseService {
  static readonly postApiRequestPermissionsChangePath = '/api/requestPermissionsChange';
  static readonly postApiRequestRolesChangePath = '/api/requestRolesChange';
  static readonly getApiAdminNotificationDetailsPath = '/api/adminNotificationDetails';
  static readonly putApiAdminNotificationsPath = '/api/adminNotifications';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiRequestPermissionsChangeResponse(body?: PermissionsChangeRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/requestPermissionsChange`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiRequestPermissionsChange(body?: PermissionsChangeRequest): __Observable<BaseResponse> {
    return this.postApiRequestPermissionsChangeResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiRequestRolesChangeResponse(body?: RolesChangeRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/requestRolesChange`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiRequestRolesChange(body?: RolesChangeRequest): __Observable<BaseResponse> {
    return this.postApiRequestRolesChangeResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param Id undefined
   * @return OK
   */
  getApiAdminNotificationDetailsResponse(Id: number): __Observable<__StrictHttpResponse<GetNotificationDetailsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Id != null) __params = __params.set('Id', Id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/adminNotificationDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetNotificationDetailsResponse>;
      })
    );
  }
  /**
   * @param Id undefined
   * @return OK
   */
  getApiAdminNotificationDetails(Id: number): __Observable<GetNotificationDetailsResponse> {
    return this.getApiAdminNotificationDetailsResponse(Id).pipe(
      __map(_r => _r.body as GetNotificationDetailsResponse)
    );
  }

  /**
   * @param params The `AdminNotificationsService.PutApiAdminNotificationsParams` containing the following parameters:
   *
   * - `IsConfirmed`:
   *
   * - `Id`:
   *
   * @return OK
   */
  putApiAdminNotificationsResponse(params: AdminNotificationsService.PutApiAdminNotificationsParams): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.IsConfirmed != null) __params = __params.set('IsConfirmed', params.IsConfirmed.toString());
    if (params.Id != null) __params = __params.set('Id', params.Id.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/adminNotifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param params The `AdminNotificationsService.PutApiAdminNotificationsParams` containing the following parameters:
   *
   * - `IsConfirmed`:
   *
   * - `Id`:
   *
   * @return OK
   */
  putApiAdminNotifications(params: AdminNotificationsService.PutApiAdminNotificationsParams): __Observable<BaseResponse> {
    return this.putApiAdminNotificationsResponse(params).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }
}

module AdminNotificationsService {

  /**
   * Parameters for putApiAdminNotifications
   */
  export interface PutApiAdminNotificationsParams {
    IsConfirmed: boolean;
    Id: number;
  }
}

export { AdminNotificationsService }
