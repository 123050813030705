/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FileStorageEntityBase } from '../models/file-storage-entity-base';
import { FileStorageEntityCategoryInfo } from '../models/file-storage-entity-category-info';
import { FileStorageEntityTagInfo } from '../models/file-storage-entity-tag-info';
@Injectable({
  providedIn: 'root',
})
class FileStorageODataService extends __BaseService {
  static readonly getApiFileStoragePath = '/api/fileStorage';
  static readonly getApiFileCategoriesPath = '/api/fileCategories';
  static readonly getApiFileTagsPath = '/api/fileTags';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  getApiFileStorageResponse(): __Observable<__StrictHttpResponse<Array<FileStorageEntityBase>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/fileStorage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FileStorageEntityBase>>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiFileStorage(): __Observable<Array<FileStorageEntityBase>> {
    return this.getApiFileStorageResponse().pipe(
      __map(_r => _r.body as Array<FileStorageEntityBase>)
    );
  }

  /**
   * @return OK
   */
  getApiFileCategoriesResponse(): __Observable<__StrictHttpResponse<Array<FileStorageEntityCategoryInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/fileCategories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FileStorageEntityCategoryInfo>>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiFileCategories(): __Observable<Array<FileStorageEntityCategoryInfo>> {
    return this.getApiFileCategoriesResponse().pipe(
      __map(_r => _r.body as Array<FileStorageEntityCategoryInfo>)
    );
  }

  /**
   * @return OK
   */
  getApiFileTagsResponse(): __Observable<__StrictHttpResponse<Array<FileStorageEntityTagInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/fileTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FileStorageEntityTagInfo>>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiFileTags(): __Observable<Array<FileStorageEntityTagInfo>> {
    return this.getApiFileTagsResponse().pipe(
      __map(_r => _r.body as Array<FileStorageEntityTagInfo>)
    );
  }
}

module FileStorageODataService {
}

export { FileStorageODataService }
