/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserGroupInfoBase } from '../models/user-group-info-base';
@Injectable({
  providedIn: 'root',
})
class UserGroupsODataService extends __BaseService {
  static readonly getApiRegisteredGroupsPath = '/api/registeredGroups';
  static readonly getApiGroupsPath = '/api/groups';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  getApiRegisteredGroupsResponse(): __Observable<__StrictHttpResponse<Array<UserGroupInfoBase>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/registeredGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserGroupInfoBase>>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiRegisteredGroups(): __Observable<Array<UserGroupInfoBase>> {
    return this.getApiRegisteredGroupsResponse().pipe(
      __map(_r => _r.body as Array<UserGroupInfoBase>)
    );
  }

  /**
   * @return OK
   */
  getApiGroupsResponse(): __Observable<__StrictHttpResponse<Array<UserGroupInfoBase>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserGroupInfoBase>>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiGroups(): __Observable<Array<UserGroupInfoBase>> {
    return this.getApiGroupsResponse().pipe(
      __map(_r => _r.body as Array<UserGroupInfoBase>)
    );
  }
}

module UserGroupsODataService {
}

export { UserGroupsODataService }
