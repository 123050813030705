import { UserGroupInfoBase, UserInfo } from "src/app/api/opal-partner-center/models";
import { IChildChangedReceiver } from "../interfaces/i-child-changed-receiver";
import { UserPermission } from "./user-permission";
import { UserRole } from "./user-role";
import { UserSecuritySettingsInfo } from "./user-security-settings-info";

export class UserDetails implements IChildChangedReceiver{

  //#region Private Fields

  /**
   * Original value of the isEnabled property.
   */
  private _isEnabledOrig: boolean;

  /**
   * Determines if user is enabled.
   */
  private _isEnabled : boolean;

  /**
   * Original value of isLockedOut property.
   */
  private _isLockedOutOrig : boolean;

  /**
   * Determines if user is locked out.
   */
  private _isLockedOut : boolean;

  //#endregion

  //#region Constructor

  constructor(userInfo: UserInfo, allCustomerRelatedPermissions: Array<UserPermission>, allCustomerRelatedRoles: Array<UserRole>){

    this.isChanged = false;
    this.commonSettingsChanged = false;
    this.isEnabledChanged = false;
    this.isLockedOutChanged = false;
    this.businessPartnerName = userInfo.businessPartnerName;
    this.country = userInfo.country;
    this.email = userInfo.email;
    this.firstName = userInfo.firstName;
    this.lastName = userInfo.lastName;

    if(this.firstName && this.lastName){
      this.initials = this.firstName.charAt(0) + " " + this.lastName.charAt(0);
    } else {
      this.initials = undefined;
    }

    this.isAdmin = userInfo.isAdmin;
    this._isEnabled = userInfo.isEnabled!;
    this._isEnabledOrig = userInfo.isEnabled!
    this._isLockedOutOrig = userInfo.isLockedOut!;
    this._isLockedOut = userInfo.isLockedOut!
    this.emailConfirmed = userInfo.emailConfirmed;
    this.language = userInfo.language;
    this.phoneNumber = userInfo.phoneNumber;
    this.representativeCompanyName = userInfo.representativeCompanyName;
    this.userName = userInfo.userName;
    this.location = userInfo.location;
    this.securitySettings = new UserSecuritySettingsInfo(userInfo.securitySettings!, allCustomerRelatedPermissions, allCustomerRelatedRoles, this);
    this.securitySettingsChanged = false;
    this.userGroups = userInfo.userGroups;
    this.isInternalUser = userInfo.isInternalUser;
    this.isCustomerUser = userInfo.isCustomerUser;
    this.isInterestedPartyUser = userInfo.isInterestedPartyUser;
    this.isBusinessPartnerUser = userInfo.isBusinessPartnerUser;
    this.isIntroVideoShown = userInfo.isIntroVideoShown;
  }

  //#endregion

  //#region IChildChangedReceiver

  notifyChildChanged(): void {
    this.checkChanges();
  }
  
  //#endregion

  //#region Properties

  /**
   * Business partner name.
   */
  public businessPartnerName?: string;

  /**
   * Country.
   */
  public country?: string;

  /**
   * User email.
   */
  public email?: string;

  /**
   * User first name.
   */
  public firstName?: string;

  /**
   * User last name.
   */
  public lastName?: string;

  /**
   * User initials.
   */
  public initials?: string;

  /**
   * Determines if user is admin.
   */
  public isAdmin?: boolean;

  /**
   * Gets information if user is enabled.
   */
  public get isEnabled() : boolean {
    return this._isEnabled;
  }

  /**
   * Sets information if user is enabled.
   */
  public set isEnabled(isEnabled : boolean) {
    this._isEnabled = isEnabled;
    this.checkChanges();
  }

  /**
   * Gets information if user is locked out.
   */
  public get isLockedOut() : boolean {
    return this._isLockedOut;
  }

  /**
   * Sets information if user is locked out.
   */
  public set isLockedOut(isLockedOut : boolean) {
    this._isLockedOut = isLockedOut;
    this.checkChanges();
  }

  /**
   * User preferred language.
   */
  public language?: string;

  /**
   * User location.
   */
  public location?: string;

  /**
   * User phone number.
   */
  public phoneNumber?: string;

  /**
   * Representative company name.
   */
  public representativeCompanyName?: string;

  /**
   * User name.
   */
  public userName?: string;

  /**
   * Determines if user details have changed.
   */
  public isChanged: boolean;

  /**
   * User security settings.
   */
  public securitySettings?: UserSecuritySettingsInfo;

  /**
   * Determines if user security settings have changed.
   */
  public securitySettingsChanged: boolean;

  /**
   * Determines if common settings have changed.
   */
  public commonSettingsChanged: boolean;

  /**
   * Determines if isEnabled property has changed.
   */
  public isEnabledChanged: boolean;

  /**
   * Determines if isLockedOut property has changed.
   */
  public isLockedOutChanged: boolean;

  /**
   * Determines if user email is confirmed.
   */
  public emailConfirmed?: boolean;

  /**
   * Determines if user is internal user.
   */
  public isInternalUser?: boolean;

  /**
   * Determines if user is customer user.
   */
  public isCustomerUser?: boolean;

  /**
   * Determines if user is interested party user.
   */
  public isInterestedPartyUser?: boolean;

  /**
   * Determines if user is business partner user.
   */
  public isBusinessPartnerUser?: boolean;

  /**
   * Flag that indicates if user saw or was notified about Partner Center intro video.
   */
  public isIntroVideoShown: boolean | undefined;

  /**
   * User groups.
   */
  public userGroups?: Array<UserGroupInfoBase>;

  //#endregion

  //#region Public Methods

  public canResetPassword(): boolean {
    if(this.emailConfirmed){
      return true;
    } else {
      return false;
    }
  }

  /**
   * Undos common settings changes.
   */
  public undoCommonSettingsChanges(){
    this.isEnabled = this._isEnabledOrig;
    this.isLockedOut = this._isLockedOutOrig;
  }

  /**
   * Undos all changes.
   */
  public undoAllChanges(){

    this.undoCommonSettingsChanges();
    this.securitySettings?.undoChanges();
  }

  //#endregion

  //#region Private Methods

  /**
   * Checks for changes in the properties.
   */
  private checkChanges(){
    
    this.securitySettingsChanged = this.securitySettings?.isChanged!;
    this.isEnabledChanged = this._isEnabled !== this._isEnabledOrig;
    this.isLockedOutChanged = this._isLockedOut !== this._isLockedOutOrig;
    this.commonSettingsChanged = this.isEnabledChanged || this.isLockedOutChanged;

    this.isChanged = this.commonSettingsChanged || 
                     this.securitySettingsChanged;
  }

  //#endregion
}