import { Clipboard } from "@angular/cdk/clipboard";
import { Component, Input, Output, EventEmitter} from "@angular/core";

import { NotificationsService } from "src/app/api/opal-partner-center/services";
import { LOCALIZE_CONSTANTS } from "../../localize.constants";
import { NotificationService } from "../../services/notification-service/notification.service";
import { ErrorResponseHandlerService } from "../../services/error-response-handler-service/error-response-handler-service";

@Component({
    selector: 'share-page',
    templateUrl: './share-page.component.html'
})
export class SharePageComponent{

    //#region Private fields

    /**
     * Clipboard.
     */
    private _clipboard: Clipboard;

    /**
     * Notification service (API).
     */
    private _notificationsApiService: NotificationsService;

    /**
     * Notification service.
     */
    private _notificationService: NotificationService;

    /**
     * Error response handler service.
     */
    private _errorResponseHandlerService: ErrorResponseHandlerService;

    //#endregion

    //#region Constructors

    constructor(clipboard: Clipboard, notificationsApiService: NotificationsService, notificationService: NotificationService, errorResponseHandlerService: ErrorResponseHandlerService) {
        this.sharedPageLink = "";
        this.sharedPageTitle = "";
        this.emailRecevers = "";
        this.message = "";
        this.emailSubject = "";
        this.isSendingEmail = false;
        this._notificationsApiService = notificationsApiService;
        this._notificationService = notificationService;
        this._errorResponseHandlerService = errorResponseHandlerService;
        this.isShown = false;
        this.dialogClosedEvent = new EventEmitter<void>();
        this._clipboard = clipboard;
    }

    //#endregion

    //#region Input Parameters

    /**
     * Shared page link.
     */
    @Input()
    public sharedPageLink: string;

    /**
     * Shared page title.
     */
    @Input()
    public sharedPageTitle: string;

    /**
     * Email subject
     */
    @Input()
    public emailSubject: string;

    /**
     * Determines if popup is shown.
     */
    @Input()
    public isShown: boolean;

    /**
     * Emits event when the popup is closed.
     */
    @Output()
    public dialogClosedEvent: EventEmitter<void>;

    /**
     * Column separated email addresses of receivers.
     */
    public emailRecevers: string;

    /**
     * Message to receivers.
     */
    public message: string;

    /**
     * Determines if sending email is in progress.
     */
    public isSendingEmail: boolean;

    //#endregion

    //#region UI Handlers

    /**
     * Shares page via email by using notifications service
     */
    public onSharePage(){

        this.isSendingEmail = true;
        var request: NotificationsService.PostApiSharePageParams = {} as NotificationsService.PostApiSharePageParams;

        request.EmailReceivers = this.emailRecevers.split(";").filter(x => x);
        request.Message = this.message;
        request.SharedPageTitle = this.sharedPageTitle;
        request.SharedPageLink = this.sharedPageLink;
        request.EmailSubject = this.emailSubject;
        
        this._notificationsApiService.postApiSharePage(request).subscribe({
            next: response=>{
                this.isSendingEmail = false;
                this.closeDialog();
                this._notificationService.notifySuccess(LOCALIZE_CONSTANTS.MESSAGES.PAGE_SHARED);
            },
            error: errorResponse=>{
                this.isSendingEmail = false;
                this.closeDialog();
                this._errorResponseHandlerService.handleHttpErrorResponse(errorResponse);
            }
        })
    }

    /**
     * Closes dialog (this page).
     */
    public closeDialog(){
        if(this.isSendingEmail){
            return;
        }

        this.isShown = false;
        this.dialogClosedEvent.emit();
    }

    /**
     * Copies shared page link to clipboard.
     */
    public onCopyToClipboard(){
        this._clipboard.copy(this.sharedPageLink);
        this._notificationService.notifySuccess(LOCALIZE_CONSTANTS.MESSAGES.LINK_COPIED);
    }
    
    //#endregion
}