export class PasswordValidationResult {
 
  //#region Constructor

  constructor(){
    this.minimalPasswordLengthValid = false;
    this.minimalNumberOfLowercaseCharactersValid = false;
    this.minimalNumberOfUppercaseCharactersValid = false;
    this.minimalNumberOfDigitsValid = false;
    this.minimalNumberOfSpecialCharactersValid = false;
  }

  //#endregion

  //#region Properties

  public minimalPasswordLengthValid: boolean;

  public minimalNumberOfLowercaseCharactersValid: boolean;

  public minimalNumberOfUppercaseCharactersValid: boolean;

  public minimalNumberOfDigitsValid: boolean;

  public minimalNumberOfSpecialCharactersValid: boolean;

  //#endregion

}