import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';

/**
 * Sets the authentication cookie for the request.
 */
@Injectable()
export class HttpSetCookieInterceptor implements HttpInterceptor{

    //#region Constructor

    constructor(private _tokenExtractor: HttpXsrfTokenExtractor){

    }

    //#endregion

    //#region HttpInterceptor

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Clone request and allow auth cookie to be send with every request.
        
        const cookieheaderName = 'X-XSRF-TOKEN';
        let csrfToken = this._tokenExtractor.getToken() as string;

        if (csrfToken !== null && !request.headers.has(cookieheaderName)) {
            request = request.clone({
                withCredentials: true,
                headers: request.headers.set(cookieheaderName, csrfToken)
            }); 
        } else {
            request = request.clone({
                withCredentials: true
            });
        }

        return next.handle(request);
    }

    //#endregion
}