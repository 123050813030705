/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BaseResponse } from '../models/base-response';
import { RegisterUserRequest } from '../models/register-user-request';
import { GetPasswordPolicyResponse } from '../models/get-password-policy-response';
@Injectable({
  providedIn: 'root',
})
class RegistrationService extends __BaseService {
  static readonly getApiConfirmEmailPath = '/api/confirmEmail';
  static readonly getApiConfirmUserRegistrationPath = '/api/confirmUserRegistration';
  static readonly postApiRegisterUserPath = '/api/registerUser';
  static readonly getApiPasswordPolicyPath = '/api/passwordPolicy';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RegistrationService.GetApiConfirmEmailParams` containing the following parameters:
   *
   * - `Username`:
   *
   * - `Token`:
   */
  getApiConfirmEmailResponse(params: RegistrationService.GetApiConfirmEmailParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Username != null) __params = __params.set('Username', params.Username.toString());
    if (params.Token != null) __params = __params.set('Token', params.Token.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/confirmEmail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RegistrationService.GetApiConfirmEmailParams` containing the following parameters:
   *
   * - `Username`:
   *
   * - `Token`:
   */
  getApiConfirmEmail(params: RegistrationService.GetApiConfirmEmailParams): __Observable<null> {
    return this.getApiConfirmEmailResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `RegistrationService.GetApiConfirmUserRegistrationParams` containing the following parameters:
   *
   * - `Username`:
   *
   * - `Token`:
   */
  getApiConfirmUserRegistrationResponse(params: RegistrationService.GetApiConfirmUserRegistrationParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Username != null) __params = __params.set('Username', params.Username.toString());
    if (params.Token != null) __params = __params.set('Token', params.Token.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/confirmUserRegistration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RegistrationService.GetApiConfirmUserRegistrationParams` containing the following parameters:
   *
   * - `Username`:
   *
   * - `Token`:
   */
  getApiConfirmUserRegistration(params: RegistrationService.GetApiConfirmUserRegistrationParams): __Observable<null> {
    return this.getApiConfirmUserRegistrationResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiRegisterUserResponse(body?: RegisterUserRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/registerUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiRegisterUser(body?: RegisterUserRequest): __Observable<BaseResponse> {
    return this.postApiRegisterUserResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @return OK
   */
  getApiPasswordPolicyResponse(): __Observable<__StrictHttpResponse<GetPasswordPolicyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/passwordPolicy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetPasswordPolicyResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiPasswordPolicy(): __Observable<GetPasswordPolicyResponse> {
    return this.getApiPasswordPolicyResponse().pipe(
      __map(_r => _r.body as GetPasswordPolicyResponse)
    );
  }
}

module RegistrationService {

  /**
   * Parameters for getApiConfirmEmail
   */
  export interface GetApiConfirmEmailParams {
    Username: string;
    Token: string;
  }

  /**
   * Parameters for getApiConfirmUserRegistration
   */
  export interface GetApiConfirmUserRegistrationParams {
    Username: string;
    Token: string;
  }
}

export { RegistrationService }
