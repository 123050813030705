/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetUserInfoResponse } from '../models/get-user-info-response';
import { GetUserAuthenticationSettingsResponse } from '../models/get-user-authentication-settings-response';
import { BaseResponse } from '../models/base-response';
import { UpdateUserAuthenticationSettingsRequest } from '../models/update-user-authentication-settings-request';
import { GetUserFavoritesResponse } from '../models/get-user-favorites-response';
import { AddUserFavoriteRequest } from '../models/add-user-favorite-request';
import { DeleteUserFavoriteRequest } from '../models/delete-user-favorite-request';
import { SetUserPasswordRequest } from '../models/set-user-password-request';
import { ChangePasswordRequest } from '../models/change-password-request';
@Injectable({
  providedIn: 'root',
})
class UsersService extends __BaseService {
  static readonly getApiUserInfoPath = '/api/userInfo';
  static readonly putApiAcceptPrivacyPolicyPath = '/api/acceptPrivacyPolicy';
  static readonly getApiUserAuthenticationSettingsPath = '/api/userAuthenticationSettings';
  static readonly putApiUserAuthenticationSettingsPath = '/api/userAuthenticationSettings';
  static readonly getApiUserFavoritesPath = '/api/userFavorites';
  static readonly postApiUserFavoritesPath = '/api/userFavorites';
  static readonly deleteApiUserFavoritesPath = '/api/userFavorites';
  static readonly putApiMarkIntroVideoShownPath = '/api/markIntroVideoShown';
  static readonly putApiResetPasswordPath = '/api/resetPassword';
  static readonly putApiChangeUserPasswordPath = '/api/changeUserPassword';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  getApiUserInfoResponse(): __Observable<__StrictHttpResponse<GetUserInfoResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/userInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetUserInfoResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiUserInfo(): __Observable<GetUserInfoResponse> {
    return this.getApiUserInfoResponse().pipe(
      __map(_r => _r.body as GetUserInfoResponse)
    );
  }
  putApiAcceptPrivacyPolicyResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/acceptPrivacyPolicy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  putApiAcceptPrivacyPolicy(): __Observable<null> {
    return this.putApiAcceptPrivacyPolicyResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return OK
   */
  getApiUserAuthenticationSettingsResponse(): __Observable<__StrictHttpResponse<GetUserAuthenticationSettingsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/userAuthenticationSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetUserAuthenticationSettingsResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiUserAuthenticationSettings(): __Observable<GetUserAuthenticationSettingsResponse> {
    return this.getApiUserAuthenticationSettingsResponse().pipe(
      __map(_r => _r.body as GetUserAuthenticationSettingsResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  putApiUserAuthenticationSettingsResponse(body?: UpdateUserAuthenticationSettingsRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/userAuthenticationSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  putApiUserAuthenticationSettings(body?: UpdateUserAuthenticationSettingsRequest): __Observable<BaseResponse> {
    return this.putApiUserAuthenticationSettingsResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @return OK
   */
  getApiUserFavoritesResponse(): __Observable<__StrictHttpResponse<GetUserFavoritesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/userFavorites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetUserFavoritesResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiUserFavorites(): __Observable<GetUserFavoritesResponse> {
    return this.getApiUserFavoritesResponse().pipe(
      __map(_r => _r.body as GetUserFavoritesResponse)
    );
  }

  /**
   * @param body undefined
   */
  postApiUserFavoritesResponse(body?: AddUserFavoriteRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/userFavorites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  postApiUserFavorites(body?: AddUserFavoriteRequest): __Observable<null> {
    return this.postApiUserFavoritesResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   */
  deleteApiUserFavoritesResponse(body?: DeleteUserFavoriteRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/userFavorites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  deleteApiUserFavorites(body?: DeleteUserFavoriteRequest): __Observable<null> {
    return this.deleteApiUserFavoritesResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return OK
   */
  putApiMarkIntroVideoShownResponse(): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/markIntroVideoShown`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  putApiMarkIntroVideoShown(): __Observable<BaseResponse> {
    return this.putApiMarkIntroVideoShownResponse().pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param body undefined
   */
  putApiResetPasswordResponse(body?: SetUserPasswordRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/resetPassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  putApiResetPassword(body?: SetUserPasswordRequest): __Observable<null> {
    return this.putApiResetPasswordResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   */
  putApiChangeUserPasswordResponse(body?: ChangePasswordRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/changeUserPassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  putApiChangeUserPassword(body?: ChangePasswordRequest): __Observable<null> {
    return this.putApiChangeUserPasswordResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UsersService {
}

export { UsersService }
