/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetUserRatingResponse } from '../models/get-user-rating-response';
import { AddUserRatingRequest } from '../models/add-user-rating-request';
import { UpdateUserRatingRequest } from '../models/update-user-rating-request';
import { GetRatingSummaryResponse } from '../models/get-rating-summary-response';
import { GetRatingScoreDistrubutionResponse } from '../models/get-rating-score-distrubution-response';
@Injectable({
  providedIn: 'root',
})
class RatingsService extends __BaseService {
  static readonly getApiUserRatingsPath = '/api/userRatings';
  static readonly postApiUserRatingsPath = '/api/userRatings';
  static readonly putApiUserRatingsPath = '/api/userRatings';
  static readonly getApiRatingSummariesPath = '/api/ratingSummaries';
  static readonly getApiRatingScoreDistributionsPath = '/api/ratingScoreDistributions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RatingsService.GetApiUserRatingsParams` containing the following parameters:
   *
   * - `RatedItemKey`:
   *
   * - `CategoryName`:
   *
   * @return OK
   */
  getApiUserRatingsResponse(params: RatingsService.GetApiUserRatingsParams): __Observable<__StrictHttpResponse<GetUserRatingResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.RatedItemKey != null) __params = __params.set('RatedItemKey', params.RatedItemKey.toString());
    if (params.CategoryName != null) __params = __params.set('CategoryName', params.CategoryName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/userRatings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetUserRatingResponse>;
      })
    );
  }
  /**
   * @param params The `RatingsService.GetApiUserRatingsParams` containing the following parameters:
   *
   * - `RatedItemKey`:
   *
   * - `CategoryName`:
   *
   * @return OK
   */
  getApiUserRatings(params: RatingsService.GetApiUserRatingsParams): __Observable<GetUserRatingResponse> {
    return this.getApiUserRatingsResponse(params).pipe(
      __map(_r => _r.body as GetUserRatingResponse)
    );
  }

  /**
   * @param body undefined
   */
  postApiUserRatingsResponse(body?: AddUserRatingRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/userRatings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  postApiUserRatings(body?: AddUserRatingRequest): __Observable<null> {
    return this.postApiUserRatingsResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   */
  putApiUserRatingsResponse(body?: UpdateUserRatingRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/userRatings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  putApiUserRatings(body?: UpdateUserRatingRequest): __Observable<null> {
    return this.putApiUserRatingsResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `RatingsService.GetApiRatingSummariesParams` containing the following parameters:
   *
   * - `RatedItemKey`:
   *
   * - `CategoryName`:
   *
   * @return OK
   */
  getApiRatingSummariesResponse(params: RatingsService.GetApiRatingSummariesParams): __Observable<__StrictHttpResponse<GetRatingSummaryResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.RatedItemKey != null) __params = __params.set('RatedItemKey', params.RatedItemKey.toString());
    if (params.CategoryName != null) __params = __params.set('CategoryName', params.CategoryName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ratingSummaries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetRatingSummaryResponse>;
      })
    );
  }
  /**
   * @param params The `RatingsService.GetApiRatingSummariesParams` containing the following parameters:
   *
   * - `RatedItemKey`:
   *
   * - `CategoryName`:
   *
   * @return OK
   */
  getApiRatingSummaries(params: RatingsService.GetApiRatingSummariesParams): __Observable<GetRatingSummaryResponse> {
    return this.getApiRatingSummariesResponse(params).pipe(
      __map(_r => _r.body as GetRatingSummaryResponse)
    );
  }

  /**
   * @param params The `RatingsService.GetApiRatingScoreDistributionsParams` containing the following parameters:
   *
   * - `RatedItemKey`:
   *
   * - `CategoryName`:
   *
   * @return OK
   */
  getApiRatingScoreDistributionsResponse(params: RatingsService.GetApiRatingScoreDistributionsParams): __Observable<__StrictHttpResponse<GetRatingScoreDistrubutionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.RatedItemKey != null) __params = __params.set('RatedItemKey', params.RatedItemKey.toString());
    if (params.CategoryName != null) __params = __params.set('CategoryName', params.CategoryName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ratingScoreDistributions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetRatingScoreDistrubutionResponse>;
      })
    );
  }
  /**
   * @param params The `RatingsService.GetApiRatingScoreDistributionsParams` containing the following parameters:
   *
   * - `RatedItemKey`:
   *
   * - `CategoryName`:
   *
   * @return OK
   */
  getApiRatingScoreDistributions(params: RatingsService.GetApiRatingScoreDistributionsParams): __Observable<GetRatingScoreDistrubutionResponse> {
    return this.getApiRatingScoreDistributionsResponse(params).pipe(
      __map(_r => _r.body as GetRatingScoreDistrubutionResponse)
    );
  }
}

module RatingsService {

  /**
   * Parameters for getApiUserRatings
   */
  export interface GetApiUserRatingsParams {
    RatedItemKey: string;
    CategoryName: string;
  }

  /**
   * Parameters for getApiRatingSummaries
   */
  export interface GetApiRatingSummariesParams {
    RatedItemKey?: string;
    CategoryName?: string;
  }

  /**
   * Parameters for getApiRatingScoreDistributions
   */
  export interface GetApiRatingScoreDistributionsParams {
    RatedItemKey?: string;
    CategoryName?: string;
  }
}

export { RatingsService }
