/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetProductsInfosResponse } from '../models/get-products-infos-response';
import { GetLatestProductsReleasesInfosResponse } from '../models/get-latest-products-releases-infos-response';
import { GetProductReleaseDetailsResponse } from '../models/get-product-release-details-response';
import { BaseResponse } from '../models/base-response';
import { CreateProductReleaseRequest } from '../models/create-product-release-request';
import { UpdateProductReleaseRequest } from '../models/update-product-release-request';
import { DeleteProductReleaseRequest } from '../models/delete-product-release-request';
import { DownloadProductReleaseResponse } from '../models/download-product-release-response';
import { GetFileLinkResponse } from '../models/get-file-link-response';
import { StartEditingProductReleaseResponse } from '../models/start-editing-product-release-response';
import { ContinueEditingProductReleaseResponse } from '../models/continue-editing-product-release-response';
import { EndEditingProductReleaseResponse } from '../models/end-editing-product-release-response';
import { Download3rdPartyProductHotfixResponse } from '../models/download-3rd-party-product-hotfix-response';
import { GetProductCompatibilityResponse } from '../models/get-product-compatibility-response';
import { DownloadProductReleaseNotesPdfResponse } from '../models/download-product-release-notes-pdf-response';
import { DownloadProductCompatibilityDetailsPdfResponse } from '../models/download-product-compatibility-details-pdf-response';
import { GetProductCategoriesResponse } from '../models/get-product-categories-response';
import { RecreateProductReleaseNotesPdfRequest } from '../models/recreate-product-release-notes-pdf-request';
import { UploadProductReleaseInstallationResponse } from '../models/upload-product-release-installation-response';
import { UploadProductReleaseInstallationRequest } from '../models/upload-product-release-installation-request';
import { GetProductReleaseInstallationsResponse } from '../models/get-product-release-installations-response';
import { GetThirdPartyProductHotfixesResponse } from '../models/get-third-party-product-hotfixes-response';
@Injectable({
  providedIn: 'root',
})
class ProductsService extends __BaseService {
  static readonly getApiAvailableProductsPath = '/api/availableProducts';
  static readonly getApiProductsPath = '/api/products';
  static readonly getApiLatestProductsReleasesPath = '/api/latestProductsReleases';
  static readonly getApiProductReleasesPath = '/api/productReleases';
  static readonly postApiProductReleasesPath = '/api/productReleases';
  static readonly putApiProductReleasesPath = '/api/productReleases';
  static readonly deleteApiProductReleasesPath = '/api/productReleases';
  static readonly getApiProductReleaseDownloadPath = '/api/productReleaseDownload';
  static readonly getApiProductReleaseDownloadLinkPath = '/api/productReleaseDownloadLink';
  static readonly putApiStartEditingProductReleasePath = '/api/startEditingProductRelease';
  static readonly putApiContinueEditingProductReleasePath = '/api/continueEditingProductRelease';
  static readonly putApiEndEditingProductReleasePath = '/api/endEditingProductRelease';
  static readonly getApiThirdPartyProductHotfixDownloadPath = '/api/thirdPartyProductHotfixDownload';
  static readonly getApiThirdPartyProductHotfixDownloadLinkPath = '/api/thirdPartyProductHotfixDownloadLink';
  static readonly getApiProductCompatibilityPath = '/api/productCompatibility';
  static readonly getApiProductReleaseNotesPdfDownloadPath = '/api/productReleaseNotesPdfDownload';
  static readonly getApiProductReleaseNotesPdfDownloadLinkPath = '/api/productReleaseNotesPdfDownloadLink';
  static readonly getApiProductCompatibilityDetailsPdfDownloadPath = '/api/productCompatibilityDetailsPdfDownload';
  static readonly getApiProductCategoriesPath = '/api/productCategories';
  static readonly putApiProductReleaseNotesPdfRecreatePath = '/api/productReleaseNotesPdfRecreate';
  static readonly postApiProductReleaseInstallationsPath = '/api/productReleaseInstallations';
  static readonly getApiProductReleaseInstallationsPath = '/api/productReleaseInstallations';
  static readonly deleteApiProductReleaseInstallationsPath = '/api/productReleaseInstallations';
  static readonly deleteApiReleaseInstallationsPath = '/api/releaseInstallations';
  static readonly getApiThirdPartyProductHotfixesPath = '/api/thirdPartyProductHotfixes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ProductsService.GetApiAvailableProductsParams` containing the following parameters:
   *
   * - `IncludeExternalProducts`:
   *
   * - `IncludeDraftVersions`:
   *
   * @return OK
   */
  getApiAvailableProductsResponse(params: ProductsService.GetApiAvailableProductsParams): __Observable<__StrictHttpResponse<GetProductsInfosResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.IncludeExternalProducts != null) __params = __params.set('IncludeExternalProducts', params.IncludeExternalProducts.toString());
    if (params.IncludeDraftVersions != null) __params = __params.set('IncludeDraftVersions', params.IncludeDraftVersions.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/availableProducts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductsInfosResponse>;
      })
    );
  }
  /**
   * @param params The `ProductsService.GetApiAvailableProductsParams` containing the following parameters:
   *
   * - `IncludeExternalProducts`:
   *
   * - `IncludeDraftVersions`:
   *
   * @return OK
   */
  getApiAvailableProducts(params: ProductsService.GetApiAvailableProductsParams): __Observable<GetProductsInfosResponse> {
    return this.getApiAvailableProductsResponse(params).pipe(
      __map(_r => _r.body as GetProductsInfosResponse)
    );
  }

  /**
   * @return OK
   */
  getApiProductsResponse(): __Observable<__StrictHttpResponse<GetProductsInfosResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/products`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductsInfosResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiProducts(): __Observable<GetProductsInfosResponse> {
    return this.getApiProductsResponse().pipe(
      __map(_r => _r.body as GetProductsInfosResponse)
    );
  }

  /**
   * @return OK
   */
  getApiLatestProductsReleasesResponse(): __Observable<__StrictHttpResponse<GetLatestProductsReleasesInfosResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/latestProductsReleases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetLatestProductsReleasesInfosResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiLatestProductsReleases(): __Observable<GetLatestProductsReleasesInfosResponse> {
    return this.getApiLatestProductsReleasesResponse().pipe(
      __map(_r => _r.body as GetLatestProductsReleasesInfosResponse)
    );
  }

  /**
   * @param params The `ProductsService.GetApiProductReleasesParams` containing the following parameters:
   *
   * - `ProductVersion`:
   *
   * - `ProductName`:
   *
   * @return OK
   */
  getApiProductReleasesResponse(params: ProductsService.GetApiProductReleasesParams): __Observable<__StrictHttpResponse<GetProductReleaseDetailsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ProductVersion != null) __params = __params.set('ProductVersion', params.ProductVersion.toString());
    if (params.ProductName != null) __params = __params.set('ProductName', params.ProductName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/productReleases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductReleaseDetailsResponse>;
      })
    );
  }
  /**
   * @param params The `ProductsService.GetApiProductReleasesParams` containing the following parameters:
   *
   * - `ProductVersion`:
   *
   * - `ProductName`:
   *
   * @return OK
   */
  getApiProductReleases(params: ProductsService.GetApiProductReleasesParams): __Observable<GetProductReleaseDetailsResponse> {
    return this.getApiProductReleasesResponse(params).pipe(
      __map(_r => _r.body as GetProductReleaseDetailsResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiProductReleasesResponse(body?: CreateProductReleaseRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/productReleases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiProductReleases(body?: CreateProductReleaseRequest): __Observable<BaseResponse> {
    return this.postApiProductReleasesResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  putApiProductReleasesResponse(body?: UpdateProductReleaseRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/productReleases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  putApiProductReleases(body?: UpdateProductReleaseRequest): __Observable<BaseResponse> {
    return this.putApiProductReleasesResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  deleteApiProductReleasesResponse(body?: DeleteProductReleaseRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/productReleases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  deleteApiProductReleases(body?: DeleteProductReleaseRequest): __Observable<BaseResponse> {
    return this.deleteApiProductReleasesResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param ProductReleaseDownloadLinkId undefined
   * @return OK
   */
  getApiProductReleaseDownloadResponse(ProductReleaseDownloadLinkId: number): __Observable<__StrictHttpResponse<DownloadProductReleaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (ProductReleaseDownloadLinkId != null) __params = __params.set('ProductReleaseDownloadLinkId', ProductReleaseDownloadLinkId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/productReleaseDownload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DownloadProductReleaseResponse>;
      })
    );
  }
  /**
   * @param ProductReleaseDownloadLinkId undefined
   * @return OK
   */
  getApiProductReleaseDownload(ProductReleaseDownloadLinkId: number): __Observable<DownloadProductReleaseResponse> {
    return this.getApiProductReleaseDownloadResponse(ProductReleaseDownloadLinkId).pipe(
      __map(_r => _r.body as DownloadProductReleaseResponse)
    );
  }

  /**
   * @param ProductReleaseDownloadLinkId undefined
   * @return OK
   */
  getApiProductReleaseDownloadLinkResponse(ProductReleaseDownloadLinkId: number): __Observable<__StrictHttpResponse<GetFileLinkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (ProductReleaseDownloadLinkId != null) __params = __params.set('ProductReleaseDownloadLinkId', ProductReleaseDownloadLinkId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/productReleaseDownloadLink`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetFileLinkResponse>;
      })
    );
  }
  /**
   * @param ProductReleaseDownloadLinkId undefined
   * @return OK
   */
  getApiProductReleaseDownloadLink(ProductReleaseDownloadLinkId: number): __Observable<GetFileLinkResponse> {
    return this.getApiProductReleaseDownloadLinkResponse(ProductReleaseDownloadLinkId).pipe(
      __map(_r => _r.body as GetFileLinkResponse)
    );
  }

  /**
   * @param params The `ProductsService.PutApiStartEditingProductReleaseParams` containing the following parameters:
   *
   * - `ProductShortName`:
   *
   * - `ProductReleaseVersion`:
   *
   * @return OK
   */
  putApiStartEditingProductReleaseResponse(params: ProductsService.PutApiStartEditingProductReleaseParams): __Observable<__StrictHttpResponse<StartEditingProductReleaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ProductShortName != null) __params = __params.set('ProductShortName', params.ProductShortName.toString());
    if (params.ProductReleaseVersion != null) __params = __params.set('ProductReleaseVersion', params.ProductReleaseVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/startEditingProductRelease`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StartEditingProductReleaseResponse>;
      })
    );
  }
  /**
   * @param params The `ProductsService.PutApiStartEditingProductReleaseParams` containing the following parameters:
   *
   * - `ProductShortName`:
   *
   * - `ProductReleaseVersion`:
   *
   * @return OK
   */
  putApiStartEditingProductRelease(params: ProductsService.PutApiStartEditingProductReleaseParams): __Observable<StartEditingProductReleaseResponse> {
    return this.putApiStartEditingProductReleaseResponse(params).pipe(
      __map(_r => _r.body as StartEditingProductReleaseResponse)
    );
  }

  /**
   * @param params The `ProductsService.PutApiContinueEditingProductReleaseParams` containing the following parameters:
   *
   * - `ProductShortName`:
   *
   * - `ProductReleaseVersion`:
   *
   * @return OK
   */
  putApiContinueEditingProductReleaseResponse(params: ProductsService.PutApiContinueEditingProductReleaseParams): __Observable<__StrictHttpResponse<ContinueEditingProductReleaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ProductShortName != null) __params = __params.set('ProductShortName', params.ProductShortName.toString());
    if (params.ProductReleaseVersion != null) __params = __params.set('ProductReleaseVersion', params.ProductReleaseVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/continueEditingProductRelease`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContinueEditingProductReleaseResponse>;
      })
    );
  }
  /**
   * @param params The `ProductsService.PutApiContinueEditingProductReleaseParams` containing the following parameters:
   *
   * - `ProductShortName`:
   *
   * - `ProductReleaseVersion`:
   *
   * @return OK
   */
  putApiContinueEditingProductRelease(params: ProductsService.PutApiContinueEditingProductReleaseParams): __Observable<ContinueEditingProductReleaseResponse> {
    return this.putApiContinueEditingProductReleaseResponse(params).pipe(
      __map(_r => _r.body as ContinueEditingProductReleaseResponse)
    );
  }

  /**
   * @param params The `ProductsService.PutApiEndEditingProductReleaseParams` containing the following parameters:
   *
   * - `ReleaseUpdateConfirmed`:
   *
   * - `ProductShortName`:
   *
   * - `ProductReleaseVersion`:
   *
   * - `LastReleaseUpdateTime`:
   *
   * @return OK
   */
  putApiEndEditingProductReleaseResponse(params: ProductsService.PutApiEndEditingProductReleaseParams): __Observable<__StrictHttpResponse<EndEditingProductReleaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ReleaseUpdateConfirmed != null) __params = __params.set('ReleaseUpdateConfirmed', params.ReleaseUpdateConfirmed.toString());
    if (params.ProductShortName != null) __params = __params.set('ProductShortName', params.ProductShortName.toString());
    if (params.ProductReleaseVersion != null) __params = __params.set('ProductReleaseVersion', params.ProductReleaseVersion.toString());
    if (params.LastReleaseUpdateTime != null) __params = __params.set('LastReleaseUpdateTime', params.LastReleaseUpdateTime.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/endEditingProductRelease`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EndEditingProductReleaseResponse>;
      })
    );
  }
  /**
   * @param params The `ProductsService.PutApiEndEditingProductReleaseParams` containing the following parameters:
   *
   * - `ReleaseUpdateConfirmed`:
   *
   * - `ProductShortName`:
   *
   * - `ProductReleaseVersion`:
   *
   * - `LastReleaseUpdateTime`:
   *
   * @return OK
   */
  putApiEndEditingProductRelease(params: ProductsService.PutApiEndEditingProductReleaseParams): __Observable<EndEditingProductReleaseResponse> {
    return this.putApiEndEditingProductReleaseResponse(params).pipe(
      __map(_r => _r.body as EndEditingProductReleaseResponse)
    );
  }

  /**
   * @param ThirdPartyProductHotfixDownloadLinkId undefined
   * @return OK
   */
  getApiThirdPartyProductHotfixDownloadResponse(ThirdPartyProductHotfixDownloadLinkId: number): __Observable<__StrictHttpResponse<Download3rdPartyProductHotfixResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (ThirdPartyProductHotfixDownloadLinkId != null) __params = __params.set('ThirdPartyProductHotfixDownloadLinkId', ThirdPartyProductHotfixDownloadLinkId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/thirdPartyProductHotfixDownload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Download3rdPartyProductHotfixResponse>;
      })
    );
  }
  /**
   * @param ThirdPartyProductHotfixDownloadLinkId undefined
   * @return OK
   */
  getApiThirdPartyProductHotfixDownload(ThirdPartyProductHotfixDownloadLinkId: number): __Observable<Download3rdPartyProductHotfixResponse> {
    return this.getApiThirdPartyProductHotfixDownloadResponse(ThirdPartyProductHotfixDownloadLinkId).pipe(
      __map(_r => _r.body as Download3rdPartyProductHotfixResponse)
    );
  }

  /**
   * @param LinkId undefined
   * @return OK
   */
  getApiThirdPartyProductHotfixDownloadLinkResponse(LinkId?: number): __Observable<__StrictHttpResponse<GetFileLinkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (LinkId != null) __params = __params.set('LinkId', LinkId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/thirdPartyProductHotfixDownloadLink`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetFileLinkResponse>;
      })
    );
  }
  /**
   * @param LinkId undefined
   * @return OK
   */
  getApiThirdPartyProductHotfixDownloadLink(LinkId?: number): __Observable<GetFileLinkResponse> {
    return this.getApiThirdPartyProductHotfixDownloadLinkResponse(LinkId).pipe(
      __map(_r => _r.body as GetFileLinkResponse)
    );
  }

  /**
   * @param params The `ProductsService.GetApiProductCompatibilityParams` containing the following parameters:
   *
   * - `ProductReleaseVersionsToTake`:
   *
   * - `ProductReleaseVersionsToSkip`:
   *
   * - `ProductName`:
   *
   * - `IncludePrereleaseVersions`:
   *
   * @return OK
   */
  getApiProductCompatibilityResponse(params: ProductsService.GetApiProductCompatibilityParams): __Observable<__StrictHttpResponse<GetProductCompatibilityResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ProductReleaseVersionsToTake != null) __params = __params.set('ProductReleaseVersionsToTake', params.ProductReleaseVersionsToTake.toString());
    if (params.ProductReleaseVersionsToSkip != null) __params = __params.set('ProductReleaseVersionsToSkip', params.ProductReleaseVersionsToSkip.toString());
    if (params.ProductName != null) __params = __params.set('ProductName', params.ProductName.toString());
    if (params.IncludePrereleaseVersions != null) __params = __params.set('IncludePrereleaseVersions', params.IncludePrereleaseVersions.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/productCompatibility`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductCompatibilityResponse>;
      })
    );
  }
  /**
   * @param params The `ProductsService.GetApiProductCompatibilityParams` containing the following parameters:
   *
   * - `ProductReleaseVersionsToTake`:
   *
   * - `ProductReleaseVersionsToSkip`:
   *
   * - `ProductName`:
   *
   * - `IncludePrereleaseVersions`:
   *
   * @return OK
   */
  getApiProductCompatibility(params: ProductsService.GetApiProductCompatibilityParams): __Observable<GetProductCompatibilityResponse> {
    return this.getApiProductCompatibilityResponse(params).pipe(
      __map(_r => _r.body as GetProductCompatibilityResponse)
    );
  }

  /**
   * @param params The `ProductsService.GetApiProductReleaseNotesPdfDownloadParams` containing the following parameters:
   *
   * - `ProductVersion`:
   *
   * - `ProductName`:
   *
   * @return OK
   */
  getApiProductReleaseNotesPdfDownloadResponse(params: ProductsService.GetApiProductReleaseNotesPdfDownloadParams): __Observable<__StrictHttpResponse<DownloadProductReleaseNotesPdfResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ProductVersion != null) __params = __params.set('ProductVersion', params.ProductVersion.toString());
    if (params.ProductName != null) __params = __params.set('ProductName', params.ProductName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/productReleaseNotesPdfDownload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DownloadProductReleaseNotesPdfResponse>;
      })
    );
  }
  /**
   * @param params The `ProductsService.GetApiProductReleaseNotesPdfDownloadParams` containing the following parameters:
   *
   * - `ProductVersion`:
   *
   * - `ProductName`:
   *
   * @return OK
   */
  getApiProductReleaseNotesPdfDownload(params: ProductsService.GetApiProductReleaseNotesPdfDownloadParams): __Observable<DownloadProductReleaseNotesPdfResponse> {
    return this.getApiProductReleaseNotesPdfDownloadResponse(params).pipe(
      __map(_r => _r.body as DownloadProductReleaseNotesPdfResponse)
    );
  }

  /**
   * @param params The `ProductsService.GetApiProductReleaseNotesPdfDownloadLinkParams` containing the following parameters:
   *
   * - `ProductVersion`:
   *
   * - `ProductName`:
   *
   * @return OK
   */
  getApiProductReleaseNotesPdfDownloadLinkResponse(params: ProductsService.GetApiProductReleaseNotesPdfDownloadLinkParams): __Observable<__StrictHttpResponse<GetFileLinkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ProductVersion != null) __params = __params.set('ProductVersion', params.ProductVersion.toString());
    if (params.ProductName != null) __params = __params.set('ProductName', params.ProductName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/productReleaseNotesPdfDownloadLink`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetFileLinkResponse>;
      })
    );
  }
  /**
   * @param params The `ProductsService.GetApiProductReleaseNotesPdfDownloadLinkParams` containing the following parameters:
   *
   * - `ProductVersion`:
   *
   * - `ProductName`:
   *
   * @return OK
   */
  getApiProductReleaseNotesPdfDownloadLink(params: ProductsService.GetApiProductReleaseNotesPdfDownloadLinkParams): __Observable<GetFileLinkResponse> {
    return this.getApiProductReleaseNotesPdfDownloadLinkResponse(params).pipe(
      __map(_r => _r.body as GetFileLinkResponse)
    );
  }

  /**
   * @param params The `ProductsService.GetApiProductCompatibilityDetailsPdfDownloadParams` containing the following parameters:
   *
   * - `ProductName`:
   *
   * - `IncludePrereleaseVersions`:
   *
   * @return OK
   */
  getApiProductCompatibilityDetailsPdfDownloadResponse(params: ProductsService.GetApiProductCompatibilityDetailsPdfDownloadParams): __Observable<__StrictHttpResponse<DownloadProductCompatibilityDetailsPdfResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ProductName != null) __params = __params.set('ProductName', params.ProductName.toString());
    if (params.IncludePrereleaseVersions != null) __params = __params.set('IncludePrereleaseVersions', params.IncludePrereleaseVersions.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/productCompatibilityDetailsPdfDownload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DownloadProductCompatibilityDetailsPdfResponse>;
      })
    );
  }
  /**
   * @param params The `ProductsService.GetApiProductCompatibilityDetailsPdfDownloadParams` containing the following parameters:
   *
   * - `ProductName`:
   *
   * - `IncludePrereleaseVersions`:
   *
   * @return OK
   */
  getApiProductCompatibilityDetailsPdfDownload(params: ProductsService.GetApiProductCompatibilityDetailsPdfDownloadParams): __Observable<DownloadProductCompatibilityDetailsPdfResponse> {
    return this.getApiProductCompatibilityDetailsPdfDownloadResponse(params).pipe(
      __map(_r => _r.body as DownloadProductCompatibilityDetailsPdfResponse)
    );
  }

  /**
   * @return OK
   */
  getApiProductCategoriesResponse(): __Observable<__StrictHttpResponse<GetProductCategoriesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/productCategories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductCategoriesResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiProductCategories(): __Observable<GetProductCategoriesResponse> {
    return this.getApiProductCategoriesResponse().pipe(
      __map(_r => _r.body as GetProductCategoriesResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  putApiProductReleaseNotesPdfRecreateResponse(body?: RecreateProductReleaseNotesPdfRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/productReleaseNotesPdfRecreate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  putApiProductReleaseNotesPdfRecreate(body?: RecreateProductReleaseNotesPdfRequest): __Observable<BaseResponse> {
    return this.putApiProductReleaseNotesPdfRecreateResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiProductReleaseInstallationsResponse(body?: UploadProductReleaseInstallationRequest): __Observable<__StrictHttpResponse<UploadProductReleaseInstallationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/productReleaseInstallations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadProductReleaseInstallationResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiProductReleaseInstallations(body?: UploadProductReleaseInstallationRequest): __Observable<UploadProductReleaseInstallationResponse> {
    return this.postApiProductReleaseInstallationsResponse(body).pipe(
      __map(_r => _r.body as UploadProductReleaseInstallationResponse)
    );
  }

  /**
   * @param params The `ProductsService.GetApiProductReleaseInstallationsParams` containing the following parameters:
   *
   * - `ProductShortName`:
   *
   * - `ProductReleaseVersion`:
   *
   * - `ProductReleaseStatusCode`:
   *
   * @return OK
   */
  getApiProductReleaseInstallationsResponse(params: ProductsService.GetApiProductReleaseInstallationsParams): __Observable<__StrictHttpResponse<GetProductReleaseInstallationsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ProductShortName != null) __params = __params.set('ProductShortName', params.ProductShortName.toString());
    if (params.ProductReleaseVersion != null) __params = __params.set('ProductReleaseVersion', params.ProductReleaseVersion.toString());
    if (params.ProductReleaseStatusCode != null) __params = __params.set('ProductReleaseStatusCode', params.ProductReleaseStatusCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/productReleaseInstallations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductReleaseInstallationsResponse>;
      })
    );
  }
  /**
   * @param params The `ProductsService.GetApiProductReleaseInstallationsParams` containing the following parameters:
   *
   * - `ProductShortName`:
   *
   * - `ProductReleaseVersion`:
   *
   * - `ProductReleaseStatusCode`:
   *
   * @return OK
   */
  getApiProductReleaseInstallations(params: ProductsService.GetApiProductReleaseInstallationsParams): __Observable<GetProductReleaseInstallationsResponse> {
    return this.getApiProductReleaseInstallationsResponse(params).pipe(
      __map(_r => _r.body as GetProductReleaseInstallationsResponse)
    );
  }

  /**
   * @param params The `ProductsService.DeleteApiProductReleaseInstallationsParams` containing the following parameters:
   *
   * - `InstallationStorageType`:
   *
   * - `DownloadLink`:
   *
   * @return OK
   */
  deleteApiProductReleaseInstallationsResponse(params: ProductsService.DeleteApiProductReleaseInstallationsParams): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.InstallationStorageType != null) __params = __params.set('InstallationStorageType', params.InstallationStorageType.toString());
    if (params.DownloadLink != null) __params = __params.set('DownloadLink', params.DownloadLink.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/productReleaseInstallations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param params The `ProductsService.DeleteApiProductReleaseInstallationsParams` containing the following parameters:
   *
   * - `InstallationStorageType`:
   *
   * - `DownloadLink`:
   *
   * @return OK
   */
  deleteApiProductReleaseInstallations(params: ProductsService.DeleteApiProductReleaseInstallationsParams): __Observable<BaseResponse> {
    return this.deleteApiProductReleaseInstallationsResponse(params).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param params The `ProductsService.DeleteApiReleaseInstallationsParams` containing the following parameters:
   *
   * - `Path`:
   *
   * - `Name`:
   *
   * - `InstallationStorageType`:
   *
   * @return OK
   */
  deleteApiReleaseInstallationsResponse(params: ProductsService.DeleteApiReleaseInstallationsParams): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Path != null) __params = __params.set('Path', params.Path.toString());
    if (params.Name != null) __params = __params.set('Name', params.Name.toString());
    if (params.InstallationStorageType != null) __params = __params.set('InstallationStorageType', params.InstallationStorageType.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/releaseInstallations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param params The `ProductsService.DeleteApiReleaseInstallationsParams` containing the following parameters:
   *
   * - `Path`:
   *
   * - `Name`:
   *
   * - `InstallationStorageType`:
   *
   * @return OK
   */
  deleteApiReleaseInstallations(params: ProductsService.DeleteApiReleaseInstallationsParams): __Observable<BaseResponse> {
    return this.deleteApiReleaseInstallationsResponse(params).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @return OK
   */
  getApiThirdPartyProductHotfixesResponse(): __Observable<__StrictHttpResponse<GetThirdPartyProductHotfixesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/thirdPartyProductHotfixes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetThirdPartyProductHotfixesResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiThirdPartyProductHotfixes(): __Observable<GetThirdPartyProductHotfixesResponse> {
    return this.getApiThirdPartyProductHotfixesResponse().pipe(
      __map(_r => _r.body as GetThirdPartyProductHotfixesResponse)
    );
  }
}

module ProductsService {

  /**
   * Parameters for getApiAvailableProducts
   */
  export interface GetApiAvailableProductsParams {
    IncludeExternalProducts: boolean;
    IncludeDraftVersions: boolean;
  }

  /**
   * Parameters for getApiProductReleases
   */
  export interface GetApiProductReleasesParams {
    ProductVersion: string;
    ProductName: string;
  }

  /**
   * Parameters for putApiStartEditingProductRelease
   */
  export interface PutApiStartEditingProductReleaseParams {
    ProductShortName: string;
    ProductReleaseVersion: string;
  }

  /**
   * Parameters for putApiContinueEditingProductRelease
   */
  export interface PutApiContinueEditingProductReleaseParams {
    ProductShortName: string;
    ProductReleaseVersion: string;
  }

  /**
   * Parameters for putApiEndEditingProductRelease
   */
  export interface PutApiEndEditingProductReleaseParams {
    ReleaseUpdateConfirmed: boolean;
    ProductShortName: string;
    ProductReleaseVersion: string;
    LastReleaseUpdateTime?: string;
  }

  /**
   * Parameters for getApiProductCompatibility
   */
  export interface GetApiProductCompatibilityParams {
    ProductReleaseVersionsToTake: number;
    ProductReleaseVersionsToSkip: number;
    ProductName: string;
    IncludePrereleaseVersions: boolean;
  }

  /**
   * Parameters for getApiProductReleaseNotesPdfDownload
   */
  export interface GetApiProductReleaseNotesPdfDownloadParams {
    ProductVersion: string;
    ProductName: string;
  }

  /**
   * Parameters for getApiProductReleaseNotesPdfDownloadLink
   */
  export interface GetApiProductReleaseNotesPdfDownloadLinkParams {
    ProductVersion: string;
    ProductName: string;
  }

  /**
   * Parameters for getApiProductCompatibilityDetailsPdfDownload
   */
  export interface GetApiProductCompatibilityDetailsPdfDownloadParams {
    ProductName: string;
    IncludePrereleaseVersions: boolean;
  }

  /**
   * Parameters for getApiProductReleaseInstallations
   */
  export interface GetApiProductReleaseInstallationsParams {
    ProductShortName: string;
    ProductReleaseVersion: string;
    ProductReleaseStatusCode: number;
  }

  /**
   * Parameters for deleteApiProductReleaseInstallations
   */
  export interface DeleteApiProductReleaseInstallationsParams {
    InstallationStorageType: string;
    DownloadLink: string;
  }

  /**
   * Parameters for deleteApiReleaseInstallations
   */
  export interface DeleteApiReleaseInstallationsParams {
    Path: string;
    Name: string;
    InstallationStorageType: string;
  }
}

export { ProductsService }
