<kendo-dialog [minHeight]="300" [minWidth]="600" *ngIf="isShown">
  <!-- Dialog Header -->
  <kendo-dialog-titlebar class="k-window-actions-custom d-flex dialog-header justify-content-center align-items-center">
    <!-- Favorite Icon -->
    <div>
      <i class="fa-solid fa-lg fa-heart opal-dark-green-text"></i>
    </div>

    <!-- Title -->
    <div class="display-9 ms-3">{{ "ADD_USER_FAVORITE.TITLE" | translate }}</div>

    <!-- Close -->
    <div class="ms-auto">
      <i class="fa-light fa-xmark fa-sm opal-grey-text cursor-pointer" (click)="closeDialog(false)"></i>
    </div>
  </kendo-dialog-titlebar>

  <!-- Dialog Body -->
  <form class="dialog-body flex-fill align-self-stretch" #addFavoriteForm="ngForm">
    <!-- Favorite Name -->
    <div class="form-field">
      <!-- Favorite Name -->
      <kendo-label text="{{ 'ADD_USER_FAVORITE.FAVORITE_NAME' | translate }}" class="text-nowrap"></kendo-label>
      <kendo-textbox autofocus [forbiddenNames]="userFavoriteNames" required name="favoriteName" #favName="ngModel" [(ngModel)]="favoriteName"></kendo-textbox>

      <!-- Required Name Error Message -->
      <span *ngIf="favName.dirty && favName.errors?.['required']" class="k-form-error k-text-start">
        {{ "ERROR_MESSAGE.FAVORITE_NAME_IS_REQUIRED" | translate }}
      </span>

      <!-- Duplicate Name Error Message -->
      <span *ngIf="favName.dirty && favName.errors?.['forbiddenNames']" class="k-form-error k-text-start">
        {{ "ERROR_MESSAGE.FAVORITE_NAME_ALREADY_EXIST" | translate }}
      </span>
    </div>
  </form>

  <div class="dialog-footer d-flex align-items-center">
    <!-- Loader -->
    <kendo-loader *ngIf="isAddingUserFavorite" type="infinite-spinner" themeColor="primary" size="medium"> </kendo-loader>

    <!-- Message -->
    <span class="ms-2 display-10" *ngIf="isAddingUserFavorite">
      {{ "MESSAGES.ADDING_USER_FAVORITE" | translate }}
    </span>

    <!-- Add Button -->
    <button kendoButton [disabled]="!addFavoriteForm.form.valid || isAddingUserFavorite" (click)="onAddUserFavorite()" class="ms-auto" [themeColor]="'primary'" rounded="none">
      <i class="fa-solid fa-md fa-plus me-2"></i>
      {{ "ADD_USER_FAVORITE.ADD" | translate }}
    </button>
  </div>
</kendo-dialog>
