export class SidenavItemInfo{
    

    //#region Constructor

    constructor(name: string, title: string, iconType:string, path: string){
        this.name = name;
        this.title = title;
        this.iconType = iconType;
        this.path = path;
        this.childItems = new Array<SidenavItemInfo>();
    }

    //#region 

    //#region Properties

    /**
     * Sidenav item title
     */
    public title: string;

    /**
     * Sidenav item icon
     */
    public iconType: string;

    /**
     * Sidenav item route path
     */
    public path: string;

    /**
     * Sidenav item name
     */
    public name: string;

    /**
     * Sidenav item child items.
     */
    public childItems: Array<SidenavItemInfo>;

    /**
     * Determines if sidenav item has child items.
     */
    public get hasChildItems(): boolean{
      if(this.childItems && this.childItems.length > 0){
        return true;
      } else {
        return false;
      }
    }


    //#endregion



}