import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AuthService, UsersService } from "src/app/api/opal-partner-center/services";
import { LAYOUT_ROUTES } from "src/app/layout/layout.routing.constants";
import { CONSTANTS } from "src/app/shared/constants";
import { ErrorResponseHandlerService } from "src/app/shared/services/error-response-handler-service/error-response-handler-service";
import { SECURITY_ROUTES } from "../security.routing.constants";
import { UserInfoService } from "src/app/shared/services/user-info-service/user-info-service";

@Component({
  selector: "privacy-policy",
  templateUrl: "./privacy-policy.component.html",
})
export class PrivacyPolicyComponent implements OnInit {

  //#region Private Fields

  /**
   * Post login route.
   */
  private _postLoginRoute: string | undefined;

  /**
   * Return route query params.
   */
  private _postLoginRouteQueryParams: Params | undefined;

  //#endregion

  //#region Constructor

  constructor(private _usersService: UsersService,
              private _activatedRoute: ActivatedRoute,
              private _router: Router,
              private _errorResponseHandlerService: ErrorResponseHandlerService,
              private _userInfoService: UserInfoService,
              private _authService: AuthService) {

  }

  //#endregion

  //#region NG Core

  public ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe(params=>{

      if(params[CONSTANTS.QUERY_PARAMS.POST_LOGIN_ROUTE]){

        const url = new URL(params[CONSTANTS.QUERY_PARAMS.POST_LOGIN_ROUTE], 'http://dummy-base')
        this._postLoginRoute = url.pathname;

        if(url.search){
          this._postLoginRouteQueryParams = this._router.parseUrl(url.search).queryParams;
        } else {
          this._postLoginRouteQueryParams = undefined;
        }
      }else {
        this._postLoginRoute = undefined;
        this._postLoginRouteQueryParams = undefined;
      }
    });
  }

  //#endregion

  //#region Properties

  //#endregion

  //#region UI Handlers

  /**
   * Deny privacy policy.
   */
  public denyPrivacyPolicy(): void {
    this.signOut();
  }

  /**
   * Navigate to privacy policy page.
   */
  public onNavigateToPrivacyPolicyPage(): void{
    let privacyPolicyUrl: string | undefined;

    if (this._userInfoService.userInfo?.language) {

      let languageCode = this._userInfoService.userInfo?.language.split("-")[0];

      switch (languageCode) {
        case CONSTANTS.LANGUAGE_CODE.ENGLISH:
          privacyPolicyUrl = CONSTANTS.PRIVACY_POLICY.LINKS.ENGLISH;
          break;
        case CONSTANTS.LANGUAGE_CODE.GERMAN:
          privacyPolicyUrl = CONSTANTS.PRIVACY_POLICY.LINKS.GERMAN;
          break;
        case CONSTANTS.LANGUAGE_CODE.FRENCH:
          privacyPolicyUrl = CONSTANTS.PRIVACY_POLICY.LINKS.FRENCH;
          break;
        default:
          privacyPolicyUrl = CONSTANTS.PRIVACY_POLICY.LINKS.ENGLISH;
          break;
      }

      window.open(privacyPolicyUrl, '_blank');
    }
  }

  /**
   * Accept privacy policy.
   */
  public acceptPrivacyPolicy(): void{

    this._usersService.putApiAcceptPrivacyPolicy().subscribe({
      next: response => {

        this._userInfoService.userInfo!.isPrivacyPolicyAccepted = true;

        if(this._postLoginRoute){

          if(this._postLoginRouteQueryParams){
            // Navigate to return route with query params.
            this._router.navigate([this._postLoginRoute], {queryParams: this._postLoginRouteQueryParams}).then(()=>{
              
              if(this._postLoginRoute == "/" + LAYOUT_ROUTES.FILE_DOWNLOAD){
                this._router.navigate([""]);
              }
            });
            
          } else {
            // Navigate to return route.
            this._router.navigate([this._postLoginRoute]);
          }
        } else {
    
          // Navigate to default route.
          this._router.navigate([""]);
        }
      },
      error: errorResponse => {
        this._errorResponseHandlerService.handleHttpErrorResponse(errorResponse);
      }
    });
  }

  //#endregion

  //#region Private Methods

  public signOut(){
    
    this._authService.getApiLogout().subscribe({
        next: response=>{
            this._router.navigate([SECURITY_ROUTES.SECURITY_LOGIN]);
        },
        error: errorResponse=>{
        }
    });
  }

  //#endregion

}