import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DialogModule } from "@progress/kendo-angular-dialog";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { LabelModule } from "@progress/kendo-angular-label";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { SharedModule } from "../shared/shared.module";
import { LoginComponent } from "./login/login.component";
import { EmailConfirmationComponent } from "./email-confirmation/email-confirmation.component";
import { RegisterUserComponent } from "./register-user/register-user.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { RouterModule } from "@angular/router";
import { SecurityRoutes } from "./security.routing";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";

@NgModule({
    imports:[
        CommonModule,
        FormsModule,
        InputsModule,
        DropDownsModule,
        LabelModule,
        RouterModule.forChild(SecurityRoutes),
        TranslateModule,
        DialogModule,
        ButtonsModule,
        IndicatorsModule,
        SharedModule,
    ],
    declarations: [
        LoginComponent,
        EmailConfirmationComponent,
        RegisterUserComponent,
        ResetPasswordComponent,
        PrivacyPolicyComponent
    ],
    exports:[
      LoginComponent, 
      EmailConfirmationComponent,
      RegisterUserComponent,
      ResetPasswordComponent]
})
export class SecurityModule{ }