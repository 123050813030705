/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateFido2CredentialCreationOptionsResponse } from '../models/create-fido-2credential-creation-options-response';
import { CreateFido2CredentialResponse } from '../models/create-fido-2credential-response';
import { CreateFido2CredentialRequest } from '../models/create-fido-2credential-request';
import { GetCredentialsInfosResponse } from '../models/get-credentials-infos-response';
import { DeleteFido2CredentialRequest } from '../models/delete-fido-2credential-request';
import { CreateFido2CredentialAssertionOptionsResponse } from '../models/create-fido-2credential-assertion-options-response';
import { CreateFido2CredentialAssertionOptionsRequest } from '../models/create-fido-2credential-assertion-options-request';
import { Fido2LoginRequest } from '../models/fido-2login-request';
@Injectable({
  providedIn: 'root',
})
class Fido2Service extends __BaseService {
  static readonly postApiFido2CredentialCreationOptionsPath = '/api/fido2/credentialCreationOptions';
  static readonly postApiFido2CredentialsPath = '/api/fido2/credentials';
  static readonly getApiFido2CredentialsPath = '/api/fido2/credentials';
  static readonly deleteApiFido2CredentialsPath = '/api/fido2/credentials';
  static readonly postApiFido2CredentialAssertionOptionsPath = '/api/fido2/credentialAssertionOptions';
  static readonly postApiFido2LoginPath = '/api/fido2/login';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  postApiFido2CredentialCreationOptionsResponse(): __Observable<__StrictHttpResponse<CreateFido2CredentialCreationOptionsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/fido2/credentialCreationOptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateFido2CredentialCreationOptionsResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  postApiFido2CredentialCreationOptions(): __Observable<CreateFido2CredentialCreationOptionsResponse> {
    return this.postApiFido2CredentialCreationOptionsResponse().pipe(
      __map(_r => _r.body as CreateFido2CredentialCreationOptionsResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiFido2CredentialsResponse(body?: CreateFido2CredentialRequest): __Observable<__StrictHttpResponse<CreateFido2CredentialResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/fido2/credentials`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateFido2CredentialResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiFido2Credentials(body?: CreateFido2CredentialRequest): __Observable<CreateFido2CredentialResponse> {
    return this.postApiFido2CredentialsResponse(body).pipe(
      __map(_r => _r.body as CreateFido2CredentialResponse)
    );
  }

  /**
   * @return OK
   */
  getApiFido2CredentialsResponse(): __Observable<__StrictHttpResponse<GetCredentialsInfosResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/fido2/credentials`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetCredentialsInfosResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiFido2Credentials(): __Observable<GetCredentialsInfosResponse> {
    return this.getApiFido2CredentialsResponse().pipe(
      __map(_r => _r.body as GetCredentialsInfosResponse)
    );
  }

  /**
   * @param body undefined
   */
  deleteApiFido2CredentialsResponse(body?: DeleteFido2CredentialRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/fido2/credentials`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  deleteApiFido2Credentials(body?: DeleteFido2CredentialRequest): __Observable<null> {
    return this.deleteApiFido2CredentialsResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiFido2CredentialAssertionOptionsResponse(body?: CreateFido2CredentialAssertionOptionsRequest): __Observable<__StrictHttpResponse<CreateFido2CredentialAssertionOptionsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/fido2/credentialAssertionOptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateFido2CredentialAssertionOptionsResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiFido2CredentialAssertionOptions(body?: CreateFido2CredentialAssertionOptionsRequest): __Observable<CreateFido2CredentialAssertionOptionsResponse> {
    return this.postApiFido2CredentialAssertionOptionsResponse(body).pipe(
      __map(_r => _r.body as CreateFido2CredentialAssertionOptionsResponse)
    );
  }

  /**
   * @param body undefined
   */
  postApiFido2LoginResponse(body?: Fido2LoginRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/fido2/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  postApiFido2Login(body?: Fido2LoginRequest): __Observable<null> {
    return this.postApiFido2LoginResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module Fido2Service {
}

export { Fido2Service }
