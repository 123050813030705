import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { UserRatingSummary } from "../../model/user-rating-summary";
import { RatingsService } from "src/app/api/opal-partner-center/services";
import { ErrorResponseHandlerService } from "../../services/error-response-handler-service/error-response-handler-service";
import { AddUserRatingRequest, GetRatingScoreDistrubutionResponse, GetRatingSummaryResponse, GetUserRatingResponse, UpdateUserRatingRequest, UserRatingDetails } from "src/app/api/opal-partner-center/models";
import { UserRating } from "./model/user-rating";
import { UserInfoService } from "../../services/user-info-service/user-info-service";
import { RatingsODataService } from "src/app/api/opal-partner-center-odata/ratings-odata.service";
import { RatingDetails } from "./model/rating-details";
import { BusyIndicationService } from "../../services/busy-indication-service/busy-indication-service";
import { LOCALIZE_CONSTANTS } from "../../localize.constants";
import { CONSTANTS } from "../../constants";
import { RatingScoreDistribution } from "./model/rating-score-distribution";
import { catchError, concatMap, Observable, of, tap } from "rxjs";
import { ODataResponse } from "src/app/api/common/model/odata-response";

@Component({
    selector: 'user-rating',
    templateUrl: './user-rating.component.html',
    styleUrls: ['./user-rating.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserRatingComponent {

    //#region Private Fields

    /**
     * Flag that indicates if internal user is logged in.
     */
    private _isInternalUser: boolean;

    /**
     * Total number of ratings for a given rated item and (optionally) rating score.
     */
    private _ratingsCount: number;

    /**
     * Number of skipped ratings (used for loading of new ratings in rating details dialog).
     */
    private _ratingsToSkip: number;

    //#endregion

    //#region Constructor

    constructor(private _ratingsService: RatingsService,
                private _userInfoService: UserInfoService,
                private _busyIndicationService: BusyIndicationService,
                private _errorResponseHandlerService: ErrorResponseHandlerService,
                private _ratingsODataService: RatingsODataService) {
                
        this.userRating = new UserRating();
        this.ratingStarsWidth = 0;
        this.isAddUpdateRatingDialogShown = false;
        this.isRatingDetailsDialogShown = false;
        this._isInternalUser = false;
        this.ratings = new Array<RatingDetails>();
        this.userRatingExists = false;
        this.ratingScoreDistribution = new RatingScoreDistribution();
        this._ratingsToSkip = 0;
        this._ratingsCount = 0;
        this.visibleRatingScores = [true, true, true, true, true];
        this.isAddingOrUpdatingUserRating = false;
        this.userRatingsChanged = new EventEmitter<void>();
    }

    //#endregion

    //#region Ng Core

    ngOnInit(): void {
        this._isInternalUser = this._userInfoService.userInfo?.isInternalUser ? this._userInfoService.userInfo?.isInternalUser : false;
        this.setAverageRatingIndicator();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['ratingSummary'] && changes['ratingSummary'].currentValue) {
            this.setAverageRatingIndicator();
        }
    }

    //#endregion

    //#region Public Properties

    /**
     * If set to true (default behavior), user is able to open rating dialog by clicking on rating component.
     * Othervise, rating of given item is disabled.
     */
    @Input()
    public userRatingEnabled: boolean = true;

    /**
     * Rating summary of a given rated item.
     */
    @Input()
    public ratingSummary?: UserRatingSummary;

    /**
     * Represents an event that is emitted when user rating of a given rated item is changed.
     */
    @Output()
    public userRatingsChanged: EventEmitter<void>;

    /**
     * Determines the visual presentation of average rating.
     */
    public ratingStarsWidth: number;

    /**
     * Flag that indicates if dialog for adding/updating user rating is shown.
     * This dialog is available for all users.
     */
    public isAddUpdateRatingDialogShown: boolean;

    /**
     * Flag that indicates if rating details dialog is shown.
     * This dialog is available only for internal users.
     */
    public isRatingDetailsDialogShown: boolean;

    /**
     * User rating of a given rated item.
     */
    public userRating: UserRating;

    /**
     * Rating score set in dialog for adding (or updating) user ratings.
     */
    public selectedRatingScore?: number;

    /**
     * Rating comment set in dialog for adding (or updating) user ratings.
     */
    public currentRatingComment?: string;

    /**
     * Tracks hovered rating stars.
     */
    public hoveredRating?: number;

    /**
     * List of ratings shown in rating details dialog.
     */
    public ratings: Array<RatingDetails>;

    /**
     * Flag that indicates if user already rated item for which rating details dialog is opened.
     * Applicable only for internal users.
     */
    public userRatingExists: boolean;

    /**
     * Rating score distribution.
     */
    public ratingScoreDistribution: RatingScoreDistribution;

    /**
     * Reflects the state of rating score filter. If all elments are true, it means that ratings are not filtered by score.
     * If 1st element is true, 5 star ratings are included in list of ratings in "Rating Details" dialog. Otherwise, they are excluced.
     * If 2nd element is true, 4 star ratings are included in list of ratings in "Rating Details" dialog. Otherwise, they are excluced etc..
     */
    public visibleRatingScores: Array<boolean>;

    /**
     * Flag that indicates if adding new or updating existing user rating is in process.
     */
    public isAddingOrUpdatingUserRating: boolean;

    //#endregion

    //#region UI Handlers

    /**
     * Triggered when user rating dialog is opened.
     */
    public onOpenRatingDialog() {

        if (this.userRatingEnabled) {

            if (this._isInternalUser) {
                this.openRatingDetailsDialog();

            } else {
                this.onOpenAddUpdateRatingDialog();
            }
        }
    }

    /**
     * Opens dialog for adding/updating user rating.
     */
    public onOpenAddUpdateRatingDialog() {

        var request = {} as RatingsService.GetApiUserRatingsParams;
        request.CategoryName = this.ratingSummary?.categoryName!;
        request.RatedItemKey = this.ratingSummary?.ratedItemKey!;

        this._ratingsService.getApiUserRatings(request).subscribe({
            next: response => {
                this.userRating = new UserRating(response.userRatingInfo);
                this.selectedRatingScore = this.userRating.score ? this.userRating.score : 0;
                this.currentRatingComment = this.userRating.comment ? this.userRating.comment : "";
                this.isAddUpdateRatingDialogShown = true;
            },
            error: errorResponse => {
                this._errorResponseHandlerService.handleHttpErrorResponse(errorResponse);
            }
        });
    }

    /**
     * Closes dialog for adding/updating user rating.
     */
    public onCloseAddUpdateRatingDialog() {
        this.isAddUpdateRatingDialogShown = false;
        this.hoveredRating = undefined;
    }

    /**
     * Toggles activity of ratings filter for a given rating score.
     * @param score Rating score.
     */
    public onToggleScoreFilter(score: number) {

        // NOTE: If initial state of visibleRatingScores was [true, true, true, true, true] (no rating score filtering),
        // then final state should reflect situation where only ratings for a given score should be visible. For example,
        // if user clicked on 5 star progress bar, final state of visibleRatingScores is [true, false, false, false, false].
        if (!this.visibleRatingScores.includes(false)) {

            for (let i = 0; i < this.visibleRatingScores.length; i++) {
                if (i != 5 - score) {
                    this.visibleRatingScores[i] = false;
                }
            }

        } else {
            this.visibleRatingScores[5 - score] = !this.visibleRatingScores[5 - score];

            // NOTE: If user disabled the only active rating score filter, the state of visibleRatingScores is temporarily set
            // to [false, false, false, false, false] and needs to be changed to [true, true, true, true, true] that corresponds
            // to inactive rating score filter.
            if (!this.visibleRatingScores.includes(true)) {
                this.visibleRatingScores = [true, true, true, true, true];
            }
        }

        this.filterRatingsByScore();
    }

    /**
     * Loads more ratings (if there are some) when user scrolls to the bottom of ratings list.
     */
    public onLoadMoreRatings() {

        if (this.ratings.length < this._ratingsCount) {

            this._ratingsToSkip += CONSTANTS.USER_RATINGS.RATING_DETAILS.PAGE_SIZE;

            var odataQuery = this.getRatingDetailsODataQuery(this._ratingsToSkip, this.visibleRatingScores);

            this._busyIndicationService.Show(LOCALIZE_CONSTANTS.MESSAGES.GETTING_RATING_DETAILS);

            this._ratingsODataService.getApiRatingDetails(odataQuery).subscribe({
                next: response => {
                    var addedRatings = response.value.map(x => new RatingDetails(x));
                    // NOTE: Creating a new array reference will be detected by Angular and trigger change detection.
                    this.ratings = [...this.ratings, ...addedRatings];
                    this._busyIndicationService.Hide();
                },
                error: (error) => {
                    this._errorResponseHandlerService.handleHttpErrorResponse(error);
                    this._busyIndicationService.Hide();
                }
            });
        }
    }

    /**
     * Closes rating details dialog.
     */
    public onCloseRatingDetailsDialog() {
        this.isRatingDetailsDialogShown = false;
        this.visibleRatingScores = [true, true, true, true, true];
        this._ratingsToSkip = 0;
    }

    /**
     * Sets the value of hovered rating according to position of currently hovered rating star.
     * @param hoveredRating Hovered rating.
     */
    public onHoverRatingStar(hoveredRating: number) {
        this.hoveredRating = hoveredRating;
    }

    /**
     * Sets the value of hovered rating when mouse leaves rating star area.
     */
    public onLeaveRatingStar() {
        this.hoveredRating = undefined;
    }

    /**
     * Sets the value of user rating according to position of clicked rating star.
     * @param ratingScore Selected rating score.
     */
    public onClickRatingStar(ratingScore: number) {
        this.selectedRatingScore = ratingScore;
    }

    /**
     * Adds new or updates existing user rating.
     * @param userRatingExists Flag that indicates if new user rating is to be added (false),
     * or existing rating is to be updated (true).
     */
    public onAddOrUpdateUserRating(userRatingExists: boolean) {

        if (!userRatingExists) {
            this.onAddUserRating();

        } else {
            this.onUpdateUserRating();
        }
    }

    //#endregion

    //#region Private Methods

    /**
     * Opens rating details dialog.
     */
    private openRatingDetailsDialog() {

        var results: any[] = [];
        var odataQuery = this.getRatingDetailsODataQuery(0);

        this._busyIndicationService.Show(LOCALIZE_CONSTANTS.MESSAGES.GETTING_RATING_DETAILS);

        this.getRatingDetails(odataQuery).pipe(
            tap(result => results.push(result)),
            catchError(error => { return of(undefined) }),

            concatMap(() => this.getRatingScoreDistribution()),
            tap(result => results.push(result)),
            catchError(error => { return of(undefined) }),
            
            concatMap(() => this.getRatingSummary()),
            tap(result => results.push(result)),
            catchError(error => { return of(undefined) }),

            concatMap(() => this.getUserRating()),
            tap(result => results.push(result)),
            catchError(error => { return of(undefined) })
        )
        .subscribe({
            complete: () => {
                this.handleGetRatingDetails(results[0]);
                this.handleGetRatingScoreDistribution(results[1]);
                this.handleGetRatingSummary(results[2]);
                this.handleGetUserRating(results[3]);
                this.isRatingDetailsDialogShown = true;
                this._busyIndicationService.Hide();
            },
            error: errorResponse => {
                this._errorResponseHandlerService.handleHttpErrorResponse(errorResponse);
                this._busyIndicationService.Hide();
            }
        });
    }

    /**
     * Gets rating details for a given rated item.
     * @param odataQuery Complete OData query.
     * @returns Rating details observable.
     */
    private getRatingDetails(odataQuery: string): Observable<ODataResponse<UserRatingDetails>> {
        return this._ratingsODataService.getApiRatingDetails(odataQuery);
    }

    /**
     * Handles rating details response.
     * @param response Rating details response.
     */
    private handleGetRatingDetails(response: ODataResponse<UserRatingDetails>) {
        this.ratings = response.value.map(x => new RatingDetails(x));
        this._ratingsCount = parseInt(response["@odata.count"]);
    }

    /**
     * Gets rating score distribution for given rated item.
     * @returns Rating score distribution observable.
     */
    private getRatingScoreDistribution(): Observable<GetRatingScoreDistrubutionResponse> {

        var request = {} as RatingsService.GetApiRatingScoreDistributionsParams;
        request.CategoryName = this.ratingSummary?.categoryName;
        request.RatedItemKey = this.ratingSummary?.ratedItemKey;

        return this._ratingsService.getApiRatingScoreDistributions(request);
    }

    /**
     * Handles rating score distribution response.
     * @param response Rating score distribution response.
     */
    private handleGetRatingScoreDistribution(response: GetRatingScoreDistrubutionResponse) {
        this.ratingScoreDistribution = new RatingScoreDistribution(response.ratingScorePercentages);
    }

    /**
     * Gets rating summary for a given rated item.
     * @returns Rating summary observable.
     */
    private getRatingSummary(): Observable<GetRatingSummaryResponse> {

        var request = {} as RatingsService.GetApiRatingSummariesParams;
        request.CategoryName = this.ratingSummary?.categoryName;
        request.RatedItemKey = this.ratingSummary?.ratedItemKey;

        return this._ratingsService.getApiRatingSummaries(request);
    }

    /**
     * Handles rating summmary response.
     * @param response Rating summary response.
     */
    private handleGetRatingSummary(response: GetRatingSummaryResponse) {
        this.ratingSummary = new UserRatingSummary(response.ratingSummaryInfo);
        this.setAverageRatingIndicator();
    }

    /**
     * Gets user rating for a given rated item.
     * @returns User rating observable.
     */
    private getUserRating(): Observable<GetUserRatingResponse> {

        var request = {} as RatingsService.GetApiUserRatingsParams;
        request.CategoryName = this.ratingSummary?.categoryName!;
        request.RatedItemKey = this.ratingSummary?.ratedItemKey!;

        return this._ratingsService.getApiUserRatings(request);
    }

    /**
     * Handles user rating response.
     * @param response User rating response.
     */
    private handleGetUserRating(response: GetUserRatingResponse) {
        this.userRatingExists = response.userRatingInfo?.score != null;
    }

    /**
     * Creates new user rating.
     */
    private onUpdateUserRating() {

        this.isAddingOrUpdatingUserRating = true;

        var updateUserRatingRequest = {} as UpdateUserRatingRequest;
        updateUserRatingRequest.categoryName = this.ratingSummary?.categoryName!;
        updateUserRatingRequest.ratedItemKey = this.ratingSummary?.ratedItemKey!;
        updateUserRatingRequest.newScore = this.selectedRatingScore!;
        updateUserRatingRequest.newComment = this.currentRatingComment;

        this._ratingsService.putApiUserRatings(updateUserRatingRequest).subscribe({
            next: response => {

                // NOTE: When internal user adds/updates rating of marketing material, we want to avoid triggering od marketing documents
                // search since it will re-render marketing documents page and "Rating Details" dialog will be closed. When external user
                // adds/updates rating of marketing material it is OK for search to be triggered, since external users don't have access
                // to "Rating Details" dialog.
                if (this.ratingSummary?.categoryName != CONSTANTS.USER_RATINGS.CATEGORIES.MARKETING_DOCUMENTATION || !this._isInternalUser) {
                    this.userRatingsChanged.emit();
                }

                this.isAddUpdateRatingDialogShown = false;
                this.isAddingOrUpdatingUserRating = false;
                this.setAverageRatingIndicator();

                if (this._isInternalUser) {
                    this.openRatingDetailsDialog();
                }
            },
            error: errorResponse => {
                this._errorResponseHandlerService.handleHttpErrorResponse(errorResponse);
                this.isAddingOrUpdatingUserRating = false;
            }
        });
    }

    /**
     * Updates existing user rating.
     */
    private onAddUserRating() {

        this.isAddingOrUpdatingUserRating = true;

        var addUserRatingRequest = {} as AddUserRatingRequest;
        addUserRatingRequest.categoryName = this.ratingSummary?.categoryName!;
        addUserRatingRequest.ratedItemKey = this.ratingSummary?.ratedItemKey!;
        addUserRatingRequest.score = this.selectedRatingScore!;
        addUserRatingRequest.comment = this.currentRatingComment;

        this._ratingsService.postApiUserRatings(addUserRatingRequest).subscribe({
            next: response => {

                // NOTE: When internal user adds/updates rating of marketing material, we want to avoid triggering od marketing documents
                // search since it will re-render marketing documents page and "Rating Details" dialog will be closed. When external user
                // adds/updates rating of marketing material it is OK for search to be triggered, since external users don't have access
                // to "Rating Details" dialog.
                if (this.ratingSummary?.categoryName != CONSTANTS.USER_RATINGS.CATEGORIES.MARKETING_DOCUMENTATION || !this._isInternalUser) {
                    this.userRatingsChanged.emit();
                }
                
                this.isAddUpdateRatingDialogShown = false;
                this.isAddingOrUpdatingUserRating = false;

                if (this._isInternalUser) {
                    this.openRatingDetailsDialog();
                }
            },
            error: errorResponse => {
                this._errorResponseHandlerService.handleHttpErrorResponse(errorResponse);
                this.isAddingOrUpdatingUserRating = false;
            }
        });
    }

    /**
     * Sets the property that controls the number of orange rating stars.
     */
    private setAverageRatingIndicator() {

        // NOTE: averageRating takes values from the set {1, 1.1, 1.2, ..., 4.8, 4.9, 5}.
        // Therefore, ratingStarsWidth takes values from the set {20, 22, 24, ..., 96, 98, 100}.
        // For example:
        // averageRating = 1   => ratingStarsWidth = 20 => 20% of the width of 5 star container will be visible, making 1 orange star visible
        // averageRating = 4.5 => ratingStarsWidth = 90 => 90% of the width of 5 star container will be visible, making 4.5 orange stars visible, etc.
        this.ratingStarsWidth = this.ratingSummary?.averageRating ? this.ratingSummary?.averageRating * 20 : 0;
    }

    /**
     * Filters ratings of given rated item based on selected score.
     */
    private filterRatingsByScore() {

        this._ratingsToSkip = 0;

        var odataQuery = this.getRatingDetailsODataQuery(this._ratingsToSkip, this.visibleRatingScores);

        this._busyIndicationService.Show(LOCALIZE_CONSTANTS.MESSAGES.GETTING_RATING_DETAILS);

        this._ratingsODataService.getApiRatingDetails(odataQuery).subscribe({
            next: response => {
                this.ratings = response.value.map(x => new RatingDetails(x));
                this._ratingsCount = parseInt(response["@odata.count"]);
                this._busyIndicationService.Hide();
            },
            error: (error) => {
                this._errorResponseHandlerService.handleHttpErrorResponse(error);
                this._busyIndicationService.Hide();
            }
        });
    }

    /**
     * Gets OData query for rating details dialog.
     * @param ratingsToSkip Ratings to skip.
     * @param visibleRatingScores Rating scores filter.
     * @returns Complete OData query.
     */
    private getRatingDetailsODataQuery(ratingsToSkip: number, visibleRatingScores?: Array<boolean>): string {

        var odataQuery = "?"
        odataQuery += this.getFilterQueryOptionExpression(visibleRatingScores);
        odataQuery += `&$skip=${ ratingsToSkip }&$top=${ CONSTANTS.USER_RATINGS.RATING_DETAILS.PAGE_SIZE }`;
        odataQuery += "&$orderby=Timestamp desc&$count=true";

        // NOTE: OData query example: ?$filter=CategoryName eq 'Software Releases' and RatedItemKey eq 'lbm|6.6.0' and (Score eq 5 or Score eq 1)&$orderby=Timestamp desc&$skip=0&$top=10&$count=true
        return odataQuery;
    }

    /**
     * Gest OData filter query option expression.
     * @param visibleRatingScores Rating scores filter.
     * @returns OData filter query option expression.
     */
    private getFilterQueryOptionExpression(visibleRatingScores?: Array<boolean>): string {

        var filterExpression =`$filter=CategoryName eq '${ this.ratingSummary?.categoryName }' and RatedItemKey eq '${ this.ratingSummary?.ratedItemKey }'`;

        if (visibleRatingScores && visibleRatingScores.includes(false)) {

            filterExpression += " and (";

            for (let i = 0; i < visibleRatingScores.length; i++) {

                if (visibleRatingScores[i]) {
                    filterExpression += `Score eq ${ 5 - i } or `;
                }
            }

            // NOTE: Remove ' or ' from the end of filter query.
            filterExpression = filterExpression.slice(0, -4);

            filterExpression += ")";
        }

        // NOTE: Character '&' appears in RatedItemKey property for product documentation user ratings. The OData query parser may interpret
        // '&' as separating query parameters rather than as part of the string, which causes exception. Therefore, '&' character is encoded.
        // NOTE: OData filter query example: ?$filter=CategoryName eq 'HTML Product Documentation' and RatedItemKey eq 'product=WPC%26productVersion=7.0.0%26category=USG%26relativePath=/docs/wpc/nhjxpq3l/en/user-guide.html'
        if (this.ratingSummary?.categoryName == CONSTANTS.USER_RATINGS.CATEGORIES.HTML_PRODUCT_DOCUMENTATION) {
            filterExpression = filterExpression.replaceAll("&", "%26");
        }
        
        return filterExpression;
    }

    //#endregion

    //#region Public Methods

    /**
     * Indicates whether rating score filter is active for a given score.
     * @param score Rating score.
     * @returns True, if filter for a given rating score is active, false otherwise.
     */
    public ratingScoreFilterActive(score: number): boolean {
        // NOTE: If all elements of visibleRatingScores are true, it means that score filter is not active.
        // Otherwise, we only need to check the value of visibleRatingScores at a given position.
        return this.visibleRatingScores.includes(false) && this.visibleRatingScores[5 - score];
    }

    //#endregion

}
