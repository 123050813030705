export class RatingScoreDistribution {

    //#region Private fields

    /**
     * Ratio of 5 star ratings to total number of ratings (expresed in %) for a given rated item.
     */
    private _fiveStarRatingPercentage: number;

    /**
     * Ratio of 4 star ratings to total number of ratings (expresed in %) for a given rated item.
     */
    private _fourStarRatingPercentage: number;

    /**
     * Ratio of 3 star ratings to total number of ratings (expresed in %) for a given rated item.
     */
    private _threeStarRatingPercentage: number;

    /**
     * Ratio of 2 star ratings to total number of ratings (expresed in %) for a given rated item.
     */
    private _twoStarRatingPercentage: number;

    /**
     * Ratio of 1 star ratings to total number of ratings (expresed in %) for a given rated item.
     */
    private _oneStarRatingPercentage: number;

    //#endregion

    //#region Constructor

    constructor(ratingScorePercentages?: Array<number>) {

        if (!ratingScorePercentages || ratingScorePercentages.length != 5) {
            this._fiveStarRatingPercentage = 0;
            this._fourStarRatingPercentage = 0;
            this._threeStarRatingPercentage = 0;
            this._twoStarRatingPercentage = 0;
            this._oneStarRatingPercentage = 0;

        } else {
            this._fiveStarRatingPercentage = ratingScorePercentages[0];
            this._fourStarRatingPercentage = ratingScorePercentages[1];
            this._threeStarRatingPercentage = ratingScorePercentages[2];
            this._twoStarRatingPercentage = ratingScorePercentages[3];
            this._oneStarRatingPercentage = ratingScorePercentages[4];
        }
    }

    //#endregion

    //#region Properties

    /**
     * Gets ratio of 5 star ratings to total number of ratings (expresed in %) for a given rated item.
     */
    public get fiveStarRatingPercentage(): number {
        return this._fiveStarRatingPercentage;
    }

    /**
     * Gets ratio of 4 star ratings to total number of ratings (expresed in %) for a given rated item.
     */
    public get fourStarRatingPercentage(): number {
        return this._fourStarRatingPercentage;
    }

    /**
     * Gets ratio of 3 star ratings to total number of ratings (expresed in %) for a given rated item.
     */
    public get threeStarRatingPercentage(): number {
        return this._threeStarRatingPercentage;
    }

    /**
     * Gets ratio of 2 star ratings to total number of ratings (expresed in %) for a given rated item.
     */
    public get twoStarRatingPercentage(): number {
        return this._twoStarRatingPercentage;
    }

    /**
     * Gets ratio of 1 star ratings to total number of ratings (expresed in %) for a given rated item.
     */
    public get oneStarRatingPercentage(): number {
        return this._oneStarRatingPercentage;
    }

    //#endregion
    
}
