
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class BusyIndicationService{

    //#region Private Fields

    /**
     * Determines if busy indicator is shown
     */
    private _isShown: boolean;

    private _main: any;

    //#endregion

    //#region Constructor

    /**
     * Initializes new instance of BusyIndicationService.
     * @param _translateService Translate service.
     */
    constructor(private _translateService: TranslateService){
        this._isShown = false;
    }

    //#endregion

    //#region Public Methods

    /**
    * Initializes BusyIndicationService
     * @param main Main html content.
     */
    public Initialize(main: any){

        this._main = main;
    }

    /**
     * @description Shows busy indication.
     * @param translationTerm Translatation term.
     */
    public Show(translationTerm: string){

        this._translateService.get(translationTerm).subscribe(translation=>{

            this._main.busyMessage = translation;
            this._main.isBusy = true;
            this._isShown = true;
        });
    }


    /**
     * @description Updates busy indicator message.
     * @param translationTerm Translation term.
     */
    public UpdateMessage(translationTerm: string){
        this._translateService.get(translationTerm).subscribe(translation=>{
            this._main.busyMessage = translation;
        });
    }

    /**
     * @description Hides busy indication.
     */
    public async Hide(){

        await this.delay(1000);
        this._main.isBusy = false;
        this._isShown = false;
    }

    //#endregion

    //#region Helpers

    /**
     * Performs delay (sleep)
     * @param delayDuration in miliseconds.
     * @returns 
     */
    public delay(delayDuration: number) {
        return new Promise( resolve => setTimeout(resolve, delayDuration) );
    }

    //#endregion
}