import { Directive } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import { CONSTANTS } from "../constants";



/**
 * Validates if the value of input field is valid semicolon separated list of email addresses.
 */
@Directive({
    selector: '[multiEmail]',
    providers:[{provide: NG_VALIDATORS, useExisting: MultiEmailValidatorDirective, multi: true}]
})
export class MultiEmailValidatorDirective implements Validator{


    //#region Validator

    validate(control: AbstractControl): ValidationErrors | null {

        if(!control.value){
            // Input valid
            // NOTE: Do not return error where there is no input
            return null
        }

        var emailAddresses: Array<string> = (control.value as string).split(";").filter(x => x).map(x => x.trim());

        for(var i = 0; i < emailAddresses.length; i++){
            
            if(!CONSTANTS.REGULAR_EXPRESSIONS.EMAIL.test(emailAddresses[i])){
                return {'multiEmail': true}; 
            }
        }
        
        // Input valid
        return null;
    }

    //#endregion
}