import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarRef } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { SNACK_TYPE } from "../../components/snackbar/snackbar.constants";
import { Observable } from "rxjs";
import { SnackbarComponent } from "../../components/snackbar/snackbar.component";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../../components/confirmation-dialog-component/confirmation-dialog.component";
import { DialogResult } from "@progress/kendo-angular-dialog";
import { DialogConstants } from "../../components/confirmation-dialog-component/confirmation-dialog.constants";


/**
 * @description Snackbar service.
 */
 @Injectable({
    providedIn: 'root'
})
export class NotificationService {

    //#region Private Fields

    /**
     * Snacbar duration in miliseconds 
     */
    private _duration: number;

    /**
     * Snackbar horizontal position:
     * - left
     * - right
     * - center
     */
    private _horizontalPosition: any;

    /**
     * Snackbar vertical position:
     * - top
     * - bottom
     * - center
     */
    private _verticalPosition: any;

    /**
     * Snacbar reference
     */
    private _snackBarRef: any;

    /**
     * Dialog reference
     */
    private _dialogReference?: MatDialogRef<ConfirmationDialogComponent, DialogResult>;

    //#endregion

    //#region Constructors

    /**
     * Initializes new instance of NotificationService.
     * @param snackBar Angular material snackbar.
     * @param _translateService Translate service.
     */
    constructor(public snackBar: MatSnackBar, private _translateService: TranslateService, private _dialog: MatDialog) {

        this._duration = 3000;
        this._verticalPosition = "top"
        this._horizontalPosition = "right";
    }

    //#endregion

    //#region Public methods

    /**
     * Closes snackbar
     */
    public close(){
        this._snackBarRef.close();
    }

    /**
     * @description Show warning notification.
     * @param translationTermOrMessage Translation term for the message to be shown or the message it self.
     * @param isMessage Determines if the first parameter is message instead of translation term.
     * @param duration Optional notification duration(ms). If not provided, configured duration will be used.
     */
    public notifyWarning(translationTermOrMessage: string, isMessage?: boolean, duration?: number){

        if(isMessage)
        {
            this._snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
                duration: duration || this._duration,
                horizontalPosition: this._horizontalPosition,
                verticalPosition: this._verticalPosition,
                data: { 
                    message: translationTermOrMessage, 
                    snackType: SNACK_TYPE.WARNING, 
                    snackBar: this.snackBar 
                }
              });
        }
        else
        {
            this._translateService.get(translationTermOrMessage).subscribe(translation=>{
                this._snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
                    duration: duration || this._duration,
                    horizontalPosition: this._horizontalPosition,
                    verticalPosition: this._verticalPosition,
                    data: { 
                        message: translation, 
                        snackType: SNACK_TYPE.WARNING, 
                        snackBar: this.snackBar 
                    }
                  });
            });
        }
    }

    /**
     * @description Show error notification.
     * @param translationTermOrMessage Translation term for the message to be shown or the message it self.
     * @param isMessage Determines if the first parameter is message instead of translation term.
     * @param duration Optional notification duration(ms). If not provided, configured duration will be used.
     */
    public notifyError(translationTermOrMessage: string, isMessage?: boolean, duration?: number){

        if(isMessage){
            this._snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
                duration: duration || this._duration,
                horizontalPosition: this._horizontalPosition,
                verticalPosition: this._verticalPosition,
                data: { 
                    message: translationTermOrMessage, 
                    snackType: SNACK_TYPE.ERROR, 
                    snackBar: this.snackBar 
                }
              });
        }else{
            this._translateService.get(translationTermOrMessage).subscribe(translation=>{
                this._snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
                    duration: duration || this._duration,
                    horizontalPosition: this._horizontalPosition,
                    verticalPosition: this._verticalPosition,
                    data: { 
                        message: translation, 
                        snackType: SNACK_TYPE.ERROR, 
                        snackBar: this.snackBar 
                    }
                  });
            });
        }

    }

    /**
     * @description Show info notification.
     * @param translationTermOrMessage Translation term for the message to be shown or the message it self.
     * @param isMessage Determines if the first parameter is message instead of translation term.
     * @param duration Optional notification duration(ms). If not provided, configured duration will be used.
     */
    public notifyInfo(translationTermOrMessage: string, isMessage?: boolean, duration?: number){

        if(isMessage)
        {
            this._snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
                duration: duration || this._duration,
                horizontalPosition: this._horizontalPosition,
                verticalPosition: this._verticalPosition,
                data: { 
                    message: translationTermOrMessage, 
                    snackType: SNACK_TYPE.INFO, 
                    snackBar: this.snackBar 
                }
              });
        }
        else
        {
            this._translateService.get(translationTermOrMessage).subscribe(translation=>{
                this._snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
                    duration: duration || this._duration,
                    horizontalPosition: this._horizontalPosition,
                    verticalPosition: this._verticalPosition,
                    data: { 
                        message: translation, 
                        snackType: SNACK_TYPE.INFO, 
                        snackBar: this.snackBar 
                    }
                  });
            });
        }
    }

    /**
     * @description Show success notification.
     * @param translationTermOrMessage Translation term for the message to be shown or the message it self.
     * @param isMessage Determines if the first parameter is message instead of translation term.
     * @param duration Optional notification duration(ms). If not provided, configured duration will be used.
     */
    public notifySuccess(translationTermOrMessage: string, isMessage?: boolean, duration?: number){

        if(isMessage)
        {
            this._snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
                duration: duration || this._duration,
                horizontalPosition: this._horizontalPosition,
                verticalPosition: this._verticalPosition,
                data: { 
                    message: translationTermOrMessage, 
                    snackType: SNACK_TYPE.SUCCESS, 
                    snackBar: this.snackBar 
                }
              });
        }
        else
        {
            this._translateService.get(translationTermOrMessage).subscribe(translation=>{
                this._snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
                    duration: duration || this._duration,
                    horizontalPosition: this._horizontalPosition,
                    verticalPosition: this._verticalPosition,
                    data: { 
                        message: translation, 
                        snackType: SNACK_TYPE.SUCCESS, 
                        snackBar: this.snackBar 
                    }
                  });
            });
        }
    }


    /**
     * Show confirmation dialog.
     * @param translationTerm Translation term for the confirmation message
     * @returns Observable of DialogResult
     */
    public showConfirmationDialog(translationTerm: string): Observable<DialogResult | undefined>{

        let dialogConfig = new MatDialogConfig();
        
        dialogConfig.minHeight = DialogConstants.DIALOG_MIN_HEIGHT;
        dialogConfig.minWidth = DialogConstants.DIALOG_MIN_WIDTH;
        dialogConfig.maxHeight = DialogConstants.DIALOG_MAX_HEIGHT;
        dialogConfig.maxWidth = DialogConstants.DIALOG_MAX_WIDTH;
        dialogConfig.height = DialogConstants.DIALOG_MIN_HEIGHT;
        dialogConfig.width = DialogConstants.DIALOG_MIN_WIDTH;
        dialogConfig.disableClose = true;
        
        dialogConfig.data = translationTerm;

        this._dialogReference = this._dialog.open(ConfirmationDialogComponent, dialogConfig);
        return this._dialogReference.afterClosed();
    }

    //#endregion

    //#region Public Properties

    messageText: string [] | undefined;
    
    //#endregion
  
  }