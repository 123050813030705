import { UserRatingInfo } from "src/app/api/opal-partner-center/models";

export class UserRating {

    //#region Constructor

    constructor(userRatingSummaryInfo?: UserRatingInfo | undefined) {

        if (userRatingSummaryInfo) {
            this.score = userRatingSummaryInfo.score;
            this.comment = userRatingSummaryInfo.comment;
        }
    }

    //#endregion

    //#region Properties

    /**
     * User rating score.
     */
    public score: number | undefined;

    /**
     * User rating comment.
     */
    public comment: string | undefined;

    //#endregion

}
