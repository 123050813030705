import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { LoginRequest, Confirm2SVRequest, AuthMethod, Confirm2FARequest, Verify2faRecoveryCodeRequest, Fido2LoginRequest, AuthenticatorAssertionRawResponse, AssertionResponse, CreateFido2CredentialAssertionOptionsRequest, PreLoginRequest } from "../../api/opal-partner-center/models";
import { AuthService, Fido2Service, InfoService } from "../../api/opal-partner-center/services";
import { LOCALIZE_CONSTANTS } from "../../shared/localize.constants";
import 'src/app/shared/extensions/ng-form.extensions';
import { AuthenticationMethod } from "./model/auth-method";
import { CONSTANTS } from "../../shared/constants";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, interval } from "rxjs";
import { NgForm } from "@angular/forms/";
import { DataConversionService } from "src/app/shared/services/data-conversion-service/data-conversion-service";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { TextBoxComponent } from "@progress/kendo-angular-inputs";
import { DropDownListComponent } from "@progress/kendo-angular-dropdowns";
import { environment } from 'src/environments/environment';
import { SECURITY_ROUTES } from "../security.routing.constants";
import { LAYOUT_ROUTES } from "src/app/layout/layout.routing.constants";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: "login",
    templateUrl:  "./login.component.html",
    styleUrls: ["./login.component.scss"],
    animations: [
      trigger('slideInOut', [
        state('in', style({ transform: 'translateX(0)' })),
        transition(':enter', [
          style({ transform: 'translateX(100%)' }),
          animate('500ms ease-out')
        ]),
        transition(':leave', [
          animate('500ms ease-in', style({ transform: 'translateX(100%)' }))
        ])
      ]),
    ],
})
export class LoginComponent{

  //#region Private Fields

  /**
   * Subscription for 2SV code lifetime countdown.
   */
  private _2svCodeLifetimeCountdownSubscription?: Subscription;

  /**
   * Subscription for lockout duration countdown.
   */
  private _lockoutDurationCountdownSubscription?: Subscription;

  /**
   * Lockout duration.
   */
  private _lockoutDuration: number;

  /**
   * Current auth method.
   */
  private _currentAuthMethod: AuthMethod | undefined;

  /**
   * Post login route.
   */
  private _postLoginRoute: string | undefined;

  /**
   * Return route query params.
   */
  private _postLoginRouteQueryParams: Params | undefined;

  /**
   * Flag that indicates if external user accepted OPAL privacy policy.
   */
  private _isPrivacyPolicyAccepted?: boolean;

  /**
   * User's language code.
   */
  private _userLanguageCode?: string;

  //#endregion

  //#region Constructor

  constructor(private _authService: AuthService, 
              private _router: Router, 
              private _translateService: TranslateService, 
              private _activatedRoute: ActivatedRoute,
              private _dataConversionService: DataConversionService,
              private _fido2Service: Fido2Service,
              private _infoService: InfoService,
              private _sanitizer: DomSanitizer) {

      this.isLoginActionInProgress = false;
      this.isAuthErrorOccurred = false;
      this.is2SVCodeExpired = false;
      this.is2FAActive = false;
      this.is2FARecoveryActive = false;
      this.isLoginEnterUsernameActive = true;
      this.isLoginEnterPasswordActive = false;
      this.isAuthMethodSelectionActive = false;
      this.isPasswordShown = false;
      this.is2SVActive = false;
      this.isFido2Active = false;
      this.emailNotConfirmed = false;
      this.authErrorMessage = "";
      this.username = "";
      this.password = "";
      this.loginActionInProgressMessage = "";
      this.twoStepVerificationCode = "";
      this.twoFactorAuthenticationCode = "";
      this.twoFactorAuthenticationRecoveryCode = "";
      this.authenticationMethods = new Array<AuthenticationMethod>();
      this.selectedAuthenticationMethod = {} as AuthenticationMethod;
      this.twoStepVerificationCodeExpiresIn = "";
      this.twoStepVerificationCodeLifetime = 0;
      this._lockoutDuration = 0;
      this.accountLockOutExpiresIn = "";
      this.isAccountLockedOut = false;
      this.isPrivacyPolicyRequirementShown = false;
      this.isDocumentationShown = false;
      this.documentationUrl = "";
  }

  //#endregion

  //#region NG Core

  ngOnInit(): void{
    this._translateService.get([LOCALIZE_CONSTANTS.SECURITY.AUTHENTICATION_METHODS.TWO_STEP_VERIFICATION, 
                                LOCALIZE_CONSTANTS.SECURITY.AUTHENTICATION_METHODS.TWO_FACTOR_AUTHENTICATION,
                                LOCALIZE_CONSTANTS.SECURITY.AUTHENTICATION_METHODS.FIDO2_AUTHENTICATION]).subscribe(translations=>{

      this.authenticationMethods.push(new AuthenticationMethod(CONSTANTS.AUTHENTICATION_METHODS.TWO_STEP_VERIFICATION, translations[LOCALIZE_CONSTANTS.SECURITY.AUTHENTICATION_METHODS.TWO_STEP_VERIFICATION]));
      this.authenticationMethods.push(new AuthenticationMethod(CONSTANTS.AUTHENTICATION_METHODS.TWO_FACTOR_AUTHENTICATION, translations[LOCALIZE_CONSTANTS.SECURITY.AUTHENTICATION_METHODS.TWO_FACTOR_AUTHENTICATION]));
      this.authenticationMethods.push(new AuthenticationMethod(CONSTANTS.AUTHENTICATION_METHODS.FIDO2_AUTHENTICATION, translations[LOCALIZE_CONSTANTS.SECURITY.AUTHENTICATION_METHODS.FIDO2_AUTHENTICATION]));
      this.selectedAuthenticationMethod = this.authenticationMethods[0];  
    });

    this.emailNotConfirmed = false;

    // Navigate to default page if user is already authenticated.
    this._infoService.getApiIsAuthenticated().subscribe({
      next: response =>{
        this._router.navigate([""]);
      },
      error: errorResponse=>{}
    });
    
    this._infoService.getApiAppInfo().subscribe({
      next: response=>{

        if(response.quickUserGuideUrl){
          this.documentationUrl = this._sanitizer.bypassSecurityTrustResourceUrl(response.quickUserGuideUrl!);
        } 
      }
    })

    this._activatedRoute.queryParams.subscribe(params=>{

      var loginFailed = params["loginFailed"];
      if(loginFailed && loginFailed == "true"){
        
        this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.LOGIN_FAILED).subscribe(translation=>{
          this.authErrorMessage = translation;
          this.isAuthErrorOccurred = true;
        })
      } else {
        if(params[CONSTANTS.QUERY_PARAMS.POST_LOGIN_ROUTE]){

          const url = new URL(params[CONSTANTS.QUERY_PARAMS.POST_LOGIN_ROUTE], 'http://dummy-base')
          this._postLoginRoute = url.pathname;

          if(url.search){
            this._postLoginRouteQueryParams = this._router.parseUrl(url.search).queryParams;
          } else {
            this._postLoginRouteQueryParams = undefined;
          }
        }else {
          this._postLoginRoute = undefined;
          this._postLoginRouteQueryParams = undefined;
        }
      }
    });
  }

  ngAfterViewInit(): void{  
    this.usernameInput?.focus();
  }

  //#endregion

  //#region Public properties

  /**
   * Authentication form. Used for:
   * - Login
   * - 2SV
   */
  @ViewChild("authForm")
  public authForm?: NgForm;

  /**
   * Username input.
   */
  @ViewChild("usernameInput")
  public usernameInput?: TextBoxComponent;

  /**
   * Password input.
   */
  @ViewChild("passwordInput")
  public passwordInput?: TextBoxComponent;

  /**
   * Two step verificateion code input.
   */
  @ViewChild("twoStepVerifCodeInput")
  public twoStepVerifCodeInput?: TextBoxComponent;

  /**
   * Two factor auth code input.
   */
  @ViewChild("twoFactorAuthCodeInput")
  public twoFactorAuthCodeInput?: TextBoxComponent;

  /**
   * Two factor recovery code input.
   */
  @ViewChild("twoStepAuthRecoveryCodeInput")
  public twoStepAuthRecoveryCodeInput?: TextBoxComponent;

  /**
   * Authentication methods input.
   */
  @ViewChild("authenticationMethodsInput")
  public authenticationMethodsInput?: DropDownListComponent;

  /**
   * Username
   */
  public username?: string;

  /**
   * User password
   */
  public password?: string;

  /**
   * Determines if password is shown.
   */
  public isPasswordShown: boolean;

  /**
   * Determines if login is in progress.
   */
  public isLoginActionInProgress: boolean;

  /**
   * Determines if error occured during authentication process.
   */
  public isAuthErrorOccurred: boolean;

  /**
   * Authentication error message.
   */
  public authErrorMessage: string;

  /**
   * Determines if login for entering username is shown.
   */
  public isLoginEnterUsernameActive: boolean;

  /**
   * Determines if login for entering user password is shown.
   */
  public isLoginEnterPasswordActive: boolean;

  /**
   * Determines if 2SV is shown.
   */
  public is2SVActive: boolean;

  /**
   * Authentication methods
   */
  public authenticationMethods: Array<AuthenticationMethod>;

  /**
   * Selected authentication method
   */
  public selectedAuthenticationMethod: AuthenticationMethod;

  /**
   * 2SV code.
   */
  public twoStepVerificationCode?: string;

  /**
   * 2SV code lifetime in minutes.
   */
  public twoStepVerificationCodeLifetime: number;

  /**
   * Determines if 2SV code is expired.
   */
  public is2SVCodeExpired: boolean;

  /**
   * 2SV code expiration.
   */
  public twoStepVerificationCodeExpiresIn: string;

  /**
   * Determines if email is not confirmed.
   */
  public emailNotConfirmed: boolean;

  /**
   * Determines if 2FA is active.
   */
  public is2FAActive: boolean;

  /**
   * Two factor authentication code.
   */
  public twoFactorAuthenticationCode: string;

  /**
   * Login action in progress message.
   */
  public loginActionInProgressMessage: string;

  /**
   * Two factor authentication recovery code.
   */
  public twoFactorAuthenticationRecoveryCode: string;

  /**
   * Determines if 2FA recovery is active.
   */
  public is2FARecoveryActive: boolean;

  /**
   * Determines if authentication method selection is active.
   */
  public isAuthMethodSelectionActive: boolean;

  /**
   * Determines if FIDO2 authentication is in progress.
   */
  public isFido2Active: boolean;

  /**
   * Account lockout expiration.
   */
  public accountLockOutExpiresIn: string;

  /**
   * Determines if account is locked out.
   */
  public isAccountLockedOut: boolean;

  /**
   * Flag that indicates if privacy policy acceptance reuirement is shown.
   */
  public isPrivacyPolicyRequirementShown: boolean;

  /**
   * Flag that indicates if documentation is shown.
   */
  public isDocumentationShown: boolean;

  /**
   * Documentation URL.
   */
  public documentationUrl: SafeResourceUrl;

  //#endregion

  //#region UI Handlers

  /**
   * Shows documentation.
   */
  public onShowDocumentation(): void{

    if(this.documentationUrl){
      this.isDocumentationShown = true;
    } 
  }

  /**
   * Hides documentation.
   */
  public onHideDocumentation():void{
    this.isDocumentationShown = false;
  }

  public onLoginWithMicrosoft():void{

    this.authForm?.resetValidation();

    let postLoginRedirectUrl: string;

    if(this._postLoginRoute){

      postLoginRedirectUrl = this._postLoginRoute;

      if(this._postLoginRouteQueryParams){

        let queryParams = this.queryParamsToString(this._postLoginRouteQueryParams);

        postLoginRedirectUrl += `?${queryParams}`;
      }

      postLoginRedirectUrl = encodeURIComponent(postLoginRedirectUrl);
      location.href = `${environment.apiRootUrl}${AuthService.getApiExternalLoginPath}?providerName=${CONSTANTS.LOGIN_PROVIDERS.MICROSOFT}&redirectUrl=${postLoginRedirectUrl}`;

    } else {
      location.href = environment.apiRootUrl + AuthService.getApiExternalLoginPath + "?providerName=" + CONSTANTS.LOGIN_PROVIDERS.MICROSOFT;
    }
  }

  /**
   * Show password.
   * @param event Event
   */
  public onShowPassword(event: any): void{
    this.isPasswordShown = true;
    this.passwordInput!.input.nativeElement.type = "text";
  }

  /**
   * Hide password.
   * @param event Event
   */
  public onHidePassword(event: any): void{
    this.isPasswordShown = false;
    this.passwordInput!.input.nativeElement.type = "password";
  }

  /**
   * Called on authentication method change.
   * @param event Selected authentication method.
   */
  public onAuthenticationMethodChange(selectedAuthMethod: AuthenticationMethod): void {
    
    this.selectedAuthenticationMethod = selectedAuthMethod;
    this.isAuthErrorOccurred = false;
  }

  /**
   * Cancels login.
   */
  public onLoginCancel():void {
    this.username = "";
    this.password = "";
    this._postLoginRoute = undefined;
    this._postLoginRouteQueryParams = undefined;
    this.isAuthErrorOccurred = false;
    this.authErrorMessage = "";
    this.loginActionInProgressMessage = "";
    this.isLoginActionInProgress = false;
    this.authForm?.resetValidation();
    this.isLoginEnterPasswordActive = false;
    this.isAuthMethodSelectionActive = false;
    this.is2SVActive = false;
    this.is2FAActive = false;
    this.isFido2Active = false;
    this.is2FARecoveryActive = false;
    this.isLoginEnterUsernameActive = true;
    this._currentAuthMethod = undefined;
    setTimeout(()=>{this.usernameInput?.focus()}, 200);
    this._lockoutDurationCountdownSubscription?.unsubscribe();
    this.isAccountLockedOut = false;
    this.isPrivacyPolicyRequirementShown = false;
    this._isPrivacyPolicyAccepted = undefined;
    this._userLanguageCode = undefined;
  }

  /**
   * Performes pre-login. The outcome can be either:
   * - Request user to enter password (If default authentication method is not FIDO)
   * - Initiate FIDO2 login (If default authentication method is FIDO)
   */
  public onUserPreLogin():void {

    if(this.authForm?.invalid){
      return;
    }

    var request = {} as PreLoginRequest;
    request.userName = this.username as string;
    this.isLoginActionInProgress = true;
    // TODO: Set login action progress message

    this._authService.postApiPreLogin(request).subscribe({
      next: response=>{

        this.isAuthErrorOccurred = false;
        this.authErrorMessage = "";

        if(response.preferFido2){
          this.isFido2Active = true;
          this.isLoginEnterUsernameActive = false;
          this.onUserFido2Login();
        }else{
          this.isLoginActionInProgress = false;
          this.isLoginEnterUsernameActive = false;
          this.isLoginEnterPasswordActive = true;
          this._isPrivacyPolicyAccepted = response.isPrivacyPolicyAccepted;
          this._userLanguageCode = response.languageCode;

          setTimeout(()=>{
            this.passwordInput!.input.nativeElement.type = "password";
            this.passwordInput?.focus()
          }, 200);
        }
      },
      error: errorResponse=>{
        this.isLoginActionInProgress = false;
        this.handleHttpErrorResponse(errorResponse);
      }
    });
  }

  /**
   * Called on user login attempt.
   */
  public onUserLogin(): void {

    var request = {} as LoginRequest;
    request.userName = this.username as string;
    request.password = this.password as string;
    
    if(this._currentAuthMethod != undefined){
      request.authenticationMethod = this._currentAuthMethod;
    }

    this._translateService.get(LOCALIZE_CONSTANTS.MESSAGES.LOGIN_IN_PROGRESS).subscribe(translation=>{
      this.loginActionInProgressMessage = translation;
    });

    this.isLoginActionInProgress = true;
    this.isAuthErrorOccurred = false;
    this.authErrorMessage = "";

    this._authService.postApiLogin(request).subscribe({
      next: response=>{

        if(response.authenticationMethod == CONSTANTS.AUTHENTICATION_METHODS.PASSWORD){
          // Allows default admin to perform authentication if SMTP configuration on the server is not valid
          this.isLoginActionInProgress = false;
          this.isLoginEnterUsernameActive = false;
          this.username = "";
          this.password = "";
          this.finishLogin();

        } else if(response.authenticationMethod == CONSTANTS.AUTHENTICATION_METHODS.TWO_STEP_VERIFICATION){
          this.isLoginEnterPasswordActive = false;
          this.twoStepVerificationCode = "";
          this.twoStepVerificationCodeLifetime = response.twoStepVerificationCodeLifetime! * 60;
          
          this.update2SVCodeLifetimeExpiration();
          this.countdown2SVTokenValidity();

          this.isLoginActionInProgress = false;
          this.isLoginEnterUsernameActive = false;
          this.is2SVActive = true;
          this.is2SVCodeExpired = false;
          setTimeout(()=>{this.twoStepVerifCodeInput?.focus()}, 200);
        } else if(response.authenticationMethod == CONSTANTS.AUTHENTICATION_METHODS.TWO_FACTOR_AUTHENTICATION){
          
          this.isLoginEnterPasswordActive = false;
          this.isLoginActionInProgress = false;
          this.isLoginEnterUsernameActive = false;
          this.is2FAActive = true;
          setTimeout(()=>{this.twoFactorAuthCodeInput?.focus()}, 200);
        }
      },
      error: errorResponse =>{

        this.isLoginActionInProgress = false;
        this.handleHttpErrorResponse(errorResponse);
      }
    });
  }

  /**
   * Called just before 2SV is confirmed.
   */
  onCheckPrivacyPolicyAcceptanceStatus() {

    // NOTE: this._isPrivacyPolicyAccepted == null indicates that internal user tries to log in (for internal users that property is never set)
    if (this._isPrivacyPolicyAccepted == null || this._isPrivacyPolicyAccepted) {
      this.onConfirmTwoStepVerification();

    } else {
      this.isPrivacyPolicyRequirementShown = true;
    }
  }

  /**
   * Called when 2SV is confirmed.
   */
  public onConfirmTwoStepVerification(): void {

    this.isPrivacyPolicyRequirementShown = false;

    if(this.authForm?.invalid){
      return;
    }

    this._2svCodeLifetimeCountdownSubscription?.unsubscribe();
    this.isLoginActionInProgress = true;
    this._translateService.get(LOCALIZE_CONSTANTS.MESSAGES.VERIFYING_2SV_CODE).subscribe(translation=>{
      this.loginActionInProgressMessage = translation;
    });

    this.isAuthErrorOccurred = false;
    this.authErrorMessage = "";
    this.is2SVCodeExpired = false;

    var request = {} as Confirm2SVRequest;
    request.verificationToken = (this.twoStepVerificationCode as string).trim();
    
    this._authService.postApiConfirm2SV(request).subscribe({
      next: response => {
        this.isLoginActionInProgress = false;
        this.is2SVActive = false;
        this.isLoginEnterUsernameActive = true;
        this.finishLogin();
      },
      error: errorResponse=>{
        this.isLoginActionInProgress = false;
        this.twoStepVerificationCode = "";
        this.authForm?.resetValidation();
        this.handleHttpErrorResponse(errorResponse);
      }
    });

  }

  /**
   * Called on 2SV code refresh attempt.
   */
  public onRefresh2SVCode(): void {

    this._2svCodeLifetimeCountdownSubscription?.unsubscribe();
    this.is2SVCodeExpired = false;
    this.isLoginActionInProgress = true;
    this.authErrorMessage = "";
    this.isAuthErrorOccurred = false;
    this.twoStepVerificationCode = "";
    this.authForm?.resetValidation();

    this._translateService.get(LOCALIZE_CONSTANTS.MESSAGES.RESENDING_2SV_CODE).subscribe(translation=>{
      this.loginActionInProgressMessage = translation;
    })

    var request = {} as LoginRequest;
    request.userName = this.username as string;
    request.password = this.password as string;
    request.authenticationMethod = CONSTANTS.AUTHENTICATION_METHODS.TWO_STEP_VERIFICATION as AuthMethod;

    this._authService.postApiLogin(request).subscribe({
      next: response=>{

        this.isLoginActionInProgress = false;

        if(response.authenticationMethod == CONSTANTS.AUTHENTICATION_METHODS.PASSWORD){
          // Allows default admin to perform authentication if SMTP configuration on the server is not valid
          this.isLoginActionInProgress = false;
          this.isLoginEnterUsernameActive = false;
          this.username = "";
          this.password = "";
          this.finishLogin();

        } else if(response.authenticationMethod == CONSTANTS.AUTHENTICATION_METHODS.TWO_STEP_VERIFICATION){
          this.twoStepVerificationCode = "";
          this.twoStepVerificationCodeLifetime = response.twoStepVerificationCodeLifetime! * 60;
          
          this.update2SVCodeLifetimeExpiration();
          this.countdown2SVTokenValidity();

        } else {
          this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNEXPECTED_SERVER_RESPONSE).subscribe(translation=>{
            this.authErrorMessage = translation;
          })
        }
      },
      error: errorResponse =>{

        this.isLoginActionInProgress = false;
        this.handleHttpErrorResponse(errorResponse);
      }
    });
  }

  /**
   * Called on user registration initiation.
   */
  public onRegisterUser(): void {
    if(this.isLoginActionInProgress){
      return;
    }

    this._router.navigate([SECURITY_ROUTES.SECURITY_USER_REGISTRATION]);
  }

  /**
   * Called on 2FA code confirmation.
   */
  public onConfirmTwoFactorAuthentication(): void {

    if(this.authForm?.invalid){
      return;
    }

    this.authErrorMessage = "";
    this.isAuthErrorOccurred = false;
    this.isLoginActionInProgress = true;
    
    this._translateService.get(LOCALIZE_CONSTANTS.MESSAGES.VERIFYING_2FA_CODE).subscribe(translation=>{
      this.loginActionInProgressMessage = translation;
    });

    var request = {} as Confirm2FARequest;
    request.verificationToken = (this.twoFactorAuthenticationCode as string).trim();
    this._authService.postApiConfirm2FA(request).subscribe({
      next: response => {
        this.isLoginActionInProgress = false;
        this.finishLogin();
      },
      error: errorResponse => {
        this.isLoginActionInProgress = false;
        this.handleHttpErrorResponse(errorResponse);
      }
    });
  }

  /**
   * Called on user request to bypass 2FA verification code.
   */
  public onBypass2faVerificationCode(): void {

    if(this.isLoginActionInProgress){
      return;
    }

    this.is2FAActive = false;
    this.is2FARecoveryActive = true;
    this.authErrorMessage = "";
    this.isAuthErrorOccurred = false;
    setTimeout(()=>{this.twoStepAuthRecoveryCodeInput?.focus()}, 200);
  }

  /**
   * Called on 2FA recovery code confirmation.
   */
  public onConfirmTwoFactorRecovery(): void {
    
    if(this.authForm?.invalid){
      return
    }

    this.authErrorMessage = "";
    this.isAuthErrorOccurred = false;
    this.isLoginActionInProgress = true;

    this._translateService.get(LOCALIZE_CONSTANTS.MESSAGES.VERIFYING_2FA_CODE).subscribe(translation=>{
      this.loginActionInProgressMessage = translation;
    });

    var request = {} as Verify2faRecoveryCodeRequest;
    request.recoveryCode = (this.twoFactorAuthenticationRecoveryCode as string).trim();

    this._authService.postApiVerify2faRecoveryCode(request).subscribe({
      next: response => {
        this.isLoginActionInProgress = false;
        this.finishLogin();
      },
      error: errorResponse => {
        this.isLoginActionInProgress = false;
        this.handleHttpErrorResponse(errorResponse);
      }
    });
  }

  /**
   * Initiates FIDO2 login.
   */
  public onUserFido2Login(): void{

    this.isLoginActionInProgress = true;
    this.authForm?.resetValidation();
    this.isLoginActionInProgress = true;
    this.authErrorMessage = "";
    this.isAuthErrorOccurred = false;

    this._translateService.get(LOCALIZE_CONSTANTS.MESSAGES.LOGIN_IN_PROGRESS).subscribe(translation=>{
      this.loginActionInProgressMessage = translation;
    });
    var request = {} as CreateFido2CredentialAssertionOptionsRequest;

    request.userName = this.username as string;
    // Get credential assetion options
    this._fido2Service.postApiFido2CredentialAssertionOptions(request).subscribe({
      next: response => {

        var credentialRequestOptions = {} as PublicKeyCredentialRequestOptions

        var challengeString = response.assertionOptions?.challenge as unknown as string;
        challengeString = this._dataConversionService.base64UrlStringToBase64String(challengeString);
        credentialRequestOptions.challenge = Uint8Array.from(atob(challengeString), c => c.charCodeAt(0));
        credentialRequestOptions.extensions = response.assertionOptions?.extensions;
        credentialRequestOptions.timeout = response.assertionOptions?.timeout;
        credentialRequestOptions.rpId = response.assertionOptions?.rpId;
        credentialRequestOptions.userVerification = response.assertionOptions?.userVerification;        
        credentialRequestOptions.allowCredentials = response.assertionOptions?.allowCredentials as PublicKeyCredentialDescriptor[];
        credentialRequestOptions.allowCredentials.forEach(credential => {
          credential.id = this._dataConversionService.coerceToArrayBuffer(credential.id);
        });

        navigator.credentials.get({
          publicKey: credentialRequestOptions
        }).then((assertion: any) => {

          var request = {} as Fido2LoginRequest;
          request.userName = this.username as string;
          request.authenticatorAssertionRawResponse = {} as AuthenticatorAssertionRawResponse;
          request.authenticatorAssertionRawResponse.id = assertion.id;          
          request.authenticatorAssertionRawResponse.rawId = this._dataConversionService.coerceToBase64Url(assertion.rawId);
          request.authenticatorAssertionRawResponse.type = assertion.type;
          request.authenticatorAssertionRawResponse.extensions = assertion.getClientExtensionResults();
          request.authenticatorAssertionRawResponse.response = {} as AssertionResponse;
          request.authenticatorAssertionRawResponse.response.authenticatorData = this._dataConversionService.coerceToBase64Url(assertion.response.authenticatorData);
          request.authenticatorAssertionRawResponse.response.clientDataJSON = this._dataConversionService.coerceToBase64Url(assertion.response.clientDataJSON);
          request.authenticatorAssertionRawResponse.response.signature = this._dataConversionService.coerceToBase64Url(assertion.response.signature);

          this._fido2Service.postApiFido2Login(request).subscribe({
            next: response => {
              // All good, finish login.
              this.finishLogin();
            },
            error: errorResponse => {
              this.isLoginActionInProgress = false;
              
              this.handleHttpErrorResponse(errorResponse);
            }
          });
        }).catch((error: any) => {
          this.isLoginActionInProgress = false;
          this.isAuthErrorOccurred = true;
          this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.LOGIN_FAILED).subscribe(translation=>{
            this.authErrorMessage = translation;
          })
        });
      },
      error: errorResponse => {
        this.isLoginActionInProgress = false;

        if(errorResponse && (errorResponse.status == 401 || errorResponse.status == 403)){
          
          this.isAuthErrorOccurred = true;

          if(errorResponse.error && errorResponse.error.errorCode == CONSTANTS.SERVER_ERROR_CODES.USER_IS_LOCKED){
            
            var fido2LoginResponse = errorResponse.error as any;
            this._lockoutDuration = fido2LoginResponse.lockoutDuration!;
            this.countdownLockoutDuration();
          } else {
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.LOGIN_FAILED).subscribe(translation=>{
              this.authErrorMessage = translation;
            });
          }
        }else{
          this.handleHttpErrorResponse(errorResponse);
        }
      }
    });

  }

  /**
   * Show auth method selection.
   */
  public onSelectAnotherAuthMethod():void{
    this.is2SVActive = false;
    this.is2FAActive = false;
    this.isFido2Active = false;
    this.isLoginEnterPasswordActive = false;
    this.isAuthMethodSelectionActive = true;
    this.password = "";
    this.isAccountLockedOut = false;
    this._lockoutDurationCountdownSubscription?.unsubscribe();

    switch(this.selectedAuthenticationMethod.id){
      case CONSTANTS.AUTHENTICATION_METHODS.TWO_STEP_VERIFICATION:
        this._currentAuthMethod = CONSTANTS.AUTHENTICATION_METHODS.TWO_STEP_VERIFICATION as AuthMethod;
        break;
      case CONSTANTS.AUTHENTICATION_METHODS.TWO_FACTOR_AUTHENTICATION:
        this._currentAuthMethod = CONSTANTS.AUTHENTICATION_METHODS.TWO_FACTOR_AUTHENTICATION as AuthMethod;
        break;
      case CONSTANTS.AUTHENTICATION_METHODS.FIDO2_AUTHENTICATION:
        this._currentAuthMethod = CONSTANTS.AUTHENTICATION_METHODS.FIDO2_AUTHENTICATION as AuthMethod;
        break;
    }

    setTimeout(()=>{this.authenticationMethodsInput?.focus()}, 200);
  }

  /**
   * Called when user selects authentication method.
   */
  public onLoginWithSelectedAuthMethod():void{

    this.isAuthMethodSelectionActive = false;
    this.isAuthErrorOccurred = false;
    this.authErrorMessage = "";

    switch(this.selectedAuthenticationMethod.id){
      case CONSTANTS.AUTHENTICATION_METHODS.FIDO2_AUTHENTICATION:
        this.isFido2Active = true;
        this.onUserFido2Login();
        break;
      default:
        this._currentAuthMethod = this.selectedAuthenticationMethod.id as AuthMethod;
        this.isLoginEnterPasswordActive = true;
        setTimeout(()=>{this.passwordInput?.focus()}, 200);
        break;
    }
  }

  /**
   * Navigates to OPAL Privacy Policy page.
   */
  public onNavigateToPrivacyPolicyPage() {

    let privacyPolicyUrl: string | undefined;

    if (this._userLanguageCode) {

      let languageCode = this._userLanguageCode.split("-")[0];

      switch (languageCode) {
        case CONSTANTS.LANGUAGE_CODE.ENGLISH:
          privacyPolicyUrl = CONSTANTS.PRIVACY_POLICY.LINKS.ENGLISH;
          break;
        case CONSTANTS.LANGUAGE_CODE.GERMAN:
          privacyPolicyUrl = CONSTANTS.PRIVACY_POLICY.LINKS.GERMAN;
          break;
        case CONSTANTS.LANGUAGE_CODE.FRENCH:
          privacyPolicyUrl = CONSTANTS.PRIVACY_POLICY.LINKS.FRENCH;
          break;
        default:
          privacyPolicyUrl = CONSTANTS.PRIVACY_POLICY.LINKS.ENGLISH;
          break;
      }

      window.open(privacyPolicyUrl, '_blank');
    }
  }

  //#endregion

  //#region Private methods

  /**
   * Countdowns lockout duration.
   */
  private countdownLockoutDuration():void{

    if(this._lockoutDurationCountdownSubscription){
      this._lockoutDurationCountdownSubscription.unsubscribe();
    }

    this.isAccountLockedOut = true;
    this.updateLockoutDurationExpiration();
    this._lockoutDurationCountdownSubscription = interval(1000).subscribe(()=>{
      if(this._lockoutDuration > 0){
        this._lockoutDuration--;
        this.updateLockoutDurationExpiration();
      } else {
        this.isAccountLockedOut = false;
        this.isAuthErrorOccurred = false;
        this.accountLockOutExpiresIn = "";
        this._lockoutDurationCountdownSubscription?.unsubscribe();
      }
    });
  }

  /**
   * Updates lockout duration expiration.
   */
  private updateLockoutDurationExpiration():void {

    this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.USER_IS_LOCKED).subscribe(translation=>{

      var minutes = Math.floor(this._lockoutDuration / 60);
      var seconds = this._lockoutDuration % 60;
      this.accountLockOutExpiresIn = minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
      this.authErrorMessage = translation + this.accountLockOutExpiresIn + "!";
    })
  }

  /**
   * Finishes login process:
   * Navigates to default page and reloads the page.
   */
  private finishLogin():void{

    if(this._postLoginRoute){

      if(this._postLoginRouteQueryParams){
        // Navigate to return route with query params.
        this._router.navigate([this._postLoginRoute], {queryParams: this._postLoginRouteQueryParams}).then(()=>{
          
          if(this._postLoginRoute == "/" + LAYOUT_ROUTES.FILE_DOWNLOAD){
            this._router.navigate([""]);
          }
        });
        
      } else {
        // Navigate to return route.
        this._router.navigate([this._postLoginRoute]);
      }
    } else {

      // Navigate to default route.
      this._router.navigate([""]);
    }
  }

  /**
   * Countdowns 2SV code lifetime.
   */
  private countdown2SVTokenValidity():void {

    this._2svCodeLifetimeCountdownSubscription = interval(1000).subscribe(()=>{
      if(this.twoStepVerificationCodeLifetime > 0){
        this.twoStepVerificationCodeLifetime--;
        this.update2SVCodeLifetimeExpiration();
      } else {
        this.is2SVCodeExpired = true;
        this.twoStepVerificationCode = "";
        this.authForm?.resetValidation();
        this._2svCodeLifetimeCountdownSubscription?.unsubscribe();
      }
    })
  }

  /**
   * Updates 2SV code lifetime expiration.
   */
  private update2SVCodeLifetimeExpiration(): void {
    var minutes = Math.floor(this.twoStepVerificationCodeLifetime / 60);
    var seconds = this.twoStepVerificationCodeLifetime % 60;
    this.twoStepVerificationCodeExpiresIn = minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
  }

  /**
   * Handles error response.
   * @param httpErrorResponse Http error response.
   */
  private handleHttpErrorResponse(httpErrorResponse: any): void {

    this.authErrorMessage = "";
    this.isAuthErrorOccurred = true;

    if(httpErrorResponse){

      if(httpErrorResponse.error && httpErrorResponse.error.errorCode){

        switch(httpErrorResponse.error.errorCode){
          case CONSTANTS.SERVER_ERROR_CODES.LOGIN_FAILED:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.LOGIN_FAILED).subscribe(translation=>{
              this.authErrorMessage = translation;
            })
            break;

          case CONSTANTS.SERVER_ERROR_CODES.AUTHENTICATION_METHOD_TEMPORARELY_UNAVAILABLE:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.AUTHENTICATION_METHOD_TEMPORARELY_UNAVAILABLE).subscribe(translation=>{
              this.authErrorMessage = translation;
            })
            break;

          case CONSTANTS.SERVER_ERROR_CODES.TWO_STEP_VERIFICATION_CODE_EXPIRED_OR_INVALID:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.TWO_STEP_VERIFICATION_CODE_EXPIRED_OR_INVALID).subscribe(translation=>{
              this.authErrorMessage = translation;
            })

            break;

          case CONSTANTS.SERVER_ERROR_CODES.UNABLE_TO_CREATE_FIDO2_CREDENTIAL_ASSERTION_OPTIONS:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNABLE_TO_CREATE_FIDO2_CREDENTIAL_ASSERTION_OPTIONS).subscribe(translation=>{
              this.authErrorMessage = translation;
            })

            break;

          case CONSTANTS.SERVER_ERROR_CODES.FIDO2_CREDENTIAL_INVALID:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.FIDO2_CREDENTIAL_INVALID).subscribe(translation=>{
              this.authErrorMessage = translation;
            })

            break;

          case CONSTANTS.SERVER_ERROR_CODES.EMAIL_NOT_CONFIRMED:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.EMAIL_NOT_CONFIRMED).subscribe(translation=>{
              this.authErrorMessage = translation;
              this.emailNotConfirmed = true;
              this.username = "";
              this.password = "";
              //
              this.authForm?.resetValidation();
            })
            break;

          case CONSTANTS.SERVER_ERROR_CODES.EMAIL_SEND_FAILED:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNABLE_TO_SEND_EMAIL_CONFIRMATION_EMAIL).subscribe(translation=>{
              this.authErrorMessage = translation;
            })
            break;

          case CONSTANTS.SERVER_ERROR_CODES.TWO_FACTOR_AUTHENTICATION_CODE_INVALID:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.TWO_FACTOR_AUTHENTICATION_CODE_INVALID).subscribe(translation=>{
              this.authErrorMessage = translation;
            })
            break;

          case CONSTANTS.SERVER_ERROR_CODES.AUTHENTICATION_METHOD_NOT_SUPPORTED:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.AUTHENTICATION_METHOD_NOT_SUPPORTED).subscribe(translation=>{
              this.authErrorMessage = translation;
            })
            break;

          case CONSTANTS.SERVER_ERROR_CODES.WRONG_AUTHENTICATION_METHOD_SPECIFIED:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.WRONG_AUTHENTICATION_METHOD_SPECIFIED).subscribe(translation=>{
              this.authErrorMessage = translation;
            })
            break;

          case CONSTANTS.SERVER_ERROR_CODES.TWO_FACTOR_RECOVERY_CODE_INVALID:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.TWO_FACTOR_RECOVERY_CODE_INVALID).subscribe(translation=>{
              this.authErrorMessage = translation;
            })
            break;

          case CONSTANTS.SERVER_ERROR_CODES.TWO_FACTOR_RECOVERY_VALIDATION_FAILED:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.TWO_FACTOR_RECOVERY_VALIDATION_FAILED).subscribe(translation=>{
              this.authErrorMessage = translation;
            })
            break;

          case CONSTANTS.SERVER_ERROR_CODES.USER_IS_DISABLED:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.USER_IS_DISABLED).subscribe(translation=>{
              this.authErrorMessage = translation;
            })
            break;

          case CONSTANTS.SERVER_ERROR_CODES.USER_IS_LOCKED:

            var loginResponse = httpErrorResponse.error as any;
            this._lockoutDuration = loginResponse.lockoutDuration!;
            this.countdownLockoutDuration();
            
            break;

          default:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNEXPECTED_SERVER_ERROR).subscribe(translation=>{
              this.authErrorMessage = translation;
            })
        }

      } else {

        switch(httpErrorResponse.status)
        {
          case 0:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.CONNECTION_PROBLEM).subscribe(translation=>{
              this.authErrorMessage = translation;
            })
            break;

          case 400:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.INVALID_REQUEST).subscribe(translation=>{
              this.authErrorMessage = translation;
            })
            break;

          case 404:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.RESOURCE_NOT_FOUND).subscribe(translation=>{
              this.authErrorMessage = translation;
            })
            break;

          case 401:
          case 403:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNAUTHORIZED_REQUEST).subscribe(translation=>{
              this.authErrorMessage = translation;
            })
            break;

          case 409:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.RESOURCE_ALREADY_EXIST).subscribe(translation=>{
              this.authErrorMessage = translation;
            })
            break;

          default:
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNEXPECTED_HTTP_STATUS_CODE).subscribe(translation=>{
              this.authErrorMessage = translation+ httpErrorResponse.status;
            })
            break;
        }
      }
    } else{
      this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.CONNECTION_PROBLEM).subscribe(translation=>{
        this.authErrorMessage = translation;
      })
    }
  }

  /**
   * Gets query params string from query params.
   * @param queryParams Query params.
   * @returns Query params as string.
   */
  private queryParamsToString(queryParams: Params): string {
    const searchParams = new URLSearchParams();
    for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
            const value = queryParams[key];
            // Handle if the value is an array (common with multiple query params of the same key)
            if (Array.isArray(value)) {
                value.forEach(item => searchParams.append(key, item));
            } else {
                searchParams.set(key, value);
            }
        }
    }
    return searchParams.toString();
}

  //#endregion
  
}