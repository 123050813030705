export abstract class LOCALIZE_CONSTANTS {
    
    static LANGUAGES = class {
        static readonly ALL = "LANGUAGES.ALL"
        static readonly ENGLISH = "LANGUAGES.ENGLISH";
        static readonly GERMAN = "LANGUAGES.GERMAN";
        static readonly FRENCH = "LANGUAGES.FRENCH";
        static readonly ITALIAN = "LANGUAGES.ITALIAN";
    }
    
    static MESSAGES = class {
        static readonly GETTING_USER_INFO = "MESSAGES.GETTING_USER_INFO";
        static readonly GETTING_USER_SECURITY_SETTINGS = "MESSAGES.GETTING_USER_SECURITY_SETTINGS";
        static readonly SAVING_USER_SECURITY_SETTINGS = "MESSAGES.SAVING_USER_SECURITY_SETTINGS";
        static readonly GETTING_PRODUCTS_RELEASES = "MESSAGES.GETTING_PRODUCTS_RELEASES";
        static readonly GETTING_PRODUCT_RELEASE_DETAILS = "MESSAGES.GETTING_PRODUCT_RELEASE_DETAILS";
        static readonly SAVING_PRODUCT_RELEASE = "MESSAGES.SAVING_PRODUCT_RELEASE";
        static readonly NEW_PRODUCT_RELEASE_SAVED = "MESSAGES.NEW_PRODUCT_RELEASE_SAVED";
        static readonly UPDATING_PRODUCT_RELEASE = "MESSAGES.UPDATING_PRODUCT_RELEASE";
        static readonly DELETING_PRODUCT_RELEASE = "MESSAGES.DELETING_PRODUCT_RELEASE";
        static readonly UPDATING_PRODUCTS_RELEASES = "MESSAGES.UPDATING_PRODUCTS_RELEASES";
        static readonly GETTING_PRODUCTS = "MESSAGES.GETTING_PRODUCTS";
        static readonly GETTING_PRODUCTS_COMPATIBILITIES = "MESSAGES.GETTING_PRODUCTS_COMPATIBILITIES";
        static readonly PRODUCT_RELEASE_DOWNLOAD_COMPLETED = "MESSAGES.PRODUCT_RELEASE_DOWNLOAD_COMPLETED";
        static readonly THIRD_PARTY_PRODUCT_HOTFIX_DOWNLOAD_COMPLETED = "MESSAGES.THIRD_PARTY_PRODUCT_HOTFIX_DOWNLOAD_COMPLETED";
        static readonly PAGE_SHARED = "MESSAGES.PAGE_SHARED";
        static readonly DOWNLOADING_RELEASE_NOTES = "MESSAGES.DOWNLOADING_RELEASE_NOTES";
        static readonly DOWNLOADING_RELEASE_NOTES_COMPLETED = "MESSAGES.DOWNLOADING_RELEASE_NOTES_COMPLETED";
        static readonly DOWNLOADING_COMPATIBILITY_DETAILS_PDF = "MESSAGES.DOWNLOADING_COMPATIBILITY_DETAILS_PDF";
        static readonly DOWNLOADING_COMPATIBILITY_DETAILS_PDF_COMPLETED = "MESSAGES.DOWNLOADING_COMPATIBILITY_DETAILS_PDF_COMPLETED";
        static readonly GETTING_USER_FAVORITES = "MESSAGES.GETTING_USER_FAVORITES";
        static readonly USER_FAVORITE_ADDED = "MESSAGES.USER_FAVORITE_ADDED";
        static readonly USER_FAVORITE_DELETED = "MESSAGES.USER_FAVORITE_DELETED";
        static readonly DELETING_USER_FAVORITE = "MESSAGES.DELETING_USER_FAVORITE";
        static readonly DELETING_USER_FAVORITE_CONFIRMATION = "MESSAGES.DELETING_USER_FAVORITE_CONFIRMATION";
        static readonly GETTING_PRODUCTS_LATEST_RELEASES = "MESSAGES.GETTING_PRODUCTS_LATEST_RELEASES";
        static readonly DIRECTORY_CREATED = "MESSAGES.DIRECTORY_CREATED";
        static readonly CREATING_DIRECTORY = "MESSAGES.CREATING_DIRECTORY";
        static readonly DIRECTORY_DELETED = "MESSAGES.DIRECTORY_DELETED";
        static readonly DELETING_DIRECTORY = "MESSAGES.DELETING_DIRECTORY";
        static readonly UPDATING_DIRECTORY = "MESSAGES.UPDATING_DIRECTORY";
        static readonly UPLOADING_FILE = "MESSAGES.UPLOADING_FILE";
        static readonly UPDATING_FILE = "MESSAGES.UPDATING_FILE";
        static readonly DELETING_DIRECTORY_CONFIRMATION = "MESSAGES.DELETING_DIRECTORY_CONFIRMATION";
        static readonly DELETING_FILE_CONFIRMATION = "MESSAGES.DELETING_FILE_CONFIRMATION";
        static readonly DIRECTORY_UPDATED = "MESSAGES.DIRECTORY_UPDATED";
        static readonly FILE_UPLOADED = "MESSAGES.FILE_UPLOADED";
        static readonly DOWNLOADING_FILE = "MESSAGES.DOWNLOADING_FILE";
        static readonly DOWNLOADING_FILE_COMPLETED = "MESSAGES.DOWNLOADING_FILE_COMPLETED";
        static readonly DELETING_FILE = "MESSAGES.DELETING_FILE";
        static readonly FILE_DELETED = "MESSAGES.FILE_DELETED";
        static readonly LINK_COPIED = "MESSAGES.LINK_COPIED";
        static readonly FiLE_UPDATED = "MESSAGES.FiLE_UPDATED";
        static readonly UPLOAD_EXTERNALLY_VISIBLE_FILE = "MESSAGES.UPLOAD_EXTERNALLY_VISIBLE_FILE";
        static readonly GETTING_FILE_CATEGORIES = "MESSAGES.GETTING_FILE_CATEGORIES";
        static readonly DELETING_FILE_CATEGORY = "MESSAGES.DELETING_FILE_CATEGORY";
        static readonly CREATING_FILE_CATEGORY = "MESSAGES.CREATING_FILE_CATEGORY";
        static readonly FILE_CATEGORY_DELETED = "MESSAGES.FILE_CATEGORY_DELETED";
        static readonly FILE_CATEGORY_CREATED = "MESSAGES.FILE_CATEGORY_CREATED";
        static readonly GETTING_FILE_TYPES = "MESSAGES.GETTING_FILE_TYPES";
        static readonly DISCARD_RELEASE_CREATION = "MESSAGES.DISCARD_RELEASE_CREATION";
        static readonly DISCARD_RELEASE_INFO_CHANGES = "MESSAGES.DISCARD_RELEASE_INFO_CHANGES";
        static readonly DISCARD_COMPATIBILITY_CHANGES = "MESSAGES.DISCARD_COMPATIBILITY_CHANGES";
        static readonly DISCARD_BUG_AND_ISSUE_FIXES_CHANGES = "MESSAGES.DISCARD_BUG_AND_ISSUE_FIXES_CHANGES";
        static readonly DISCARD_MIGRATION_CHANGES = "MESSAGES.DISCARD_MIGRATION_CHANGES";
        static readonly DISCARD_FEATURES_CHANGES = "MESSAGES.DISCARD_FEATURES_CHANGES";
        static readonly DISCARD_PRODUCT_DOWNLOADS_CHANGES = "MESSAGES.DISCARD_PRODUCT_DOWNLOADS_CHANGES";
        static readonly DISCARD_PREREQUISITES_CHANGES = "MESSAGES.DISCARD_PREREQUISITES_CHANGES";
        static readonly DISCARD_3RD_PARTY_HOTFIXES_CHANGES = "MESSAGES.DISCARD_3RD_PARTY_HOTFIXES_CHANGES";
        static readonly DO_YOU_WANT_TO_DISCARD_CHANGES = "MESSAGES.DO_YOU_WANT_TO_DISCARD_CHANGES";
        static readonly GETTING_APP_SETTINGS = "MESSAGES.GETTING_APP_SETTINGS";
        static readonly UPDATING_APP_SETTINGS = "MESSAGES.UPDATING_APP_SETTINGS";
        static readonly APP_SETTINGS_UPDATED = "MESSAGES.APP_SETTINGS_UPDATED";
        static readonly INITIATING_DATA_SYNC = "MESSAGES.INITIATING_DATA_SYNC";
        static readonly DATA_SYNC_COMPLETED = "MESSAGES.DATA_SYNC_COMPLETED";
        static readonly INITIATING_PRODUCT_DOCUMENTATION_SYNC = "MESSAGES.INITIATING_PRODUCT_DOCUMENTATION_SYNC";
        static readonly PRODUCT_DOCUMENTATION_SYNC_COMPLETED = "MESSAGES.PRODUCT_DOCUMENTATION_SYNC_COMPLETED";
        static readonly INITIATING_CORE_DATA_SYNC = "MESSAGES.INITIATING_CORE_DATA_SYNC";
        static readonly CORE_DATA_SYNC_COMPLETED = "MESSAGES.CORE_DATA_SYNC_COMPLETED";
        static readonly INITIATING_USER_GROUPS_SYNC = "MESSAGES.INITIATING_USER_GROUPS_SYNC";
        static readonly USER_GROUPS_SYNC_COMPLETED = "MESSAGES.USER_GROUPS_SYNC_COMPLETED";
        static readonly GETTING_DOCUMENTATION_SUMMARY = "MESSAGES.GETTING_DOCUMENTATION_SUMMARY";
        static readonly GETTING_DOCUMENTATION_DETAILS = "MESSAGES.GETTING_DOCUMENTATION_DETAILS";
        static readonly GETTING_PDF_DOCUMENTATION_DETAILS = "MESSAGES.GETTING_PDF_DOCUMENTATION_DETAILS";
        static readonly DOWNLOADING_PDF_DOCUMENT_COMPLETED = "MESSAGES.DOWNLOADING_PDF_DOCUMENT_COMPLETED";
        static readonly USER_FAVORITE_LINK_COPIED = "MESSAGES.USER_FAVORITE_LINK_COPIED";
        static readonly MINIMUM_SEARCH_TERM_LENGTH = "MESSAGES.MINIMUM_SEARCH_TERM_LENGTH";
        static readonly SEARCH_IN_PROGRESS = "MESSAGES.SEARCH_IN_PROGRESS";
        static readonly SEARCH_INDEX_REBUILD_COMPLETED = "MESSAGES.SEARCH_INDEX_REBUILD_COMPLETED";
        static readonly EMAIL_CONFIRMED = "MESSAGES.EMAIL_CONFIRMED";
        static readonly REGISTRATION_CONFIRMED = "MESSAGES.REGISTRATION_CONFIRMED";
        static readonly GETTING_TWO_FACTOR_AUTH_CONFIGURATION = "MESSAGES.GETTING_TWO_FACTOR_AUTH_CONFIGURATION";
        static readonly RESETTING_TWO_FACTOR_AUTH_CONFIGURATION = "MESSAGES.RESETTING_TWO_FACTOR_AUTH_CONFIGURATION";
        static readonly SAVING_TWO_FACTOR_AUTH_CONFIGURATION = "MESSAGES.SAVING_TWO_FACTOR_AUTH_CONFIGURATION";
        static readonly TWO_FACTOR_AUTH_CONFIGURATION_SAVED = "MESSAGES.TWO_FACTOR_AUTH_CONFIGURATION_SAVED";
        static readonly SENDING_TWO_FACTOR_AUTH_RECOVERY_CODE = "MESSAGES.SENDING_TWO_FACTOR_AUTH_RECOVERY_CODE";
        static readonly TWO_FACTOR_AUTH_RECOVERY_CODE_SENT = "MESSAGES.TWO_FACTOR_AUTH_RECOVERY_CODE_SENT";
        static readonly LOGIN_IN_PROGRESS = "MESSAGES.LOGIN_IN_PROGRESS";
        static readonly VERIFYING_2SV_CODE = "MESSAGES.VERIFYING_2SV_CODE";
        static readonly RESENDING_2SV_CODE = "MESSAGES.RESENDING_2SV_CODE";
        static readonly VERIFYING_2FA_CODE = "MESSAGES.VERIFYING_2FA_CODE";
        static readonly WAITING_FOR_FIDO2_CREDENTIAL_COMPLETION = "MESSAGES.WAITING_FOR_FIDO2_CREDENTIAL_COMPLETION";
        static readonly CONFIRM_FIDO2_CREDENTIAL_DELETION = "MESSAGES.CONFIRM_FIDO2_CREDENTIAL_DELETION";
        static readonly DELETING_FIDO_CREDENTIAL = "MESSAGES.DELETING_FIDO_CREDENTIAL";
        static readonly SAVING_USER_CHANGES = "MESSAGES.SAVING_USER_CHANGES";
        static readonly USER_CHANGES_SAVED = "MESSAGES.USER_CHANGES_SAVED";
        static readonly GETTING_USER_DETAILS = "MESSAGES.GETTING_USER_DETAILS";
        static readonly RESETING_USER_PASSWORD = "MESSAGES.RESETING_USER_PASSWORD";
        static readonly RESETING_USER_PASSWORD_DONE = "MESSAGES.RESETING_USER_PASSWORD_DONE";
        static readonly GETTING_USER_ACTIVITY_CATEGORIES = "MESSAGES.GETTING_USER_ACTIVITY_CATEGORIES";
        static readonly GETTING_USER_ACTIVITIES = "MESSAGES.GETTING_USER_ACTIVITIES";
        static readonly GETTING_USER_GROUPS = "MESSAGES.GETTING_USER_GROUPS";
        static readonly SAVING_USER_GROUPS_REGISTRATION = "MESSAGES.SAVING_USER_GROUPS_REGISTRATION";
        static readonly USER_GROUPS_REGISTRATION_SAVED = "MESSAGES.USER_GROUPS_REGISTRATION_SAVED";
        static readonly GETTING_USER_GROUP_DETAILS = "MESSAGES.GETTING_USER_GROUP_DETAILS";
        static readonly SAVING_USER_GROUP_CHANGES = "MESSAGES.SAVING_USER_GROUP_CHANGES";
        static readonly USER_GROUP_CHANGES_SAVED = "MESSAGES.USER_GROUP_CHANGES_SAVED";
        static readonly SYNCHRONIZING_USER_GROUPS = "MESSAGES.SYNCHRONIZING_USER_GROUPS";
        static readonly USER_GROUPS_SYNCHRONIZED = "MESSAGES.USER_GROUPS_SYNCHRONIZED";
        static readonly PUBLICATION_CATEGORY_CREATED = "MESSAGES.PUBLICATION_CATEGORY_CREATED";
        static readonly PUBLICATION_CATEGORY_EDITED = "MESSAGES.PUBLICATION_CATEGORY_EDITED";
        static readonly DELETING_PUBLICATION_CATEGORY_CONFIRMATION = "MESSAGES.DELETING_PUBLICATION_CATEGORY_CONFIRMATION";
        static readonly PUBLICATION_CATEGORY_DELETED = "MESSAGES.PUBLICATION_CATEGORY_DELETED";
        static readonly GETTING_AUDIT_TRAIL_DETAILS = "MESSAGES.GETTING_AUDIT_TRAIL_DETAILS";
        static readonly REBUILDING_SEARCH_INDEX_IN_PROGRESS = "MESSAGES.REBUILDING_SEARCH_INDEX_IN_PROGRESS";
        static readonly CREATING_PUBLICATION_CATEGORY = "MESSAGES.CREATING_PUBLICATION_CATEGORY";
        static readonly EDITING_PUBLICATION_CATEGORY = "MESSAGES.EDITING_PUBLICATION_CATEGORY";
        static readonly SENDING_PERMISSIONS_REQUEST = "MESSAGES.SENDING_PERMISSIONS_REQUEST";
        static readonly PERMISSIONS_REQUEST_SENT = "MESSAGES.PERMISSIONS_REQUEST_SENT";
        static readonly APPROVING_REQUESTED_PERMISSIONS = "MESSAGES.APPROVING_REQUESTED_PERMISSIONS";
        static readonly REQUESTED_PERMISSIONS_APPROVED = "MESSAGES.REQUESTED_PERMISSIONS_APPROVED";
        static readonly DENYING_REQUESTED_PERMISSIONS = "MESSAGES.DENYING_REQUESTED_PERMISSIONS";
        static readonly REQUESTED_PERMISSIONS_DENIED = "MESSAGES.REQUESTED_PERMISSIONS_DENIED";
        static readonly SENDING_ROLES_REQUEST = "MESSAGES.SENDING_ROLES_REQUEST";
        static readonly ROLES_REQUEST_SENT = "MESSAGES.ROLES_REQUEST_SENT";
        static readonly APPROVING_REQUESTED_ROLES = "MESSAGES.APPROVING_REQUESTED_ROLES";
        static readonly REQUESTED_ROLES_APPROVED = "MESSAGES.REQUESTED_ROLES_APPROVED";
        static readonly DENYING_REQUESTED_ROLES = "MESSAGES.DENYING_REQUESTED_ROLES";
        static readonly REQUESTED_ROLES_DENIED = "MESSAGES.REQUESTED_ROLES_DENIED";
        static readonly PROCESSING_REGISTRATION_NOTIFICATION = "MESSAGES.PROCESSING_REGISTRATION_NOTIFICATION";
        static readonly REGISTRATION_NOTIFICATION_PROCESSED = "MESSAGES.REGISTRATION_NOTIFICATION_PROCESSED";
        static readonly DELETING_DATA_SYNC_RUN_CONFIRMATION = "MESSAGES.DELETING_DATA_SYNC_RUN_CONFIRMATION";
        static readonly DELETING_DATA_SYNC_RUN = "MESSAGES.DELETING_DATA_SYNC_RUN";
        static readonly DATA_SYNC_RUN_DELETED = "MESSAGES.DATA_SYNC_RUN_DELETED";
        static readonly DELETING_DATA_SYNC_RUNS_CONFIRMATION = "MESSAGES.DELETING_DATA_SYNC_RUNS_CONFIRMATION";
        static readonly DELETING_DATA_SYNC_RUNS = "MESSAGES.DELETING_DATA_SYNC_RUNS";
        static readonly DATA_SYNC_RUNS_DELETED = "MESSAGES.DATA_SYNC_RUNS_DELETED";
        static readonly DOWNLOADING_PRODUCT_RELEASE = "MESSAGES.DOWNLOADING_PRODUCT_RELEASE";
        static readonly GETTING_FILE_TAGS = "MESSAGES.GETTING_FILE_TAGS";
        static readonly DELETING_FILE_TAG = "MESSAGES.DELETING_FILE_TAG";
        static readonly FILE_TAG_DELETED = "MESSAGES.FILE_TAG_DELETED";
        static readonly CREATING_FILE_TAG = "MESSAGES.CREATING_FILE_TAG";
        static readonly FILE_TAG_CREATED = "MESSAGES.FILE_TAG_CREATED";
        static readonly GETTING_RELATED_VIDEOS = "MESSAGES.GETTING_RELATED_VIDEOS";
        static readonly RECREATING_RELEASE_NOTES_PDFS = "MESSAGES.RECREATING_RELEASE_NOTES_PDFS";
        static readonly RECREATING_RELEASE_NOTES_PDFS_SUCCESS = "MESSAGES.RECREATING_RELEASE_NOTES_PDFS_SUCCESS";
        static readonly RECREATING_RELEASE_NOTES_PDFS_FAILURE = "MESSAGES.RECREATING_RELEASE_NOTES_PDFS_FAILURE";
        static readonly EXPORTING_CUSTOMERS_PRODUCTS_DATA = "MESSAGES.EXPORTING_CUSTOMERS_PRODUCTS_DATA";
        static readonly EXPORTING_CUSTOMERS_USERS_DATA = "MESSAGES.EXPORTING_CUSTOMERS_USERS_DATA";
        static readonly PASSWORD_CHANGED = "MESSAGES.PASSWORD_CHANGED";
        static readonly CHANGING_PASSWORD = "MESSAGES.CHANGING_PASSWORD";
        static readonly GETTING_RATING_DETAILS = "MESSAGES.GETTING_RATING_DETAILS";
        static readonly MERGING_PRODUCT_RELEASES = "MESSAGES.MERGING_PRODUCT_RELEASES";
        static readonly GETTING_AVAILABLE_RELEASE_INSTALLATIONS = "MESSAGES.GETTING_AVAILABLE_RELEASE_INSTALLATIONS";
        static readonly UPLOADING_RELEASE_INSTALLATION = "MESSAGES.UPLOADING_RELEASE_INSTALLATION";
        static readonly DELETING_RELEASE_INSTALLATION = "MESSAGES.DELETING_RELEASE_INSTALLATION";
        static readonly DELETING_RELEASE_INSTALLATIONS = "MESSAGES.DELETING_RELEASE_INSTALLATIONS";
        static readonly GETTING_AVAILABLE_THIRD_PARTY_PRODUCT_HOTFIXES = "MESSAGES.GETTING_AVAILABLE_THIRD_PARTY_PRODUCT_HOTFIXES";
        static readonly DELETING_THIRD_PARTY_PRODUCT_HOTFIX = "MESSAGES.DELETING_THIRD_PARTY_PRODUCT_HOTFIX";
    }

    static SIDENAV = class {
        static readonly DASHBOARD: string = "SIDENAV.DASHBOARD";
        static readonly DASHBOARD_WELCOME: string = "SIDENAV.DASHBOARD_WELCOME";
        static readonly DASHBOARD_CUSTOMER_OVERVIEW: string = "SIDENAV.DASHBOARD_CUSTOMER_OVERVIEW";
        static readonly ACCOUNT: string = "SIDENAV.ACCOUNT";
        static readonly ACCOUNT_PROFILE: string = "SIDENAV.ACCOUNT_PROFILE";
        static readonly ACCOUNT_SUPPORT_INFORMATION: string = "SIDENAV.ACCOUNT_SUPPORT_INFORMATION";
        static readonly ACCOUNT_PRODUCTS: string = "SIDENAV.ACCOUNT_PRODUCTS";
        static readonly ADMNISTRATION: string = "SIDENAV.ADMNISTRATION";
        static readonly ADMNISTRATION_CONFIGURATION: string = "SIDENAV.ADMNISTRATION_CONFIGURATION";
        static readonly ADMNISTRATION_FILE_STORAGE: string = "SIDENAV.ADMNISTRATION_FILE_STORAGE";
        static readonly ADMNISTRATION_USER_MANAGEMENT: string = "SIDENAV.ADMNISTRATION_USER_MANAGEMENT";
        static readonly ADMINISTRATION_PUBLICATION_CATEGORIES: string = "SIDENAV.ADMINISTRATION_PUBLICATION_CATEGORIES";
        static readonly HELP_CENTER: string = "SIDENAV.HELP_CENTER";
        static readonly HELP_CENTER_DOCUMENTATION: string = "SIDENAV.HELP_CENTER_DOCUMENTATION";
        static readonly HELP_CENTER_TRAINING_VIDEOS: string = "SIDENAV.HELP_CENTER_TRAINING_VIDEOS";
        static readonly LICENSE_AND_INSTALLATIONS: string = "SIDENAV.LICENSE_AND_INSTALLATIONS";
        static readonly SOFTWARE: string = "SIDENAV.SOFTWARE";
        static readonly SOFTWARE_RELEASES: string = "SIDENAV.SOFTWARE_RELEASES";
        static readonly SOFTWARE_RELEASE_POLICY: string = "SIDENAV.SOFTWARE_RELEASE_POLICY";
        static readonly SOFTWARE_COMPATIBILITY: string = "SIDENAV.SOFTWARE_COMPATIBILITY";
        static readonly GENERAL_SEARCH: string = "SIDENAV.GENERAL_SEARCH";
        static readonly MARKETING_RESOURCES: string = "SIDENAV.MARKETING_RESOURCES";
        static readonly MARKETING_RESOURCES_DOCUMENTATION: string = "SIDENAV.MARKETING_RESOURCES_DOCUMENTATION";
        static readonly MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEOS: string = "SIDENAV.MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEOS";
        static readonly AUDIT_TRAIL: string = "SIDENAV.AUDIT_TRAIL";
        static readonly ADMINISTRATIVE_NOTIFICATIONS: string = "SIDENAV.ADMINISTRATIVE_NOTIFICATIONS";
        static readonly DATA_SYNCHRONIZATION: string = "SIDENAV.DATA_SYNCHRONIZATION";
        static readonly CUSTOMERS_OVERVIEW: string = "SIDENAV.CUSTOMERS_OVERVIEW";
        static readonly USER_GUIDE: string = "SIDENAV.USER_GUIDE";
    }

    static PAGE_TITLES = class {
      static readonly HELP_CENTER_DOCUMENTATION: string = "PAGE_TITLES.HELP_CENTER_DOCUMENTATION";
      static readonly HELP_CENTER_TRAINING_VIDEOS: string = "PAGE_TITLES.HELP_CENTER_TRAINING_VIDEOS";
      static readonly HELP_CENTER_TRAINING_VIDEO: string = "PAGE_TITLES.HELP_CENTER_TRAINING_VIDEO";
      static readonly MARKETING_RESOURCES_DOCUMENTATION: string = "PAGE_TITLES.MARKETING_RESOURCES_DOCUMENTATION";
      static readonly MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEOS: string = "PAGE_TITLES.MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEOS";
      static readonly MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEO: string = "PAGE_TITLES.MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEO";
    }

    static ERROR_MESSAGE = class {
        static readonly ERROR_CODE = "ERROR_MESSAGE.ERROR_CODE";
        static readonly HTTP_STATUS = "ERROR_MESSAGE.HTTP_STATUS";
        static readonly CONNECTION_PROBLEM = "ERROR_MESSAGE.CONNECTION_PROBLEM";
        static readonly INVALID_REQUEST = "ERROR_MESSAGE.INVALID_REQUEST";
        static readonly SERVER_CONFIGURATION_ERROR = "ERROR_MESSAGE.SERVER_CONFIGURATION_ERROR";
        static readonly INTERNAL_SERVER_ERROR = "ERROR_MESSAGE.INTERNAL_SERVER_ERROR";
        static readonly UNEXPECTED_SERVER_ERROR = "ERROR_MESSAGE.UNEXPECTED_SERVER_ERROR";
        static readonly UNEXPECTED_SERVER_RESPONSE = "ERROR_MESSAGE.UNEXPECTED_SERVER_RESPONSE";
        static readonly UNEXPECTED_HTTP_STATUS_CODE = "ERROR_MESSAGE.UNEXPECTED_HTTP_STATUS_CODE";
        static readonly RESOURCE_NOT_FOUND = "ERROR_MESSAGE.RESOURCE_NOT_FOUND";
        static readonly UNAUTHORIZED_REQUEST = "ERROR_MESSAGE.UNAUTHORIZED_REQUEST";
        static readonly RESOURCE_ALREADY_EXIST = "ERROR_MESSAGE.RESOURCE_ALREADY_EXIST";
        static readonly ERROR_OCCURED = "ERROR_MESSAGE.ERROR_OCCURED";
        static readonly DOWNLOAD_ALREADY_IN_PROGRESS = "ERROR_MESSAGE.DOWNLOAD_ALREADY_IN_PROGRESS";
        static readonly RELEASE_PERIOD_IS_INVALID = "ERROR_MESSAGE.RELEASE_PERIOD_IS_INVALID";
        static readonly RELEASED_AT_IS_REQUIRED = "ERROR_MESSAGE.RELEASED_AT_IS_REQUIRED";
        static readonly END_SUPPORT_DATE_IS_REQUIRED = "ERROR_MESSAGE.END_SUPPORT_DATE_IS_REQUIRED";
        static readonly UNKNOWN_PRODUCT_NAME = "ERROR_MESSAGE.UNKNOWN_PRODUCT_NAME";
        static readonly INVALID_PRODUCT_RELEASE_STATUS = "ERROR_MESSAGE.INVALID_PRODUCT_RELEASE_STATUS";
        static readonly EMAIL_SEND_FAILED = "ERROR_MESSAGE.EMAIL_SEND_FAILED";
        static readonly LOGIN_FAILED = "ERROR_MESSAGE.LOGIN_FAILED";
        static readonly AUTHENTICATION_METHOD_TEMPORARELY_UNAVAILABLE = "ERROR_MESSAGE.AUTHENTICATION_METHOD_TEMPORARELY_UNAVAILABLE";
        static readonly TWO_STEP_VERIFICATION_CODE_EXPIRED_OR_INVALID = "ERROR_MESSAGE.TWO_STEP_VERIFICATION_CODE_EXPIRED_OR_INVALID";
        static readonly EMAIL_NOT_CONFIRMED = "ERROR_MESSAGE.EMAIL_NOT_CONFIRMED";
        static readonly UNABLE_TO_CONFIRM_EMAIL = "ERROR_MESSAGE.UNABLE_TO_CONFIRM_EMAIL";
        static readonly EMAIL_ALREADY_CONFIRMED = "ERROR_MESSAGE.EMAIL_ALREADY_CONFIRMED";
        static readonly EMAIL_CONFIRMATION_LINK_INVAILD = "ERROR_MESSAGE.EMAIL_CONFIRMATION_LINK_INVAILD";
        static readonly UNABLE_TO_SEND_EMAIL_CONFIRMATION_EMAIL = "ERROR_MESSAGE.UNABLE_TO_SEND_EMAIL_CONFIRMATION_EMAIL";
        static readonly EMAIL_CONFIRMATION_UNEXPECTED_ERROR = "ERROR_MESSAGE.EMAIL_CONFIRMATION_UNEXPECTED_ERROR";
        static readonly UNABLE_TO_REGISTER_USER = "ERROR_MESSAGE.UNABLE_TO_REGISTER_USER";
        static readonly USER_ALREADY_REGISTERED = "ERROR_MESSAGE.USER_ALREADY_REGISTERED";
        static readonly UNABLE_TO_SEND_REGISTRATION_CONFIRMATION_EMAIL = "ERROR_MESSAGE.UNABLE_TO_SEND_REGISTRATION_CONFIRMATION_EMAIL";
        static readonly USER_REGISTRATION_CONFIRMATION_LINK_INVALID = "ERROR_MESSAGE.USER_REGISTRATION_CONFIRMATION_LINK_INVALID";
        static readonly USER_REGISTRATION_ALREADY_CONFIRMED = "ERROR_MESSAGE.USER_REGISTRATION_ALREADY_CONFIRMED";
        static readonly UNABLE_TO_CONFIRM_USER_REGISTRATION = "ERROR_MESSAGE.UNABLE_TO_CONFIRM_USER_REGISTRATION";
        static readonly USER_REGISTRATION_CONFIRMATION_UNEXPECTED_ERROR = "ERROR_MESSAGE.USER_REGISTRATION_CONFIRMATION_UNEXPECTED_ERROR";
        static readonly UNABLE_TO_GENERATE_AUTHENTICATOR_KEY = "ERROR_MESSAGE.UNABLE_TO_GENERATE_AUTHENTICATOR_KEY";
        static readonly UNABLE_TO_REGISTER_AUTHENTICATOR = "ERROR_MESSAGE.UNABLE_TO_REGISTER_AUTHENTICATOR";
        static readonly UNABLE_TO_SEND_2FA_RECOVERY_CODE = "ERROR_MESSAGE.UNABLE_TO_SEND_2FA_RECOVERY_CODE";
        static readonly TWO_FACTOR_AUTHENTICATION_CODE_INVALID = "ERROR_MESSAGE.TWO_FACTOR_AUTHENTICATION_CODE_INVALID";
        static readonly SECURITY_SETTINGS_UNEXPECTED_ERROR = "ERROR_MESSAGE.SECURITY_SETTINGS_UNEXPECTED_ERROR";
        static readonly AUTHENTICATION_METHOD_NOT_SUPPORTED = "ERROR_MESSAGE.AUTHENTICATION_METHOD_NOT_SUPPORTED";
        static readonly WRONG_AUTHENTICATION_METHOD_SPECIFIED = "ERROR_MESSAGE.WRONG_AUTHENTICATION_METHOD_SPECIFIED";
        static readonly TWO_FACTOR_RECOVERY_CODE_INVALID = "ERROR_MESSAGE.TWO_FACTOR_RECOVERY_CODE_INVALID";
        static readonly TWO_FACTOR_RECOVERY_VALIDATION_FAILED = "ERROR_MESSAGE.TWO_FACTOR_RECOVERY_VALIDATION_FAILED";
        static readonly FIDO2_AUTHENTICATOR_CREDENTIAL_CREATION_ERROR = "ERROR_MESSAGE.FIDO2_AUTHENTICATOR_CREDENTIAL_CREATION_ERROR";
        static readonly FIDO2_AUTHENTICATOR_CREDENTIAL_VALIDATION_ERROR = "ERROR_MESSAGE.FIDO2_AUTHENTICATOR_CREDENTIAL_VALIDATION_ERROR";
        static readonly UNABLE_TO_CREATE_FIDO2_CREDENTIAL_CREATION_OPTIONS = "ERROR_MESSAGE.UNABLE_TO_CREATE_FIDO2_CREDENTIAL_CREATION_OPTIONS";
        static readonly UNABLE_TO_CREATE_FIDO2_CREDENTIALS = "ERROR_MESSAGE.UNABLE_TO_CREATE_FIDO2_CREDENTIALS";
        static readonly UNABLE_TO_CREATE_FIDO2_CREDENTIAL_ASSERTION_OPTIONS = "ERROR_MESSAGE.UNABLE_TO_CREATE_FIDO2_CREDENTIAL_ASSERTION_OPTIONS";
        static readonly FIDO2_CREDENTIAL_INVALID = "ERROR_MESSAGE.FIDO2_CREDENTIAL_INVALID";
        static readonly DEFAULT_AUTH_TWO_STEP_VERIFICATION_NOT_ENABLED = "ERROR_MESSAGE.DEFAULT_AUTH_TWO_STEP_VERIFICATION_NOT_ENABLED";
        static readonly DEFAULT_AUTH_TWO_FACTOR_AUTHENTICATION_NOT_ENABLED = "ERROR_MESSAGE.DEFAULT_AUTH_TWO_FACTOR_AUTHENTICATION_NOT_ENABLED";
        static readonly DEFAULT_AUTH_FIDO2_AUTHENTICATION_NOT_ENABLED = "ERROR_MESSAGE.DEFAULT_AUTH_FIDO2_AUTHENTICATION_NOT_ENABLED";
        static readonly PASSWORD_POLICY_PASSWORD_MUST_BE_AT_LEAST = "ERROR_MESSAGE.PASSWORD_POLICY_PASSWORD_MUST_BE_AT_LEAST";
        static readonly PASSWORD_POLICY_CHARACTERS_LONG = "ERROR_MESSAGE.PASSWORD_POLICY_CHARACTERS_LONG";
        static readonly PASSWORD_POLICY_PASSWORD_MUST_HAVE_AT_LEAST= "ERROR_MESSAGE.PASSWORD_POLICY_PASSWORD_MUST_HAVE_AT_LEAST";
        static readonly PASSWORD_POLICY_UPPERCASE_LETTERS = "ERROR_MESSAGE.PASSWORD_POLICY_UPPERCASE_LETTERS";
        static readonly PASSWORD_POLICY_LOWERCASE_LETTERS = "ERROR_MESSAGE.PASSWORD_POLICY_LOWERCASE_LETTERS";
        static readonly PASSWORD_POLICY_DIGITS = "ERROR_MESSAGE.PASSWORD_POLICY_DIGITS";
        static readonly PASSWORD_POLICY_SPECIAL_CHARACTERS = "ERROR_MESSAGE.PASSWORD_POLICY_SPECIAL_CHARACTERS";
        static readonly USER_IS_DISABLED = "ERROR_MESSAGE.USER_IS_DISABLED";
        static readonly USER_IS_LOCKED = "ERROR_MESSAGE.USER_IS_LOCKED";
        static readonly OPTION_NOT_SELECTED = "ERROR_MESSAGE.OPTION_NOT_SELECTED";
        static readonly INVALID_PASSWORD_RESET_TOKEN = "ERROR_MESSAGE.INVALID_PASSWORD_RESET_TOKEN";
        static readonly USER_REGISTRATION_UNKNOWN_USER = "ERROR_MESSAGE.USER_REGISTRATION_UNKNOWN_USER";
        static readonly VIDEO_STREAMING_ERROR = "ERROR_MESSAGE.VIDEO_STREAMING_ERROR";
        static readonly INVALID_CURRENT_PASSWORD = "ERROR_MESSAGE.INVALID_CURRENT_PASSWORD";
        static readonly PASSWORD_POLICY_NOT_MET = "ERROR_MESSAGE.PASSWORD_POLICY_NOT_MET";
    }

    static WARNING_MESSAGE = class {
        static readonly CORE_DATA_SYNC_IN_PROGRESS = "WARNING_MESSAGE.CORE_DATA_SYNC_IN_PROGRESS";
        static readonly PRODUCT_DOCUMENTATION_SYNC_IN_PROGRESS = "WARNING_MESSAGE.PRODUCT_DOCUMENTATION_SYNC_IN_PROGRESS";
        static readonly CATEGORY_NOT_ELIGIBLE_FOR_DELETION = "WARNING_MESSAGE.CATEGORY_NOT_ELIGIBLE_FOR_DELETION";
        static readonly PUBLICATION_CATEGORY_IS_PREDEFINED = "WARNING_MESSAGE.PUBLICATION_CATEGORY_IS_PREDEFINED";
        static readonly PUBLICATION_CATEGORY_IS_USED = "WARNING_MESSAGE.PUBLICATION_CATEGORY_IS_USED";
        static readonly DOCUMENTATION_NOT_AVAILABLE_FOR_PRODUCT_VERSION = "WARNING_MESSAGE.DOCUMENTATION_NOT_AVAILABLE_FOR_PRODUCT_VERSION";
        static readonly TAG_NOT_ELIGIBLE_FOR_DELETION = "WARNING_MESSAGE.TAG_NOT_ELIGIBLE_FOR_DELETION";
        static readonly RELEASE_VERSION_UPDATED = "WARNING_MESSAGE.RELEASE_VERSION_UPDATED";
        static readonly CONCURRENT_PRODUCT_RELEASE_UPDATE_IN_PROGRESS = "WARNING_MESSAGE.CONCURRENT_PRODUCT_RELEASE_UPDATE_IN_PROGRESS";
        static readonly PRODUCT_RELEASE_UPDATED_BY_ANOTHER_USER = "WARNING_MESSAGE.PRODUCT_RELEASE_UPDATED_BY_ANOTHER_USER";
        static readonly INSTALLATION_ALREADY_LINKED = "WARNING_MESSAGE.INSTALLATION_ALREADY_LINKED";
        static readonly FILE_NOT_ELIGIBLE_FOR_DELETION = "WARNING_MESSAGE.FILE_NOT_ELIGIBLE_FOR_DELETION";
        static readonly DIRECTORY_NOT_ELIGIBLE_FOR_DELETION = "WARNING_MESSAGE.DIRECTORY_NOT_ELIGIBLE_FOR_DELETION";
        static readonly FILE_ALREADY_EXISTS = "WARNING_MESSAGE.FILE_ALREADY_EXISTS";
        static readonly DIRECTORY_ALREADY_EXISTS = "WARNING_MESSAGE.DIRECTORY_ALREADY_EXISTS";
        static readonly UPDATE_RELEASE_INSTALLATIONS_LINKS_STATUS = "WARNING_MESSAGE.UPDATE_RELEASE_INSTALLATIONS_LINKS_STATUS";
        static readonly UPDATE_RELEASE_INSTALLATIONS_LINKS_VERSION = "WARNING_MESSAGE.UPDATE_RELEASE_INSTALLATIONS_LINKS_VERSION";
    }

    static SOFTWARE = class {

        static SOFTWARE_RELEASES = class {

            static FEATURES = class {
              static readonly FEATURE_IMAGE_TOO_BIG = "SOFTWARE.SOFTWARE_RELEASES.FEATURES.FEATURE_IMAGE_TOO_BIG";
              static readonly PASTING_FEATURE_IMAGE_FAILED = "SOFTWARE.SOFTWARE_RELEASES.FEATURES.PASTING_FEATURE_IMAGE_FAILED";
              static readonly PASTING_TEXT_AND_IMAGE_ONLLY = "SOFTWARE.SOFTWARE_RELEASES.FEATURES.PASTING_TEXT_AND_IMAGE_ONLLY";
            }

            static PRODUCT_RELEASE_STATUSES = class {
                static readonly BETA = "SOFTWARE.SOFTWARE_RELEASES.PRODUCT_RELEASE_STATUSES.BETA";
                static readonly RELEASE_CANDIDATE = "SOFTWARE.SOFTWARE_RELEASES.PRODUCT_RELEASE_STATUSES.RELEASE_CANDIDATE";
                static readonly RELEASED = "SOFTWARE.SOFTWARE_RELEASES.PRODUCT_RELEASE_STATUSES.RELEASED";
                static readonly UNKNOWN = "SOFTWARE.SOFTWARE_RELEASES.PRODUCT_RELEASE_STATUSES.UNKNOWN";
            }

            static PRODUCT_RELEASE_PROPERTIES = class {
                static readonly BUG_FIX = "SOFTWARE.SOFTWARE_RELEASES.PRODUCT_RELEASE_PROPERTIES.BUG_FIX";
                static readonly FEATURE = "SOFTWARE.SOFTWARE_RELEASES.PRODUCT_RELEASE_PROPERTIES.FEATURE";
                static readonly MIGRATION = "SOFTWARE.SOFTWARE_RELEASES.PRODUCT_RELEASE_PROPERTIES.MIGRATION";
                static readonly DOWNLOADS = "SOFTWARE.SOFTWARE_RELEASES.PRODUCT_RELEASE_PROPERTIES.DOWNLOADS";
                static readonly THIRD_PARTY_HOT_FIX = "SOFTWARE.SOFTWARE_RELEASES.PRODUCT_RELEASE_PROPERTIES.3RD_PARTY_HOTFIX";
                static readonly PREREQUISITE = "SOFTWARE.SOFTWARE_RELEASES.PRODUCT_RELEASE_PROPERTIES.PREREQUISITE";
            }
        }
        static COMPATIBILITY = class {
            static readonly ANY_VERSION = "SOFTWARE.COMPATIBILITY.ANY_VERSION";
        }
    }

    static COMMON = class {
        static readonly PRODUCT_VERSION = "COMMON.PRODUCT_VERSION";
        static readonly PAGE_HAS_BEEN_SHARED_WITH_YOU = "COMMON.PAGE_HAS_BEEN_SHARED_WITH_YOU";
        static readonly DOCUMENT_HAS_BEEN_SHARED_WITH_YOU = "COMMON.DOCUMENT_HAS_BEEN_SHARED_WITH_YOU";
        static readonly VIDEO_HAS_BEEN_SHARED_WITH_YOU = "COMMON.VIDEO_HAS_BEEN_SHARED_WITH_YOU";
        static readonly LATEST_RELEASE_INDICATOR = "COMMON.LATEST_RELEASE_INDICATOR";
    }

    static DOCUMENT = class {

        static CATEGORIES = class {
            static readonly BASE_NAME = "DOCUMENT.CATEGORIES.";
            static readonly ALL = "DOCUMENT.CATEGORIES.ALL";
            static readonly ADMINISTRATOR_GUIDE = "DOCUMENT.CATEGORIES.ADM";
            static readonly API_DOCUMENTATION = "DOCUMENT.CATEGORIES.API";
            static readonly CONFIGURATION_GUIDE = "DOCUMENT.CATEGORIES.CNF";
            static readonly DESIGNER_GUIDE = "DOCUMENT.CATEGORIES.DES";
            static readonly DEVELOPMENT_GUIDE = "DOCUMENT.CATEGORIES.DEV";
            static readonly HOW_TO = "DOCUMENT.CATEGORIES.HOW";
            static readonly INSTALATION_GUIDE = "DOCUMENT.CATEGORIES.INS";
            static readonly USER_GUIDE = "DOCUMENT.CATEGORIES.USG";
            static readonly WHATS_NEW = "DOCUMENT.CATEGORIES.NEW";
        }
    }

    static SEARCH_RESULT_CONTENT_TYPE_OPTIONS = class {
        static readonly ALL = "SEARCH_RESULT_CONTENT_TYPE_OPTIONS.ALL";
        static readonly TEXT = "SEARCH_RESULT_CONTENT_TYPE_OPTIONS.TEXT";
        static readonly TABLE = "SEARCH_RESULT_CONTENT_TYPE_OPTIONS.TABLE";
        static readonly CODE_SNIPPET = "SEARCH_RESULT_CONTENT_TYPE_OPTIONS.CODE_SNIPPET";
        static readonly VIDEO = "SEARCH_RESULT_CONTENT_TYPE_OPTIONS.VIDEO";
    }

    static SEARCH_RESULT_SUBTYPE_OPTIONS = class {
        static readonly ALL = "SEARCH_RESULT_SUBTYPE_OPTIONS.ALL";
        static readonly BUG_FIX = "SEARCH_RESULT_SUBTYPE_OPTIONS.BUG_FIX";
        static readonly FEATURE = "SEARCH_RESULT_SUBTYPE_OPTIONS.FEATURE";
        static readonly MIGRATION = "SEARCH_RESULT_SUBTYPE_OPTIONS.MIGRATION";
        static readonly DOWNLOAD = "SEARCH_RESULT_SUBTYPE_OPTIONS.DOWNLOAD";
        static readonly THIRD_PARTY_HOT_FIX = "SEARCH_RESULT_SUBTYPE_OPTIONS.THIRD_PARTY_HOT_FIX";
        static readonly PREREQUISITE = "SEARCH_RESULT_SUBTYPE_OPTIONS.PREREQUISITE";
    }

    static GENERAL_SEARCH = class {

        static SEARCH_AREAS = class {
            static readonly ALL = "GENERAL_SEARCH.SEARCH_AREAS.ALL";
            static readonly HELP_CENTER = "GENERAL_SEARCH.SEARCH_AREAS.HELP_CENTER";
            static readonly RELEASES = "GENERAL_SEARCH.SEARCH_AREAS.RELEASES";
            static readonly MARKETING = "GENERAL_SEARCH.SEARCH_AREAS.MARKETING";
            static readonly HELP_CENTER_DOCUMENTATION = "GENERAL_SEARCH.SEARCH_AREAS.HELP_CENTER_DOCUMENTATION";
            static readonly HELP_CENTER_TRAINING_VIDEOS = "GENERAL_SEARCH.SEARCH_AREAS.HELP_CENTER_TRAINING_VIDEOS";
            static readonly MARKETING_RESOURCES_DOCUMENTATION = "GENERAL_SEARCH.SEARCH_AREAS.MARKETING_RESOURCES_DOCUMENTATION";
            static readonly MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEOS = "GENERAL_SEARCH.SEARCH_AREAS.MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEOS";
        }

        static MARKETING = class {
            static readonly UNCATEGORIZED_RESULT = "GENERAL_SEARCH.MARKETING.UNCATEGORIZED_RESULT";
        }
    }

    static USER_FAVORITE_CATEGORIES = class {
        static readonly RELEASE_NOTES = "USER_FAVORITE_CATEGORIES.RELEASE_NOTES";
        static readonly SOFTWARE_COMPATIBILITY = "USER_FAVORITE_CATEGORIES.SOFTWARE_COMPATIBILITY";
        static readonly PRODUCT_DOCUMENTATION = "USER_FAVORITE_CATEGORIES.PRODUCT_DOCUMENTATION";
        static readonly MARKETING_MATERIAL = "USER_FAVORITE_CATEGORIES.MARKETING_MATERIAL";
        static readonly VIDEO_FILE = "USER_FAVORITE_CATEGORIES.VIDEO_FILE";
    }

    static PERMISSIONS = class {

      static SUPPORT_INFO = class {
        static readonly TITLE = "PERMISSIONS.SUPPORT_INFO.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.SUPPORT_INFO.DESCRIPTION";
      }

      static SUPPORT_INFO_CUST = class {
        static readonly TITLE = "PERMISSIONS.SUPPORT_INFO_CUST.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.SUPPORT_INFO_CUST.DESCRIPTION";
      }

      static PRODUCT_RELEASE_INFO = class {
        static readonly TITLE = "PERMISSIONS.PRODUCT_RELEASE_INFO.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.PRODUCT_RELEASE_INFO.DESCRIPTION";
      }

      static PRODUCT_RELEASE_MANAGEMENT = class {
        static readonly TITLE = "PERMISSIONS.PRODUCT_RELEASE_MANAGEMENT.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.PRODUCT_RELEASE_MANAGEMENT.DESCRIPTION";
      }

      static SOFTWARE_DOWNLOAD = class {
        static readonly TITLE = "PERMISSIONS.SOFTWARE_DOWNLOAD.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.SOFTWARE_DOWNLOAD.DESCRIPTION";
      }

      static PRODUCT_DOCS = class {
        static readonly TITLE = "PERMISSIONS.PRODUCT_DOCS.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.PRODUCT_DOCS.DESCRIPTION";
      }

      static PRODUCT_DOCS_MANAGEMENT = class {
        static readonly TITLE = "PERMISSIONS.PRODUCT_DOCS_MANAGEMENT.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.PRODUCT_DOCS_MANAGEMENT.DESCRIPTION";
      }

      static SYSTEM_ACTIVITIES = class {
        static readonly TITLE = "PERMISSIONS.SYSTEM_ACTIVITIES.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.SYSTEM_ACTIVITIES.DESCRIPTION";
      }

      static MARKETING_MATERIAL = class {
        static readonly TITLE = "PERMISSIONS.MARKETING_MATERIAL.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.MARKETING_MATERIAL.DESCRIPTION";
      }

      static ADMIN_NOTIFICATIONS = class {
        static readonly TITLE = "PERMISSIONS.ADMIN_NOTIFICATIONS.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.ADMIN_NOTIFICATIONS.DESCRIPTION";
      }

      static SYSTEM_SETTINGS = class {
        static readonly TITLE = "PERMISSIONS.SYSTEM_SETTINGS.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.SYSTEM_SETTINGS.DESCRIPTION";
      }

      static AUDIT_TRAIL = class {
        static readonly TITLE = "PERMISSIONS.AUDIT_TRAIL.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.AUDIT_TRAIL.DESCRIPTION";
      }

      static SYNC_COMPANIES = class {
        static readonly TITLE = "PERMISSIONS.SYNC_COMPANIES.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.SYNC_COMPANIES.DESCRIPTION";
      }

      static SYNC_PRODUCT_DOCS = class {
        static readonly TITLE = "PERMISSIONS.SYNC_PRODUCT_DOCS.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.SYNC_PRODUCT_DOCS.DESCRIPTION";
      }

      static SYNC_USER_GROUPS = class {
        static readonly TITLE = "PERMISSIONS.SYNC_USER_GROUPS.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.SYNC_USER_GROUPS.DESCRIPTION";
      }

      static FILE_STORAGE_MANAGEMENT = class {
        static readonly TITLE = "PERMISSIONS.FILE_STORAGE_MANAGEMENT.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.FILE_STORAGE_MANAGEMENT.DESCRIPTION";
      }

      static USER_MANAGEMENT = class {
        static readonly TITLE = "PERMISSIONS.USER_MANAGEMENT.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.USER_MANAGEMENT.DESCRIPTION";
      }

      static USER_GROUP_MANAGEMENT = class {
        static readonly TITLE = "PERMISSIONS.USER_GROUP_MANAGEMENT.NAME";
        static readonly DESCRIPTION = "PERMISSIONS.USER_GROUP_MANAGEMENT.DESCRIPTION";
      }
    }

    static ROLES = class {
      static CUST_USER = class{
        static readonly TITLE = "ROLES.CUST_USER.NAME";
        static readonly DESCRIPTION = "ROLES.CUST_USER.DESCRIPTION";
      }

      static CUST_ADMIN = class{
        static readonly TITLE = "ROLES.CUST_ADMIN.NAME";
        static readonly DESCRIPTION = "ROLES.CUST_ADMIN.DESCRIPTION";
      }

      static BUS_PARTNER_USER = class{
        static readonly TITLE = "ROLES.BUS_PARTNER_USER.NAME";
        static readonly DESCRIPTION = "ROLES.BUS_PARTNER_USER.DESCRIPTION";
      }

      static INTERESTED_PARTY_USER = class{
        static readonly TITLE = "ROLES.INTERESTED_PARTY_USER.NAME";
        static readonly DESCRIPTION = "ROLES.INTERESTED_PARTY_USER.DESCRIPTION";
      }

      static INT_USER = class{
        static readonly TITLE = "ROLES.INT_USER.NAME";
        static readonly DESCRIPTION = "ROLES.INT_USER.DESCRIPTION";
      }

      static INT_CONSULTANT = class{
        static readonly TITLE = "ROLES.INT_CONSULTANT.NAME";
        static readonly DESCRIPTION = "ROLES.INT_CONSULTANT.DESCRIPTION";
      }

      static INT_ADMIN = class{
        static readonly TITLE = "ROLES.INT_ADMIN.NAME";
        static readonly DESCRIPTION = "ROLES.INT_ADMIN.DESCRIPTION";
      }

      static INT_SUPER_ADMIN = class{
        static readonly TITLE = "ROLES.INT_SUPER_ADMIN.NAME";
        static readonly DESCRIPTION = "ROLES.INT_SUPER_ADMIN.DESCRIPTION";
      }

      static INT_PRODUCT_MANAGER = class{
        static readonly TITLE = "ROLES.INT_PRODUCT_MANAGER.NAME";
        static readonly DESCRIPTION = "ROLES.INT_PRODUCT_MANAGER.DESCRIPTION";
      }
    }

    static REBUILD_INDEX_OPTIONS = class {
        static readonly ALL = "ADMINISTRATION.CONFIGURATION.SEARCH.REBUILD_SEARCH_INDEX.OPTIONS.ALL";
        static readonly PRODUCT_DOCUMENTATION = "ADMINISTRATION.CONFIGURATION.SEARCH.REBUILD_SEARCH_INDEX.OPTIONS.PRODUCT_DOCUMENTATION";
        static readonly PRODUCT_RELEASES = "ADMINISTRATION.CONFIGURATION.SEARCH.REBUILD_SEARCH_INDEX.OPTIONS.PRODUCT_RELEASES";
        static readonly MARKETING_RESOURCES_AND_TRAINING_VIDEOS = "ADMINISTRATION.CONFIGURATION.SEARCH.REBUILD_SEARCH_INDEX.OPTIONS.MARKETING_RESOURCES_AND_TRAINING_VIDEOS";
    }

    static SECURITY = class {

      static AUTHENTICATION_METHODS = class{
        static readonly DEFAULT = "SECURITY.AUTHENTICATION_METHODS.DEFAULT";
        static readonly TWO_STEP_VERIFICATION = "SECURITY.AUTHENTICATION_METHODS.2SV";
        static readonly TWO_FACTOR_AUTHENTICATION = "SECURITY.AUTHENTICATION_METHODS.2FA";
        static readonly FIDO2_AUTHENTICATION = "SECURITY.AUTHENTICATION_METHODS.FIDO2";
      }
    }

    static SYNC_TYPES = class {
        static readonly HOURLY = "SYNC_TYPES.HOURLY";
        static readonly DAILY = "SYNC_TYPES.DAILY";
        static readonly SPECIFIC_DAYS_OF_WEEK = "SYNC_TYPES.SPECIFIC_DAYS_OF_WEEK";
    }

    static USER_ACTIVITIES = class {

      static CATEGORIES = class {
        static readonly LOGIN = "USER_ACTIVITIES.CATEGORIES.LOGIN";
        static readonly PRODUCT_RELEASE_DOWNLOAD: string = "USER_ACTIVITIES.CATEGORIES.PRODUCT_RELEASE_DOWNLOAD";
        static readonly PRODUCT_RELEASE_NOTES: string = "USER_ACTIVITIES.CATEGORIES.PRODUCT_RELEASE_NOTES";
        static readonly PRODUCT_DOCUMENTATION: string = "USER_ACTIVITIES.CATEGORIES.PRODUCT_DOCUMENTATION";
        static readonly MARKETING_MATERIAL_DOWNLOAD: string = "USER_ACTIVITIES.CATEGORIES.MARKETING_MATERIAL_DOWNLOAD";
        static readonly PRODUCT_DOCUMENTATION_SEARCH: string = "USER_ACTIVITIES.CATEGORIES.PRODUCT_DOCUMENTATION_SEARCH";
        static readonly PRODUCT_RELEASE_NOTES_SEARCH: string = "USER_ACTIVITIES.CATEGORIES.PRODUCT_RELEASE_NOTES_SEARCH";
        static readonly MARKETING_MATERIAL_SEARCH: string = "USER_ACTIVITIES.CATEGORIES.MARKETING_MATERIAL_SEARCH";
        static readonly HELP_CENTER_TRAINING_VIDEO: string = "USER_ACTIVITIES.CATEGORIES.HELP_CENTER_TRAINING_VIDEO";
        static readonly MARKETING_MATERIAL_VIDEO: string = "USER_ACTIVITIES.CATEGORIES.MARKETING_MATERIAL_VIDEO";
      }

      static BUSINESS_PARTNER_SPECIFIC_OPTIONS = class {
        static readonly ALL: string = "USER_ACTIVITIES.BUSINESS_PARTNER_SPECIFIC_OPTIONS.ALL";
      }

      static CATEGORY_SPECIFIC_OPTIONS = class {
        static readonly ALL: string = "USER_ACTIVITIES.CATEGORY_SPECIFIC_OPTIONS.ALL";
      }

      static PRODUCT_SPECIFIC_OPTIONS = class {
        static readonly ALL: string = "USER_ACTIVITIES.PRODUCT_SPECIFIC_OPTIONS.ALL";
        static readonly ALL_PRODUCTS: string = "USER_ACTIVITIES.PRODUCT_SPECIFIC_OPTIONS.ALL_PRODUCTS";
        static readonly NOT_PRODUCT_RELATED: string = "USER_ACTIVITIES.PRODUCT_SPECIFIC_OPTIONS.NOT_PRODUCT_RELATED";
      }

      static SELECTED_ACTIVITIES_FILTER_OPTIONS = class {
        static readonly ALL: string = "USER_ACTIVITIES.SELECTED_ACTIVITIES_FILTER_OPTIONS.ALL";
        static readonly ALL_CATEGORIES: string = "USER_ACTIVITIES.SELECTED_ACTIVITIES_FILTER_OPTIONS.ALL_CATEGORIES";
        static readonly MULTIPLE_CATEGORIES: string = "USER_ACTIVITIES.SELECTED_ACTIVITIES_FILTER_OPTIONS.MULTIPLE_CATEGORIES";
        static readonly SELECT_CATEGORY: string = "USER_ACTIVITIES.SELECTED_ACTIVITIES_FILTER_OPTIONS.SELECT_CATEGORY";
        static readonly ALL_PRODUCTS: string = "USER_ACTIVITIES.SELECTED_ACTIVITIES_FILTER_OPTIONS.ALL_PRODUCTS";
        static readonly MULTIPLE_PRODUCTS: string = "USER_ACTIVITIES.SELECTED_ACTIVITIES_FILTER_OPTIONS.MULTIPLE_PRODUCTS";
        static readonly NOT_RELATED_TO_PRODUCT: string = "USER_ACTIVITIES.SELECTED_ACTIVITIES_FILTER_OPTIONS.NOT_RELATED_TO_PRODUCT";
        static readonly SELECT_PRODUCT: string = "USER_ACTIVITIES.SELECTED_ACTIVITIES_FILTER_OPTIONS.SELECT_PRODUCT";
        static readonly MULTIPLE_OPTIONS: string = "USER_ACTIVITIES.SELECTED_ACTIVITIES_FILTER_OPTIONS.MULTIPLE_OPTIONS";
      }
    }

    static CUSTOMERS_OVERVIEW = class {

      static SUPPORTED_PRODUCTS = class {

        static PRODUCT_CATEGORY_SPECIFIC_OPTIONS = class {
          static readonly ALL: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.PRODUCT_CATEGORY_SPECIFIC_OPTIONS.ALL";
        }
  
        static PRODUCT_SPECIFIC_OPTIONS = class {
          static readonly ALL: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.PRODUCT_SPECIFIC_OPTIONS.ALL";
        }
  
        static SUBCONTRACT_VALIDITY_SPECIFIC_OPTIONS = class {
          static readonly ALL: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.SUBCONTRACT_VALIDITY_SPECIFIC_OPTIONS.ALL";
          static readonly CURRENTLY_VALID: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.SUBCONTRACT_VALIDITY_SPECIFIC_OPTIONS.CURRENTLY_VALID";
          static readonly CURRENTLY_NOT_VALID: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.SUBCONTRACT_VALIDITY_SPECIFIC_OPTIONS.CURRENTLY_NOT_VALID";
        }
  
        static SELECTED_CUSTOMERS_FILTER_OPTIONS = class {
          static readonly ALL_PRODUCTS: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.SELECTED_CUSTOMERS_FILTER_OPTIONS.ALL_PRODUCTS";
          static readonly MULTIPLE_PRODUCTS: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.SELECTED_CUSTOMERS_FILTER_OPTIONS.MULTIPLE_PRODUCTS";
          static readonly SELECT_PRODUCT: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.SELECTED_CUSTOMERS_FILTER_OPTIONS.SELECT_PRODUCT";
          static readonly ALL_PRODUCT_CATEGORIES: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.SELECTED_CUSTOMERS_FILTER_OPTIONS.ALL_PRODUCT_CATEGORIES";
          static readonly MULTIPLE_PRODUCT_CATEGORIES: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.SELECTED_CUSTOMERS_FILTER_OPTIONS.MULTIPLE_PRODUCT_CATEGORIES";
          static readonly SELECT_PRODUCT_CATEGORY: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.SELECTED_CUSTOMERS_FILTER_OPTIONS.SELECT_PRODUCT_CATEGORY";
        }
        
        static COLUMNS_SELECTION = class {
  
          static FIXED_COLUMNS_NAMES = class {
            static readonly CUSTOMER: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.COLUMNS_SELECTION.FIXED_COLUMNS.CUSTOMER";
            static readonly MAIN_CONTRACTS: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.COLUMNS_SELECTION.FIXED_COLUMNS.MAIN_CONTRACTS";
            static readonly SUB_CONTRACTS: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.COLUMNS_SELECTION.FIXED_COLUMNS.SUB_CONTRACTS";
            static readonly LOCATION: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.COLUMNS_SELECTION.FIXED_COLUMNS.LOCATION";
            static readonly SALES_REPRESENTATIVE: string = "CUSTOMERS_OVERVIEW.SUPPORTED_PRODUCTS.COLUMNS_SELECTION.FIXED_COLUMNS.SALES_REPRESENTATIVE";
          }
        }
  
        static MESSAGES = class {
          static readonly GETTING_CUSTOMERS: string = "Getting customers...";
        }

      }

      static ASSIGNED_USERS = class {

        static IS_ADMIN_FILTER_OPTIONS = class {
          static readonly ALL: string = "CUSTOMERS_OVERVIEW.ASSIGNED_USERS.IS_ADMIN_FILTER_OPTIONS.ALL";
          static readonly YES: string = "CUSTOMERS_OVERVIEW.ASSIGNED_USERS.IS_ADMIN_FILTER_OPTIONS.YES";
          static readonly NO: string = "CUSTOMERS_OVERVIEW.ASSIGNED_USERS.IS_ADMIN_FILTER_OPTIONS.NO";
        }

        static MESSAGES = class {
          static readonly GETTING_CUSTOMERS: string = "Getting customers...";
        }
      }
    }

    static AUDIT_TRAIL = class {

      static ACTION_TYPES = class {
        static readonly ALL: string = "AUDIT_TRAIL.ACTION_TYPES.ALL";
        static readonly USER_CHANGE: string = "AUDIT_TRAIL.ACTION_TYPES.USER_CHANGE";
        static readonly USER_GROUP_CHANGE: string = "AUDIT_TRAIL.ACTION_TYPES.USER_GROUP_CHANGE";
        static readonly USER_GROUP_IMPORT_CHANGE: string = "AUDIT_TRAIL.ACTION_TYPES.USER_GROUP_IMPORT_CHANGE";
      }

      static UPDATED_USER_PROPERTY_NAMES = class {
        static readonly FIRST_NAME: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_NAMES.FIRST_NAME";
        static readonly LAST_NAME: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_NAMES.LAST_NAME";
        static readonly USERNAME: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_NAMES.USERNAME";
        static readonly EMAIL: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_NAMES.EMAIL";
        static readonly PHONE_NUMBER: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_NAMES.PHONE_NUMBER";
        static readonly DEFAULT_AUTH_METHOD: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_NAMES.DEFAULT_AUTH_METHOD";
        static readonly IS_ENABLED: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_NAMES.IS_ENABLED";
        static readonly IS_LOCKED: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_NAMES.IS_LOCKED";
        static readonly TWO_FA_ENABLED: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_NAMES.TWO_FA_ENABLED";
        static readonly TWO_SV_ENABLED: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_NAMES.TWO_SV_ENABLED";
        static readonly FIDO2_ENABLED: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_NAMES.FIDO2_ENABLED";
      }

      static UPDATED_USER_PROPERTY_VALUES = class {
        static readonly DEFAULT: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_VALUES.DEFAULT";
        static readonly TWO_FA: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_VALUES.TWO_FA";
        static readonly TWO_SV: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_VALUES.TWO_SV";
        static readonly FIDO2: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_VALUES.FIDO2";
        static readonly TRUE: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_VALUES.TRUE";
        static readonly FALSE: string = "AUDIT_TRAIL.UPDATED_USER_PROPERTY_VALUES.FALSE";
      }

      static PERMISSIONS_ROLES_AND_USER_GROUPS_UPDATE_DESCRIPTORS = class {
        static readonly ADDED: string = "AUDIT_TRAIL.PERMISSIONS_ROLES_AND_USER_GROUPS_UPDATE_DESCRIPTORS.ADDED";
        static readonly REMOVED: string = "AUDIT_TRAIL.PERMISSIONS_ROLES_AND_USER_GROUPS_UPDATE_DESCRIPTORS.REMOVED";
      }

    }

    static ADMINISTRATION = class {
      static FILE_STORAGE = class {
        static STORAGE = class {
          static readonly NOT_PRODUCT_RELATED: string = "ADMINISTRATION.FILE_STORAGE.STORAGE.NOT_PRODUCT_RELATED";
        }
      }

      static CONFIGURATION = class {
        static SECURITY = class {
          static FIDO2 = class {
           static BACKUP_ELIGIBLE_CREDENTIAL_POLICY_OPTIONS = class{
            static readonly ALLOWED: string = "ADMINISTRATION.CONFIGURATION.SECURITY.FIDO2.BACKUP_ELIGIBLE_CREDENTIAL_POLICY_OPTIONS.ALLOWED";
            static readonly REQUIRED: string = "ADMINISTRATION.CONFIGURATION.SECURITY.FIDO2.BACKUP_ELIGIBLE_CREDENTIAL_POLICY_OPTIONS.REQUIRED";
            static readonly DISALLOWED: string = "ADMINISTRATION.CONFIGURATION.SECURITY.FIDO2.BACKUP_ELIGIBLE_CREDENTIAL_POLICY_OPTIONS.DISALLOWED";
           }

           static BACKUP_UP_CREDENTIAL_POLICY_OPTIONS = class{
            static readonly ALLOWED: string = "ADMINISTRATION.CONFIGURATION.SECURITY.FIDO2.BACKUP_UP_CREDENTIAL_POLICY_OPTIONS.ALLOWED";
            static readonly REQUIRED: string = "ADMINISTRATION.CONFIGURATION.SECURITY.FIDO2.BACKUP_UP_CREDENTIAL_POLICY_OPTIONS.REQUIRED";
            static readonly DISALLOWED: string = "ADMINISTRATION.CONFIGURATION.SECURITY.FIDO2.BACKUP_UP_CREDENTIAL_POLICY_OPTIONS.DISALLOWED";
           }
          }
        }
      }
    
    }

    static ADMINISTRATIVE_NOTIFICATIONS = class {

      static TYPES = class {
        static readonly ALL: string = "ADMINISTRATIVE_NOTIFICATIONS.TYPES.ALL";
        static readonly PERMISSIONS_CHANGE_REQUEST: string = "ADMINISTRATIVE_NOTIFICATIONS.TYPES.PERMISSIONS_CHANGE_REQUEST";
        static readonly ROLES_CHANGE_REQUEST: string = "ADMINISTRATIVE_NOTIFICATIONS.TYPES.ROLES_CHANGE_REQUEST";
        static readonly USER_REGISTRATION_REQUEST: string = "ADMINISTRATIVE_NOTIFICATIONS.TYPES.USER_REGISTRATION_REQUEST";
      }

      static IS_CONFIRMED_OPTIONS = class {
        static readonly ALL: string = "ADMINISTRATIVE_NOTIFICATIONS.IS_CONFIRMED_OPTIONS.ALL";
        static readonly CONFIRMED: string = "ADMINISTRATIVE_NOTIFICATIONS.IS_CONFIRMED_OPTIONS.CONFIRMED";
        static readonly DENIED: string = "ADMINISTRATIVE_NOTIFICATIONS.IS_CONFIRMED_OPTIONS.DENIED";
        static readonly NOT_PROCESSED: string = "ADMINISTRATIVE_NOTIFICATIONS.IS_CONFIRMED_OPTIONS.NOT_PROCESSED";
      }
    }

    static DATA_SYNCHRONIZATION = class {

      static STATUSES = class {
        static readonly ALL: string = "DATA_SYNCHRONIZATION.STATUSES.ALL";
        static readonly INFO: string = "DATA_SYNCHRONIZATION.STATUSES.INFO";
        static readonly WARNING: string = "DATA_SYNCHRONIZATION.STATUSES.WARNING";
        static readonly ERROR: string = "DATA_SYNCHRONIZATION.STATUSES.ERROR";
      }

      static TITLES = class {
        static readonly DOCUMENTATION_SYNC_DETAILS: string = "DATA_SYNCHRONIZATION.TITLES.DOCUMENTATION_SYNC_DETAILS";
        static readonly CORE_DATA_SYNC_DETAILS: string = "DATA_SYNCHRONIZATION.TITLES.CORE_DATA_SYNC_DETAILS";
        static readonly USER_GROUPS_SYNC_DETAILS: string = "DATA_SYNCHRONIZATION.TITLES.USER_GROUPS_SYNC_DETAILS";
      }
    }

    static BOOLEAN_FILTER_OPTIONS = class {
      static readonly ALL: string = "BOOLEAN_FILTER_OPTIONS.ALL";
      static readonly YES: string = "BOOLEAN_FILTER_OPTIONS.YES";
      static readonly NO: string = "BOOLEAN_FILTER_OPTIONS.NO";
    }

    static FILE_SUBCATEGORIES = class {
      static readonly MARKETING_MATERIAL_VIDEO: string = "FILE_SUBCATEGORIES.MARKETING_MATERIAL_VIDEO";
      static readonly TRAINING_VIDEO: string = "FILE_SUBCATEGORIES.TRAINING_VIDEO";
    }

    static USER_RATING = class {

      static RATING_DETAILS_DIALOG = class {

        static RATING_SCORE_OPTIONS = class {
          static readonly ALL: string = "USER_RATING.RATING_DETAILS_DIALOG.RATING_SCORE_OPTIONS.ALL";
        }
      }
    }
}
