import { IChildChangedReceiver } from "../interfaces/i-child-changed-receiver";

export class UserPermission{

  //#region Private Fields

  private _isAssignedOrig: boolean;

  private _isAssigned : boolean;

  //#endregion

  //#region Constructor

  constructor(name: string, title: string, description: string, isAssigned: boolean = false){
    this._isAssignedOrig = isAssigned;
    this._isAssigned = isAssigned;
    this.name = name;
    this.title = title;
    this.description = description;
    this.isRolePermission = false;
    this.relatedRoleName = "";
    this.isGroupPermission = false;
    this.relatedGroupName = "";
    this.isChanged = false;
  }

  //#endregion

  //#region Public Properties

  /**
   * Permission name.
   */
  public name: string;

  /**
   * Permission title.
   */
  public title: string;

  /**
   * Permission description.
   */
  public description: string;

  /**
   * Determines if permission is assigned.
   */
  public get isAssigned() : boolean {
    return this._isAssigned;
  }

  /**
   * Sets if permission is assigned.
   */
  public set isAssigned(isAssigned : boolean) {
    this._isAssigned = isAssigned;
    this.isChanged = this._isAssignedOrig !== this._isAssigned;
    
    if(this.childChangedReceiver){
      this.childChangedReceiver.notifyChildChanged();
    }
  }

  /**
   * Determines if permission is coming from role.
   */
  public isRolePermission: boolean;

  /**
   * Related role name. Applicable if permission is coming from role.
   */
  public relatedRoleName: string;

  /**
   * Determines if permission is group permission.
   */
  public isGroupPermission: boolean;

  /**
   * Related group name. Applicable if permission is group permission.
   */
  public relatedGroupName: string;

  /**
   * Determines if permission has changed (assigned/unassigned).
   */
  public isChanged: boolean;

  /**
   * Child changed receiver.
   */
  public childChangedReceiver?: IChildChangedReceiver;

  //#endregion

  //#region Public Methods

  /**
   * Undo changes.
   */
  public undoChanges(){
    this.isAssigned = this._isAssignedOrig;
  }

  //#endregion
}