/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { QueryActivitiesResponse } from '../models/query-activities-response';
import { QueryActivitiesRequest } from '../models/query-activities-request';
import { GetActivityCategoriesResponse } from '../models/get-activity-categories-response';
import { TrackDocsAccessActivityResponse } from '../models/track-docs-access-activity-response';
import { TrackDocsAccessActivityRequest } from '../models/track-docs-access-activity-request';
@Injectable({
  providedIn: 'root',
})
class ActivitiesService extends __BaseService {
  static readonly putApiActivitiesPath = '/api/activities';
  static readonly getApiActivityCategoriesPath = '/api/activityCategories';
  static readonly postApiTrackDocsAccessActivityPath = '/api/trackDocsAccessActivity';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return OK
   */
  putApiActivitiesResponse(body?: QueryActivitiesRequest): __Observable<__StrictHttpResponse<QueryActivitiesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/activities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryActivitiesResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  putApiActivities(body?: QueryActivitiesRequest): __Observable<QueryActivitiesResponse> {
    return this.putApiActivitiesResponse(body).pipe(
      __map(_r => _r.body as QueryActivitiesResponse)
    );
  }

  /**
   * @return OK
   */
  getApiActivityCategoriesResponse(): __Observable<__StrictHttpResponse<GetActivityCategoriesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/activityCategories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetActivityCategoriesResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiActivityCategories(): __Observable<GetActivityCategoriesResponse> {
    return this.getApiActivityCategoriesResponse().pipe(
      __map(_r => _r.body as GetActivityCategoriesResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiTrackDocsAccessActivityResponse(body?: TrackDocsAccessActivityRequest): __Observable<__StrictHttpResponse<TrackDocsAccessActivityResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/trackDocsAccessActivity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TrackDocsAccessActivityResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiTrackDocsAccessActivity(body?: TrackDocsAccessActivityRequest): __Observable<TrackDocsAccessActivityResponse> {
    return this.postApiTrackDocsAccessActivityResponse(body).pipe(
      __map(_r => _r.body as TrackDocsAccessActivityResponse)
    );
  }
}

module ActivitiesService {
}

export { ActivitiesService }
