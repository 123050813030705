import { UserRatingDetails } from "src/app/api/opal-partner-center/models";

export class RatingDetails {

    //#region Private Fields

    /**
     * Username of the user who provided rating.
     */
    private _username: string;

    /**
     * Rating score.
     */
    private _score: number;

    /**
     * Rating timestamp.
     */
    private _timestamp: string;

    /**
     * Rating comment.
     */
    private _comment: string;

    //#endregion

    //#region Constructor

    constructor(userRatingDetails: UserRatingDetails) {

        this._username = userRatingDetails.userName!;
        this._score = userRatingDetails.score!;
        this._timestamp = userRatingDetails.timestamp!;
        this._comment = userRatingDetails.comment!;
    }

    //#endregion

    //#region Properties

    /***
     * Gets username of the user who provided rating.
     */
    public get username(): string {
        return this._username;
    }

    /**
     * Gets rating score.
     */
    public get score(): number {
        return this._score;
    }

    /**
     * Gets rating timestamp.
     */
    public get timestamp(): string {
        return this._timestamp;
    }

    /**
     * Gets rating comment.
     */
    public get comment(): string {
        return this._comment;
    }

    //#endregion
}
