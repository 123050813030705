export class ConfirmFavoriteMessage{

  //#region Constructor

  constructor(favoriteLink: string){
      this.favoriteLink = favoriteLink;
  }

  //#endregion

  //#region Properties

  public favoriteLink?: string;

  //#endregion
}