/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PreLoginResponse } from '../models/pre-login-response';
import { PreLoginRequest } from '../models/pre-login-request';
import { LoginResponse } from '../models/login-response';
import { LoginRequest } from '../models/login-request';
import { BaseResponse } from '../models/base-response';
import { Confirm2SVRequest } from '../models/confirm-2svrequest';
import { Confirm2FARequest } from '../models/confirm-2farequest';
import { Verify2faRecoveryCodeRequest } from '../models/verify-2fa-recovery-code-request';
import { GenerateAuthenticatorKeyResponse } from '../models/generate-authenticator-key-response';
import { GenerateAuthenticatorKeyRequest } from '../models/generate-authenticator-key-request';
import { RegisterAuthenticatorRequest } from '../models/register-authenticator-request';
@Injectable({
  providedIn: 'root',
})
class AuthService extends __BaseService {
  static readonly postApiPreLoginPath = '/api/preLogin';
  static readonly postApiLoginPath = '/api/login';
  static readonly getApiExternalLoginPath = '/api/externalLogin';
  static readonly postApiConfirm2SVPath = '/api/confirm2SV';
  static readonly postApiConfirm2FAPath = '/api/confirm2FA';
  static readonly postApiVerify2faRecoveryCodePath = '/api/verify2faRecoveryCode';
  static readonly getApiLogoutPath = '/api/logout';
  static readonly postApiGenerateAuthenticatorKeyPath = '/api/generateAuthenticatorKey';
  static readonly postApiRegisterAuthenticatorPath = '/api/registerAuthenticator';
  static readonly postApiSend2faRecoveryCodePath = '/api/send2faRecoveryCode';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiPreLoginResponse(body?: PreLoginRequest): __Observable<__StrictHttpResponse<PreLoginResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/preLogin`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PreLoginResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiPreLogin(body?: PreLoginRequest): __Observable<PreLoginResponse> {
    return this.postApiPreLoginResponse(body).pipe(
      __map(_r => _r.body as PreLoginResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiLoginResponse(body?: LoginRequest): __Observable<__StrictHttpResponse<LoginResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoginResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiLogin(body?: LoginRequest): __Observable<LoginResponse> {
    return this.postApiLoginResponse(body).pipe(
      __map(_r => _r.body as LoginResponse)
    );
  }

  /**
   * @param params The `AuthService.GetApiExternalLoginParams` containing the following parameters:
   *
   * - `RedirectUrl`:
   *
   * - `ProviderName`:
   */
  getApiExternalLoginResponse(params: AuthService.GetApiExternalLoginParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.RedirectUrl != null) __params = __params.set('RedirectUrl', params.RedirectUrl.toString());
    if (params.ProviderName != null) __params = __params.set('ProviderName', params.ProviderName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/externalLogin`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AuthService.GetApiExternalLoginParams` containing the following parameters:
   *
   * - `RedirectUrl`:
   *
   * - `ProviderName`:
   */
  getApiExternalLogin(params: AuthService.GetApiExternalLoginParams): __Observable<null> {
    return this.getApiExternalLoginResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiConfirm2SVResponse(body?: Confirm2SVRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/confirm2SV`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiConfirm2SV(body?: Confirm2SVRequest): __Observable<BaseResponse> {
    return this.postApiConfirm2SVResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiConfirm2FAResponse(body?: Confirm2FARequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/confirm2FA`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiConfirm2FA(body?: Confirm2FARequest): __Observable<BaseResponse> {
    return this.postApiConfirm2FAResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiVerify2faRecoveryCodeResponse(body?: Verify2faRecoveryCodeRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/verify2faRecoveryCode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiVerify2faRecoveryCode(body?: Verify2faRecoveryCodeRequest): __Observable<BaseResponse> {
    return this.postApiVerify2faRecoveryCodeResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }
  getApiLogoutResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/logout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  getApiLogout(): __Observable<null> {
    return this.getApiLogoutResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiGenerateAuthenticatorKeyResponse(body?: GenerateAuthenticatorKeyRequest): __Observable<__StrictHttpResponse<GenerateAuthenticatorKeyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/generateAuthenticatorKey`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GenerateAuthenticatorKeyResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiGenerateAuthenticatorKey(body?: GenerateAuthenticatorKeyRequest): __Observable<GenerateAuthenticatorKeyResponse> {
    return this.postApiGenerateAuthenticatorKeyResponse(body).pipe(
      __map(_r => _r.body as GenerateAuthenticatorKeyResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiRegisterAuthenticatorResponse(body?: RegisterAuthenticatorRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/registerAuthenticator`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiRegisterAuthenticator(body?: RegisterAuthenticatorRequest): __Observable<BaseResponse> {
    return this.postApiRegisterAuthenticatorResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @return OK
   */
  postApiSend2faRecoveryCodeResponse(): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/send2faRecoveryCode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  postApiSend2faRecoveryCode(): __Observable<BaseResponse> {
    return this.postApiSend2faRecoveryCodeResponse().pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }
}

module AuthService {

  /**
   * Parameters for getApiExternalLogin
   */
  export interface GetApiExternalLoginParams {
    RedirectUrl?: string;
    ProviderName?: string;
  }
}

export { AuthService }
