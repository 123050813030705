import { Directive, Input } from "@angular/core";
import { TextBoxComponent } from "@progress/kendo-angular-inputs";
import { Subscription } from "rxjs";

@Directive({
    selector: 'kendo-textbox'
})
export class TrimDirective {
    
    private sub?: Subscription;
    
    constructor(private host: TextBoxComponent) {
        this.trim = false;
    }
    
    @Input()
    public trim: boolean;
    
    ngOnInit() {

        if (this.host.value) {

            this.host.value = this.host.value.trim();

            this.sub = this.host.valueChange.subscribe((value) => {
                if(this.trim) {
                    setTimeout( () => {
                        this.host.value = this.host.value.trim();
                    }, 100);
                }
            });
        }
        
    }
    
    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}
