import { UserFavoriteInfo } from "src/app/api/opal-partner-center/models";
import { UserFavoriteCategory } from "./user-favorite-category";
import { CONSTANTS } from "../constants";
import { TranslateService } from "@ngx-translate/core";
import { LOCALIZE_CONSTANTS } from "../localize.constants";

export class UserFavorite {

    //#region Constructor

    constructor(private _translateService: TranslateService, userFavoriteInfo: UserFavoriteInfo) {

        this.name = userFavoriteInfo.name;
        this.urlLink = userFavoriteInfo.urlLink;
        this.setCategory(userFavoriteInfo.category);
    }

    //#endregion

    //#region Properties

    /**
     * Favorite name.
     */
    name?: string;

    /**
     * Favorite category.
     */
    category?: UserFavoriteCategory;
    
    /**
     * Url link.
     */
    urlLink?: string;

    //#endregion

    //#region Private Methods

    /**
     * Sets user favorite category.
     * @param categoryName Category name.
     */
    private setCategory(categoryName: string | undefined) {

        this._translateService.get([LOCALIZE_CONSTANTS.USER_FAVORITE_CATEGORIES.RELEASE_NOTES, 
                                    LOCALIZE_CONSTANTS.USER_FAVORITE_CATEGORIES.SOFTWARE_COMPATIBILITY, 
                                    LOCALIZE_CONSTANTS.USER_FAVORITE_CATEGORIES.PRODUCT_DOCUMENTATION, 
                                    LOCALIZE_CONSTANTS.USER_FAVORITE_CATEGORIES.MARKETING_MATERIAL,
                                    LOCALIZE_CONSTANTS.USER_FAVORITE_CATEGORIES.VIDEO_FILE]).subscribe(translations => {
            
            switch(categoryName) {

                case CONSTANTS.USER_FAVORITE_CATEGORIES.RELEASE_NOTES:
                    this.category = new UserFavoriteCategory(CONSTANTS.USER_FAVORITE_CATEGORIES.RELEASE_NOTES, translations[LOCALIZE_CONSTANTS.USER_FAVORITE_CATEGORIES.RELEASE_NOTES]);
                    break;

                case CONSTANTS.USER_FAVORITE_CATEGORIES.SOFTWARE_COMPATIBILITY:
                    this.category = new UserFavoriteCategory(CONSTANTS.USER_FAVORITE_CATEGORIES.SOFTWARE_COMPATIBILITY, translations[LOCALIZE_CONSTANTS.USER_FAVORITE_CATEGORIES.SOFTWARE_COMPATIBILITY]);
                    break;

                case CONSTANTS.USER_FAVORITE_CATEGORIES.PRODUCT_DOCUMENTATION:
                    this.category = new UserFavoriteCategory(CONSTANTS.USER_FAVORITE_CATEGORIES.PRODUCT_DOCUMENTATION, translations[LOCALIZE_CONSTANTS.USER_FAVORITE_CATEGORIES.PRODUCT_DOCUMENTATION]);
                    break;

                case CONSTANTS.USER_FAVORITE_CATEGORIES.MARKETING_MATERIAL:
                    this.category = new UserFavoriteCategory(CONSTANTS.USER_FAVORITE_CATEGORIES.MARKETING_MATERIAL, translations[LOCALIZE_CONSTANTS.USER_FAVORITE_CATEGORIES.MARKETING_MATERIAL]);
                    break;
                
                case CONSTANTS.USER_FAVORITE_CATEGORIES.VIDEO_FILE:
                    this.category = new UserFavoriteCategory(CONSTANTS.USER_FAVORITE_CATEGORIES.VIDEO_FILE, translations[LOCALIZE_CONSTANTS.USER_FAVORITE_CATEGORIES.VIDEO_FILE]);
            }
        });
    }

    //#endregion
}
