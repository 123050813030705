/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ChangeUserGroupsRegistrationRequest } from '../models/change-user-groups-registration-request';
import { GetUserGroupInfoResponse } from '../models/get-user-group-info-response';
import { BaseResponse } from '../models/base-response';
import { UpdateUserGroupRequest } from '../models/update-user-group-request';
@Injectable({
  providedIn: 'root',
})
class UserGroupsManagementService extends __BaseService {
  static readonly putApiChangeGroupsRegistrationPath = '/api/changeGroupsRegistration';
  static readonly getApiGroupsManagementPath = '/api/groupsManagement';
  static readonly putApiGroupsManagementPath = '/api/groupsManagement';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   */
  putApiChangeGroupsRegistrationResponse(body?: ChangeUserGroupsRegistrationRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/changeGroupsRegistration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  putApiChangeGroupsRegistration(body?: ChangeUserGroupsRegistrationRequest): __Observable<null> {
    return this.putApiChangeGroupsRegistrationResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param UserGroupName undefined
   * @return OK
   */
  getApiGroupsManagementResponse(UserGroupName: string): __Observable<__StrictHttpResponse<GetUserGroupInfoResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (UserGroupName != null) __params = __params.set('UserGroupName', UserGroupName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/groupsManagement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetUserGroupInfoResponse>;
      })
    );
  }
  /**
   * @param UserGroupName undefined
   * @return OK
   */
  getApiGroupsManagement(UserGroupName: string): __Observable<GetUserGroupInfoResponse> {
    return this.getApiGroupsManagementResponse(UserGroupName).pipe(
      __map(_r => _r.body as GetUserGroupInfoResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  putApiGroupsManagementResponse(body?: UpdateUserGroupRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/groupsManagement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  putApiGroupsManagement(body?: UpdateUserGroupRequest): __Observable<BaseResponse> {
    return this.putApiGroupsManagementResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }
}

module UserGroupsManagementService {
}

export { UserGroupsManagementService }
