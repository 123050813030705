import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AddUserFavoriteRequest } from "src/app/api/opal-partner-center/models";
import { UsersService } from "src/app/api/opal-partner-center/services";
import { LOCALIZE_CONSTANTS } from "../../localize.constants";
import { ErrorResponseHandlerService } from "../../services/error-response-handler-service/error-response-handler-service";
import { NotificationService } from "../../services/notification-service/notification.service";
import { UserInfoService } from "../../services/user-info-service/user-info-service";
import { catchError, concatMap, of, tap } from "rxjs";


@Component({
    selector: 'add-user-favorite',
    templateUrl: './add-user-favorite.component.html'
})
export class AddUserFavoriteComponent {

    //#region Constructors

    constructor(private _usersService: UsersService, 
                private _notificationService: NotificationService, 
                private _errorResponseHandlerService: ErrorResponseHandlerService,
                private _userInfoStoreService: UserInfoService) {
        this.favoriteName = "";
        this.favoriteCategory = "";
        this.favoriteUrlLink = "";
        this.isAddingUserFavorite = false;
        this.isShown = false;
        this.dialogClosedEvent = new EventEmitter<boolean>();
        this.favoriteAddedEvent = new EventEmitter<string>();
        this.userFavoriteNames = new Array<string>;
    }

    //#endregion

    //#region Public Parameters

    /**
     * Favorite name
     */
    @Input()
    public favoriteName: string;

    /**
     * Favorite category
     */
    @Input()
    public favoriteCategory: string;

    /**
     * Favorite url link
     */
    @Input()
    public favoriteUrlLink: string;

    /**
     * Determines if popup is shown.
     */
    @Input()
    public isShown: boolean;

    /**
     * Emits event when the popup is closed
     */
    @Output()
    public dialogClosedEvent: EventEmitter<boolean>;

    /**
     * Emits event when new user favorite is added.
     */
    @Output()
    public favoriteAddedEvent: EventEmitter<string>;

    /**
     * Indicates if adding user favorite is in progress
     */
    public isAddingUserFavorite: boolean;

    /**
     * List of user favorite names.
     */
    @Input()
    public userFavoriteNames: Array<string>;

    //#endregion

    //#region UI Handlers

    public onAddUserFavorite() {
        
        this.isAddingUserFavorite = true;

        var request: AddUserFavoriteRequest = {} as AddUserFavoriteRequest;
        request.name = this.favoriteName;
        request.category = this.favoriteCategory;
        request.urlLink = this.favoriteUrlLink;

        this._usersService.postApiUserFavorites(request)
        .pipe(
            concatMap(response => {
                return of(null).pipe(
                    tap(() => {
                        this.isAddingUserFavorite = false;
                        this.closeDialog();
                        this._notificationService.notifySuccess(LOCALIZE_CONSTANTS.MESSAGES.USER_FAVORITE_ADDED);
                        this._userInfoStoreService.updateUserFavorites();
                    })
                );
            }),
            tap(() => this.favoriteAddedEvent.emit(this.favoriteUrlLink)),
            catchError(error => { return of(undefined)})
        )
        .subscribe({
            complete: () => { },
            error: (error) => {
                this.isAddingUserFavorite = false;
                this.closeDialog();
                this._errorResponseHandlerService.handleHttpErrorResponse(error);
            }
        });
    }

    /**
     * Closes dialog (this page)
     * @param addingFavoriteConfirmed Value "true" indicates the case when dialog is closed after "Add" button was clicked.
     * Value "false" indicates the case when dialog is closed after "x" icon was clicked.
     */
    public closeDialog(addingFavoriteConfirmed: boolean = true) {

        if (this.isAddingUserFavorite) {
            return;
        }

        this.isShown = false;
        this.dialogClosedEvent.emit(addingFavoriteConfirmed);
    }

    //#endregion
}