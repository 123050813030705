import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CONSTANTS } from "src/app/shared/constants";
import { LOCALIZE_CONSTANTS } from "src/app/shared/localize.constants";
import { SECURITY_ROUTES } from "../security.routing.constants";

@Component({
  selector: "email-confirmation",
  templateUrl: "./email-confirmation.component.html"
})
export class EmailConfirmationComponent{

  //#region Private Fields
  
  //#endregion

  //#region Constructor

  constructor(private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _translateService: TranslateService){
    
    this.emailConfirmationMessage = "";
    this.emailConfirmationErrorMessage = "";
    this.isEmailConfirmationErrorOccured = false;
    this.isRegistrationConfirmation = false;
  }

  //#endregion

  //#region NG Core

  public ngOnInit(): void {

    this._activatedRoute.queryParams.subscribe(params=>{

      var success = params["success"];
      var errorCodeParam = params["errorCode"];
      var isRegistrationConfirmation = params["isRegistration"];
      
      if(success && success == "true"){

        this.isEmailConfirmationErrorOccured = false;

        if(isRegistrationConfirmation && isRegistrationConfirmation == "true"){
          this.isRegistrationConfirmation = true;
          this._translateService.get(LOCALIZE_CONSTANTS.MESSAGES.REGISTRATION_CONFIRMED).subscribe(message=>{
            this.emailConfirmationMessage = message;
          });
        } else {
          this.isRegistrationConfirmation = false;
          this._translateService.get(LOCALIZE_CONSTANTS.MESSAGES.EMAIL_CONFIRMED).subscribe(message=>{
            this.emailConfirmationMessage = message;
          });
        }
      } else {

        this.isEmailConfirmationErrorOccured = true;

        if(isRegistrationConfirmation && isRegistrationConfirmation == "true"){
          this.isRegistrationConfirmation = true;
        }else{
          this.isRegistrationConfirmation = false;
        }

        if(errorCodeParam){

          var errorCode: number = parseInt(errorCodeParam);

          if(!isNaN(errorCode)){
            switch(errorCode){

              case CONSTANTS.SERVER_ERROR_CODES.EMAIL_ALREADY_CONFIRMED:
                this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.EMAIL_ALREADY_CONFIRMED).subscribe(message=>{
                  this.emailConfirmationMessage = message;
                });
                break;

              case CONSTANTS.SERVER_ERROR_CODES.UNABLE_TO_CONFIRM_EMAIL:
                this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNABLE_TO_CONFIRM_EMAIL).subscribe(message=>{
                  this.emailConfirmationMessage = message;
                });
                break;

              case CONSTANTS.SERVER_ERROR_CODES.EMAIL_CONFIRMATION_LINK_INVAILD:
                this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.EMAIL_CONFIRMATION_LINK_INVAILD).subscribe(message=>{
                  this.emailConfirmationMessage = message;
                });
                break;

              case CONSTANTS.SERVER_ERROR_CODES.USER_REGISTRATION_ALREADY_CONFIRMED:
                this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.USER_REGISTRATION_ALREADY_CONFIRMED).subscribe(message=>{
                  this.emailConfirmationMessage = message;
                });
                break;

              case CONSTANTS.SERVER_ERROR_CODES.UNABLE_TO_CONFIRM_USER_REGISTRATION:
                this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNABLE_TO_CONFIRM_USER_REGISTRATION).subscribe(message=>{
                  this.emailConfirmationMessage = message;
                });
                break;
  
              case CONSTANTS.SERVER_ERROR_CODES.USER_REGISTRATION_CONFIRMATION_LINK_INVALID:
                this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.USER_REGISTRATION_CONFIRMATION_LINK_INVALID).subscribe(message=>{
                  this.emailConfirmationMessage = message;
                });
                break;
  
              default:
  
                if(this.isRegistrationConfirmation){
                  this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.USER_REGISTRATION_CONFIRMATION_UNEXPECTED_ERROR).subscribe(message=>{
                    this.emailConfirmationMessage = message;
                  });
                } else {
                  this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.EMAIL_CONFIRMATION_UNEXPECTED_ERROR).subscribe(message=>{
                    this.emailConfirmationMessage = message;
                  });
                }

                break;
            }
          } else{
            if(this.isRegistrationConfirmation){
              this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.USER_REGISTRATION_CONFIRMATION_UNEXPECTED_ERROR).subscribe(message=>{
                this.emailConfirmationMessage = message;
              });
            } else {
              this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.EMAIL_CONFIRMATION_UNEXPECTED_ERROR).subscribe(message=>{
                this.emailConfirmationMessage = message;
              });
            }
          }
        } else {
          if(this.isRegistrationConfirmation){
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.USER_REGISTRATION_CONFIRMATION_UNEXPECTED_ERROR).subscribe(message=>{
              this.emailConfirmationMessage = message;
            });
          } else {
            this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.EMAIL_CONFIRMATION_UNEXPECTED_ERROR).subscribe(message=>{
              this.emailConfirmationMessage = message;
            });
          }
        }
      }
    })
  }

  //#endregion

  //#region Public Properties

  /**
   * The email confirmation message.
   */
  public emailConfirmationMessage: string;

  /**
   * Email confirmation error message.
   */
  public emailConfirmationErrorMessage: string;

  /**
   * Indicates whether the email confirmation error occured.
   */
  public isEmailConfirmationErrorOccured: boolean;

  /**
   * Indicates whether the email confirmation is for user registration.
   */
  public isRegistrationConfirmation: boolean;

  //#endregion

  //#region UI Handlers

  /**
   * Navigates to the login page.
   */
  public onGoToLogin(): void{
    this._router.navigate([SECURITY_ROUTES.SECURITY_LOGIN]);
  }

  //#endregion

}