import { Language } from "../services/translation-service/language";
import { DocumentationCategory } from "./documentation-category";
import { ProductVersionOption } from "./product-version-option";
import { SearchResultContentTypeOption } from "./search-result-content-type-option";


export class ProductDocumentDetailsMessage {

    //#region Constructor

    constructor(productName: string, 
                productShortName: string, 
                selectedProductVersionOption: ProductVersionOption, 
                productVersionOptions?: Array<ProductVersionOption>) {

        this.productName = productName;
        this.productShortName = productShortName;
        this.selectedProductVersionOption = selectedProductVersionOption;
        this.productVersionOptions = new Array<ProductVersionOption>();

        if (productVersionOptions) {
            for (let i = 0; i < productVersionOptions.length; i++) {
                this.productVersionOptions.push(productVersionOptions[i]);
            }
        }
    }

    //#endregion

    //#region Properties

    /**
     * Product name.
     */
    productName: string;

    /**
     * Product short name.
     */
    productShortName: string;

    /**
     * Document category.
     */
    documentCategory?: DocumentationCategory;

    /**
     * Content type:
     * - Text
     * - Code Snippet
     */
    contentType?: SearchResultContentTypeOption;

    /**
     * Document language.
     */
    language?: Language;

    /**
     * Product versions for a given product.
     */
    productVersionOptions: Array<ProductVersionOption>;

    /**
     * Selected product version.
     */
    selectedProductVersionOption: ProductVersionOption;

    //#endregion
}
