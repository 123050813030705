import { Component, AfterViewInit, NgZone, ViewChild, OnInit, ChangeDetectorRef, HostListener, SecurityContext, ElementRef } from '@angular/core';
import { MdbSidenavComponent } from 'mdb-angular-ui-kit/sidenav';
import { catchError, concatMap, delay, fromEvent, Observable, of, tap } from 'rxjs';
import { SidenavItemInfo } from './sidenav-item-info';
import { TranslateService } from '@ngx-translate/core';
import { LOCALIZE_CONSTANTS } from '../shared/localize.constants';
import { CONSTANTS } from '../shared/constants';
import { BusyIndicationService } from '../shared/services/busy-indication-service/busy-indication-service';
import { AppSettingsService, InfoService, UsersService, RatingsService, VideoStreamingService } from '../api/opal-partner-center/services';
import { UserInfoService } from '../shared/services/user-info-service/user-info-service';
import { LoggerService } from '../shared/services/log-service/logger-service';
import { AuthService } from '../api/opal-partner-center/services/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PubSubService } from '../shared/services/pub-sub-service/pub-sub-service';
import { UpdateToolbarMessage } from '../shared/model/update-toolbar-message';
import { ProductDocumentDetailsMessage } from '../shared/model/product-document-details-message';
import { ProductVersionOption } from '../shared/model/product-version-option';
import { PageTitleClickedMessage } from '../shared/model/page-title-clicked-message';
import { CheckFavoriteMessage } from '../shared/model/check-favorite-message';
import { SearchMessage } from '../shared/model/search-message';
import { ConfirmFavoriteMessage } from '../shared/model/confirm-favorite.message';
import { ConfirmSharePageMessage } from '../shared/model/confirm-share-page.message';
import { NotificationService } from '../shared/services/notification-service/notification.service';
import { SearchResultContentTypeOption } from '../shared/model/search-result-content-type-option';
import { Language } from '../shared/services/translation-service/language';
import { DocumentationCategory } from '../shared/model/documentation-category';
import { UserFavorite } from '../shared/model/user-favorite';
import { ProductVersionChangeMessage } from '../shared/model/product-version-change-message';
import { LAYOUT_ROUTES } from './layout.routing.constants';
import { SECURITY_ROUTES } from '../security/security.routing.constants';
import { UserInactivityService } from '../shared/services/user-inactivity-service/user-inactivity.service';
import { UpdateSearchFilterMessage } from '../shared/model/update-search-filter-message';
import { UserDetails } from '../shared/model/user-details';
import { UserPermission } from '../shared/model/user-permission';
import { UserRole } from '../shared/model/user-role';
import { UserRatingSummary } from '../shared/model/user-rating-summary';
import { RatingSummaryMessage } from '../shared/model/rating-summary-message';
import { ErrorResponseHandlerService } from '../shared/services/error-response-handler-service/error-response-handler-service';
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { GetPartnerCenterInfoResponse, GetUserFavoritesResponse, GetUserInfoResponse } from '../api/opal-partner-center/models';
import { HttpClient } from '@angular/common/http';


@Component({
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements AfterViewInit, OnInit {

  //#region Private Fields

  /**
   * All sidenav items.
   */
  private _allSidenavItems: Array<SidenavItemInfo>; 

  /**
   * Short name of the product for which documentation is accessed through Help Center page.
   */
  private _productShortName?: string;

  /**
   * Version of the product for which documentation is accessed through Help Center page.
   */
  private _productVersion?: string;

  /**
   * Category of product document accessed through Help Center page.
   */
  private _documentCategory?: string;

  /**
   * Relative path of product document accessed through Help Center page.
   */
  private _documentRelativePath?: string;

  /**
   * List of product versions used for product documentation search filter.
   */
  private _productVersionOptions: Array<ProductVersionOption>;

  /**
   * Product version filter used in the last product documentation filtered search that was 
   * initiated while some product document is accessed (opened) through Help Center page.
   */
  private _appliedProductVersionFilter?: ProductVersionOption;

  /**
   * List of search result content types used for product documentation search filter.
   */
  private _contentTypeOptions: Array<SearchResultContentTypeOption>;

  /**
   * Content type filter used in the last product documentation filtered search that was 
   * initiated while some product document is accessed (opened) through Help Center page.
   */
  private _appliedContentTypeFilter?: SearchResultContentTypeOption;

  /**
   * List of documentation languages used for product documentation search filter.
   */
  private _documentationLanguageOptions: Array<Language>;

  /**
   * File content language filter used in the last product documentation filtered search that was 
   * initiated while some product document is accessed (opened) through Help Center page.
   */
  private _appliedDocumentationLanguageFilter?: Language;

  /**
   * Time elapsed on Partner Center intro video before video playing error occurred.
   */
  private _pcIntroVideoTimeBeforeError: number = 0;

  /**
   * Determines if sidenav should be kept opened.
   */
  private _keepSidenavShown: boolean;

  //#endregion

  //#region Constructors

  /**
   * Initializes new instance of AppComponent
   * @param _ngZone NgZone.
   * @param _translateService Translate service.
   * @param _userInfoService User info service.
   * @param _usersService Users service.
   * @param _loggerService Logger service.
   * @param _infoService Info service.
   * @param _authService Auth service.
   * @param _pubSubService Publisher subscriber service.
   * @param _router Router service.
   * @param _activatedRoute Activated route service.
   * @param _busyIndicationService Busy indication service.
   * @param _changeDetector Change detector.
   * @param _notificationService Notification service.
   * @param _authStatusService Authentication status service.
   * @param _ratingsService User ratings service.
   */
  constructor(private _ngZone: NgZone, 
              private _translateService: TranslateService, 
              private _userInfoService: UserInfoService, 
              private _usersService: UsersService, 
              private _loggerService: LoggerService, 
              private _infoService: InfoService, 
              private _authService: AuthService,
              private _pubSubService: PubSubService, 
              private _router: Router, 
              private _activatedRoute: ActivatedRoute, 
              private _busyIndicationService: BusyIndicationService,
              private _changeDetector: ChangeDetectorRef,
              private _notificationService: NotificationService, 
              private _userInavtivityService: UserInactivityService,
              private _appSettingsService: AppSettingsService,
              private _ratingsService: RatingsService,
              private _videoStreamingService: VideoStreamingService,
              private _httpClient: HttpClient,
              private _domSanitizer: DomSanitizer,
              private _errorResponseHandlerService: ErrorResponseHandlerService) {

    this.isBusy = false;
    this.busyMessage = "";
    this.partnerCenterVersion = "";
    this.partnerCenterIntroVideoGuid = "";
    this.isIntroVideoDialogShown = false;
    this.showDocumentOptions = false;
    this.documentProductVersions = new Array<ProductVersionOption>();
    this.selectedDocumentProductVersion = new ProductVersionOption(this._translateService, "", false);
    this.isSharePageDialogShown = false;
    this.sharedPageLink = "";
    this.sharedPageTitle = "";
    this.emailSubject = "";
    this.isAddUserFavoriteDialogShown = false;
    this._keepSidenavShown = false;
    this.userInfoShown = false;
    this.userInfoDialogLeftPosition = 0;
    this.sidenavButtonVisible = false;

    this._translateService.get(LOCALIZE_CONSTANTS.COMMON.PAGE_HAS_BEEN_SHARED_WITH_YOU).subscribe(translation => {
      this.emailSubject = translation;
    });

    this.isAddUserFavoriteDialogShown = false;
    this.userFavoriteUrlLink = "";
    this.productDocumentSelectedAsFavorite = false;
    
    _busyIndicationService.Initialize(this);

    this.sidenavItems = new Array<SidenavItemInfo>();
    this._allSidenavItems = new Array<SidenavItemInfo>();

    this._translateService.get([LOCALIZE_CONSTANTS.SIDENAV.GENERAL_SEARCH, 
                                LOCALIZE_CONSTANTS.SIDENAV.ACCOUNT, 
                                LOCALIZE_CONSTANTS.SIDENAV.ACCOUNT_PROFILE, 
                                LOCALIZE_CONSTANTS.SIDENAV.ACCOUNT_PRODUCTS, 
                                LOCALIZE_CONSTANTS.SIDENAV.ACCOUNT_SUPPORT_INFORMATION, 
                                LOCALIZE_CONSTANTS.SIDENAV.DASHBOARD, 
                                LOCALIZE_CONSTANTS.SIDENAV.DASHBOARD_WELCOME, 
                                LOCALIZE_CONSTANTS.SIDENAV.DASHBOARD_CUSTOMER_OVERVIEW, 
                                LOCALIZE_CONSTANTS.SIDENAV.LICENSE_AND_INSTALLATIONS, 
                                LOCALIZE_CONSTANTS.SIDENAV.SOFTWARE, 
                                LOCALIZE_CONSTANTS.SIDENAV.SOFTWARE_RELEASES, 
                                LOCALIZE_CONSTANTS.SIDENAV.SOFTWARE_RELEASE_POLICY, 
                                LOCALIZE_CONSTANTS.SIDENAV.SOFTWARE_COMPATIBILITY, 
                                LOCALIZE_CONSTANTS.SIDENAV.HELP_CENTER, 
                                LOCALIZE_CONSTANTS.SIDENAV.HELP_CENTER_DOCUMENTATION, 
                                LOCALIZE_CONSTANTS.SIDENAV.HELP_CENTER_TRAINING_VIDEOS, 
                                LOCALIZE_CONSTANTS.SIDENAV.MARKETING_RESOURCES, 
                                LOCALIZE_CONSTANTS.SIDENAV.MARKETING_RESOURCES_DOCUMENTATION, 
                                LOCALIZE_CONSTANTS.SIDENAV.MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEOS, 
                                LOCALIZE_CONSTANTS.SIDENAV.ADMNISTRATION,
                                LOCALIZE_CONSTANTS.SIDENAV.ADMNISTRATION_CONFIGURATION,
                                LOCALIZE_CONSTANTS.SIDENAV.ADMNISTRATION_FILE_STORAGE,
                                LOCALIZE_CONSTANTS.SIDENAV.ADMNISTRATION_USER_MANAGEMENT,
                                LOCALIZE_CONSTANTS.SIDENAV.ADMINISTRATION_PUBLICATION_CATEGORIES,
                                LOCALIZE_CONSTANTS.SIDENAV.AUDIT_TRAIL,
                                LOCALIZE_CONSTANTS.SIDENAV.ADMINISTRATIVE_NOTIFICATIONS,
                                LOCALIZE_CONSTANTS.SIDENAV.DATA_SYNCHRONIZATION,
                                LOCALIZE_CONSTANTS.SIDENAV.CUSTOMERS_OVERVIEW,
                                LOCALIZE_CONSTANTS.SIDENAV.USER_GUIDE]).subscribe(translations => {
      
      this._allSidenavItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.GENERAL_SEARCH, translations[LOCALIZE_CONSTANTS.SIDENAV.GENERAL_SEARCH], "fa-magnifying-glass", `/${LAYOUT_ROUTES.GENERAL_SEARCH}`));

      var dashboardSidenavItem = new SidenavItemInfo(CONSTANTS.SIDENAV.DASHBOARD, translations[LOCALIZE_CONSTANTS.SIDENAV.DASHBOARD], "fa-chart-column", `/${LAYOUT_ROUTES.DASHBOARD}`);
      dashboardSidenavItem.childItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.DASHBOARD_WELCOME, translations[LOCALIZE_CONSTANTS.SIDENAV.DASHBOARD_WELCOME], "fa-house", `/${LAYOUT_ROUTES.DASHBOARD_WELCOME}`));
      
      this._allSidenavItems.push(dashboardSidenavItem);

      var accountSidenavItem = new SidenavItemInfo(CONSTANTS.SIDENAV.ACCOUNT, translations[LOCALIZE_CONSTANTS.SIDENAV.ACCOUNT], "fa-user", `/${LAYOUT_ROUTES.ACCOUNT}`);
      accountSidenavItem.childItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.ACCOUNT_PROFILE, translations[LOCALIZE_CONSTANTS.SIDENAV.ACCOUNT_PROFILE], "fa-user", `/${LAYOUT_ROUTES.ACCOUNT_PROFILE}`));

      this._allSidenavItems.push(accountSidenavItem);
      this._allSidenavItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.ACCOUNT_SUPPORT_INFORMATION, translations[LOCALIZE_CONSTANTS.SIDENAV.ACCOUNT_SUPPORT_INFORMATION], "fa-user", `/${LAYOUT_ROUTES.ACCOUNT_SUPPORT_INFORMATION}`));
      this._allSidenavItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.ACCOUNT_PRODUCTS, translations[LOCALIZE_CONSTANTS.SIDENAV.ACCOUNT_PRODUCTS], "fa-user", `/${LAYOUT_ROUTES.ACCOUNT_PRODUCTS}`));


      var softwareSidenavItem = new SidenavItemInfo(CONSTANTS.SIDENAV.SOFTWARE, translations[LOCALIZE_CONSTANTS.SIDENAV.SOFTWARE], "fa-arrow-down-to-line", `/${LAYOUT_ROUTES.SOFTWARE}`);
      softwareSidenavItem.childItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.SOFTWARE_RELEASES, translations[LOCALIZE_CONSTANTS.SIDENAV.SOFTWARE_RELEASES], "fa-arrow-down-to-line", `/${LAYOUT_ROUTES.SOFTWARE_RELEASES}`));
      softwareSidenavItem.childItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.SOFTWARE_COMPATIBILITY, translations[LOCALIZE_CONSTANTS.SIDENAV.SOFTWARE_COMPATIBILITY], "fa-arrow-down-to-line", `/${LAYOUT_ROUTES.SOFTWARE_COMPATIBILITY}`));
      softwareSidenavItem.childItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.SOFTWARE_RELEASE_POLICY, translations[LOCALIZE_CONSTANTS.SIDENAV.SOFTWARE_RELEASE_POLICY], "fa-arrow-down-to-line", `/${LAYOUT_ROUTES.SOFTWARE_RELEASE_POLICY}`));
      this._allSidenavItems.push(softwareSidenavItem);
      
      var helpCenterSidenavItem = new SidenavItemInfo(CONSTANTS.SIDENAV.HELP_CENTER, translations[LOCALIZE_CONSTANTS.SIDENAV.HELP_CENTER], "fa-circle-question", `/${LAYOUT_ROUTES.HELP_CENTER}`);
      helpCenterSidenavItem.childItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.HELP_CENTER_DOCUMENTATION, translations[LOCALIZE_CONSTANTS.SIDENAV.HELP_CENTER_DOCUMENTATION], "", `/${LAYOUT_ROUTES.HELP_CENTER_DOCUMENTATION}`));
      helpCenterSidenavItem.childItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.HELP_CENTER_TRAINING_VIDEOS, translations[LOCALIZE_CONSTANTS.SIDENAV.HELP_CENTER_TRAINING_VIDEOS], "", `/${LAYOUT_ROUTES.HELP_CENTER_TRAINING_VIDEOS}`));
      this._allSidenavItems.push(helpCenterSidenavItem);

      var marketingResourcesSidenavItem = new SidenavItemInfo(CONSTANTS.SIDENAV.MARKETING_RESOURCES, translations[LOCALIZE_CONSTANTS.SIDENAV.MARKETING_RESOURCES], "fa-bullhorn", `/${LAYOUT_ROUTES.MARKETING_RESOURCES}`);
      marketingResourcesSidenavItem.childItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.MARKETING_RESOURCES_DOCUMENTATION, translations[LOCALIZE_CONSTANTS.SIDENAV.MARKETING_RESOURCES_DOCUMENTATION], "", `/${LAYOUT_ROUTES.MARKETING_RESOURCES_DOCUMENTATION}`));
      marketingResourcesSidenavItem.childItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEOS, translations[LOCALIZE_CONSTANTS.SIDENAV.MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEOS], "", `/${LAYOUT_ROUTES.MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEOS}`));
      this._allSidenavItems.push(marketingResourcesSidenavItem);

      
      this._allSidenavItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.ADMNISTRATION, translations[LOCALIZE_CONSTANTS.SIDENAV.ADMNISTRATION], "fa-gear", `/${LAYOUT_ROUTES.ADMINISTRATION}`));
      this._allSidenavItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.ADMNISTRATION_CONFIGURATION, translations[LOCALIZE_CONSTANTS.SIDENAV.ADMNISTRATION_CONFIGURATION], "fa-gear", `/${LAYOUT_ROUTES.ADMINISTRATION_CONFIGURATION}`));
      this._allSidenavItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.ADMNISTRATION_FILESTORAGE, translations[LOCALIZE_CONSTANTS.SIDENAV.ADMNISTRATION_FILE_STORAGE], "fa-gear", `/${LAYOUT_ROUTES.ADMINISTRATION_FILESTORAGE}`));
      this._allSidenavItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.ADMNISTRATION_USER_MANAGEMENT, translations[LOCALIZE_CONSTANTS.SIDENAV.ADMNISTRATION_USER_MANAGEMENT], "fa-USER", `/${LAYOUT_ROUTES.ADMINISTRATION_USER_MANAGEMENT}`));
      this._allSidenavItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.ADMINISTRATION_PUBLICATION_CATEGORIES, translations[LOCALIZE_CONSTANTS.SIDENAV.ADMINISTRATION_PUBLICATION_CATEGORIES], "fa-USER", `/${LAYOUT_ROUTES.ADMINISTRATION_PUBLICATION_CATEGORIES}`));
      this._allSidenavItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.DATA_SYNCHRONIZATION, translations[LOCALIZE_CONSTANTS.SIDENAV.DATA_SYNCHRONIZATION], "fa-rotate", `/${LAYOUT_ROUTES.ADMINISTRATION_DATA_SYNCHRONIZATION}`));


      this._allSidenavItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.AUDIT_TRAIL, translations[LOCALIZE_CONSTANTS.SIDENAV.AUDIT_TRAIL], "fa-file-magnifying-glass", `/${LAYOUT_ROUTES.AUDIT_TRAIL}`));

      this._allSidenavItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.ADMINISTRATIVE_NOTIFICATIONS, translations[LOCALIZE_CONSTANTS.SIDENAV.ADMINISTRATIVE_NOTIFICATIONS], "fa-bell", `/${LAYOUT_ROUTES.ADMINISTRATIVE_NOTIFICATIONS}`));

      this._allSidenavItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.CUSTOMERS_OVERVIEW, translations[LOCALIZE_CONSTANTS.SIDENAV.CUSTOMERS_OVERVIEW], "fa-users", `/${LAYOUT_ROUTES.CUSTOMERS_OVERVIEW}`));

      this._allSidenavItems.push(new SidenavItemInfo(CONSTANTS.SIDENAV.USER_GUIDE, translations[LOCALIZE_CONSTANTS.SIDENAV.USER_GUIDE], "fa-circle-info", `/${LAYOUT_ROUTES.USER_GUIDE}`));
    });

    this.currentYear = (new Date()).getFullYear();

    this.sidenavMode = window.innerWidth >= 1400 ? 'side' : 'over';
    this.sidenavHidden = window.innerWidth >= 1400 ? false : true;

    this.sidenavCollapsed = false;

    this.isSearchFiltersDialogShown = false;

    this._productVersionOptions = new Array<ProductVersionOption>();
    this._contentTypeOptions = new Array<SearchResultContentTypeOption>();
    this._documentationLanguageOptions = new Array<Language>();

    this.filteredProductVersionOptions = new Array<ProductVersionOption>();
    this.filteredContentTypeOptions = new Array<SearchResultContentTypeOption>();
    this.filteredDocumentationLanguageOptions = new Array<Language>();
  }

  //#endregion

  //#region Ng Core

  /**
   * Call on Angular init.
   */
  ngOnInit(): void {

    window.addEventListener('message', (event) => {

      if(event.data && event.data && event.data.action && event.data.action == CONSTANTS.EXTERNAL_ACTIONS.DOWNLOAD_FILE && event.data.href){
        let url = new URL(event.data.href);

        if(url.hash){
          let [route, queryString] = url.hash.split('?');

          if(route && queryString){
            route = route.replace('#/', '');
            let hrefQueryParams = new URLSearchParams(queryString);

            let fileGuid = hrefQueryParams.get(CONSTANTS.QUERY_PARAMS.FILE_GUID);
            let fileCategory = hrefQueryParams.get(CONSTANTS.QUERY_PARAMS.FILE_CATEGORY);
    
            let queryParams: Params = {};
            queryParams[CONSTANTS.QUERY_PARAMS.FILE_GUID] = fileGuid;
            queryParams[CONSTANTS.QUERY_PARAMS.FILE_CATEGORY] = fileCategory;
  
            if(route == LAYOUT_ROUTES.FILE_DOWNLOAD){
              this._router.navigate([route],{
                queryParams: queryParams
              });
            }
          }
        }
      }
    })

    // Get user info & permissions
    this._loggerService.info("Getting user info...");
    
    this.getContentTypeOptions();
    this.getDocumentationLanguageOptions();

    var signoutTimeout = 15 * 60 * 1000;

    this._appSettingsService.getApiAppSetting(CONSTANTS.CONFIGURATION_PARAMETERS.SECURITY.AUTH_COOKIE_LIFETIME).subscribe({
      next: response =>{

        if(response.appSetting!.value){

          var authCookieLifetime = parseInt(response.appSetting!.value);

          if(!isNaN(authCookieLifetime)){
            signoutTimeout = (authCookieLifetime - 1) * 60 * 1000;
          }

          this._userInavtivityService.start(signoutTimeout, this.signOut.bind(this));
        }
      },
      error: errorResponse =>{
        this._userInavtivityService.start(signoutTimeout, this.signOut.bind(this));
      }
    });

    var results: any[] = [];

    this.getUserInfo().pipe(
      tap(result => results.push(result)),
      catchError(error => { return of(undefined) }),

      concatMap(() => this.getUserFavorites()),
      tap(result => results.push(result)),
      catchError(error => { return of(undefined) }),

      concatMap(() => this.getPartnerCenterInfo()),
      tap(result => results.push(result)),
      catchError(error => { return of(undefined) })
    )
    .subscribe({
      complete: () => {
        this.handleGetUserInfo(results[0]);
        this.handleGetUserFavorites(results[1]);
        this.handleGetPartnerCenterInfo(results[2]);
        this.tryShowIntroVideo();

      },
      error: (error: any) => {
        this._loggerService.errorFormat("Error occured: {0}", error);
      }
    });

    this._pubSubService.subscribe(CONSTANTS.TOPICS.UPDATE_TOOLBAR)?.subscribe({
      next: message => {

        if (message instanceof UpdateToolbarMessage) {

          var subtitle = message.subtitle;

          this.toolbarTitlePageNamePart = message.title;
          this.toolbarTitleProductPart = subtitle == "" ? undefined : subtitle;
          this.showSearchBar = message.showSearchBar;
          this.showDocumentOptions = message.showDocumentOptions;
          this.searchTerm = message.searchTerm;
          this._changeDetector.detectChanges();

        } else if (message instanceof ProductDocumentDetailsMessage) {
          
          if (message.productShortName && message.productShortName != "") {

            this.productName = message.productName;
            this.showDocumentOptions = true;
            this.documentProductVersions = new Array<ProductVersionOption>();

            for (let i = 0; i < message.productVersionOptions.length; i++) {
              this.documentProductVersions.push(message.productVersionOptions[i]);
            }

            this.selectedDocumentProductVersion = message.selectedProductVersionOption;

            if(message.contentType){
              this.contentTypeFilter = this._contentTypeOptions.find(x => x.name == message.contentType!.name);
              this._appliedContentTypeFilter = this.contentTypeFilter;
            }

            if(message.language){
              this.documentationLanguageFilter = this._documentationLanguageOptions.find(x => x.languageCode == message.language!.languageCode);
              this._appliedDocumentationLanguageFilter = this.documentationLanguageFilter;
            }

            this.setProductVersionOptions();
            this.productVersionFilter = this.filteredProductVersionOptions.find(x => x.value ==  this.selectedDocumentProductVersion.value);
            this._appliedProductVersionFilter = this.productVersionFilter;

            if (message.documentCategory) {
              this.searchTerm = "";
              this.showSearchBar = true;
              this.documentCategoryFilter = message.documentCategory;
            } else {
              this.showSearchBar = false;
            }
          } else {
            this.showDocumentOptions = false;
          }
        }
      }
    });
    
    this._pubSubService.subscribe(CONSTANTS.TOPICS.CHECK_FAVORITE)?.pipe(delay(300)).subscribe({
      next: message => {

        if (message instanceof CheckFavoriteMessage) {
          
          if (message.isProductDocumentFavorite) {
            this.productDocumentSelectedAsFavorite = true;

          } else {
            this.productDocumentSelectedAsFavorite = false;
          }
        }
      }
    });

    this._pubSubService.subscribe(CONSTANTS.TOPICS.RATING_SUMMARY)?.subscribe({
      next: message => {
        if (message instanceof RatingSummaryMessage) {
          this.documentRatingSummary = message.ratingSummary;
        }
      }
    });

    this._pubSubService.subscribe(CONSTANTS.TOPICS.CONFIRM_FAVORITE)?.pipe(delay(300)).subscribe({
      next: message => {  
        if(message instanceof ConfirmFavoriteMessage){
          if(message.favoriteLink != undefined){
            this.userFavoriteUrlLink = message.favoriteLink;
          }
        }
      }
    });

    this._pubSubService.subscribe(CONSTANTS.TOPICS.CONFIRM_SHARE_PAGE)?.pipe(delay(300)).subscribe({
      next: message=>{
        if(message instanceof ConfirmSharePageMessage){
          if(message.pageLink != undefined && message.pageTitle != undefined){
            this.sharedPageLink = message.pageLink;
            this.sharedPageTitle = message.pageTitle;
          }
        }
      }
    });

    this._activatedRoute.queryParams.subscribe(params => {

      if (params){
        if (params.product && params.productVersion && params.category) {
          this._productShortName = params.product;
          this._productVersion = params.productVersion;
          this._documentCategory = params.category;
  
          if (params.relativePath) {
            this._documentRelativePath = params.relativePath;
          }
          //this.checkFavoriteStatus();
        } else{
          if (params.relativePath) {
            this._documentRelativePath = params.relativePath;
          }
        }
      }
    });

    this._pubSubService.subscribe(CONSTANTS.TOPICS.UPDATE_DOCUMENT_PRODUCT_VERSION)?.subscribe({
      next: message => {
        if (message instanceof ProductVersionChangeMessage && message.selectedProductVersion) {
          this.selectedDocumentProductVersion = this.documentProductVersions.find(x => x.value == message.selectedProductVersion)!;
        }
      }
    });

    this._pubSubService.subscribe(CONSTANTS.TOPICS.UPDATE_SEARCH_FILTER_OPTION)?.subscribe({
      next: message =>{
        if(message instanceof UpdateSearchFilterMessage){
          if(message.filterOption == CONSTANTS.SEARCH.FILTERS.DOCUMENT_CATEGORY){
            this.documentCategoryFilter = message.value;
            this._loggerService.infoFormat("Document category filter updated to {0}", this.documentCategoryFilter?.name);
          } 
        }
      }
    });
  }

  /**
   * Called after angular finishes view initialization
   */
  ngAfterViewInit() {

    this.userInfoDialogLeftPosition = window.innerWidth - 400 - 10;

    if(window.innerWidth < 1400){
      this.sidenavButtonVisible = true;
    } else {
      this.sidenavButtonVisible = false;
    }

    // Handle screen resize to:
    // - show/hide sidenav
    // - change sidenav mode over/side
    this._ngZone.runOutsideAngular(() => {
      fromEvent(window, 'resize').subscribe(() => {
        if (window.innerWidth < 1400 && this.sidenavMode !== 'over') {
          this._ngZone.run(() => {
            this.sidenavMode = 'over';
            this.hideSidenav();
            this.sidenavHidden = true;
          });
        } else if (window.innerWidth >= 1400 && this.sidenavMode !== 'side') {
          this._ngZone.run(() => {
            this.sidenavMode = 'side';
            this.showSidenav();
            this.sidenavHidden = false;
          });
        }
      });
    }); 
  }

  //#endregion

  //#region Public properties

  /**
   * Sidenav
   */
  @ViewChild('sidenav', { static: true }) 
  public sidenav!: MdbSidenavComponent;

  /**
   * Native video player element (for Partner Center intro video).
   */
  @ViewChild('videoPlayer', { static: false })
  public videoPlayer?: ElementRef<HTMLVideoElement>;

  /**
   * Sidenav mode
   */
  public sidenavMode;

  /**
   * Determines if sidebar is hidden.
   */
  public sidenavHidden;

  /**
   * Determines if sidenav is collapsed.
   */
  public sidenavCollapsed;

  /**
   * Sidenav items
   */
  public sidenavItems: SidenavItemInfo[];

  /**
   * Current year, used for the copyright in sidenav.
   */
  public currentYear: number;

  /**
   * @description Determines if busy indicator is shown (Loader Service).
   */
  public isBusy: boolean;

  /**
   * @description Busy indicator message (Loader Service).
   */
  public busyMessage: string;

  /**
   * Partner center version.
   */
  public partnerCenterVersion?: string;

  /**
   * Partner Center intro video GUID.
   */
  public partnerCenterIntroVideoGuid: string;

  /**
   * Video file stream URL.
   */
  public videoStreamUrl?: SafeUrl;

  /**
   * Flag that indicates if Partner Center intro video dialog is shown.
   */
  public isIntroVideoDialogShown: boolean;

  /**
   * Part of application title that holds name of the selected page.
   */
  public toolbarTitlePageNamePart?: string;

  /**
   * Part of application title that holds product name (visible only when HTML documentation is opened in help center page).
   */
  public toolbarTitleProductPart?: string;

  /**
   * Flag that indicates if sharing document link and choosing document product version are enabled.
   */
  public showDocumentOptions: boolean;

  /**
   * Name of the product for which documentation is accessed through Help Center page.
   */
  public productName?: string;

  /**
   * Versions of the product for opened document.
   */
  public documentProductVersions: Array<ProductVersionOption>;

  /**
   * Selected version of the product for which documentation needs to be shown.
   */
  public selectedDocumentProductVersion: ProductVersionOption;

  /**
   * Flag that indicates if search bar is shown.
   */
  public showSearchBar?: boolean;

  /**
   * Documentation search bar text.
   */
  public searchTerm?: string;

  /**
   * Determines if share page dialog is shown.
   */
  public isSharePageDialogShown: boolean;

  /**
   * Shared page link
   */
  public sharedPageLink: string;

  /**
   * Shared page title.
   */
  public sharedPageTitle: string;

  /**
   * Shared page email subject.
   */
  public emailSubject: string;

  /**
   * Indicates if "Add User Favorite" dialog is shown.
   */
  public isAddUserFavoriteDialogShown: boolean;

  /**
   * User favorite category.
   */
  public userFavoriteCategory: string = CONSTANTS.USER_FAVORITE_CATEGORIES.PRODUCT_DOCUMENTATION;

  /** 
   * User favorite URL link.
   */
  public userFavoriteUrlLink: string;

  /**
   * User info dialog left position.
   */
  public userInfoDialogLeftPosition: number;

  /**
   * User info details.
   */
  public userDetails?: UserDetails;

  /**
   * Stores the names of favorites for a given user.
   */
  public get userFavoriteNames(): Array<string> {

    var result = new Array<string>;

    if (this._userInfoService.userFavorites) {
      this._userInfoService.userFavorites.forEach(x => {
          if (x.name) {
              result.push(x.name);
          }
      });
    }
    return result;
  }

  /**
   * Indicates if currently opened product document is tagged as favorite.
   */
  public productDocumentSelectedAsFavorite: boolean;

  /**
   * Determines if search filters dialog is shown.
   */
  public isSearchFiltersDialogShown: boolean;

  /**
   * List of filtered product versions used for product documentation search filter.
   */
  public filteredProductVersionOptions: Array<ProductVersionOption>;

  /**
   * Selected version (of document related product) for search results filtering.
   */
  public productVersionFilter?: ProductVersionOption;

  /**
   * List of filtered search result content types used for product documentation search filter.
   */
  public filteredContentTypeOptions: Array<SearchResultContentTypeOption>;

  /**
   * Selected search result content type for search results filtering.
   */
  public contentTypeFilter?: SearchResultContentTypeOption;

  /**
   * Category of the document accessed through Help Center page.
   */
  public documentCategoryFilter?: DocumentationCategory;

  /**
   * List of filtered documentation languages used for product documentation search filter.
   */
  public filteredDocumentationLanguageOptions: Array<Language>;

  /**
   * Selected file content language for search results filtering.
   */
  public documentationLanguageFilter?: Language;

  /**
   * Indicates if user info is shown.
   */
  public userInfoShown: boolean;

  /**
   * Determines if sidenav button is.
   */
  public sidenavButtonVisible: boolean;

  /**
   * Rating summary of opened product document.
   */
  public documentRatingSummary: UserRatingSummary | undefined;

  //#endregion

  //#region UI Event Handlers

  /**
   * Recalculates position of user info dialog on window resize.
   * @param event Event.
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.userInfoDialogLeftPosition = window.innerWidth - 400 - 10;

    if(window.innerWidth < 1400){
      this.sidenavButtonVisible = true;
    } else {
      this.sidenavButtonVisible = false;
    }
  }

  /**
   * Shows/hides user info dialog
   */
  public showHideUserInfo():void {
    this.userInfoShown = !this.userInfoShown;
  }

  /**
   * Navigates to user 
   */
  public onViewAccountProfile(): void{
    this._router.navigate([LAYOUT_ROUTES.ACCOUNT_PROFILE]);
    this.userInfoShown = false;
  }

  /**
   * Handles sidenav mouse enter event.
   */
  public onSidenavEnter():void{
    this.sidenav?.setSlim(false);
  }

  /**
   * Handles sidenav mouse leave event.
   */
  public onSidenavLeave():void{

    if(!this._keepSidenavShown){
      this.sidenav?.setSlim(true);
    }
  }

  /**
   * Handles event of user pressing "Enter" key after setting of the search term.
   */
  public onStartSearch() {
    
    this.searchTerm = this.searchTerm?.trim();

    if (this.searchTerm!.length < CONSTANTS.SEARCH.SEARCH_TERM_MINIMUM_LENGTH) {
        this._notificationService.notifyWarning(LOCALIZE_CONSTANTS.MESSAGES.MINIMUM_SEARCH_TERM_LENGTH);
        return;
    }

    var searchMessage = new SearchMessage(this.searchTerm!);
    this.setSearchFilterOptions(searchMessage);
    this.showDocumentOptions = false;

    this._pubSubService.publish(CONSTANTS.TOPICS.SEND_SEARCH_TERM_WITH_FILTERS, searchMessage);
  }

  /**
   * Handles opening of search filters dialog.
   */
  public onSearchFilterButtonClick() {

    this.isSearchFiltersDialogShown = true;
  }

  /**
   * Handles closing of search filters dialog.
   */
  public closeSearchFiltersDialog() {

    this.isSearchFiltersDialogShown = false;

    this.productVersionFilter = this._appliedProductVersionFilter;
    this.contentTypeFilter = this._appliedContentTypeFilter;
    this.documentationLanguageFilter = this._appliedDocumentationLanguageFilter;
  }

  /**
   * Handles click on "Clear" button in search filter dialog.
   */
  public onClearSearchFilters() {

    this.productVersionFilter = this.documentProductVersions[0];
    this.contentTypeFilter = this._contentTypeOptions[0];
    this.documentationLanguageFilter = this._documentationLanguageOptions[0];
  }

  /**
   * Publishes search term together with selected options in search filter dialog.
   */
  public onPublishSearchFilters() {

    this.isSearchFiltersDialogShown = false;
    this._appliedProductVersionFilter = this.productVersionFilter;
    this._appliedContentTypeFilter = this.contentTypeFilter;
    this._appliedDocumentationLanguageFilter = this.documentationLanguageFilter;

    this.searchTerm = this.searchTerm?.trim();

    if (this.searchTerm == undefined || this.searchTerm.length < CONSTANTS.SEARCH.SEARCH_TERM_MINIMUM_LENGTH) {
        this._notificationService.notifyWarning(LOCALIZE_CONSTANTS.MESSAGES.MINIMUM_SEARCH_TERM_LENGTH);
        return;
    }

    var searchMessage = new SearchMessage(this.searchTerm);
    this.setSearchFilterOptions(searchMessage);
    this.showDocumentOptions = false;

    this._pubSubService.publish(CONSTANTS.TOPICS.SEND_SEARCH_TERM_WITH_FILTERS, searchMessage);
  }

  /**
   * Closes intro video dialog.
   */
  public onCloseIntroVideoDialog() {
    this.isIntroVideoDialogShown = false;
  }

  /**
   * Publishes toolbar title text after it is clicked.
   */
  public onToolbarTitleClicked(titleText: string) {

    this._pubSubService.publish(CONSTANTS.TOPICS.TOOLBAR_TITLE_CLICKED, new PageTitleClickedMessage(titleText));
    this.showDocumentOptions = false;
    this.showSearchBar = false;
    this.toolbarTitleProductPart = undefined;
  }

  /**
   * Opens "share page dialog".
   */
  public onSharePage() {

      this.sharedPageTitle = "";
      this.sharedPageLink = "";
      this._pubSubService.publish(CONSTANTS.TOPICS.SHARING_PAGE, undefined);
      this.isSharePageDialogShown = true;
  }

  /**
   * Handles click on favorites icon.
   */
  public onFavoritesIconClicked() {

    if (this.productDocumentSelectedAsFavorite) {
      this._pubSubService.publish(CONSTANTS.TOPICS.DELETE_FAVORITE, undefined);
      this.productDocumentSelectedAsFavorite = false;

    } else {
      this.isAddUserFavoriteDialogShown = true;
      this._pubSubService.publish(CONSTANTS.TOPICS.ADDING_FAVORITE, undefined);

      // Releted component will publish message to set userfavoriteUrlLink property
      this.userFavoriteUrlLink = "";
    }
  }

  /**
   * Updates property that indicates if "share page dialog" is shown.
   */
  public onSharePageDialogClosedEvent() {
    this.isSharePageDialogShown = false;
  }

  /**
   * Updates the property that controls visibility of the "Add User Favorite" dialog.
   * @param addingFavoriteConfirmed Value "true" indicates the case when Add Favorite dialog was closed after "Add" button was clicked.
   * Value "false" indicates the case when Add Favorite dialog is closed after "x" icon was clicked.
   */
  public onAddFavoriteDialogClosedEvent(addingFavoriteConfirmed: boolean) {

    this.isAddUserFavoriteDialogShown = false;
    this.productDocumentSelectedAsFavorite = addingFavoriteConfirmed;
  }

  /**
   * Updates selected product version.
   * @param selectedProductVersion Selected product version.
   */
  public onProductVersionChanged(selectedProductVersion: ProductVersionOption) {
    
    var message = new ProductVersionChangeMessage();
    message.productShortName = this._productShortName;
    message.documentCategory = this._documentCategory;
    message.productVersionForAvailableDocumentation = this.selectedDocumentProductVersion.value;
    message.selectedProductVersion = selectedProductVersion.value;

    this._pubSubService.publish(CONSTANTS.TOPICS.DOCUMENT_PRODUCT_VERSION_CHANGED, message);
  }
  
  /**
   * Handle sidenav colapse event.
   */
  public onSidenavColapse(){
    this.sidenavCollapsed = true;
  }

  /**
   * Handle sidenav expand event.
   */
  public onSidenavExpand(){
    this.sidenavCollapsed = false;
  }

  /**
   * Handles sidenav button click
   * - If the inner width is less than 1400px, toggle sidenav.
   * - If the inner width is greater or equal than 1400px, toggle sidenav slim
   */
  public onSidenavButtonClick(){

    if (window.innerWidth < 1400){
      this.toogleSidenav();
    } else {
      this.toggleSidenavSlim();
    }
  }

  /**
   * Global On-Click handler for sidenav content:
   * If the inner content is less than 1400px, and the sidenav is shown, it will hide the sidenav.
   */
  public onSidenavContentClick(){
    if (window.innerWidth < 1400){

      if(this.sidenav?.isVisible){
        this.hideSidenav();
      }
    }
  }

  /**
   * TODO
   */
  public onSidenavItemClick(){
    if(window.innerWidth < 1400){
      this.hideSidenav();
    }
  }

  /**
   * Event handler for product version filter option change.
   * @param filterText Filter text.
   */
  public onProductVersionFilterOptionChange(filterText: string) {
    this.filteredProductVersionOptions = this._productVersionOptions?.filter(x => x.title?.toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
  }

  /**
   * Event handler for content type filter option change.
   * @param filterText Filter text.
   */
  public onContentTypeFilterOptionChange(filterText: string) {
    this.filteredContentTypeOptions = this._contentTypeOptions?.filter(x => x.name?.toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
  }

  /**
   * Event handler for documentation language filter option change.
   * @param filterText Filter text.
   */
  public onDocumentationLanguageFilterOptionChange(filterText: string) {
    this.filteredDocumentationLanguageOptions = this._documentationLanguageOptions?.filter(x => x.languageName?.toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
  }

  /**
   * Handles video streaming error:
   * - If authorization error occured (if Azure Blob SAS token has expired), video is reloaded.
   * - If other error occured, error message is shown.
   * @param event Event.
   */
  public onVideoStreamingError(event: Event) {

    if (environment.deployment == CONSTANTS.DEPLOYMENT_TYPE.CLOUD) {

      var videoStreamUrlString = this._domSanitizer.sanitize(SecurityContext.URL, this.videoStreamUrl!);

      if (videoStreamUrlString) {
        this._httpClient.get(videoStreamUrlString, {responseType: 'blob'}).subscribe({
          next: async blobResponse => {},
          error: errorResponse => {
            if (errorResponse.status == 403 || errorResponse.status == 401) {
              this._pcIntroVideoTimeBeforeError = this.videoPlayer?.nativeElement.currentTime!;
              this.refreshVideoLink();
            
            } else {
              this._notificationService.notifyError(LOCALIZE_CONSTANTS.ERROR_MESSAGE.VIDEO_STREAMING_ERROR);
            }
          }
        });
      }
    
    } else {
      this._notificationService.notifyError(LOCALIZE_CONSTANTS.ERROR_MESSAGE.VIDEO_STREAMING_ERROR);
    }
  }

  /**
   * Updates product document rating summary after user adds new or updates existing product document rating.
   */
  public onUpdateDocumentUserRatingSummary() {

    var request = {} as RatingsService.GetApiRatingSummariesParams;
    request.CategoryName = CONSTANTS.USER_RATINGS.CATEGORIES.HTML_PRODUCT_DOCUMENTATION;
    request.RatedItemKey = this.documentRatingSummary?.ratedItemKey;

    this._ratingsService.getApiRatingSummaries(request).subscribe({
      next: response => {
        this.documentRatingSummary = new UserRatingSummary(response.ratingSummaryInfo);
      },
      error: errorResponse => {
        this._errorResponseHandlerService.handleHttpErrorResponse(errorResponse);
      }
    });
  }

  //#endregion

  //#region Private Methods - Sidenav

  /**
   * Toggles (show/hide) sidenav.
   */
  private toogleSidenav(){

    setTimeout(() => {
      this.sidenav?.toggle();
    }, 0);
  }

  /**
   * Toggles sidenav slim apperiance (slim/normal)
   */
  private toggleSidenavSlim(){
    
    setTimeout(() => {

      if(this.sidenavCollapsed){
        this._keepSidenavShown = true;
      } else {
        this._keepSidenavShown = false;
      }
      
      this.sidenav?.toggleSlim();
    }, 0);
  }

  /**
   * Hides sidenav.
   */
  private hideSidenav() {
    setTimeout(() => {
      
      this.sidenav?.hide();
    }, 0);
  }

  /**
   * Shows sidenav
   */
  private showSidenav() {
    setTimeout(() => {
      this.sidenav?.show();
    });
  }
  //#endregion

  //#region Private methods

  /**
   * Sets filter options for a given search message based on selected values in search filters dialog.
   * @param searchMessage Search message.
   */
  private setSearchFilterOptions(searchMessage: SearchMessage) {
    
    searchMessage.searchFilterOptions.set(CONSTANTS.SEARCH.FILTERS.PRODUCT_NAME, this._productShortName!);

    if (this.productVersionFilter && this.productVersionFilter.value != CONSTANTS.SEARCH.ALL) {
      searchMessage.searchFilterOptions.set(CONSTANTS.SEARCH.FILTERS.PRODUCT_VERSION, this.productVersionFilter.value);
    }

    if (this.contentTypeFilter && this.contentTypeFilter.name != CONSTANTS.SEARCH.ALL) {
      searchMessage.searchFilterOptions.set(CONSTANTS.SEARCH.FILTERS.CONTENT_TYPE, this.contentTypeFilter.name);
    }

    if (this.documentationLanguageFilter && this.documentationLanguageFilter.languageCode != CONSTANTS.SEARCH.ALL) {
      searchMessage.searchFilterOptions.set(CONSTANTS.SEARCH.FILTERS.LANGUAGE, this.documentationLanguageFilter.languageCode);
    }

    if(this.documentCategoryFilter && this.documentCategoryFilter.abbreviation != CONSTANTS.SEARCH.ALL){
      searchMessage.searchFilterOptions.set(CONSTANTS.SEARCH.FILTERS.DOCUMENT_CATEGORY, this.documentCategoryFilter.abbreviation);
    }
  }

  /**
   * Removes currently opened product document from the list of favorites.
   */
  private deleteUserFavorite() {

    var userFavoriteName = "";
    var currentFavorites = this._userInfoService.userFavorites?.filter(x => x.category?.name == this.userFavoriteCategory);

    if (currentFavorites) {

      for (var i = 0; i < currentFavorites.length; i++) {

        var urlParts = currentFavorites[i].urlLink?.split(/[\?\&]+/);

        if (urlParts) {

          var productShortNameUrlParam = urlParts[1].split("=")[1];
          var productVersionUrlParam = urlParts[2].split("=")[1];
          var docCategoryUrlParam = urlParts[3].split("=")[1];

          if (this._productShortName == productShortNameUrlParam &&
              this._productVersion == productVersionUrlParam && 
              this._documentCategory == docCategoryUrlParam) {

              userFavoriteName = currentFavorites[i].name as string;
              break;
          }
        }
      }
      this._userInfoService.deleteUserFavorite(userFavoriteName);
    }
  }

  /**
   * Checks if currently opened product document is tagged as favorite and sets
   * the value of boolean flag that controls color of favorites icon accordingly.
   */
  private checkFavoriteStatus() {

    var currentFavorites = this._userInfoService.userFavorites?.filter(x => x.category?.name == this.userFavoriteCategory);

    if (currentFavorites) {
      for (var i = 0; i < currentFavorites.length; i++) {

        var urlParts = currentFavorites[i].urlLink?.split(/[\?\&]+/);

        if (urlParts) {

          var productShortNameUrlParam = urlParts[1].split("=")[1];
          var productVersionUrlParam = urlParts[2].split("=")[1];
          var documentCategoryUrlParam = urlParts[3].split("=")[1];

          if (this._productShortName == productShortNameUrlParam && 
              this._productVersion == productVersionUrlParam && 
              this._documentCategory == documentCategoryUrlParam) {

            this.productDocumentSelectedAsFavorite = true;
            return;
          }
        }
      }
      this.productDocumentSelectedAsFavorite = false;
    }
  }

  /**
   * Applies user permission and initializes sidenav items that are available to user.
   */
  private applyUserPermissions(){
    
    this.sidenavItems = new Array<SidenavItemInfo>();

    for(let i = 0; i < this._allSidenavItems.length; i++){

      switch(this._allSidenavItems[i].name){

        case CONSTANTS.SIDENAV.MARKETING_RESOURCES:

          if(this._userInfoService.hasPermission(CONSTANTS.PERMISSIONS.MARKETING_MATERIAL)){
            this.sidenavItems.push(this._allSidenavItems[i]);
          }

          break;

        case CONSTANTS.SIDENAV.GENERAL_SEARCH:
          if(this._userInfoService.hasAnyOfPermissions(CONSTANTS.PERMISSIONS.PRODUCT_DOCS, CONSTANTS.PERMISSIONS.MARKETING_MATERIAL, CONSTANTS.PERMISSIONS.PRODUCT_RELEASE_INFO)){
            this.sidenavItems.push(this._allSidenavItems[i]);
          }          

          break;
          
        case CONSTANTS.SIDENAV.ACCOUNT:

          var accountSideNavItem = this._allSidenavItems[i];

          if(this._userInfoService.hasAnyOfPermissions(CONSTANTS.PERMISSIONS.SUPPORT_INFO, CONSTANTS.PERMISSIONS.SUPPORT_INFO_CUST)){

            var supportInfoSidenavItem = this._allSidenavItems.find(x => x.name == CONSTANTS.SIDENAV.ACCOUNT_SUPPORT_INFORMATION) as SidenavItemInfo;
            accountSideNavItem.childItems.push(supportInfoSidenavItem);
          }


          this.sidenavItems.push(accountSideNavItem);

          break;

        case CONSTANTS.SIDENAV.DASHBOARD:

          this.sidenavItems.push(this._allSidenavItems[i]);

          break;

        case CONSTANTS.SIDENAV.ADMNISTRATION:
          
          var canAccessAdministration: boolean = false;

          var administrationSidenavItem = this._allSidenavItems[i];

          if(this._userInfoService.hasPermission(CONSTANTS.PERMISSIONS.SYSTEM_SETTINGS)){
            canAccessAdministration = true;
            var configurationSidenavItem = this._allSidenavItems.find(x => x.name == CONSTANTS.SIDENAV.ADMNISTRATION_CONFIGURATION) as SidenavItemInfo;
            administrationSidenavItem.childItems.push(configurationSidenavItem);
          }

          if(this._userInfoService.hasPermission(CONSTANTS.PERMISSIONS.FILE_STORAGE_MANAGEMENT)){
            canAccessAdministration = true;
            var fileStorageSidenavItem = this._allSidenavItems.find(x => x.name == CONSTANTS.SIDENAV.ADMNISTRATION_FILESTORAGE) as SidenavItemInfo;
            administrationSidenavItem.childItems.push(fileStorageSidenavItem);
          }

          if(this._userInfoService.hasAnyOfPermissions(CONSTANTS.PERMISSIONS.USER_MANAGEMENT, CONSTANTS.PERMISSIONS.USER_GROUP_MANAGEMENT)){
            canAccessAdministration = true;
            var usersManagementSidenavItem = this._allSidenavItems.find(x => x.name == CONSTANTS.SIDENAV.ADMNISTRATION_USER_MANAGEMENT) as SidenavItemInfo;
            administrationSidenavItem.childItems.push(usersManagementSidenavItem);
          }

          if(this._userInfoService.hasPermission(CONSTANTS.PERMISSIONS.PRODUCT_DOCS_MANAGEMENT)){
            canAccessAdministration = true;
            var publicationCategoriesSidenavItem = this._allSidenavItems.find(x => x.name == CONSTANTS.SIDENAV.ADMINISTRATION_PUBLICATION_CATEGORIES) as SidenavItemInfo;
            administrationSidenavItem.childItems.push(publicationCategoriesSidenavItem);
          }

          if(this._userInfoService.hasAnyOfPermissions(CONSTANTS.PERMISSIONS.SYNC_PRODUCT_DOCS, CONSTANTS.PERMISSIONS.SYNC_COMPANIES, CONSTANTS.PERMISSIONS.SYNC_USER_GROUPS)){
            var dataSyncSidenavItem = this._allSidenavItems.find(x => x.name == CONSTANTS.SIDENAV.DATA_SYNCHRONIZATION) as SidenavItemInfo;
            administrationSidenavItem.childItems.push(dataSyncSidenavItem);
            canAccessAdministration = true;

          }

          if(canAccessAdministration){
            this.sidenavItems.push(administrationSidenavItem);
          }

          break;

        case CONSTANTS.SIDENAV.HELP_CENTER:
          if(this._userInfoService.hasPermission(CONSTANTS.PERMISSIONS.PRODUCT_DOCS)){
            this.sidenavItems.push(this._allSidenavItems[i]);
          }

          break;

        case CONSTANTS.SIDENAV.SOFTWARE:
          if(this._userInfoService.hasAnyOfPermissions(CONSTANTS.PERMISSIONS.PRODUCT_RELEASE_INFO, CONSTANTS.PERMISSIONS.PRODUCT_RELEASE_MANAGEMENT)){
            this.sidenavItems.push(this._allSidenavItems[i]);
          }
          break;

        case CONSTANTS.SIDENAV.AUDIT_TRAIL:
          if (this._userInfoService.hasPermission(CONSTANTS.PERMISSIONS.AUDIT_TRAIL)) {
            this.sidenavItems.push(this._allSidenavItems[i]);
          }
          break;
        
        case CONSTANTS.SIDENAV.ADMINISTRATIVE_NOTIFICATIONS:
          if (this._userInfoService.hasPermission(CONSTANTS.PERMISSIONS.ADMIN_NOTIFICATIONS)) {
            this.sidenavItems.push(this._allSidenavItems[i]);
          }
          break;

        case CONSTANTS.SIDENAV.CUSTOMERS_OVERVIEW:
          if (this._userInfoService.hasPermission(CONSTANTS.PERMISSIONS.CUSTOMERS_OVERVIEW)) {
            this.sidenavItems.push(this._allSidenavItems[i]);
          }
          break;

        case CONSTANTS.SIDENAV.USER_GUIDE:
            this.sidenavItems.push(this._allSidenavItems[i]);
          break;

      }
    }
  }

  /**
   * Gets search record content types for product documentation search.
   */
  private getContentTypeOptions() {

    this._translateService.get([LOCALIZE_CONSTANTS.SEARCH_RESULT_CONTENT_TYPE_OPTIONS.ALL, 
                                LOCALIZE_CONSTANTS.SEARCH_RESULT_CONTENT_TYPE_OPTIONS.TEXT, 
                                LOCALIZE_CONSTANTS.SEARCH_RESULT_CONTENT_TYPE_OPTIONS.CODE_SNIPPET]).subscribe(translations => {
      
      this._contentTypeOptions.push(new SearchResultContentTypeOption(translations[LOCALIZE_CONSTANTS.SEARCH_RESULT_CONTENT_TYPE_OPTIONS.ALL], -1));
      this._contentTypeOptions.push(new SearchResultContentTypeOption(translations[LOCALIZE_CONSTANTS.SEARCH_RESULT_CONTENT_TYPE_OPTIONS.TEXT], CONSTANTS.SEARCH.SEARCH_RECORD_CONTENT_TYPES.TEXT));
      this._contentTypeOptions.push(new SearchResultContentTypeOption(translations[LOCALIZE_CONSTANTS.SEARCH_RESULT_CONTENT_TYPE_OPTIONS.CODE_SNIPPET], CONSTANTS.SEARCH.SEARCH_RECORD_CONTENT_TYPES.CODE_SNIPPET));

      this.contentTypeFilter = this._contentTypeOptions[0];
      this._appliedContentTypeFilter = this._contentTypeOptions[0];
      this.filteredContentTypeOptions = this._contentTypeOptions.slice();
    });
  }

  /**
   * Gets available documentation languages for product documentation search.
   */
  private getDocumentationLanguageOptions() {

    this._translateService.get([LOCALIZE_CONSTANTS.LANGUAGES.ALL, 
                                LOCALIZE_CONSTANTS.LANGUAGES.ENGLISH, 
                                LOCALIZE_CONSTANTS.LANGUAGES.GERMAN, 
                                LOCALIZE_CONSTANTS.LANGUAGES.FRENCH, 
                                LOCALIZE_CONSTANTS.LANGUAGES.ITALIAN]).subscribe(translations => {

      this._documentationLanguageOptions.push(new Language(CONSTANTS.SEARCH.ALL, translations[LOCALIZE_CONSTANTS.LANGUAGES.ALL]));
      this._documentationLanguageOptions.push(new Language(CONSTANTS.FILE_LANGUAGE_CODES.ENGLISH, translations[LOCALIZE_CONSTANTS.LANGUAGES.ENGLISH]));
      this._documentationLanguageOptions.push(new Language(CONSTANTS.FILE_LANGUAGE_CODES.GERMAN, translations[LOCALIZE_CONSTANTS.LANGUAGES.GERMAN]));
      this._documentationLanguageOptions.push(new Language(CONSTANTS.FILE_LANGUAGE_CODES.FRENCH, translations[LOCALIZE_CONSTANTS.LANGUAGES.FRENCH]));
      this._documentationLanguageOptions.push(new Language(CONSTANTS.FILE_LANGUAGE_CODES.ITALIAN, translations[LOCALIZE_CONSTANTS.LANGUAGES.ITALIAN]));

      this.documentationLanguageFilter = this._documentationLanguageOptions[0];
      this._appliedDocumentationLanguageFilter = this._documentationLanguageOptions[0];
      this.filteredDocumentationLanguageOptions = this._documentationLanguageOptions.slice();
    });
  }

  /**
   * Sets product version options for product documentation search filter.
   */
  private setProductVersionOptions() {

    this._productVersionOptions = new Array<ProductVersionOption>();
    this._productVersionOptions.push(new ProductVersionOption(this._translateService, CONSTANTS.SEARCH.ALL, false));

    for (let i = 0; i < this.documentProductVersions.length; i++) {
      this._productVersionOptions.push(this.documentProductVersions[i]);
    }
    this.filteredProductVersionOptions = this._productVersionOptions.slice();
  }

  //#endregion

  //#region Private Methods - API

  /**
   * Gets user info.
   * @returns User info observable.
   */
  private getUserInfo(): Observable<GetUserInfoResponse> {
    this._loggerService.info("Getting user info ...");
    return this._usersService.getApiUserInfo();
  }

  /**
   * Handles get user info response.
   * @param response Get user info response.
   */
  private handleGetUserInfo(response: GetUserInfoResponse) {
    
    this._loggerService.info("Getting user info done.");
    this._userInfoService.userInfo = response.userInfo;
    this.userDetails = new UserDetails(this._userInfoService.userInfo!, new Array<UserPermission>, new Array<UserRole>);
    this.applyUserPermissions();
  }

  /**
   * Gets user favorites.
   * @returns User favorites observable.
   */
  private getUserFavorites(): Observable<GetUserFavoritesResponse> {
    this._loggerService.info("Getting user favorites ...");
    return this._usersService.getApiUserFavorites();
  }

  /**
   * Handles get user favorites response.
   * @param response Get user favorites response.
   */
  private handleGetUserFavorites(response: GetUserFavoritesResponse) {

    this._loggerService.info("Getting user favorites done.");

    if (response.userFavorites) {

      this._userInfoService.userFavorites = new Array<UserFavorite>();
      
      for (let i = 0; i < response.userFavorites.length; i++) {
          this._userInfoService.userFavorites.push(new UserFavorite(this._translateService, response.userFavorites[i]));
      }
    }
  }

  /**
   * Gets Partner Center info.
   * @returns Partner Center info observable.
   */
  private getPartnerCenterInfo(): Observable<GetPartnerCenterInfoResponse> {
    return this._infoService.getApiAppInfo();
  }

  /**
   * Handles get Partner Center info response.
   * @param response Partner Center info response.
   */
  private handleGetPartnerCenterInfo(response: GetPartnerCenterInfoResponse) {

    this.partnerCenterVersion = response.version;

    if (response.introVideoGuid && response.introVideoGuid != "") {
      this.partnerCenterIntroVideoGuid = response.introVideoGuid;
    }
  }

  /**
   * Opens intro video dialog in case that user wasn't notified about intro video before.
   */
  private tryShowIntroVideo() {

    if (!this.userDetails?.isIntroVideoShown && this.partnerCenterIntroVideoGuid != "") {

      if (environment.deployment == CONSTANTS.DEPLOYMENT_TYPE.CLOUD) {

        let videoLinkSubscription = this._videoStreamingService.getApiVideoStreamLink(this.partnerCenterIntroVideoGuid).subscribe({
          next: response => {
              this.videoStreamUrl = response.fileDownloadLink?.downloadLink!;
              videoLinkSubscription.unsubscribe();
          },
          error: errorResponse => {
              videoLinkSubscription.unsubscribe();
          }
        });

      } else {
          this.videoStreamUrl = this._videoStreamingService.rootUrl + VideoStreamingService.getApiVideoStreamPath + CONSTANTS.DOCUMENT.GUID_QUERY_PARAMETER + this.partnerCenterIntroVideoGuid;
      }

      this.isIntroVideoDialogShown = true;

      this._loggerService.info(`Marking intro video as shown to user ${this.userDetails?.userName} ...`);

      this._usersService.putApiMarkIntroVideoShown().subscribe({
        next: response => {
          this._loggerService.info(`Marking intro video as shown to user ${this.userDetails?.userName} done.`);
        },
        error: errorResponse => {
          this._loggerService.error(`Error occurred while marking intro video as shown to user ${this.userDetails?.userName}!`);
        }
      })
    }
  }

  /**
   * Refreshes video link.
   * This is needed when the video link (Azure blob SAS token) has expired and the video player needs to be reloaded with the new link.
   */
  private refreshVideoLink() {

    let videoLinkSubscription = this._videoStreamingService.getApiVideoStreamLink(this.partnerCenterIntroVideoGuid).subscribe({
      next: response => {
        this.videoStreamUrl = response.fileDownloadLink?.downloadLink!;

        this.videoPlayer!.nativeElement.onloadeddata = () => {
          this.videoPlayer!.nativeElement.currentTime = this._pcIntroVideoTimeBeforeError;
          this.videoPlayer!.nativeElement.play();
          this.videoPlayer!.nativeElement.onloadeddata = null;
        }
        
        this.videoPlayer?.nativeElement.load();
        videoLinkSubscription.unsubscribe();
      },
      error: errorResponse => {
        this._errorResponseHandlerService.handleHttpErrorResponse(errorResponse);
        videoLinkSubscription.unsubscribe();
      }
    });
  }

  //#endregion

  //#region SignOut Implementation

  public signOut(){
    this._userInavtivityService.stop();
    this._authService.getApiLogout().subscribe({
        next: response=>{
            this.sidenavItems = new Array<SidenavItemInfo>();
            this._router.navigate([SECURITY_ROUTES.SECURITY_LOGIN]);
            
        },
        error: errorResponse=>{
          this.sidenavItems = new Array<SidenavItemInfo>();
          this._router.navigate([SECURITY_ROUTES.SECURITY_LOGIN]);
        }
    });
  }

  //#endregion

}
