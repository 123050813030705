import {  UserAuthenticationSettings, UserPermissionInfo, UserSecuritySettings } from "src/app/api/opal-partner-center/models";
import { IChildChangedReceiver } from "../interfaces/i-child-changed-receiver";
import { UserAuthenticationSettingsInfo } from "./user-authentication-settings-info";
import { UserPermission } from "./user-permission";
import { UserRole } from "./user-role";

export class UserSecuritySettingsInfo implements IChildChangedReceiver{
 
  //#region Private Fields

  /**
   * Child changed receiver
   */
  private _childChangedReceiver?: IChildChangedReceiver;

  //#endregion

  //#region Constructor

  constructor(securitySettings: UserSecuritySettings, allCustomerRelatedPermissions?: Array<UserPermission>, allCustomerRelatedRoles?: Array<UserRole>, childChangedReceiver?: IChildChangedReceiver){

    this.isChanged = false;
    this.authenticationSettingsChanged = false;
    this.permissionsChanged = false;
    this.rolesChanged = false;
    this._childChangedReceiver = childChangedReceiver;
    this.userAuthenticationSettings = new UserAuthenticationSettingsInfo(securitySettings.userAuthenticationSettings, this);
    this.userPermissions = new Array<UserPermission>();
    this.userRoles = new Array<UserRole>();

    if(allCustomerRelatedPermissions && allCustomerRelatedRoles){
      for(let i = 0; i < allCustomerRelatedPermissions.length; i++){

        let permissionInfo = securitySettings!.permissions!.find(p=>p.name === allCustomerRelatedPermissions[i].name);
        let userPermission: UserPermission;
        
        if(permissionInfo){
          
          userPermission = new UserPermission(allCustomerRelatedPermissions[i].name, allCustomerRelatedPermissions[i].title, allCustomerRelatedPermissions[i].description, true);
          userPermission.isRolePermission = permissionInfo.isRolePermission!;
          userPermission.isGroupPermission = permissionInfo.isGroupPermission!;
  
          if(userPermission.isRolePermission){
            userPermission.relatedRoleName = allCustomerRelatedRoles.find(r=>r.name === permissionInfo!.relatedRoleName)?.title!;
          }

          if(userPermission.isGroupPermission){
            userPermission.relatedGroupName = permissionInfo.relatedGroupName!;
          }
        } else {
          userPermission = new UserPermission(allCustomerRelatedPermissions[i].name, allCustomerRelatedPermissions[i].title, allCustomerRelatedPermissions[i].description, false);
        }
        
        userPermission.childChangedReceiver = this;
        this.userPermissions.push(userPermission);
      }
  
      for(let i = 0; i < allCustomerRelatedRoles.length; i++){
        
        var userRole: UserRole;
        let role = securitySettings!.roles!.find(r=>r.name === allCustomerRelatedRoles[i].name);
  
        if(role){
          userRole = new UserRole(allCustomerRelatedRoles[i].name, allCustomerRelatedRoles[i].title, allCustomerRelatedRoles[i].description, true);
          userRole.isGroupRole = role.isGroupRole!;
          userRole.relatedGroupName = role.relatedGroupName!;
        } else {
          userRole = new UserRole(allCustomerRelatedRoles[i].name, allCustomerRelatedRoles[i].title, allCustomerRelatedRoles[i].description, false);
        }
  
        userRole.childChangedReceiver = this;
        this.userRoles.push(userRole);
      }
    }
  }

  //#endregion

  //#region IChildChangedReceiver

  notifyChildChanged(): void {
    this.checkChanges();
  }

  //#endregion

  //#region Public Properties

  /**
   * Determines if user security settings have changed.
   */
  public isChanged: boolean;

  /**
   * Determines if authentication settings have changed.
   */
  public authenticationSettingsChanged: boolean;

  /**
   * Determines if user permissions have changed.
   */
  public permissionsChanged: boolean;

  /**
   * Determines if user roles have changed.
   */
  public rolesChanged: boolean;

  /**
   * User authentication settings.
   */
  public userAuthenticationSettings: UserAuthenticationSettingsInfo;

  /**
   * User permissions.
   */
  public userPermissions: Array<UserPermission>;

  /**
   * User roles.
   */
  public userRoles: Array<UserRole>;

  //#endregion

  //#region Public Methods

  public undoAuthenticationSettingsChanges(): void {
    this.userAuthenticationSettings.undoChanges();
  }

  public undoPermissionsChanges(): void {
    this.userPermissions.forEach(p=>p.undoChanges());
  }

  public undoRolesChanges(): void {
    this.userRoles.forEach(r=>r.undoChanges());
  }

  /**
   * Undo changes.
   */
  public undoChanges(): void {
    this.undoAuthenticationSettingsChanges();
    this.undoPermissionsChanges();
    this.undoRolesChanges();
  }

  public toDto(): UserSecuritySettings{
    let userSecuritySettings: UserSecuritySettings = {} as UserSecuritySettings;
    userSecuritySettings.userAuthenticationSettings = {} as UserAuthenticationSettings;

    userSecuritySettings.userAuthenticationSettings.twoStepEnabled = this.userAuthenticationSettings.twoStepEnabled;
    userSecuritySettings.userAuthenticationSettings.twoFactorEnabled = this.userAuthenticationSettings.twoFactorEnabled;
    userSecuritySettings.userAuthenticationSettings.fido2Enabled = this.userAuthenticationSettings.fido2Enabled;
    userSecuritySettings.userAuthenticationSettings.defaultAuthMethod = this.userAuthenticationSettings.defaultAuthMethod!;

    userSecuritySettings.permissions = new Array<UserPermission>();

    var permissionsToApply = this.userPermissions.filter(p => !p.isRolePermission && !p.isGroupPermission && p.isAssigned);
    permissionsToApply.forEach(p=>{
      var appliedPermission = {} as UserPermissionInfo;
      appliedPermission.name = p.name;
      userSecuritySettings.permissions?.push(appliedPermission);
    });

    userSecuritySettings.roles = new Array<UserRole>();

    var rolesToApply = this.userRoles.filter(r => r.isAssigned);
    rolesToApply.forEach(r=>{
      var appliedRole = {} as UserRole;
      appliedRole.name = r.name;
      userSecuritySettings.roles?.push(appliedRole);
    });

    return userSecuritySettings;
  }

  //#endregion

  //#region Private Methods

  /**
   * Checks if changes have been made.
   */
  private checkChanges(): void {
      
    this.authenticationSettingsChanged = this.userAuthenticationSettings.isChanged;

    this.permissionsChanged = false;
    this.rolesChanged = false;

    if(this.userPermissions){
      for(let i = 0; i < this.userPermissions.length; i++){
        if(this.userPermissions[i].isChanged){
          this.permissionsChanged = true;
          break;
        }
      }
    }

    if(this.userRoles){
      for(let i = 0; i < this.userRoles.length; i++){
        if(this.userRoles[i].isChanged){
          this.rolesChanged = true;
          break;
        }
      }
    }

    this.isChanged = this.authenticationSettingsChanged ||
                      this.permissionsChanged ||
                      this.rolesChanged;


    if(this._childChangedReceiver){
      this._childChangedReceiver.notifyChildChanged();
    }
  }

  //#endregion
}