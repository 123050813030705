import { inject } from "@angular/core";
import { CanActivateFn, Params, Router } from "@angular/router";
import { catchError, map } from "rxjs";
import { InfoService, UsersService } from "src/app/api/opal-partner-center/services";
import { SECURITY_ROUTES } from "src/app/security/security.routing.constants";
import { firstValueFrom } from 'rxjs';
import { CONSTANTS } from "../../constants";
import { UserInfoService } from "../user-info-service/user-info-service";



export const AuthGuard: CanActivateFn = async (route, state) => {

  const infoService = inject(InfoService);
  const router = inject(Router);
  const userInfoService = inject(UserInfoService);
  const usersService = inject(UsersService);

  var isAuthenticatedObservable = await infoService.getApiIsAuthenticated().pipe(
    map((response) => true),
    catchError(() => {
      return [false];
    })
  );

  var isAuthenticated = await firstValueFrom(isAuthenticatedObservable);

  if(isAuthenticated){

    if(!userInfoService.userInfo){
    
      var userInfoObserbable = await usersService.getApiUserInfo().pipe(
        map((response) => response),
        catchError(() => {
          return [undefined];
        })
      );

      if(userInfoObserbable === undefined){
        if(state.url){

          let queryParameters: Params = {};
          queryParameters[CONSTANTS.QUERY_PARAMS.POST_LOGIN_ROUTE] = state.url;
          return router.createUrlTree([SECURITY_ROUTES.SECURITY_LOGIN], {queryParams: queryParameters});
    
        } else {
          
          return router.createUrlTree([SECURITY_ROUTES.SECURITY_LOGIN]);
        }
      } else {
        var userInfoResponse = await firstValueFrom(userInfoObserbable);  

        if(userInfoResponse === undefined){
          if(state.url){

            let queryParameters: Params = {};
            queryParameters[CONSTANTS.QUERY_PARAMS.POST_LOGIN_ROUTE] = state.url;
            return router.createUrlTree([SECURITY_ROUTES.SECURITY_LOGIN], {queryParams: queryParameters});
      
          } else {
            
            return router.createUrlTree([SECURITY_ROUTES.SECURITY_LOGIN]);
          } 
        } else{
          userInfoService.userInfo = userInfoResponse.userInfo;
        }
      }
    }

    if(userInfoService.userInfo?.isPrivacyPolicyAccepted){
      return true;
    } else {

      if(state.url){

        let queryParameters: Params = {};
        queryParameters[CONSTANTS.QUERY_PARAMS.POST_LOGIN_ROUTE] = state.url;
        return router.createUrlTree([SECURITY_ROUTES.SECURITY_PRIVACY_POLICY], {queryParams: queryParameters});
  
      } else {
  
        return router.createUrlTree([SECURITY_ROUTES.SECURITY_PRIVACY_POLICY]);
      }
    }
  } else {

    if(state.url){

      let queryParameters: Params = {};
      queryParameters[CONSTANTS.QUERY_PARAMS.POST_LOGIN_ROUTE] = state.url;
      return router.createUrlTree([SECURITY_ROUTES.SECURITY_LOGIN], {queryParams: queryParameters});

    } else {

      return router.createUrlTree([SECURITY_ROUTES.SECURITY_LOGIN]);
    }
  }
}

