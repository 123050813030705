import { Injectable } from "@angular/core";
import { formatDate} from "@angular/common";
import { LogLevel } from "./log-level";

@Injectable({
    providedIn: 'root'
})
export class LoggerService{

    //#region Private Fields

    /**
     * Regex for formated message parameter replacement 
     */
    private readonly _replaceRegex: RegExp = /{(\d+)}/g;

    //#endregion

    //#region Constructors

    /**
     * Initializes new instance of LoggerService.
     */
    public constructor(){

        // NOTE: This shall be moved to configuration on the backend
        this.logLevel = LogLevel.INFO;

    }

    //#endregion

    //#region Public Properties

    /**
     * Current logs level
     */
    public logLevel: number;

    //#endregion

    //#region Public Methods

    /**
     * Logs message with Info log level.
     * @param message Message to log.
     */
    public info(message: any){

        if(this.logLevel >= LogLevel.INFO){
            console.info(this.getTimeStamp() + ' ' + message);
        }
    }

    /**
     * Logs formated message with Info log level.
     * @param formatedMessage Formated message to log.
     * @param args Formated message arguments.
     */
    public infoFormat(formatedMessage: any, ...args: any[]){

        for(var i = 0; i < args.length; i++){
            args[i] = JSON.stringify(args[i]);
        }
        
        if(this.logLevel >= LogLevel.INFO){
            console.info(this.getTimeStamp() + ' ' + formatedMessage.replace(this._replaceRegex, (match: any, index: number) => args[index] || ''));
        }
    }

    /**
     * Logs message with Debug log level.
     * @param message Message to log.
     */
    public debug(message: any){
        if(this.logLevel >= LogLevel.DEBUG){
            console.debug(this.getTimeStamp() + ' ' + message);
        }
    }

    /**
     * Logs formated message with Debug log level.
     * @param formatedMessage Formated message to log.
     * @param args Formated message arguments.
     */
    public debugFormat(formatedMessage: any, ...args: any[]){

        for(var i = 0; i < args.length; i++){
            args[i] = JSON.stringify(args[i]);
        }

        if(this.logLevel >= LogLevel.DEBUG){
            console.debug(this.getTimeStamp() + ' ' + formatedMessage.replace(this._replaceRegex, (match: any, index: number) => args[index] || ''));
        }
    }

    /**
     * Logs message with Warn log level.
     * @param message Message to log.
     */
    public warn(message: any){
        if(this.logLevel >= LogLevel.WARNING){
            console.warn(this.getTimeStamp() + ' ' + message);    
        }
    }

    /**
     * Logs formated message with Warn log level.
     * @param formatedMessage Formated message to log.
     * @param args Formated message arguments.
     */
    public warnFormat(formatedMessage: any, ...args: any[]){

        for(var i = 0; i < args.length; i++){
            args[i] = JSON.stringify(args[i]);
        }

        if(this.logLevel >= LogLevel.WARNING){
            console.warn(this.getTimeStamp() + ' ' + formatedMessage.replace(this._replaceRegex, (match: any, index: number) => args[index] || ''));
        }
    }

    /**
     * Logs message with Error log level.
     * @param message Message to log.
     */
    public error(message: any){
        if(this.logLevel >= LogLevel.ERROR){
            console.error(this.getTimeStamp() + ' ' + message);
        }
    }

    /**
     * Logs formated message with Error log level.
     * @param formatedMessage Formated message to log.
     * @param args Formated message arguments.
     */
    public errorFormat(formatedMessage: any, ...args: any[]){

        for(var i = 0; i < args.length; i++){
            args[i] = JSON.stringify(args[i]);
        }

        if(this.logLevel >= LogLevel.ERROR){
            console.error(this.getTimeStamp() + ' ' + formatedMessage.replace(this._replaceRegex, (match: any, index: number) => args[index] || ''));
        }
    }

    //#endregion

    //#region Helpers

    /**
     * Gets current date/time timestamp in dd/MM/yyyy HH:mm:ss.SSS format.
     * @returns Current date/time timestamp in dd/MM/yyyy HH:mm:ss.SSS format.
     */
    private getTimeStamp():string{
        return formatDate(Date.now(), "dd/MM/yyyy HH:mm:ss.SSS", "en-US");
    }

    //#endregion
}