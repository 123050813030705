/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DownloadFileResponse } from '../models/download-file-response';
import { GetFileLinkResponse } from '../models/get-file-link-response';
import { GetProductsInfosResponse } from '../models/get-products-infos-response';
@Injectable({
  providedIn: 'root',
})
class MarketingMaterialService extends __BaseService {
  static readonly getApiMarketingMaterialDownloadPath = '/api/marketingMaterialDownload';
  static readonly getApiMarketingMaterialDownloadLinkPath = '/api/marketingMaterialDownloadLink';
  static readonly getApiMarketingProductsPath = '/api/marketing/products';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiMarketingMaterialDownloadResponse(FileGuid: string): __Observable<__StrictHttpResponse<DownloadFileResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (FileGuid != null) __params = __params.set('FileGuid', FileGuid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/marketingMaterialDownload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DownloadFileResponse>;
      })
    );
  }
  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiMarketingMaterialDownload(FileGuid: string): __Observable<DownloadFileResponse> {
    return this.getApiMarketingMaterialDownloadResponse(FileGuid).pipe(
      __map(_r => _r.body as DownloadFileResponse)
    );
  }

  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiMarketingMaterialDownloadLinkResponse(FileGuid: string): __Observable<__StrictHttpResponse<GetFileLinkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (FileGuid != null) __params = __params.set('FileGuid', FileGuid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/marketingMaterialDownloadLink`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetFileLinkResponse>;
      })
    );
  }
  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiMarketingMaterialDownloadLink(FileGuid: string): __Observable<GetFileLinkResponse> {
    return this.getApiMarketingMaterialDownloadLinkResponse(FileGuid).pipe(
      __map(_r => _r.body as GetFileLinkResponse)
    );
  }

  /**
   * @param searchVideoRelatedProducts undefined
   * @return OK
   */
  getApiMarketingProductsResponse(searchVideoRelatedProducts: boolean): __Observable<__StrictHttpResponse<GetProductsInfosResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (searchVideoRelatedProducts != null) __params = __params.set('searchVideoRelatedProducts', searchVideoRelatedProducts.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/marketing/products`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductsInfosResponse>;
      })
    );
  }
  /**
   * @param searchVideoRelatedProducts undefined
   * @return OK
   */
  getApiMarketingProducts(searchVideoRelatedProducts: boolean): __Observable<GetProductsInfosResponse> {
    return this.getApiMarketingProductsResponse(searchVideoRelatedProducts).pipe(
      __map(_r => _r.body as GetProductsInfosResponse)
    );
  }
}

module MarketingMaterialService {
}

export { MarketingMaterialService }
