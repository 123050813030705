<!-- Partner Center Intro Video Dialog -->
<kendo-dialog [minHeight]="700" [minWidth]="1000" *ngIf="isIntroVideoDialogShown">

  <!-- Dialog Header -->
  <kendo-dialog-titlebar class="k-window-actions-custom d-flex dialog-header justify-content-center align-items-center">

    <!-- Video Icon -->
    <div>
      <i class="fa-solid fa-lg fa-circle-video opal-dark-green-text"></i>
    </div>

    <!-- Title -->
    <div class="display-9 ms-3">{{ "DASHBOARD.WELCOME.INTRO_VIDEO.TITLE" | translate }}</div>

    <!-- Close -->
    <div class="ms-auto">
      <i class="fa-light fa-xmark fa-sm opal-grey-text cursor-pointer" (click)="onCloseIntroVideoDialog()"></i>
    </div>
  </kendo-dialog-titlebar>

  <!-- Dialog Body -->
  <form class="dialog-body flex-fill align-self-stretch">

     <!-- Intro Video -->
    <div class="d-flex flex-column py-2-5 intro-video-container">
      <video
        #videoPlayer
        class="mh-100 h-100 intro-video"
        controls
        controlsList="nodownload"
        [src]="videoStreamUrl"
        (error)="onVideoStreamingError($event)">
      </video>
    </div>
  </form>
  
</kendo-dialog>

<!-- Busy indicator (used by BusyIndicationService) -->
<div class="k-loading-panel" *ngIf="isBusy">
  <div class="k-loading-panel-mask"></div>
  <div class="k-loading-panel-wrapper">
    <!-- Loader -->
      <kendo-loader
          type="infinite-spinner"
          themeColor="primary"
          size="large">
      </kendo-loader>

    <!-- Loader Message -->
    <div class="k-loading-panel-text">{{ busyMessage }}</div>
  </div>
</div>

<share-page
  *ngIf="isSharePageDialogShown"
  [isShown]="isSharePageDialogShown"
  (dialogClosedEvent)="onSharePageDialogClosedEvent()"
  [sharedPageLink]="sharedPageLink"
  [sharedPageTitle]="sharedPageTitle"
  [emailSubject]="emailSubject">
</share-page>

<add-user-favorite
  *ngIf="isAddUserFavoriteDialogShown"
  [isShown]="isAddUserFavoriteDialogShown"
  (dialogClosedEvent)="onAddFavoriteDialogClosedEvent($event)"
  [favoriteCategory]="userFavoriteCategory"
  [favoriteUrlLink]="userFavoriteUrlLink"
  [userFavoriteNames]="userFavoriteNames">
</add-user-favorite>

<!-- Search Filters Dialog -->
<kendo-dialog [minHeight]="500" [minWidth]="430" *ngIf="isSearchFiltersDialogShown">
  <!-- Dialog Header -->
  <kendo-dialog-titlebar class="k-window-actions-custom d-flex dialog-header justify-content-center align-items-center">
    <!-- Search Filter Icon -->
    <div>
      <i class="fa-solid fa-lg fa-filter opal-dark-green-text"></i>
    </div>

    <!-- Title -->
    <div class="display-9 ms-3">{{ "HELP_CENTER.DOCUMENTATION.SEARCH_FILTERS.TITLE" | translate }}</div>

    <!-- Close -->
    <div class="ms-auto">
      <i class="fa-light fa-xmark fa-sm opal-grey-text cursor-pointer" (click)="closeSearchFiltersDialog()"></i>
    </div>
  </kendo-dialog-titlebar>

  <!-- Dialog Body -->
  <form class="dialog-body flex-fill align-self-stretch">

    <!-- Product -->
    <div class="form-field">
      <kendo-label text="{{ 'HELP_CENTER.DOCUMENTATION.SEARCH_FILTERS.PRODUCT' | translate }}" class="text-nowrap"></kendo-label>
      <kendo-textbox [disabled]="true" value="{{ productName }}"> </kendo-textbox>
    </div>

    <!-- Product Version -->
    <div class="form-field">
      <kendo-label text="{{ 'HELP_CENTER.DOCUMENTATION.SEARCH_FILTERS.PRODUCT_VERSION' | translate }}" class="text-nowrap"></kendo-label>
      <kendo-dropdownlist 
        [data]="filteredProductVersionOptions" 
        textField="title" 
        valueField="value" 
        [filterable]="true" 
        (filterChange)="onProductVersionFilterOptionChange($event)" 
        name="productVersionFilter"
        [(ngModel)]="productVersionFilter">
      </kendo-dropdownlist>
    </div>

    <!-- Documentation Category -->
    <div class="form-field">
      <kendo-label text="{{ 'HELP_CENTER.DOCUMENTATION.SEARCH_FILTERS.DOCUMENTATION_CATEGORY' | translate }}" class="text-nowrap"></kendo-label>
      <kendo-textbox [disabled]="true" value="{{ documentCategoryFilter!.name }}"> </kendo-textbox>
    </div>

    <!-- Content Type -->
    <div class="form-field">
      <kendo-label text="{{ 'HELP_CENTER.DOCUMENTATION.SEARCH_FILTERS.CONTENT_TYPE' | translate }}" class="text-nowrap"></kendo-label>
      <kendo-dropdownlist 
        [data]="filteredContentTypeOptions" 
        textField="name" 
        valueField="name" 
        [filterable]="true" 
        (filterChange)="onContentTypeFilterOptionChange($event)" 
        name="contentTypeFilter" 
        [(ngModel)]="contentTypeFilter">
      </kendo-dropdownlist>
    </div>

    <!-- Documentation Language -->
    <div class="form-field">
      <kendo-label text="{{ 'HELP_CENTER.DOCUMENTATION.SEARCH_FILTERS.DOCUMENTATION_LANGUAGE' | translate }}" class="text-nowrap"></kendo-label>
      <kendo-dropdownlist 
        [data]="filteredDocumentationLanguageOptions" 
        textField="languageName" 
        valueField="languageCode" 
        [filterable]="true" 
        (filterChange)="onDocumentationLanguageFilterOptionChange($event)" 
        name="documentationLanguageFilter"
        [(ngModel)]="documentationLanguageFilter">
      </kendo-dropdownlist>
    </div>
  </form>

  <!-- Dialog Footer -->
  <div class="dialog-footer d-flex align-items-center">
    <!-- Clear Filters Button -->
    <button kendoButton (click)="onClearSearchFilters()" [themeColor]="'primary'" rounded="none">
      <i class="fa-solid fa-md fa-rotate-left me-2"></i>
      {{ "COMMON.BUTTONS.RESET" | translate }}
    </button>

    <!-- Apply Filters Button -->
    <button kendoButton (click)="onPublishSearchFilters()" class="ms-auto" [themeColor]="'primary'" rounded="none">
      <i class="fa-solid fa-md fa-check me-2"></i>
      {{ "COMMON.BUTTONS.APPLY" | translate }}
    </button>
  </div>
</kendo-dialog>

<!-- User Info Window -->
<kendo-window *ngIf="userInfoShown" class="user-info-dialog" [width]="400" [top]="50" [(left)]="userInfoDialogLeftPosition" [resizable]="false" (focusout)="showHideUserInfo()">
  
  <div class="d-flex flex-column p-3">

    <!-- Company Name & Sign out -->
    <div class="d-flex align-items-center pb-3">
      <!-- Company Name -->
      <div>{{userDetails!.businessPartnerName}}</div>
      <!-- Sign out -->
      <a class="ms-auto ps-2 cursor-pointer opal-dark-green-text text-nowrap align-self-start" (click)="signOut()">Sign out</a>
    </div>
    
    <!-- User Info -->
    <div class="d-flex">

      <!-- Avatar -->
      <div class="usar-avatar">
        <i class="fa-solid fa-circle user-avatar-icon"></i>
        <div class="user-avatar-initials">{{userDetails!.initials}}</div>
      </div>
      
      <div class="d-flex flex-column ps-3">
        <!-- First & Last name -->
        <div class="display-8 text-truncate">{{userDetails!.firstName}} {{userDetails!.lastName}}</div>
        <!-- Email -->
        <div class="pt-1 text-truncate">{{userDetails!.email}}</div>
        <!-- Go to account -->
        <a class="pt-1 cursor-pointer opal-dark-green-text" (click)="onViewAccountProfile()">{{'ACCOUNT_INFO.VIEW_ACCOUNT' | translate}}</a>
      </div>
    </div>
  </div>
</kendo-window>

<mdb-sidenav-layout>
  <!-- Sidenav  -->
  <mdb-sidenav
    #sidenav="mdbSidenav"
    [mode]="sidenavMode"
    [position]="'absolute'"
    (mouseleave)="onSidenavLeave()"
    [hidden]="sidenavHidden"
    [slimCollapsed]="true"
    [width]="280"
    (sidenavCollapse)="onSidenavColapse()"
    (sidenavExpand)="onSidenavExpand()">
    <div class="d-flex flex-column vh-100">
      <!-- Brand -->
      <div class="d-flex justify-content-between align-items-center brand-wrapper">
        <!-- LBM Logo -->
        <div class="d-flex h-100 sidenav-item-left justify-content-center align-items-center brand-image" (click)="onSidenavEnter()">
          <img class="img-fluid" src="/assets/img/OPAL_Logo.jpg"/>
        </div>

        <!-- Product Name -->
        <div class="ps-3 h-100 sidenav-item-right d-flex flex-column justify-content-center">
          <h5 class="brand display-9 mb-0">PARTNER CENTER</h5>
          <span class="brand display-11 mb-0">OPAL Associates Holding AG</span>
        </div>
      </div>

      <!-- Sidenav Menu Items-->
      <ul class="sidenav-menu">
        <mdb-sidenav-item *ngFor="let sidenavItem of sidenavItems">
          <a *ngIf="sidenavItem.hasChildItems" class="sidenav-link p-0" (click)="onSidenavEnter()">
            <!-- Icon -->
            <div class="sidenav-item-left h-100 d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-xl opal-white-text" [ngClass]="sidenavItem.iconType"></i>
            </div>

            <!-- Title -->
            <div class="sidenav-item-right h-100 d-flex align-items-center">
              <span class="ms-3">{{ sidenavItem.title | uppercase }}</span>
            </div>
          </a>

          <a
            *ngIf="!sidenavItem.hasChildItems"
            class="sidenav-link p-0"
            [routerLink]="sidenavItem.path"
            routerLinkActive="active"
            (click)="onSidenavItemClick()">
            <!-- Icon -->
            <div class="sidenav-item-left h-100 d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-xl opal-white-text" [ngClass]="sidenavItem.iconType"></i>
            </div>

            <!-- Title -->
            <div class="sidenav-item-right h-100 d-flex align-items-center">
              <span class="ms-3">{{ sidenavItem.title | uppercase }}</span>
            </div>
          </a>

          <ul mdbCollapse class="sidenav-collapse" *ngIf="sidenavItem.hasChildItems">
            <li *ngFor="let childSidenavItem of sidenavItem.childItems" class="sidenav-item">
              <a
                class="sidenav-link"
                [routerLink]="childSidenavItem.path"
                [routerLinkActiveOptions]="{ exact: false }"
                routerLinkActive="active"
                (click)="onSidenavItemClick()">
                <!-- Icon -->
                <div class="sidenav-item-left h-100 d-flex justify-content-center align-items-center">
                  <!-- <i class="fa-solid fa-xl opal-white-text" [ngClass]="childSidenavItem.iconType"></i> -->
                </div>

                <!-- Title -->
                <div class="sidenav-item-right h-100 d-flex align-items-center">
                  <span class="ms-3">{{ childSidenavItem.title | uppercase }}</span>
                </div>
              </a>
            </li>
          </ul>
        </mdb-sidenav-item>
      </ul>

      <!-- Fill space -->
      <div class="mt-auto d-flex h-100" (click)="onSidenavEnter()">
        <div class="sidenav-item-left h-100"></div>
        <div class="sidenav-item-right h-100"></div>
      </div>

      <!-- Sidenav footer -->
      <div class="d-flex" (click)="onSidenavEnter()">
        <!-- Version -->
        <div class="sidenav-item-left d-flex justify-content-center align-items-center pb-3">
          <span class="brand display-11">V {{ partnerCenterVersion }}</span>
        </div>

        <!-- Copyright -->
        <div class="sidenav-item-right d-flex justify-content-center align-items-start flex-column pb-3">
          <div class="brand display-11 mb-0 ms-3">Copyright &#169; {{ currentYear }}</div>
          <div class="brand display-11 mb-0 ms-3">OPAL Associates Holding AG</div>
        </div>
      </div>
    </div>
  </mdb-sidenav>

  <!-- Sidenav Content -->
  <mdb-sidenav-content #sidenavContent class="min-vh-100" (click)="onSidenavContentClick()">
    <div class="w-100 min-vh-100 d-flex flex-column">
      <nav class="navbar navbar-expand d-flex align-items-center">
        <!-- Sidenav toogle button -->
        <button class="btn shadow-0 p-0 mx-2 d-block" aria-controls="#sidenav-1" aria-haspopup="true" (click)="onSidenavButtonClick()" *ngIf="sidenavButtonVisible">
          <i class="fas fa-chevron-right fa-2xl sidenav-icon-rotate" [class.rotate-down]="sidenavHidden == true || sidenavCollapsed == true"></i>
        </button>

        <!-- Title -->
        <div class="d-flex">
          <h1 class="display-7 ps-3 mb-0 appTitle" (click)="onToolbarTitleClicked(toolbarTitlePageNamePart!)">
            {{ toolbarTitlePageNamePart }}
          </h1>
        </div>

        <div class="d-flex flex-fill align-items-center">
          <!-- Search Bar -->
          <div class="d-flex ms-4 align-items-center searchBar" *ngIf="showSearchBar">
            <kendo-textbox
              size="large"
              placeholder="{{ 'COMMON.SEARCH_BAR_PLACEHOLDER' | translate }}"
              [clearButton]="true"
              [(ngModel)]="searchTerm"
              (keyup.enter)="onStartSearch()">
            </kendo-textbox>

            <!-- Search Filter Button -->
            <i class="fa-solid fa-xl fa-filter fa-hover opal-dark-green-text cursor-pointer ms-2 h-100" (click)="onSearchFilterButtonClick()"></i>

          </div>

          <div class="d-flex align-items-center ms-auto" *ngIf="showDocumentOptions">

            <!-- Rating Summary -->
            <div>
              <user-rating
                [ratingSummary]="documentRatingSummary"
                (userRatingsChanged)="onUpdateDocumentUserRatingSummary()">
              </user-rating>
            </div>

            <!-- Add/Remove User Favorite -->
            <div class="h-100 ms-3">
              <i
                class="fa-solid fa-xl fa-heart fa-hover opal-dark-green-text cursor-pointer"
                (click)="onFavoritesIconClicked()"
                [ngClass]="{ 'fa-selected': productDocumentSelectedAsFavorite }">
              </i>
            </div>
            
            <!-- Share Document -->
            <div class="h-100 ms-3">
              <i class="fa-solid fa-xl fa-share-nodes fa-hover opal-dark-green-text cursor-pointer" (click)="onSharePage()"></i>
            </div>
            
            <!-- Product Version Selection -->
            <kendo-dropdownlist
              class="mx-4 width-200"
              (keydown)="$event.preventDefault()"
              [data]="documentProductVersions"
              textField="title"
              valueField="value"
              [(ngModel)]="selectedDocumentProductVersion"
              (selectionChange)="onProductVersionChanged($event)">
            </kendo-dropdownlist>
          </div>

        </div>

        <div class="usar-avatar-small me-3 cursor-pointer" (click)="showHideUserInfo()">
          <i class="fa-solid fa-circle fa-3xl user-avatar-icon-small fa-hover cursor-pointer"></i>
          <div *ngIf="userDetails" class="user-avatar-initials-small">{{userDetails!.initials}}</div>
        </div>

      </nav>

      <!-- Main Content -->
      <main class="flex-fill d-flex h-100 sidenav-main-content">
        <!-- <main class="flex-fill card m-2 container align-self-center"> -->
        <router-outlet></router-outlet>
      </main>
    </div>
  </mdb-sidenav-content>
</mdb-sidenav-layout>
