<kendo-dialog [minHeight]="500" [minWidth]="350" [maxWidth]="350" [animation]="false">

  <!-- Header -->
  <div class="d-flex flex-column bg-opal-green-rad-gradient align-items-center p-3" >
    <!-- Logo -->
    <img class="login-image" src="../../../assets/img/OPAL_Logo.jpg"/>
    <div class="opal-white pe-3 action-link" (click)="onShowPasswordPolicy()" *ngIf="!isPasswordPolicyShown && !isPasswordLinkInvalid && !isPasswordSuccessfullyReset">{{'SECURITY.PASSWORD_RESET.PASSWORD_POLICY.TITLE' | translate}}</div>
    <div class="opal-white fs-4 mt-3" *ngIf="!isPasswordPolicyShown">{{'SECURITY.PASSWORD_RESET.TITLE' | translate}}</div>
    <div class="opal-white fs-4 mt-3" *ngIf="isPasswordPolicyShown">{{'SECURITY.PASSWORD_RESET.PASSWORD_POLICY.TITLE' | translate}}</div>
  </div>

  <!-- Body & Footer- Password Policy -->
  <div class="d-flex flex-column flex-grow-1" *ngIf="isPasswordPolicyShown">

    <div class="d-flex flex-column px-2 pt-2 flex-grow-1">

      <!-- Password Length -->
      <div class="d-flex align-items-center my-2">
        <i class="fa-solid fa-xl fa-check pt-1 col-1 opal-green-text text-center" *ngIf="passwordValidationResult.minimalPasswordLengthValid"></i>
        <i class="fa-solid fa-xl fa-xmark pt-1 col-1 opal-red-text text-center" *ngIf="!passwordValidationResult.minimalPasswordLengthValid"></i>
        <div class="col-11 ms-1">{{'SECURITY.PASSWORD_RESET.PASSWORD_POLICY.MIN_LENGTH' | translate}} {{passwordPolicy!.minimalLength}}.</div>
      </div>

      <!-- Min. number of uppercase letters -->
      <div class="d-flex align-items-center mb-2">
        <i class="fa-solid fa-xl fa-check pt-1 col-1 opal-green-text text-center" *ngIf="passwordValidationResult.minimalNumberOfUppercaseCharactersValid"></i>
        <i class="fa-solid fa-xl fa-xmark pt-1 col-1 opal-red-text text-center" *ngIf="!passwordValidationResult.minimalNumberOfUppercaseCharactersValid"></i>
        <div class="col-11 ms-1">{{'SECURITY.PASSWORD_RESET.PASSWORD_POLICY.MIN_NO_OF_UPPERCASE_LETTERS' | translate}} {{passwordPolicy!.minimalNumberOfUppercaseLetters}}.</div>
      </div>

      <!-- Min. number of lowercase letters -->
      <div class="d-flex align-items-center mb-2">
        <i class="fa-solid fa-xl fa-check pt-1 col-1 opal-green-text text-center" *ngIf="passwordValidationResult.minimalNumberOfLowercaseCharactersValid"></i>
        <i class="fa-solid fa-xl fa-xmark pt-1 col-1 opal-red-text text-center" *ngIf="!passwordValidationResult.minimalNumberOfLowercaseCharactersValid"></i>
        <div class="col-11 ms-1">{{'SECURITY.PASSWORD_RESET.PASSWORD_POLICY.MIN_NO_OF_LOWERCASE_LETTERS' | translate}} {{passwordPolicy!.minimalNumberOfLowercaseLetters}}.</div>
      </div>

      <!-- Min. number of digits -->
      <div class="d-flex align-items-center mb-2">
        <i class="fa-solid fa-xl fa-check pt-1 col-1 opal-green-text text-center" *ngIf="passwordValidationResult.minimalNumberOfDigitsValid"></i>
        <i class="fa-solid fa-xl fa-xmark pt-1 col-1 opal-red-text text-center" *ngIf="!passwordValidationResult.minimalNumberOfDigitsValid"></i>
        <div class="col-11 ms-1">{{'SECURITY.PASSWORD_RESET.PASSWORD_POLICY.MIN_NO_OF_DIGITS' | translate}} {{passwordPolicy!.minimalNumberOfDigits}}.</div>
      </div>

      <!-- Min. number of special characters -->
      <div class="d-flex align-items-center mb-2">
        <i class="fa-solid fa-xl fa-check pt-1 col-1 opal-green-text text-center" *ngIf="passwordValidationResult.minimalNumberOfSpecialCharactersValid"></i>
        <i class="fa-solid fa-xl fa-xmark pt-1 col-1 opal-red-text text-center" *ngIf="!passwordValidationResult.minimalNumberOfSpecialCharactersValid"></i>
        <div class="col-11 ms-1">{{'SECURITY.PASSWORD_RESET.PASSWORD_POLICY.MIN_NO_OF_SPECIAL_CHARS' | translate}} {{passwordPolicy!.minimalNumberOfSpecialCharacters}}.</div>
      </div>

    </div>

    <div class="dialog-footer d-flex align-items-center">

      <!-- Close Button -->
      <button 
          kendoButton 
          (click)="onClosePasswordPolicy()"
          class="flex-grow-1"
          [themeColor]="'primary'"
          rounded="none">
          {{'SECURITY.PASSWORD_RESET.CLOSE' | translate}}
      </button>
    </div>

  </div>

  <!-- Body & Footer - Reset Password -->
  <div class="d-flex flex-column flex-grow-1" *ngIf="!isPasswordPolicyShown && !isPasswordLinkInvalid && !isPasswordSuccessfullyReset">

    <!-- Dialog Body - Reset Password -->
    <form class="dialog-body flex-fill align-self-stretch d-flex flex-column pt-4" #passwordResetForm="ngForm" >

      <!-- Userame -->
      <div class="mb-3">
          <kendo-label text="{{'SECURITY.PASSWORD_RESET.USERNAME' | translate}}"></kendo-label>
          <kendo-textbox 
            class="mt-1" 
            required 
            [readonly]="true"
            name="user"
            #usernameInput
            [(ngModel)]="username">
          </kendo-textbox>
      </div>

      <!-- Password -->
      <div class="mb-3">
          
          <kendo-label text="{{'SECURITY.PASSWORD_RESET.PASSWORD' | translate}}"></kendo-label>

          <kendo-textbox
            class="mt-1" 
            required 
            passwordPolicyValidator
            [passwordPolicy]="passwordPolicy"
            [readonly]="isPasswordResetInProgress"
            (keyup.enter)="confirmPasswordInput!.focus()"
            name="pwd" 
            id="pwd"
            #pwd="ngModel" 
            #passwordInput
            [(ngModel)]="password" 
            type="password">
            <ng-template kendoTextBoxSuffixTemplate>
              <i [attr.tabindex]="-1" class="fa-duotone fa-lg me-2" [ngClass]="isPasswordShown ? 'fa-eye-slash' : 'fa-eye'" (mouseup)="onHidePassword($event)" (mousedown)="onShowPassword($event)"></i>
            </ng-template>
          </kendo-textbox>

          <!-- Error Message -->
          <span *ngIf="pwd.touched && pwd.errors?.['required']" class="k-form-error k-text-start">
              {{'ERROR_MESSAGE.PASSWORD_IS_REQUIRED' | translate}}
          </span>

          <span *ngIf="!pwd.errors?.['required'] && pwd.dirty && pwd.errors?.['passwordPolicyError']" class="k-form-error k-text-start">
            {{pwd.errors?.['passwordPolicyError']}}
          </span>
      </div>

      <!-- Confirm password -->
      <div class="mb-3">  
          
        <kendo-label text="{{'SECURITY.PASSWORD_RESET.CONFIRM_PASSWORD' | translate}}"></kendo-label>
        <kendo-textbox 
          class="mt-1"  
          required 
          [readonly]="isPasswordResetInProgress"
          (keyup.enter)="onResetUserPassword()"
          passwordMatch="pwd"
          name="confirmPwd" 
          #confirmPwd="ngModel" 
          #confirmPasswordInput
          [(ngModel)]="confirmPassword" 
          type="password">
        </kendo-textbox>

        <!-- Error Message -->
        <span *ngIf="confirmPwd.touched && confirmPwd.errors?.['required']" class="k-form-error k-text-start">
          {{'ERROR_MESSAGE.PASSWORD_CONFIRMATION_IS_REQUIRED' | translate}}
        </span>

        <!-- Password Match Error Message -->
        <span *ngIf="confirmPwd.dirty && !confirmPwd.errors?.['required'] && confirmPwd.errors?.['passwordMatch']" class="k-form-error k-text-start">
          {{'ERROR_MESSAGE.PASSWORD_DOES_NOT_MATCH' | translate}}
      </span>
      </div>

      <div class="text-center opal-red-text align-self-center" *ngIf="isPasswordResetErrorOccured">
          {{passwordResetErrorMessage}}
      </div>

      <!-- In-progress loader & message -->
      <div class="d-flex justify-content-center" *ngIf="isPasswordResetInProgress">

        <!-- Loader -->
        <kendo-loader class="align-self-center" type="infinite-spinner" themeColor="primary" size="medium">
        </kendo-loader>

        <!-- Message -->
        <span class="ms-2 align-self-center">
            {{'SECURITY.PASSWORD_RESET.MESSAGES.PASSWORD_RESET_IN_PROGRESS' | translate}}
        </span>
      </div>

    </form>

    <div class="dialog-footer d-flex align-items-center">

      <!-- Cancel Button -->
      <button 
          kendoButton 
          [attr.tabindex]="-1"
          (click)="onCancelResetUserPassword()"
          class="flex-grow-1 me-2"
          [themeColor]="'primary'"
          rounded="none">
          {{'SECURITY.PASSWORD_RESET.CANCEL_PASSWORD_RESET' | translate}}
      </button>

      <!-- Password Reset Button -->
      <button 
          kendoButton 
          [disabled]="!passwordResetForm.form.valid || isPasswordResetInProgress"
          (click)="onResetUserPassword()"
          class="flex-grow-1 ms-2"
          [themeColor]="'primary'"
          rounded="none">
          {{'SECURITY.PASSWORD_RESET.CONFIRM_PASSWORD_RESET' | translate}}
      </button>
    </div>
  </div>

  <!-- Body & Footer - Reset Reset Success -->
  <div class="d-flex flex-column flex-grow-1" *ngIf="!isPasswordPolicyShown && !isPasswordLinkInvalid && isPasswordSuccessfullyReset">

    <div class="dialog-body flex-fill align-self-stretch d-flex flex-column justify-content-center text-center">
      {{'SECURITY.PASSWORD_RESET.MESSAGES.PASSWORD_RESET_SUCCESS' | translate}}
    </div>

    <div class="dialog-footer d-flex align-items-center">

      <!-- Go To Login Button -->
      <button 
          kendoButton 
          (click)="onBackToLogin()"
          class="flex-grow-1"
          [themeColor]="'primary'"
          rounded="none">
          {{'SECURITY.PASSWORD_RESET.BACK_TO_LOGIN' | translate}}
      </button>
  </div>
  </div>

  <!-- Body & Footer - Reset Password Link Invalid -->
  <div class="d-flex flex-column flex-grow-1" *ngIf="!isPasswordPolicyShown && isPasswordLinkInvalid && !isPasswordSuccessfullyReset">
    <div class="dialog-body flex-fill align-self-stretch d-flex flex-column justify-content-center opal-red-text text-center">
      {{'ERROR_MESSAGE.INVALID_PASSWORD_RESET_LINK' | translate}}
      
      </div>
  </div>


</kendo-dialog>