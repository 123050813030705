import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DialogConstants } from "./confirmation-dialog.constants";
import { DialogResult } from "./dialog-result";



@Component({
    selector: 'confirmation-dialog',
    styleUrls: ['confirmation-dialog.component.scss'],
    templateUrl:'confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent{

    //#region Private Fieldss

    constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public messageTerm: string, public translate: TranslateService) {

        this.message = "";
        this.translate.get(messageTerm).subscribe(translation=>{
            this.message = translation;
        });

        this.dialogRef.keydownEvents().subscribe(event => {
            if (event.key === "Escape") {
                this.onCancel();
            }
        });
    
        this.dialogRef.backdropClick().subscribe(event => {
            // this.onCancel();
        });
    }
        

    //#endregion

    //#region Public properties

    public get dialogMinHeight(){
        return DialogConstants.DIALOG_MIN_HEIGHT;
    }

    public get dialogMinWidth(){
        return DialogConstants.DIALOG_MIN_WIDTH;
    }

    public get dialogHeight(){
        return DialogConstants.DIALOG_MIN_HEIGHT;
    }

    public get dialogWidth(){
        return DialogConstants.DIALOG_MIN_WIDTH;
    }

    public get dialogMaxHeight(){
        return DialogConstants.DIALOG_MAX_HEIGHT;
    }

    public get dialogMaxWidth(){
        return DialogConstants.DIALOG_MAX_WIDTH;
    }
    
    public message: string;

    //#endregion

    //#region Public Methods

    public onCancel():void{

        this.dialogRef.close(DialogResult.No);
    }


    public onConfirm(): void{
        this.dialogRef.close(DialogResult.Yes);
    }


    //#endregion



}