import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class DataConversionService {

  private _textEncoder: TextEncoder;

  constructor() {
    this._textEncoder = new TextEncoder();
  }

  /**
   * Converts string, arrays
   * @param dataToConvert Data to convert to an ArrayBuffer.
   * @returns 
   */
  public coerceToArrayBuffer(dataToConvert: any): any {

    var arrayBuffer: ArrayBuffer;
    

    if(typeof dataToConvert === "string"){

      var convertedData = this.base64UrlStringToBase64String(dataToConvert);
      convertedData = window.atob(convertedData);
      var uint8Bytes = new Uint8Array(convertedData.length);

      for (var i = 0; i < convertedData.length; i++) {
        uint8Bytes[i] = convertedData.charCodeAt(i);
      }

      arrayBuffer = uint8Bytes.buffer;
      
    } else if (Array.isArray(dataToConvert)){

      arrayBuffer = new Uint8Array(dataToConvert).buffer;

    } else if(dataToConvert instanceof Uint8Array) {
      arrayBuffer = dataToConvert.buffer;
    } else {

      throw new Error("Could not coerce to string. Data type not supported.");
    }

    return arrayBuffer;
  }

  /**
   * Converts Array/ArrayBuffer/Uint8Array/string to a Base64 URL encoded string.
   * @param dataToConvert Data to conver.
   * @returns 
   */
  public coerceToBase64Url(dataToConvert: any): any {

    var convertedData = dataToConvert;

    if(Array.isArray(convertedData)){
      convertedData = Uint8Array.from(convertedData);
    }

    if (convertedData instanceof ArrayBuffer) {
      convertedData = new Uint8Array(convertedData);
    }

    if (convertedData instanceof Uint8Array) {
      var tempString = "";

      for (var i = 0; i < convertedData.length; i++) {
        tempString += String.fromCharCode(convertedData[i]);
      } 

      convertedData = window.btoa(tempString);
    }

    if (typeof convertedData !== "string") {
      throw new Error("Could not coerce to string. Data type not supported.");
    }

    return this.base64StringToBase64UrlString(convertedData);
  }

  private coerceToBase64Urld(thing: any) {
    // Array or ArrayBuffer to Uint8Array
    if (Array.isArray(thing)) {
        thing = Uint8Array.from(thing);
    }
  
    if (thing instanceof ArrayBuffer) {
        thing = new Uint8Array(thing);
    }
  
    // Uint8Array to base64
    if (thing instanceof Uint8Array) {
        var str = "";
        var len = thing.byteLength;
  
        for (var i = 0; i < len; i++) {
            str += String.fromCharCode(thing[i]);
        }
        thing = window.btoa(str);
    }
  
    if (typeof thing !== "string") {
        throw new Error("could not coerce to string");
    }
  
    // base64 to base64url
    // NOTE: "=" at the end of challenge is optional, strip it off here
    thing = thing.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");
  
    return thing;
  };

  /**
   * Converts Base64 URL encoded string to a Base64 string.
   * @param dataToConvert Base64 URL encoded string.
   * @returns Base64 encoded string.
   */
  public base64UrlStringToBase64String(base64UrlString: string): string{
    return base64UrlString.replace(/-/g, "+").replace(/_/g, "/");
  }

    /**
   * Converts Base64 encoded string to a Base64 URL string.
   * @param dataToConvert Base64 encoded string.
   * @returns Base64 URL encoded string.
   */
    public base64StringToBase64UrlString(base64lString: string): string{
      return base64lString.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");
    }
}
