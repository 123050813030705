import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

/**
 * @description Publisher subscriber service
 */
@Injectable({
    providedIn: 'root'
})
export class PubSubService {

    //#region Private Fields

    /**
     * Map for multiple topic support. Key represents topic name and value is corresponding subject.
     */
    private _subjectMap: Map<string, Subject<any>>;

    //#endregion

    //#region Constructor

    constructor() {
        this._subjectMap = new Map<string, Subject<any>>();
    }

    //#endregion

    //#region Public Methods

    /**
     * Publishes message for a given topic with optional message data.
     * @param topic Topic name.
     * @param message Message data.
     */
    public publish(topic: string, message: any) {

        if (!this._subjectMap.has(topic)) {
            this._subjectMap.set(topic, new Subject<any>());
        }

        this._subjectMap.get(topic)!.next(message);
    }

    /**
     * Subscribes to a given topic.
     * @param topic Topic name.
     * @returns Observable for a given topic.
     */
    public subscribe(topic: string): Observable<any> | undefined {

        if (!this._subjectMap.has(topic)) {
            this._subjectMap.set(topic, new Subject<any>());
        }

        return this._subjectMap.get(topic)!.asObservable();
    }

    //#endregion
}
