<!-- Add/Update User Rating Dialog -->
<kendo-dialog id="addUpdateRatingDialog" [minHeight]="300" [minWidth]="450" *ngIf="isAddUpdateRatingDialogShown">

    <!-- Dialog Header -->
    <kendo-dialog-titlebar class="k-window-actions-custom d-flex dialog-header justify-content-center align-items-center">

        <!-- Favorite Icon -->
        <div>
            <i class="fa-solid fa-lg fa-star opal-dark-green-text"></i>
        </div>

        <!-- Title -->
        <div class="display-9 ms-3">{{ "USER_RATING.ADD_UPDATE_RATING_DIALOG.TITLE" | translate }}</div>

        <!-- Close -->
        <div class="ms-auto">
            <i class="fa-light fa-xmark fa-sm opal-grey-text cursor-pointer" (click)="onCloseAddUpdateRatingDialog()"></i>
        </div>

    </kendo-dialog-titlebar>

    <!-- Dialog Body -->
    <div class="dialog-body d-flex flex-column flex-fill justify-content-center align-items-center">

        <!-- User Rating Stars -->
        <div class="my-3">
            <i
                *ngFor="let star of [1, 2, 3, 4, 5]; let starIndex = index"
                class="fa-solid fa-3xl fa-star"
                [ngClass]="{
                    'opal-orange-text': (hoveredRating! || selectedRatingScore!) >= starIndex + 1,
                    'opal-grey-text': (hoveredRating! || selectedRatingScore!) < starIndex + 1,
                    'pe-3': starIndex != 4
                }"
                (mouseenter)="onHoverRatingStar(starIndex + 1)"
                (mouseleave)="onLeaveRatingStar()"
                (click)="onClickRatingStar(starIndex + 1)">
            </i>
        </div>

        <!-- User Rating Comment -->
        <div class="mt-3 mb-2 align-self-stretch">
            <div class="form-field">
                <kendo-label text="{{ 'USER_RATING.ADD_UPDATE_RATING_DIALOG.COMMENT' | translate }}" class="text-nowrap"></kendo-label>
                <kendo-textarea
                    resizable="none"
                    [rows]="4"
                    name="userRatingComment"
                    #userRatingComment="ngModel"
                    [(ngModel)]="currentRatingComment">
                </kendo-textarea>
            </div>
        </div>

    </div>

    <!-- Dialog Footer -->
    <div class="dialog-footer d-flex align-items-center">

        <!-- Loader -->
        <kendo-loader *ngIf="isAddingOrUpdatingUserRating" type="infinite-spinner" themeColor="primary" size="medium"></kendo-loader>

        <!-- Message -->
        <span class="ms-2 display-10" *ngIf="isAddingOrUpdatingUserRating">
            {{ "MESSAGES.ADDING_UPDATING_USER_RATING" | translate }}
        </span>

        <!-- Add/Update User Rating Button -->
        <button
            kendoButton
            [disabled]="isAddingOrUpdatingUserRating || selectedRatingScore == 0 || (selectedRatingScore == userRating.score && currentRatingComment == userRating.comment)"
            (click)="onAddOrUpdateUserRating(userRating.score != null)"
            class="ms-auto"
            [themeColor]="'primary'"
            rounded="none">
            <i class="fa-solid fa-md fa-check me-2"></i>
            {{ "USER_RATING.ADD_UPDATE_RATING_DIALOG.BUTTONS.OK" | translate }}
        </button>
    </div>

</kendo-dialog>

<!-- Rating Details Dialog -->
<kendo-dialog [minHeight]="500" [minWidth]="1200" [maxWidth]="1200" *ngIf="isRatingDetailsDialogShown">

    <!-- Dialog Header -->
    <kendo-dialog-titlebar class="k-window-actions-custom d-flex dialog-header justify-content-center align-items-center">

        <!-- Rating Icon -->
        <div>
            <i class="fa-solid fa-lg fa-star opal-dark-green-text"></i>
        </div>

        <!-- Title -->
        <div class="display-9 ms-3">{{ "USER_RATING.RATING_DETAILS_DIALOG.TITLE" | translate }}</div>

        <!-- Close -->
        <div class="ms-auto">
            <i class="fa-light fa-xmark fa-sm opal-grey-text cursor-pointer" (click)="onCloseRatingDetailsDialog()"></i>
        </div>

    </kendo-dialog-titlebar>

    <!-- Dialog Body -->
    <div class="dialog-body d-flex flex-fill">

        <!-- Rating Metrics -->
        <div class="d-flex flex-column col-4 h-100 py-2 px-3">

            <div class="d-flex my-1 align-items-center">

                <!-- Average Rating (Numeric) -->
                <div class="display-8 me-2" *ngIf="ratingSummary?.averageRating">
                    {{ ratingSummary?.averageRating }}
                </div>

                <!-- Average Rating (Rating Stars) -->
                <div class="stars-container-details">

                    <!-- Grey Stars (Background) -->
                    <div class="stars-layer">
                        <i class="fa-solid fa-star opal-grey-text" *ngFor="let star of [1, 2, 3, 4, 5]"></i>
                    </div>

                    <!-- Orange Stars -->
                    <div class="stars-layer" [ngStyle]="{ width: ratingStarsWidth + '%' }">
                        <i class="fa-solid fa-star opal-orange-text" *ngFor="let star of [1, 2, 3, 4, 5]"></i>
                    </div>

                </div>

                <!-- Rating Count -->
                <div class="display-8 ms-2 pb-1" *ngIf="ratingSummary?.ratingCount">
                    ({{ ratingSummary?.ratingCount }})
                </div>

            </div>

            <!-- Rating Bars -->
            <div class="display-10 my-1">

                <div
                    class="d-flex align-items-center cursor-pointer py-1"
                    [ngClass]="{'score-filter-active' : ratingScoreFilterActive(5)}" (click)="onToggleScoreFilter(5)">

                    <div class="d-flex align-items-center me-2">
                        <span>5</span>
                        <i class="fa-solid fa-star fa-xs opal-orange-text ms-1"></i>
                      </div>
                    <kendo-progressbar class="me-1" [label]="false" [value]="ratingScoreDistribution.fiveStarRatingPercentage"></kendo-progressbar>
                    <span class="text-nowrap percentage-text">{{ ratingScoreDistribution.fiveStarRatingPercentage }} %</span>  
                </div>

                <div
                    class="d-flex align-items-center cursor-pointer py-1"
                    [ngClass]="{'score-filter-active' : ratingScoreFilterActive(4)}" (click)="onToggleScoreFilter(4)">

                    <div class="d-flex align-items-center me-2">
                        <span>4</span>
                        <i class="fa-solid fa-star fa-xs opal-orange-text ms-1"></i>
                      </div>
                    <kendo-progressbar class="me-1" [label]="false" [value]="ratingScoreDistribution.fourStarRatingPercentage"></kendo-progressbar>
                    <span class="text-nowrap percentage-text">{{ ratingScoreDistribution.fourStarRatingPercentage }} %</span>  
                </div>

                <div
                    class="d-flex align-items-center cursor-pointer py-1"
                    [ngClass]="{'score-filter-active' : ratingScoreFilterActive(3)}" (click)="onToggleScoreFilter(3)">

                    <div class="d-flex align-items-center me-2">
                        <span>3</span>
                        <i class="fa-solid fa-star fa-xs opal-orange-text ms-1"></i>
                      </div>
                    <kendo-progressbar class="me-1" [label]="false" [value]="ratingScoreDistribution.threeStarRatingPercentage"></kendo-progressbar>
                    <span class="text-nowrap percentage-text">{{ ratingScoreDistribution.threeStarRatingPercentage }} %</span>  
                </div>

                <div
                    class="d-flex align-items-center cursor-pointer py-1"
                    [ngClass]="{'score-filter-active' : ratingScoreFilterActive(2)}" (click)="onToggleScoreFilter(2)">

                    <div class="d-flex align-items-center me-2">
                        <span>2</span>
                        <i class="fa-solid fa-star fa-xs opal-orange-text ms-1"></i>
                      </div>
                    <kendo-progressbar class="me-1" [label]="false" [value]="ratingScoreDistribution.twoStarRatingPercentage"></kendo-progressbar>
                    <span class="text-nowrap percentage-text">{{ ratingScoreDistribution.twoStarRatingPercentage }} %</span>  
                </div>

                <div 
                    class="d-flex align-items-center cursor-pointer py-1"
                    [ngClass]="{'score-filter-active' : ratingScoreFilterActive(1)}" (click)="onToggleScoreFilter(1)">
                    <div class="d-flex align-items-center me-2">
                        <span>1</span>
                        <i class="fa-solid fa-star fa-xs opal-orange-text ms-1"></i>
                      </div>
                    <kendo-progressbar class="me-1" [label]="false" [value]="ratingScoreDistribution.oneStarRatingPercentage"></kendo-progressbar>
                    <span class="text-nowrap percentage-text">{{ ratingScoreDistribution.oneStarRatingPercentage }} %</span>  
                </div>
                
            </div>

        </div>

        <!-- Related Ratings -->
        <div class="col-8 h-100 py-2 px-3">
            <kendo-listview
                [data]="ratings"
                class="ratings-list"
                (scrollBottom)="onLoadMoreRatings()">
                
                <!-- List Header Template -->
                <ng-template kendoListViewHeaderTemplate>
                    <div class="display-8 p-2 opal-green-text">{{ "USER_RATING.RATING_DETAILS_DIALOG.RELATED_RATINGS" | translate }}</div>
                </ng-template>

                <!-- Related Rating Template -->
                <ng-template kendoListViewItemTemplate let-dataItem="dataItem">
                    
                    <div class="d-flex flex-column m-2 ">

                        <!-- User & Rating Date-->
                        <div class="d-flex">
                            <div class="display-10 fw-bold">
                                {{dataItem.username}}
                            </div>

                            <!-- Rating Date -->
                            <div class="ms-auto">
                                {{dataItem.timestamp | date: 'dd/MM/yyyy'}}
                            </div>
                        </div>

                       <!-- Rating Score -->
                        <div>
                            <i class="fa-solid fa-sm fa-star opal-orange-text" *ngFor="let star of [].constructor(dataItem.score)"></i>
                            <i class="fa-solid fa-sm fa-star opal-grey-text" *ngFor="let star of [].constructor(5 - dataItem.score)"></i>
                        </div>
                        
                        <!-- Rating Comment -->
                        <div class="display-10 comment-container">
                            {{dataItem.comment ? dataItem.comment : '-'}}
                        </div>

                        <!-- Separator -->
                        <div class="separator pt-2"></div>

                    </div>
                </ng-template>

            </kendo-listview>
        </div>
    </div>

    <!-- Dialog Footer -->
    <div class="dialog-footer d-flex align-items-center">

        <!-- Add/Update Rating -->
        <button
            kendoButton
            [disabled]="isAddingOrUpdatingUserRating"
            (click)="onOpenAddUpdateRatingDialog()"
            [themeColor]="'primary'"
            rounded="none">
            <i class="fa-solid fa-md fa-plus me-2" *ngIf="!userRatingExists" ></i>
            <i class="fa-solid fa-md fa-pen me-2" *ngIf="userRatingExists"></i>
            {{ userRatingExists ? 
                ("USER_RATING.RATING_DETAILS_DIALOG.BUTTONS.UPDATE_RATING" | translate) : 
                ("USER_RATING.RATING_DETAILS_DIALOG.BUTTONS.ADD_RATING" | translate) }}
        </button>

        <!-- Close Dialog -->
        <button
            kendoButton
            [disabled]="isAddingOrUpdatingUserRating"
            (click)="onCloseRatingDetailsDialog()"
            class="ms-auto"
            [themeColor]="'primary'"
            rounded="none">
            <i class="fa-solid fa-md fa-times me-2"></i>
            {{ "USER_RATING.RATING_DETAILS_DIALOG.BUTTONS.CLOSE" | translate }}
        </button>
    </div>

</kendo-dialog>

<!-- Rating Summary (Component Template) -->
<div class="d-flex align-items-center rating-summary">

    <!-- Average Rating (Numeric) -->
    <div *ngIf="ratingSummary?.averageRating" class="pb-0 me-1 display-9">
        <span>{{ ratingSummary?.averageRating }}</span>
    </div>

    <!-- Average Rating (Rating Stars) -->
    <div
        class="stars-container"
        [ngClass]="{'cursor-pointer' : userRatingEnabled}" (click)="onOpenRatingDialog()">

        <!-- Grey Stars (Background) -->
        <div class="stars-layer">
            <i class="fa-solid fa-star opal-grey-text" *ngFor="let star of [1, 2, 3, 4, 5]"></i>
        </div>

        <!-- Orange Stars -->
        <div class="stars-layer" [ngStyle]="{ width: ratingStarsWidth + '%' }">
            <i class="fa-solid fa-star opal-orange-text" *ngFor="let star of [1, 2, 3, 4, 5]"></i>
        </div>

    </div>

    <!-- Rating Count -->
    <div *ngIf="ratingSummary?.ratingCount" class="pb-0 ms-1 display-9">
        <span>({{ ratingSummary?.ratingCount }})</span>
    </div>

</div>
