/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { OpalPartnerCenterApiConfiguration, OpalPartnerCenterApiConfigurationInterface } from './opal-partner-center-api-configuration';

import { ActivitiesService } from './services/activities.service';
import { AdminNotificationsService } from './services/admin-notifications.service';
import { AdminNotificationsODataService } from './services/admin-notifications-odata.service';
import { AppSettingsService } from './services/app-settings.service';
import { AuditTrailService } from './services/audit-trail.service';
import { AuditTrailODataService } from './services/audit-trail-odata.service';
import { AuthService } from './services/auth.service';
import { CompaniesService } from './services/companies.service';
import { DataSyncService } from './services/data-sync.service';
import { DataSyncLogODataService } from './services/data-sync-log-odata.service';
import { Fido2Service } from './services/fido-2.service';
import { FileStorageService } from './services/file-storage.service';
import { FileStorageODataService } from './services/file-storage-odata.service';
import { InfoService } from './services/info.service';
import { MarketingMaterialService } from './services/marketing-material.service';
import { MetadataService } from './services/metadata.service';
import { NotificationsService } from './services/notifications.service';
import { ProductsService } from './services/products.service';
import { ProductsDocumentationService } from './services/products-documentation.service';
import { RatingsService } from './services/ratings.service';
import { RatingsODataService } from './services/ratings-odata.service';
import { RegistrationService } from './services/registration.service';
import { SearchService } from './services/search.service';
import { UserGroupsManagementService } from './services/user-groups-management.service';
import { UserGroupsODataService } from './services/user-groups-odata.service';
import { UsersService } from './services/users.service';
import { UsersManagementService } from './services/users-management.service';
import { UsersODataService } from './services/users-odata.service';
import { VideoStreamingService } from './services/video-streaming.service';

/**
 * Provider for all OpalPartnerCenterApi services, plus OpalPartnerCenterApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    OpalPartnerCenterApiConfiguration,
    ActivitiesService,
    AdminNotificationsService,
    AdminNotificationsODataService,
    AppSettingsService,
    AuditTrailService,
    AuditTrailODataService,
    AuthService,
    CompaniesService,
    DataSyncService,
    DataSyncLogODataService,
    Fido2Service,
    FileStorageService,
    FileStorageODataService,
    InfoService,
    MarketingMaterialService,
    MetadataService,
    NotificationsService,
    ProductsService,
    ProductsDocumentationService,
    RatingsService,
    RatingsODataService,
    RegistrationService,
    SearchService,
    UserGroupsManagementService,
    UserGroupsODataService,
    UsersService,
    UsersManagementService,
    UsersODataService,
    VideoStreamingService
  ],
})
export class OpalPartnerCenterApiModule {
  static forRoot(customParams: OpalPartnerCenterApiConfigurationInterface): ModuleWithProviders<OpalPartnerCenterApiModule> {
    return {
      ngModule: OpalPartnerCenterApiModule,
      providers: [
        {
          provide: OpalPartnerCenterApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
