export class UpdateSearchFilterMessage{

    //#region Constructor
    
    constructor(filterOption: string, value: any) {
        this.filterOption = filterOption;
        this.value = value;
    }

    //#endregion

    //#region Properties

    /**
     * Filter option.
     */
    public filterOption: string;

    /**
     * Value of filter options.
     */
    public value: any;

    //#endregion
}