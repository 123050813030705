export class SearchResultContentTypeOption {

    //#region Constructor

    constructor(name: string, id: number) {
        this._name = name;
        this._id = id;
    }

    //#endregion

    //#region Private Properties

    /**
     * Name of search result content type option.
     */
    private _name: string;

    /**
     * Id of search result content type option.
     */
    private _id: number;

    //#endregion

    //#region Properties

    /**
     * Gets name of search result content type option.
     */
    get name(): string {
        return this._name;
    }

    /**
     * Gets id of search result content type option.
     */
    get id(): number {
        return this._id;
    }

    //#endregion
}