import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class UserInactivityService{

  //#region Private Fields

  private _timeoutId: any;

  /**
   * Inactivity timeout in milliseconds.
   */
  private _inactivityTimeout?: number;

  /**
   * Events to listen for to determine user activity.
   */
  private _events: Array<string>;

  //#endregion

  //#region Constructor

  constructor(){

    // Good balance between user experience and performance.
    // Using mousemove event is not recommended as it can cause performance issues.
    this._events = ["click", "keydown", "scroll", "touchstart"];
  }

  //#endregion

  //#region Public Methods

  /**
   * Starts tracking user inactivity.
   * @param inactivityTimeout Inactivity timeout in milliseconds.
   * @param onUserInactive Callback function to execute when user is inactive.
   */
  public start(inactivityTimeout: number, onUserInactive: () => void ): void{

    this._inactivityTimeout = inactivityTimeout;
    this.stop();

    const resetTimer = () => {
      clearTimeout(this._timeoutId);
      this._timeoutId = setTimeout(onUserInactive, this._inactivityTimeout);
    }

    this._events.forEach(event => {
      document.addEventListener(event, resetTimer);
    });

    resetTimer();

    this.stop = ()=> {
      clearTimeout(this._timeoutId);
      this._events.forEach(event => {
        document.addEventListener(event, resetTimer);
      });
    }
  }

  public stop(): void{
    clearTimeout(this._timeoutId);
  }

  //#endregion
}