// NOTE: This message is used when user tries to change product version for opened product documentation. If
// there is no available documentation for selected product version and also for previous product versions,
// selected product version is reseted to the last choosen version for which available documentation exists.
export class ProductVersionChangeMessage {

    //#region Properties

    /**
     * Product short name.
     */
    public productShortName?: string;

    /**
     * Category of opened product document.
     */
    public documentCategory?: string;

    /**
     * Product version for which available documentation exists.
     */
    public productVersionForAvailableDocumentation?: string;

    /**
     * Product version selected by the user.
     */
    public selectedProductVersion?: string;

    //#endregion

}
