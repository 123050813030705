import { Injectable } from "@angular/core";
import { DeleteUserFavoriteRequest, UserInfo } from "src/app/api/opal-partner-center/models";
import { UsersService } from "src/app/api/opal-partner-center/services";
import { LOCALIZE_CONSTANTS } from "../../localize.constants";
import { UserFavorite } from "../../model/user-favorite";
import { BusyIndicationService } from "../busy-indication-service/busy-indication-service";
import { ErrorResponseHandlerService } from "../error-response-handler-service/error-response-handler-service";
import { LoggerService } from "../log-service/logger-service";
import { NotificationService } from "../notification-service/notification.service";
import { TranslateService } from "@ngx-translate/core";


@Injectable({
    providedIn: "root"
})
export class UserInfoService {

    //#region Private Properties
    
    /**
     * User permissions.
     */
    private _userPermissions: Set<string>;

    /**
     * User info.
     */
    private _userInfo?: UserInfo;

    //#endregion

    //#region Constructor

    constructor(private _usersService: UsersService, 
                private _loggerService: LoggerService, 
                private _busyIndicationService: BusyIndicationService, 
                private _errorResponseHandlerService: ErrorResponseHandlerService, 
                private _notificationService: NotificationService, 
                private _translateService: TranslateService) { 

        this._userPermissions = new Set<string>();
    }

    //#endregion

    //#region Public Properties

    /**
     * Sets user info.
     */
    public set userInfo(value: UserInfo | undefined) {

        this._userInfo = value;

        if (this._userInfo && this._userInfo?.securitySettings?.permissions && this._userInfo?.securitySettings?.roles) {

            var permissions = this._userInfo?.securitySettings?.permissions.map(x => x.name);
            var roles = this._userInfo.securitySettings?.roles.map(x => x.name);

            this._userPermissions = new Set<string>(permissions as string[]);
        }
    }

    /**
     * Gets user info.
     */
    public get userInfo(): UserInfo | undefined {
        return this._userInfo;
    }

    /**
     * User favorites.
     */
    public userFavorites?: Array<UserFavorite>;

    /**
     * Flag that indicates if the list of user favorites is loaded
     */
    public userFavoritesLoaded?: boolean;

    //#endregion

    //#region Public Methods

    /**
     * Updates user favorites.
     */
    public updateUserFavorites() {

        // this._busyIndicationService.Show(LOCALIZE_CONSTANTS.MESSAGES.GETTING_USER_FAVORITES);
        this._loggerService.info("Getting user favorites ...");

        this._usersService.getApiUserFavorites().subscribe({

            next: response => {

                this._loggerService.info("Getting user favorites done.");
                // this._busyIndicationService.Hide();

                if (response.userFavorites) {

                    this.userFavorites = new Array<UserFavorite>();

                    for (let i = 0; i < response.userFavorites.length; i++) {
                        this.userFavorites.push(new UserFavorite(this._translateService, response.userFavorites[i]));
                    }

                    this.userFavoritesLoaded = true;
                }
            },
            error: errorResponse => {
                // this._busyIndicationService.Hide();
                this._errorResponseHandlerService.handleHttpErrorResponse(errorResponse);
            }
        });
    }

    /**
     * Deletes user favorite.
     * @param nameOfFavoriteToBeDeleted Name of user favorite that is to be deleted.
     */
    public deleteUserFavorite(nameOfFavoriteToBeDeleted: string) {
        
        this._loggerService.info(`Deleting user favorite named ${nameOfFavoriteToBeDeleted} ...`);

        var request = {} as DeleteUserFavoriteRequest;
        request.name = nameOfFavoriteToBeDeleted;

        this._usersService.deleteApiUserFavorites(request).subscribe({
            next: response => {
                this._loggerService.info(`Deleting user favorite named ${nameOfFavoriteToBeDeleted} done.`);
                this._notificationService.notifySuccess(LOCALIZE_CONSTANTS.MESSAGES.USER_FAVORITE_DELETED);
                this.updateUserFavorites();
            },
            error: error => {
                this._loggerService.errorFormat("Error occured: {0}", error);
                // NOTE: Do not show error message temporarely
                // When deleting use favorite multiple messages for favorite deletion are send throuh pubsub
                // which must be investigated and fixed.
                //this._errorResponseHandlerService.handleHttpErrorResponse(error);
            }
        });
    }

    /**
     * Determins if a user has a given permission.
     * @param permission Permission to check.
     * @returns True if user have a given permission, otherwise false.
     */
    public hasPermission(permission: string): boolean {
        return this._userPermissions?.has(permission);
    }
    
    /**
     * Check if a user has any of given permissions.
     * @param permissions Permissions to check.
     * @returns True if user has at least one of given permissions, false otherwise.
     */
    public hasAnyOfPermissions(...permissions: string[]): boolean {

        if (permissions) {

            for (let permission of permissions) {
                if (this._userPermissions.has(permission)) {
                    return true;
                }
            }
            return false;
        } else {

            return true;
        }
    }
   
    //#endregion
}
