import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { MdbSidenavModule } from 'mdb-angular-ui-kit/sidenav';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CONSTANTS } from './shared/constants';
import { OpalPartnerCenterApiModule } from './api/opal-partner-center/opal-partner-center-api.module';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { SharedModule as KendoSharedModule } from '@progress/kendo-angular-grid';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { HttpSetCookieInterceptor } from './shared/services/http-set-cookie-interceptor/http-set-cookie-interceptor';
import { OpalPartnerCenterODataApiModule } from './api/opal-partner-center-odata/opal-partner-center-odata-api.module';
import { MatDialogModule } from '@angular/material/dialog';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';
import { SharedModule as AppSharedModule } from './shared/shared.module';
import { EditorModule } from '@progress/kendo-angular-editor';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { environment } from 'src/environments/environment';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { SecurityModule } from './security/security.module';
import { BarcodesModule } from '@progress/kendo-angular-barcodes';
import { LabelModule } from '@progress/kendo-angular-label';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { PopoverModule } from '@progress/kendo-angular-tooltip';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { LayoutModule} from './layout/layout.module';
import { RouterModule } from '@angular/router';
import 'hammerjs';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { Observable, tap } from 'rxjs';
import { OpalPartnerCenterODataApiConfiguration } from './api/opal-partner-center-odata/opal-partner-center-odata-api-configuration';
import { OpalPartnerCenterApiConfiguration } from './api/opal-partner-center/opal-partner-center-api-configuration';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    SecurityModule,
    RouterModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    KendoSharedModule,
    MatDialogModule,
    MatSnackBarModule,
    OpalPartnerCenterApiModule.forRoot({
      rootUrl: environment.apiRootUrl,
    }),
    OpalPartnerCenterODataApiModule.forRoot({
      rootUrl: environment.apiRootUrl,
    }),
    TranslateModule.forRoot({
      loader:{
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: CONSTANTS.LANGUAGE_CODE.ENGLISH
    }),    
    IndicatorsModule,
    DialogsModule,
    ButtonsModule,
    DateInputsModule,
    DropDownsModule,
    FormsModule,
    AppSharedModule,
    EditorModule,
    UploadsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
      }),
    MdbCollapseModule,
    InputsModule,
    BarcodesModule,
    InputsModule,
    LabelModule,
    TooltipsModule,
    PopoverModule,
    LabelModule,
    ChartsModule,
    TreeListModule
  ],
  providers: [{
      provide: HTTP_INTERCEPTORS, 
      useClass: HttpSetCookieInterceptor, 
      multi:true
    },{
      provide: APP_INITIALIZER,
      useFactory: initializeApp, 
      multi: true,
      deps: [HttpClient, OpalPartnerCenterODataApiConfiguration, OpalPartnerCenterApiConfiguration],

    }],
  exports:[RouterModule],
  bootstrap: [AppComponent]
})
export class AppModule { }

/**
 * 
 * @param httpClient Http client.
 * @returns Translate loader.
 */
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
 }

/**
 * Initialize the application:
 * - Reconfigures Web API root URL based on the configuration file.
 * @param httpClient Http client.
 * @param odataApiConfig OData Web API configuration.
 * @param apiConfig Web API Configuration.
 * @returns Observable.
 */
export function initializeApp(httpClient: HttpClient, odataApiConfig: OpalPartnerCenterODataApiConfiguration, apiConfig: OpalPartnerCenterApiConfiguration) {
  return (): Observable<any> => {
    return httpClient.get('/config.json').pipe(
      tap((config: any) => {

        if(config && config.apiPath){
          odataApiConfig.rootUrl = config.apiPath;
          apiConfig.rootUrl = config.apiPath;
          environment.apiRootUrl = config.apiPath;
        }
      })
    );
  };
}