import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms/";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { TextBoxComponent } from "@progress/kendo-angular-inputs";
import { PasswordPolicy, SetUserPasswordRequest } from "src/app/api/opal-partner-center/models";
import { RegistrationService, UsersService } from "src/app/api/opal-partner-center/services";
import { CONSTANTS } from "src/app/shared/constants";
import { LOCALIZE_CONSTANTS } from "src/app/shared/localize.constants";
import { PasswordValidationResult } from "src/app/shared/model/password-validation-result";
import { ErrorResponseHandlerService } from "src/app/shared/services/error-response-handler-service/error-response-handler-service";
import { PasswordValidationService } from "src/app/shared/services/password-validation-service/password-validation.service";
import { Location as AngularLocation } from '@angular/common';
import { SECURITY_ROUTES } from "../security.routing.constants";

@Component({
  selector: "reset-password",
  templateUrl: "./reset-password.component.html"
})
export class ResetPasswordComponent{

  //#region Private Fields

  /**
   * User password.
   */
  private _password: string;

  /**
   * Password reset token.
   */
  private _passwordResetToken: string;

  //#endregion

  //#region Constructor

  constructor(private _registrationService: RegistrationService,
    private _translateService: TranslateService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _location: AngularLocation,
    private _passwordValidationService: PasswordValidationService,
    private _userService: UsersService,
    private _errorResponseHandlerService: ErrorResponseHandlerService){

  this.username = "";
  this._password = "";
  this.confirmPassword = "";
  this._passwordResetToken = "";
  this.passwordResetErrorMessage = "";
  this.isPasswordResetInProgress = false;
  this.isPasswordResetErrorOccured = false;
  this.isPasswordPolicyShown = false;
  this.isPasswordShown = false;
  this.isPasswordSuccessfullyReset = false;
  this.isPasswordLinkInvalid = false;
  this.passwordValidationResult = new PasswordValidationResult();
}

  //#endregion

  //#region NgCore

  public ngOnInit(): void{

    this._registrationService.getApiPasswordPolicy().subscribe({
      next: response=>{
        this.passwordPolicy = response.passwordPolicy;
      },
      error: errorResponse=>{
        this._errorResponseHandlerService.handleHttpErrorResponse(errorResponse);
      }
    });

    this._activatedRoute.queryParams.subscribe(params=>{
      if(params && params.token && params.username){

        this.username = params.username;
        this._passwordResetToken = params.token;
        let path = this._location.path().split("?")[0];
        this._location.replaceState(path);
      } else{
        this.isPasswordLinkInvalid = true;
      }
    });
  }

  public ngAfterViewInit(): void{
    this.passwordInput!.input.nativeElement.type = "password";
    this.confirmPasswordInput!.input.nativeElement.type = "password";
    this.passwordInput!.focus();
  }

  //#endregion

  //#region Public Properties

  /**
   * Password policy.
   */
  public passwordPolicy?: PasswordPolicy;

  /**
   * Password validation result.
   */
  public passwordValidationResult: PasswordValidationResult;

  /**
   * Username.
   */
  public username: string;

  /**
   * Gets password.
   */
  public get password() : string {
    return this._password;
  }

  /**
   * Sets password and validates passoword againts password policy.
   */
  public set password(password : string) {
    this._password = password;

    this.passwordValidationResult = this._passwordValidationService.validatePassword(this._password, this.passwordPolicy!);
  }

  /**
   * The password confirmation.
   */
  public confirmPassword: string;

  /**
   * Password reset form.
   */
  @ViewChild("passwordResetForm")
  public passwordResetForm?: NgForm;

  /**
   * Password input.
   */
  @ViewChild("passwordInput")
  public passwordInput?: TextBoxComponent;

  /**
   * Confirm password input.
   */
  @ViewChild("confirmPasswordInput")
  public confirmPasswordInput?: TextBoxComponent;

  /**
   * Indicates whether the password policy is shown.
   */
  public isPasswordPolicyShown: boolean;

  /**
   * Indicates whether the password is shown.
   */
  public isPasswordShown: boolean;

  /**
   * Indicates whether the registration is in progress.
   */
  public isPasswordResetInProgress: boolean;

  /**
   * Password reset error message.
   */
  public passwordResetErrorMessage: string;

  /**
   * Indicates whether the password reset error occured.
   */
  public isPasswordResetErrorOccured: boolean;

  /**
   * Indicates whether the password is successfully reset.
   */
  public isPasswordSuccessfullyReset: boolean;

  /**
   * Indicates whether the password link is invalid.
   */
  public isPasswordLinkInvalid: boolean;

  //#endregion

  //#region UI Handlers

  /**
   * Cancels password reset.
   */
  public onCancelResetUserPassword(): void {
    this._router.navigate([SECURITY_ROUTES.SECURITY_LOGIN]);
  }

  /**
   * Resets user password.
   */
  public onResetUserPassword():void{

    this.isPasswordResetInProgress = true;
    var request = {} as SetUserPasswordRequest;
    request.password = this._password;
    request.resetPasswordToken = this._passwordResetToken;
    request.userName = this.username;

    this._userService.putApiResetPassword(request).subscribe({
      next: response=>{
        this.isPasswordResetInProgress = false;
        this.isPasswordSuccessfullyReset = true;
      },
      error: errorResponse=>{
        this.isPasswordResetInProgress = false;
        this.password = "";
        this.confirmPassword = "";
        this.passwordResetForm?.resetValidation();
        this.handleHttpErrorResponse(errorResponse);
      }
    });
  }

  /**
   * Navigates back to login.
   */
  public onBackToLogin(): void{
    this._router.navigate([SECURITY_ROUTES.SECURITY_LOGIN]);
  }

  /**
   * Shows password.
   * @param event Event
   */
  public onShowPassword(event: any): void{
    this.isPasswordShown = true;
    this.passwordInput!.input.nativeElement.type = "text";
  }

  /**
   * Hides password.
   * @param event Event
   */
  public onHidePassword(event: any): void{
    this.isPasswordShown = false;
    this.passwordInput!.input.nativeElement.type = "password";
  }

  /**
   * Shows password policy.
   */
  public onShowPasswordPolicy(): void{
    this.isPasswordPolicyShown = true;
  }

  /**
   * Closes password policy.
   */
  public onClosePasswordPolicy():void{
    this.isPasswordPolicyShown = false;
  }

  //#endregion

  //#region Private Methods

    /**
   * Handles error response.
   * @param httpErrorResponse Http error response.
   */
    private handleHttpErrorResponse(httpErrorResponse: any): void {
    
      this.isPasswordResetErrorOccured = true;
  
      if(httpErrorResponse){
  
        if(httpErrorResponse.error && httpErrorResponse.error.errorCode){
  
          switch(httpErrorResponse.error.errorCode){
  
            case CONSTANTS.SERVER_ERROR_CODES.INVALID_PASSWORD_RESET_TOKEN:
              this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.INVALID_PASSWORD_RESET_TOKEN).subscribe(translation=>{
                this.passwordResetErrorMessage = translation;
              })
              break;
  
            default:
              this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNEXPECTED_SERVER_ERROR).subscribe(translation=>{
                this.passwordResetErrorMessage = translation;
              })
  
          }
  
        } else {
  
          switch(httpErrorResponse.status)
          {
            case 0:
              this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.CONNECTION_PROBLEM).subscribe(translation=>{
                this.passwordResetErrorMessage = translation;
              })
              break;
  
            case 400:
              this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.INVALID_REQUEST).subscribe(translation=>{
                this.passwordResetErrorMessage = translation;
              })
              break;
  
            case 404:
              this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.RESOURCE_NOT_FOUND).subscribe(translation=>{
                this.passwordResetErrorMessage = translation;
              })
              break;
  
            case 401:
            case 403:
              this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNAUTHORIZED_REQUEST).subscribe(translation=>{
                this.passwordResetErrorMessage = translation;
              })
              break;
  
            case 409:
              this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.RESOURCE_ALREADY_EXIST).subscribe(translation=>{
                this.passwordResetErrorMessage = translation;
              })
              break;
  
            default:
              this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.UNEXPECTED_HTTP_STATUS_CODE).subscribe(translation=>{
                this.passwordResetErrorMessage = translation+ httpErrorResponse.status;
              })
              break;
          }
        }
  
      } else {
        this._translateService.get(LOCALIZE_CONSTANTS.ERROR_MESSAGE.CONNECTION_PROBLEM).subscribe(translation=>{
          this.passwordResetErrorMessage = translation;
        })
      }
    }

  //#endregion

}