import { Directive } from "@angular/core";
import { TextBoxComponent } from "@progress/kendo-angular-inputs";

/**
 * Suppport for kendo texbox with type "password" and autofocus:
 * - If attribute 'password' is applied to <kendo-textbox> it will be rendered as password input field, masking entered value.
 * - If attribute 'autofocus' is applied to <kendo-textbox> it will be focused and the content will be selected.
 */
@Directive({
    selector: "kendo-textbox"
})
export class KendoTexboxDirective {

    //#region Constructors

    constructor(private _kendoTextBox: TextBoxComponent) {}

    //#endregion

    //#region NgCore

    ngAfterViewInit(): void {

        var hostElementAttributes = this._kendoTextBox["hostElement"].nativeElement.attributes as NamedNodeMap

        if (hostElementAttributes.getNamedItem("type")?.nodeValue == "password") {
            this._kendoTextBox.input.nativeElement.type = "password";
        }

        if (hostElementAttributes.getNamedItem("autofocus")){
          setTimeout(() => {
            this._kendoTextBox.focus();
            this._kendoTextBox.input.nativeElement.select();
          }, 400)
          
        }
    }

    //#endregion
}
