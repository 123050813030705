/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SearchQueryResponse } from '../models/search-query-response';
import { GetProductsInfosResponse } from '../models/get-products-infos-response';
@Injectable({
  providedIn: 'root',
})
class SearchService extends __BaseService {
  static readonly getApiSearchPath = '/api/search';
  static readonly getApiSearchableProductsPath = '/api/searchableProducts';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SearchService.GetApiSearchParams` containing the following parameters:
   *
   * - `SearchPageSize`:
   *
   * - `SearchPageNumber`:
   *
   * - `SearchTerm`:
   *
   * - `SearchFilters`:
   *
   * @return OK
   */
  getApiSearchResponse(params: SearchService.GetApiSearchParams): __Observable<__StrictHttpResponse<SearchQueryResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.SearchPageSize != null) __params = __params.set('SearchPageSize', params.SearchPageSize.toString());
    if (params.SearchPageNumber != null) __params = __params.set('SearchPageNumber', params.SearchPageNumber.toString());
    if (params.SearchTerm != null) __params = __params.set('SearchTerm', params.SearchTerm.toString());
    if (params.SearchFilters != null) __params = __params.set('SearchFilters', params.SearchFilters.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SearchQueryResponse>;
      })
    );
  }
  /**
   * @param params The `SearchService.GetApiSearchParams` containing the following parameters:
   *
   * - `SearchPageSize`:
   *
   * - `SearchPageNumber`:
   *
   * - `SearchTerm`:
   *
   * - `SearchFilters`:
   *
   * @return OK
   */
  getApiSearch(params: SearchService.GetApiSearchParams): __Observable<SearchQueryResponse> {
    return this.getApiSearchResponse(params).pipe(
      __map(_r => _r.body as SearchQueryResponse)
    );
  }

  /**
   * @return OK
   */
  getApiSearchableProductsResponse(): __Observable<__StrictHttpResponse<GetProductsInfosResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/searchableProducts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductsInfosResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiSearchableProducts(): __Observable<GetProductsInfosResponse> {
    return this.getApiSearchableProductsResponse().pipe(
      __map(_r => _r.body as GetProductsInfosResponse)
    );
  }
}

module SearchService {

  /**
   * Parameters for getApiSearch
   */
  export interface GetApiSearchParams {
    SearchPageSize: number;
    SearchPageNumber: number;
    SearchTerm?: string;
    SearchFilters?: string;
  }
}

export { SearchService }
