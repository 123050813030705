import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { ODataResponse } from '../common/model/odata-response';
import { UserRatingDetails } from '../opal-partner-center/models';
import { StrictHttpResponse } from '../opal-partner-center/strict-http-response';
import { OpalPartnerCenterODataApiConfiguration } from './opal-partner-center-odata-api-configuration';

@Injectable({
  providedIn: 'root',
})
export class RatingsODataService {

  //#region Constructors

  constructor(private _httpClient: HttpClient, private _config: OpalPartnerCenterODataApiConfiguration) { }

  //#endregion

  //#region API

  getApiRatingDetailsResponse(query: string): Observable<StrictHttpResponse<ODataResponse<UserRatingDetails>>> {
    
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this._config.rootUrl + `/api/ratingDetails` + query,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });
      
    return this._httpClient.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map((_r) => {
        return _r as StrictHttpResponse<ODataResponse<UserRatingDetails>>;
      })
    );
  }

  getApiRatingDetails(query: string): Observable<ODataResponse<UserRatingDetails>> {
    return this.getApiRatingDetailsResponse(query).pipe(
      map(_r => _r.body as ODataResponse<UserRatingDetails>)
    );
  }

  //#endregion

  //#region Private methods

  private newParams(): HttpParams {
    return new HttpParams({
      encoder: new ParameterCodec()
    });
  }

  //#endregion
}

class ParameterCodec implements HttpParameterCodec {

  //#region HttpParameterCodec

  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }

  //#region 

}
