<kendo-dialog [minHeight]="500" [minWidth]="350" [maxWidth]="350" [animation]="false">

  <!-- Header -->
  <div class="d-flex flex-column bg-opal-green-rad-gradient align-items-center p-3">
    <img class="login-image" src="../../../assets/img/OPAL_Logo.jpg"/>
    <div class="opal-white fs-4 mt-3" >{{ (isRegistrationConfirmation ? 'SECURITY.EMAIL_CONFIRMATION.REGISTRATION_CONFIRMATION_TITLE' : 'SECURITY.EMAIL_CONFIRMATION.EMAIL_CONFIRMATION_TITLE') | translate}}</div>
  </div>

  <!-- Message -->
  <span class="p-4 d-flex flex-column justify-content-center flex-grow-1 text-center" [ngClass]="{'opal-red-text': isEmailConfirmationErrorOccured}">
    {{emailConfirmationMessage}}
  </span>

  <div class="dialog-footer d-flex align-items-center mt-auto">

    <!-- Go TO Login Button -->
    <button 
        kendoButton
        (click)="onGoToLogin()"
        class="flex-grow-1"
        [themeColor]="'primary'"
        rounded="none">
        {{'SECURITY.EMAIL_CONFIRMATION.GO_TO_LOGIN' | translate}}
    </button>
  </div>

</kendo-dialog>