<kendo-dialog [minHeight]="500" [minWidth]="600" *ngIf="isShown">
  <!-- Dialog Header -->
  <kendo-dialog-titlebar class="k-window-actions-custom d-flex dialog-header justify-content-center align-items-center">
    <!-- Share Icon -->
    <div>
      <i class="fa-solid fa-lg fa-share-nodes opal-dark-green-text"></i>
    </div>

    <!-- Title -->
    <div class="display-9 ms-3">{{ "SHARE_PAGE.TITLE" | translate }}</div>

    <!-- Close -->
    <div class="ms-auto">
      <i class="fa-light fa-xmark fa-sm opal-grey-text cursor-pointer" (click)="closeDialog()"></i>
    </div>
  </kendo-dialog-titlebar>

  <!-- Dialog Body -->
  <form class="dialog-body flex-fill align-self-stretch" #sharePageForm="ngForm">
    <!-- Share Link -->
    <div class="form-field">
      <div class="d-flex">
        <!-- Shared Link -->
        <div class="flex-grow-1 me-4">
          <kendo-label text="{{ 'SHARE_PAGE.SHARE_LINK' | translate }}" class="text-nowrap"></kendo-label>
          <kendo-textbox name="sharedLink" #sharedLink="ngModel" [disabled]="true" [(ngModel)]="sharedPageLink"></kendo-textbox>
        </div>

        <!-- Copy Shared Link -->
        <button kendoButton (click)="onCopyToClipboard()" class="ms-auto align-self-end" [themeColor]="'primary'" fillMode="outline" rounded="none">{{ "SHARE_PAGE.COPY_LINK" | translate }}</button>
      </div>
    </div>

    <!-- Email Receivers -->
    <div class="form-field">
      <kendo-label text="{{ 'SHARE_PAGE.RECEIVERS' | translate }}" class="text-nowrap"></kendo-label>
      <kendo-textbox required multiEmail name="emailReceivers" #emailReceivers="ngModel" [(ngModel)]="emailRecevers"></kendo-textbox>

      <!-- Validation Error Message -->
      <span *ngIf="(emailReceivers.touched || emailReceivers.dirty) && emailReceivers.errors?.['required']" class="k-form-error k-text-start">{{ "ERROR_MESSAGE.EMAIL_RECEIVERS_ARE_REQUIRED" | translate }}</span>
      <span *ngIf="emailReceivers.dirty && emailReceivers.errors?.['multiEmail']" class="k-form-error k-text-start">{{ "ERROR_MESSAGE.EMAIL_RECEIVERS_ARE_INVALID" | translate }}</span>
    </div>

    <!-- Message -->
    <div class="form-field">
      <kendo-label text="{{ 'SHARE_PAGE.MESSAGE' | translate }}"></kendo-label>
      <kendo-textarea required name="emailMessage" #emailMessage="ngModel" [rows]="5" [(ngModel)]="message"> </kendo-textarea>

      <!-- Validation Error Message -->
      <span *ngIf="(emailMessage.touched || emailMessage.dirty) && emailMessage.errors?.['required']" class="k-form-error k-text-start">{{ "ERROR_MESSAGE.MESSAGE_IS_REQUIRED" | translate }}</span>
    </div>
  </form>

  <!-- Dialog Button Bar -->
  <div class="dialog-footer d-flex align-items-center">
    <!-- Loader -->
    <kendo-loader *ngIf="isSendingEmail" type="infinite-spinner" themeColor="primary" size="medium"> </kendo-loader>

    <!-- Message -->
    <span class="ms-2 display-10" *ngIf="isSendingEmail">
      {{ "MESSAGES.SENDING_EMAIL" | translate }}
    </span>

    <!-- Send Button -->
    <button kendoButton [disabled]="!sharePageForm.form.valid || isSendingEmail" (click)="onSharePage()" class="ms-auto" [themeColor]="'primary'" rounded="none">
      <i class="fa-solid fa-md fa-envelope me-2"></i>
      {{ "SHARE_PAGE.SHARE" | translate }}
    </button>
  </div>
</kendo-dialog>
