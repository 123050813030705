export class UserFavoriteCategory {

    //#region Private Fields

    /**
     * User favorite category name in english.
     */
    private _name: string;

    /**
     * Translatable (display) name of user favorite category.
     */
    private _displaName: string;

    //#endregion

    //#region Constructor

    constructor(name: string, displayName: string) {
        this._name = name;
        this._displaName = displayName;
    }

    //#endregion

    //#region Properties

    /**
     * Gets the name of user favorite category.
     */
    get name(): string {
        return this._name;
    }

    /**
     * Gets display name of user favorite category.
     */
    get displayName(): string {
        return this._displaName;
    }

    //#endregion
}
