import { Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent  {

  //#region 


  //#endregion

  //#region Constructors

  constructor() {
    
    
  }

  //#endregion

  //#region Ng Core


  //#endregion


  //#endregion

}
