/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetFileLinkResponse } from '../models/get-file-link-response';
import { GetFileStorageEntityDetailsResponse } from '../models/get-file-storage-entity-details-response';
import { GetRelatedVideosResponse } from '../models/get-related-videos-response';
@Injectable({
  providedIn: 'root',
})
class VideoStreamingService extends __BaseService {
  static readonly getApiVideoStreamPath = '/api/videoStream';
  static readonly getApiVideoStreamLinkPath = '/api/videoStreamLink';
  static readonly getApiVideoDetailsPath = '/api/videoDetails';
  static readonly getApiRelatedVideosPath = '/api/relatedVideos';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiVideoStreamResponse(FileGuid: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (FileGuid != null) __params = __params.set('FileGuid', FileGuid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/videoStream`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiVideoStream(FileGuid: string): __Observable<string> {
    return this.getApiVideoStreamResponse(FileGuid).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiVideoStreamLinkResponse(FileGuid: string): __Observable<__StrictHttpResponse<GetFileLinkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (FileGuid != null) __params = __params.set('FileGuid', FileGuid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/videoStreamLink`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetFileLinkResponse>;
      })
    );
  }
  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiVideoStreamLink(FileGuid: string): __Observable<GetFileLinkResponse> {
    return this.getApiVideoStreamLinkResponse(FileGuid).pipe(
      __map(_r => _r.body as GetFileLinkResponse)
    );
  }

  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiVideoDetailsResponse(FileGuid: string): __Observable<__StrictHttpResponse<GetFileStorageEntityDetailsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (FileGuid != null) __params = __params.set('FileGuid', FileGuid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/videoDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetFileStorageEntityDetailsResponse>;
      })
    );
  }
  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiVideoDetails(FileGuid: string): __Observable<GetFileStorageEntityDetailsResponse> {
    return this.getApiVideoDetailsResponse(FileGuid).pipe(
      __map(_r => _r.body as GetFileStorageEntityDetailsResponse)
    );
  }

  /**
   * @param params The `VideoStreamingService.GetApiRelatedVideosParams` containing the following parameters:
   *
   * - `Take`:
   *
   * - `Skip`:
   *
   * - `FileGuid`:
   *
   * @return OK
   */
  getApiRelatedVideosResponse(params: VideoStreamingService.GetApiRelatedVideosParams): __Observable<__StrictHttpResponse<GetRelatedVideosResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Take != null) __params = __params.set('Take', params.Take.toString());
    if (params.Skip != null) __params = __params.set('Skip', params.Skip.toString());
    if (params.FileGuid != null) __params = __params.set('FileGuid', params.FileGuid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/relatedVideos`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetRelatedVideosResponse>;
      })
    );
  }
  /**
   * @param params The `VideoStreamingService.GetApiRelatedVideosParams` containing the following parameters:
   *
   * - `Take`:
   *
   * - `Skip`:
   *
   * - `FileGuid`:
   *
   * @return OK
   */
  getApiRelatedVideos(params: VideoStreamingService.GetApiRelatedVideosParams): __Observable<GetRelatedVideosResponse> {
    return this.getApiRelatedVideosResponse(params).pipe(
      __map(_r => _r.body as GetRelatedVideosResponse)
    );
  }
}

module VideoStreamingService {

  /**
   * Parameters for getApiRelatedVideos
   */
  export interface GetApiRelatedVideosParams {
    Take: number;
    Skip: number;
    FileGuid: string;
  }
}

export { VideoStreamingService }
