<kendo-dialog [minHeight]="500" [minWidth]="350" [maxWidth]="350" [animation]="false" *ngIf="!isDocumentationShown">
  <!-- Header -->
  <div class="d-flex flex-column bg-opal-green-rad-gradient p-3">

    <div class="d-flex">
      <div>
        <i class="fa fa-solid fa-xl fa-circle-question opal-white cursor-pointer" (click)="onShowDocumentation()"></i>
      </div>

      <!-- Register Link -->
      <div
        class="opal-white action-link  ms-auto"
        [ngClass]="{ 'disable-click': isLoginActionInProgress }"
        (click)="onRegisterUser()"
        *ngIf="isLoginEnterUsernameActive">
        {{ "SECURITY.LOGIN.REGISTER" | translate }}
      </div>

      <!-- Login Options Link -->
      <div
        class="opal-white action-link ms-auto"
        [ngClass]="{ 'disable-click': isLoginActionInProgress }"
        (click)="onSelectAnotherAuthMethod()"
        *ngIf="(is2SVActive || is2FAActive || isFido2Active || isLoginEnterPasswordActive) && !isPrivacyPolicyRequirementShown && !emailNotConfirmed">
        {{ "SECURITY.LOGIN.LOGIN_OPTIONS" | translate }}
      </div>

    </div>

    <div class="d-flex flex-column align-items-center">

      <!-- Logo -->
      <img class="login-image" src="../../../assets/img/OPAL_Logo.jpg"/>

      <!-- Title -->
      <div *ngIf="!isPrivacyPolicyRequirementShown" class="opal-white fs-4 mt-3">{{ "SECURITY.LOGIN.TITLE" | translate }}</div>
      <div *ngIf="isPrivacyPolicyRequirementShown" class="opal-white fs-4 mt-3">{{ "SECURITY.LOGIN.PRIVACY_POLICY.TITLE" | translate }}</div>

    </div>
  </div>

  <!-- Login Body - Enter Username -->
  <div *ngIf="isLoginEnterUsernameActive" class="d-flex flex-column flex-grow-1">
    <!-- Dialog Body - Login -->
    <form class="dialog-body flex-fill align-self-stretch d-flex flex-column pt-4" #authForm="ngForm">
      <!-- Userame -->
      <div class="form-field">
        <kendo-label text="{{ 'SECURITY.LOGIN.USERNAME' | translate }}"></kendo-label>
        <kendo-textbox
          class="suppress-validation"
          required
          [readonly]="isLoginActionInProgress"
          name="user"
          (keyup.enter)="onUserPreLogin()"
          #usernameInput
          #user="ngModel"
          [(ngModel)]="username">
        </kendo-textbox>
      </div>

      <!-- Authentication Error Message -->
      <div class="mt-3 text-center opal-red-text" *ngIf="isAuthErrorOccurred">
        {{ authErrorMessage }}
      </div>

      <!-- In-progress loader & message -->
      <div class="mt-3 d-flex justify-content-center" *ngIf="isLoginActionInProgress">
        <!-- Loader -->
        <kendo-loader class="align-self-center" type="infinite-spinner" themeColor="primary" size="medium"> </kendo-loader>

        <!-- Message -->
        <span class="ms-2 align-self-center">
          {{ loginActionInProgressMessage }}
        </span>
      </div>
    </form>

    <div class="dialog-footer d-flex align-items-center">

      <!-- Login with Microsoft -->
      <div class="d-flex flex-grow-1 justify-content-center align-items-center" *ngIf="!isLoginActionInProgress">
        <a class="hover-link cursor-pointer" (click)="onLoginWithMicrosoft()">{{ "SECURITY.LOGIN.LOGIN_WITH_MICROSOFT" | translate }}</a>
      </div>

      <!-- Login Button -->
      <button
        kendoButton
        [disabled]="!authForm.form.valid || isLoginActionInProgress"
        (click)="onUserPreLogin()"
        class="flex-grow-1"
        [themeColor]="'primary'"
        rounded="none">
        {{ "SECURITY.LOGIN.NEXT" | translate }}
      </button>
    </div>
  </div>

  <!-- Login Body - Enter Password -->
  <div *ngIf="isLoginEnterPasswordActive && !emailNotConfirmed" class="d-flex flex-column flex-grow-1">
    <form class="dialog-body flex-fill align-self-stretch d-flex flex-column pt-4" #authForm="ngForm">

      <!-- Password -->
      <div class="form-field">
        <kendo-label text="{{ 'SECURITY.LOGIN.PASSWORD' | translate }}"></kendo-label>
        <kendo-textbox
          kendoTextBox
          required
          class="suppress-validation"
          (keyup.enter)="onUserLogin()"
          [readonly]="isLoginActionInProgress"
          name="pwd"
          #pwd="ngModel"
          #passwordInput
          [(ngModel)]="password"
          type="password">
          <ng-template kendoTextBoxSuffixTemplate>
            <i
              tabindex="0"
              class="fa-duotone fa-lg me-2"
              [ngClass]="{ 'fa-eye-slash': isPasswordShown, 'fa-eye': !isPasswordShown, 'disable-click': isLoginActionInProgress }"
              (mouseup)="onHidePassword($event)"
              (mousedown)="onShowPassword($event)">
            </i>
          </ng-template>
        </kendo-textbox>
      </div>

      <!-- Authentication Error Message -->
      <div class="text-center opal-red-text" *ngIf="isAuthErrorOccurred">
        {{ authErrorMessage }}
      </div>

      <!-- In-progress loader & message -->
      <div class="d-flex justify-content-center" *ngIf="isLoginActionInProgress">
        <!-- Loader -->
        <kendo-loader class="align-self-center" type="infinite-spinner" themeColor="primary" size="medium"> </kendo-loader>

        <!-- Message -->
        <span class="ms-2 align-self-center">
          {{ loginActionInProgressMessage }}
        </span>
      </div>
    </form>

    <div class="dialog-footer d-flex align-items-center">
      <!-- Cancel Login Button -->
      <button
        kendoButton
        [disabled]="isLoginActionInProgress"
        (click)="onLoginCancel()"
        class="flex-grow-1 me-2"
        [themeColor]="'primary'"
        rounded="none">
        {{ "SECURITY.LOGIN.CANCEL" | translate }}
      </button>

      <!-- Login Button -->
      <button
        kendoButton
        [disabled]="!authForm.form.valid || isLoginActionInProgress || isAccountLockedOut"
        (click)="onUserLogin()"
        class="flex-grow-1 ms-2"
        [themeColor]="'primary'"
        rounded="none">
        {{ "SECURITY.LOGIN.NEXT" | translate }}
      </button>
    </div>
  </div>

  <!-- Email Confirmation Sent -->
  <div *ngIf="emailNotConfirmed" class="d-flex flex-grow-1 align-items-center">
      <!-- Authentication Error Message -->
      <div class="text-center opal-red-text" *ngIf="isAuthErrorOccurred">
        {{ authErrorMessage }}
      </div>
  </div>

  <!-- Privacy Policy Body -->
  <div *ngIf="isPrivacyPolicyRequirementShown" class="d-flex flex-column flex-grow-1">
    <div class="dialog-body flex-fill align-self-stretch d-flex flex-column pt-4">
      <div class="text-center">
        {{ 'SECURITY.LOGIN.PRIVACY_POLICY.ACCEPTANCE_REQUIREMENT_START' | translate }}
        <a class="cursor-pointer" (click)="onNavigateToPrivacyPolicyPage()">{{ 'SECURITY.LOGIN.PRIVACY_POLICY.DATA_PROTECTION' | translate }}</a>
        {{ 'SECURITY.LOGIN.PRIVACY_POLICY.ACCEPTANCE_REQUIREMENT_END' | translate }}
      </div>
    </div>

    <!-- Button Bar -->
    <div class="dialog-footer d-flex align-items-center">

      <!-- Deny Privacy Policy Button -->
      <button
        kendoButton
        (click)="onLoginCancel()"
        class="flex-grow-1 me-2"
        [themeColor]="'primary'"
        rounded="none">
        {{ "SECURITY.LOGIN.PRIVACY_POLICY.BUTTONS.DENY" | translate }}
      </button>

      <!-- Accept Privacy Policy Button -->
      <button
        kendoButton
        (click)="onConfirmTwoStepVerification()"
        class="flex-grow-1 ms-2"
        [themeColor]="'primary'"
        rounded="none">
        {{ "SECURITY.LOGIN.PRIVACY_POLICY.BUTTONS.ACCEPT" | translate }}
      </button>
    </div>
  </div>

  <!-- 2SV Body -->
  <div *ngIf="is2SVActive && !isPrivacyPolicyRequirementShown" class="d-flex flex-column flex-grow-1">
    <form class="dialog-body flex-fill align-self-stretch d-flex flex-column pt-4" #authForm="ngForm">
      <!-- 2SV Code -->
      <div class="form-field">
        <kendo-label text="{{ 'SECURITY.2SV.VERIFICATION_CODE' | translate }}"></kendo-label>

        <kendo-textbox
          required
          class="suppress-validation"
          (keyup.enter)="onCheckPrivacyPolicyAcceptanceStatus()"
          name="twoStepVerifCode"
          [readonly]="isLoginActionInProgress || is2SVCodeExpired"
          #twoStepVerifCode="ngModel"
          #twoStepVerifCodeInput
          [(ngModel)]="twoStepVerificationCode">
        </kendo-textbox>
      </div>

      <!-- Enter 2SV instruction -->
      <div class="d-flex flex-column align-items-center" *ngIf="!is2SVCodeExpired && !isAuthErrorOccurred && !isLoginActionInProgress">
        <div class="text-center">{{ "MESSAGES.ENTER_2SV_CODE" | translate }}</div>
        <div>
          <strong class="opal-dark-green-text">{{ twoStepVerificationCodeExpiresIn }} </strong>
        </div>
      </div>

      <!-- 2SV Validation failed error message-->
      <div class="text-center opal-red-text" *ngIf="isAuthErrorOccurred">
        {{ authErrorMessage }}
      </div>

      <!-- 2SV Code Expired/Resend 2SV Code message -->
      <strong
        class="text-center opal-dark-green-text cursor-pointer"
        (click)="onRefresh2SVCode()"
        *ngIf="(is2SVCodeExpired || isAuthErrorOccurred) && !isLoginActionInProgress">
        {{ "MESSAGES.RESEND_2SV_CODE" | translate }}
      </strong>

      <div class="d-flex justify-content-center" *ngIf="isLoginActionInProgress">
        <!-- Loader -->
        <kendo-loader class="align-self-center" type="infinite-spinner" themeColor="primary" size="medium"> </kendo-loader>

        <!-- In-progress message -->
        <div class="ms-2 align-self-center">
          {{ loginActionInProgressMessage }}
        </div>
      </div>
    </form>

    <!-- Footer -->
    <div class="dialog-footer d-flex align-items-center">
      <!-- Cancel Button -->
      <button
        kendoButton
        class="flex-grow-1 me-2"
        (click)="onLoginCancel()"
        [themeColor]="'primary'"
        rounded="none">
        {{ "SECURITY.2SV.CANCEL" | translate }}
      </button>

      <!-- Confirm Button -->
      <button
        kendoButton
        [disabled]="!authForm.form.valid || isLoginActionInProgress"
        (click)="onCheckPrivacyPolicyAcceptanceStatus()"
        class="flex-grow-1 ms-2"
        [themeColor]="'primary'"
        rounded="none">
        {{ "SECURITY.2SV.CONFIRM" | translate }}
      </button>
    </div>
  </div>

  <!-- 2FA Body -->
  <div *ngIf="is2FAActive" class="d-flex flex-column flex-grow-1">

    <form class="dialog-body flex-fill align-self-stretch d-flex flex-column pt-2" #authForm="ngForm">

      <div class="d-flex">
        <div
          class="action-link ms-auto"
          [ngClass]="{ 'disable-click': isLoginActionInProgress }"
          (click)="onBypass2faVerificationCode()"
          *ngIf="is2FAActive">
          {{ "SECURITY.2FA.BYPASS_VERIFICATION_CODE" | translate }}
        </div>
      </div>


      <!-- 2FA Code -->
      <div class="form-field">
        <kendo-label text="{{ 'SECURITY.2FA.VERIFICATION_CODE' | translate }}"></kendo-label>
        <kendo-textbox
          required
          class="suppress-validation"
          (keyup.enter)="onConfirmTwoFactorAuthentication()"
          name="twoFactorAuthCode"
          [readonly]="isLoginActionInProgress"
          #twoFactorAuthCodeInput
          #twoFactorAuthCode="ngModel"
          [(ngModel)]="twoFactorAuthenticationCode">
        </kendo-textbox>
      </div>

      <!-- Enter 2FA instruction -->
      <div class="d-flex flex-column align-items-center" *ngIf="!isAuthErrorOccurred && !isLoginActionInProgress">
        <div class="text-center">{{ "MESSAGES.ENTER_2FA_CODE" | translate }}</div>
      </div>

      <!-- 2FA Error Message  -->
      <div class="text-center opal-red-text" *ngIf="isAuthErrorOccurred">
        {{ authErrorMessage }}
      </div>

      <!-- In-Progress Message -->
      <div class="d-flex justify-content-center" *ngIf="isLoginActionInProgress">
        <!-- Loader -->
        <kendo-loader class="align-self-center" type="infinite-spinner" themeColor="primary" size="medium"> </kendo-loader>

        <!-- In-progress message -->
        <div class="ms-2 align-self-center">
          {{ loginActionInProgressMessage }}
        </div>
      </div>
    </form>

    <!-- Footer -->
    <div class="dialog-footer d-flex align-items-center">
      <!-- Cancel Button -->
      <button kendoButton (click)="onLoginCancel()" [themeColor]="'primary'" class="flex-grow-1 me-2" rounded="none">
        {{ "SECURITY.2FA.CANCEL" | translate }}
      </button>

      <!-- Confirm Button -->
      <button
        kendoButton
        [disabled]="!authForm.form.valid || isLoginActionInProgress"
        (click)="onConfirmTwoFactorAuthentication()"
        class="flex-grow-1 ms-2"
        [themeColor]="'primary'"
        rounded="none">
        {{ "SECURITY.2FA.CONFIRM" | translate }}
      </button>
    </div>
  </div>

  <!-- Bypass 2FA Body -->
  <div *ngIf="is2FARecoveryActive" class="d-flex flex-column flex-grow-1">
    <form class="dialog-body flex-fill align-self-stretch d-flex flex-column pt-4" #authForm="ngForm">
      <!-- 2FA Recovery Code -->
      <div class="mb-2">
        <kendo-label text="{{ 'SECURITY.2FA.RECOVERY_CODE' | translate }}"></kendo-label>

        <div class="d-flex mt-1 align-items-center">
          <kendo-textbox
            required
            (keyup.enter)="onConfirmTwoFactorRecovery()"
            name="twoStepAuthRecoveryCode"
            [readonly]="isLoginActionInProgress"
            #twoStepAuthRecoveryCode="ngModel"
            #twoStepAuthRecoveryCodeInput
            [(ngModel)]="twoFactorAuthenticationRecoveryCode">
          </kendo-textbox>
        </div>

        <!-- Error Message -->
        <span
          *ngIf="(twoStepAuthRecoveryCode.dirty || twoStepAuthRecoveryCode.touched) && twoStepAuthRecoveryCode.errors?.['required']"
          class="k-form-error k-text-start">
          {{ "ERROR_MESSAGE.2FA_RECOVERY_CODE_IS_REQUIRED" | translate }}
        </span>
      </div>

      <!-- Enter 2FA Recovery Instruction -->
      <div class="d-flex flex-column align-items-center" *ngIf="!isAuthErrorOccurred && !isLoginActionInProgress">
        <div class="text-center">{{ "MESSAGES.ENTER_2FA_RECOVERY_CODE" | translate }}</div>
      </div>

      <!-- 2FA Recovery Error Message  -->
      <div class="text-center opal-red-text" *ngIf="isAuthErrorOccurred">
        {{ authErrorMessage }}
      </div>

      <!-- In-Progress Message -->
      <div class="d-flex justify-content-center" *ngIf="isLoginActionInProgress">
        <!-- Loader -->
        <kendo-loader class="align-self-center" type="infinite-spinner" themeColor="primary" size="medium"> </kendo-loader>

        <!-- In-progress message -->
        <div class="ms-2 align-self-center">
          {{ loginActionInProgressMessage }}
        </div>
      </div>
    </form>

    <!-- Footer -->
    <div class="dialog-footer d-flex align-items-center">
      <!-- Cancel Button -->
      <button kendoButton (click)="onLoginCancel()" [themeColor]="'primary'" class="flex-grow-1 me-2" rounded="none">
        {{ "SECURITY.2FA.CANCEL" | translate }}
      </button>

      <!-- Confirm Button -->
      <button
        kendoButton
        [disabled]="!authForm.form.valid || isLoginActionInProgress"
        (click)="onConfirmTwoFactorRecovery()"
        class="flex-grow-1 ms-2"
        [themeColor]="'primary'"
        rounded="none">
        {{ "SECURITY.2FA.CONFIRM" | translate }}
      </button>
    </div>
  </div>

  <!-- Auth Method Selection -->
  <div *ngIf="isAuthMethodSelectionActive" class="d-flex flex-column flex-grow-1">
    <!-- Authentication Method -->
    <div class="dialog-body flex-grow-1 pt-4">
      <kendo-label text="{{ 'SECURITY.LOGIN.AUTH_METHOD' | translate }}"></kendo-label>
      <kendo-dropdownlist
        class="mt-1"
        [data]="authenticationMethods"
        (keydown.enter)="onLoginWithSelectedAuthMethod()"
        (selectionChange)="onAuthenticationMethodChange($event)"
        [(ngModel)]="selectedAuthenticationMethod"
        #authenticationMethodsInput
        [readonly]="isLoginActionInProgress"
        textField="name"
        valueField="id"
        name="authMethods">
      </kendo-dropdownlist>
    </div>

    <!-- Footer -->
    <div class="dialog-footer d-flex align-items-center">
      <!-- Cancel Button -->
      <button kendoButton (click)="onLoginCancel()" [themeColor]="'primary'" class="flex-grow-1 me-2" rounded="none">
        {{ "SECURITY.LOGIN.CANCEL" | translate }}
      </button>

      <!-- Confirm Button -->
      <button
        kendoButton
        [disabled]="isLoginActionInProgress"
        (click)="onLoginWithSelectedAuthMethod()"
        class="flex-grow-1 ms-2"
        [themeColor]="'primary'"
        rounded="none">
        {{ "SECURITY.LOGIN.NEXT" | translate }}
      </button>
    </div>
  </div>

  <!-- FIDO2 Body -->
  <div *ngIf="isFido2Active" class="d-flex flex-column flex-grow-1">
    <div class="dialog-body flex-grow-1 flex-column">
      <!-- FIDO2 Error Message  -->
      <div class="d-flex h-100" *ngIf="isAuthErrorOccurred">
        <div class="d-flex justify-content-center flex-grow-1 align-self-center opal-red-text text-center">
          {{ authErrorMessage }}
        </div>
      </div>

      <!-- In-Progress Message -->
      <div class="d-flex justify-content-center flex-grow-1 h-100" *ngIf="isLoginActionInProgress">
        <!-- Loader -->
        <kendo-loader class="align-self-center" type="infinite-spinner" themeColor="primary" size="medium"> </kendo-loader>

        <!-- In-progress message -->
        <div class="ms-2 align-self-center">
          {{ loginActionInProgressMessage }}
        </div>
      </div>
    </div>

    <div class="dialog-footer d-flex align-items-center">
      <!-- Cancel Button -->
      <button kendoButton (click)="onLoginCancel()" [disabled]="isLoginActionInProgress" [themeColor]="'primary'" class="flex-grow-1" rounded="none">
        {{ "SECURITY.LOGIN.CANCEL" | translate }}
      </button>

      <button
        kendoButton
        (click)="onUserFido2Login()"
        [disabled]="isLoginActionInProgress || isAccountLockedOut"
        [themeColor]="'primary'"
        class="flex-grow-1 ms-2"
        rounded="none">
        {{ "SECURITY.LOGIN.LOGIN" | translate }}
      </button>
    </div>
  </div>
</kendo-dialog>

<!-- Login documentation  -->
<div *ngIf="isDocumentationShown" class="h-100">

  <!-- Close documentation button -->
  <div class="login-docs-button">
    <i class="fa-thin ms-auto fa-xmark fa-2xl  fa-hover cursor-pointer " (click)="onHideDocumentation()"></i>
  </div>

  <!-- Docs - Iframe -->
  <iframe [src]="documentationUrl" class="d-flex w-100 h-100" ></iframe>

</div>
