import { IChildChangedReceiver } from "../interfaces/i-child-changed-receiver";

export class UserRole{

  //#region Private Fields

  private _isAssignedOrig: boolean;

  private _isAssigned : boolean;

  //#endregion

  //#region Constructor

  constructor(name: string, title: string, description: string, isAssigned: boolean = false){
    this._isAssignedOrig = isAssigned;
    this._isAssigned = isAssigned;
    this.isGroupRole = false;
    this.relatedGroupName = "";
    this.name = name;
    this.title = title;
    this.description = description;
    this.isChanged = false;
  }

  //#endregion

  //#region Public Properties

  /**
   * Role name.
   */
  public name: string;

  /**
   * Role title.
   */
  public title: string;

  /**
   * Role description.
   */
  public description: string;

  /**
   * Determines if role is a group role.
   */
  public isGroupRole: boolean;

  /**
   * Determines if role is a permission role.
   */
  public relatedGroupName: string;

  /**
   * Determines if role has changed (assigned/unassigned).
   */
  public isChanged: boolean;

  /**
   * Determines if role is assigned.
   */
  public get isAssigned() : boolean {
    return this._isAssigned;
  }

  /**
   * Sets if role is assigned.
   */
  public set isAssigned(isAssigned : boolean) {
    this._isAssigned = isAssigned;
    this.isChanged = this._isAssignedOrig !== this._isAssigned;

    if(this.childChangedReceiver){
      this.childChangedReceiver.notifyChildChanged();
    }
  }

  /**
   * Child changed receiver.
   */
  public childChangedReceiver?: IChildChangedReceiver;

  //#endregion

  //#region Public Methods

  /**
   * Undo changes.
   */
  public undoChanges(){
    this.isAssigned = this._isAssignedOrig;
  }

  //#endregion
}