import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/services/guards/auth.guard';


export const routes: Routes = [
  {
    path: 'sec',
    loadChildren: () => import('./security/security.module').then(m => m.SecurityModule)
  },
  {
    path: '',
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  }

  // {
  //   path: '**', redirectTo:'', pathMatch: 'full',
  // }
];
 

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false, useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
