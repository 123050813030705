export abstract class LAYOUT_ROUTES{
  static readonly DASHBOARD: string = "dashboard";
  static readonly DASHBOARD_WELCOME: string = "dashboard/welcome";
  static readonly DASHBOARD_CUSTOMER_OVERVIEW: string = "dashboard/customer-overview";
  static readonly ACCOUNT: string = "account";
  static readonly ACCOUNT_PROFILE: string = "account/profile";
  static readonly ACCOUNT_SUPPORT_INFORMATION: string = "account/support-info";
  static readonly ACCOUNT_PRODUCTS: string = "account/products";
  static readonly ADMINISTRATION: string = "administration";
  static readonly ADMINISTRATION_CONFIGURATION: string = "administration/configuration";
  static readonly ADMINISTRATION_FILESTORAGE: string = "administration/file-storage";
  static readonly ADMINISTRATION_USER_MANAGEMENT: string = "administration/user-management";
  static readonly ADMINISTRATION_PUBLICATION_CATEGORIES: string = "administration/publication-categories";
  static readonly ADMINISTRATION_DATA_SYNCHRONIZATION: string = "administration/data-synchronization";
  static readonly SOFTWARE: string = "software";
  static readonly SOFTWARE_RELEASES: string = "software/software-releases";
  static readonly SOFTWARE_COMPATIBILITY: string = "software/software-compatibility";
  static readonly SOFTWARE_RELEASE_POLICY: string = "software/software-release-policy";
  static readonly HELP_CENTER: string = "help-center";
  static readonly HELP_CENTER_DOCUMENTATION: string = "help-center/documentation";
  static readonly HELP_CENTER_TRAINING_VIDEOS: string = "help-center/training-videos";
  static readonly HELP_CENTER_TRAINING_VIDEO: string = "help-center/training-video";
  static readonly LICENSE_AND_INSTALLATIONS: string = "license-and-installations";
  static readonly GENERAL_SEARCH: string = "general-search";
  static readonly MARKETING_RESOURCES = "marketing-resources";
  static readonly MARKETING_RESOURCES_DOCUMENTATION: string = "marketing-resources/documentation";
  static readonly MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEOS: string = "marketing-resources/marketing-material-videos";
  static readonly MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEO: string = "marketing-resources/marketing-material-video";
  static readonly AUDIT_TRAIL: string = "audit-trail";
  static readonly ADMINISTRATIVE_NOTIFICATIONS: string = "administrative-notifications";
  static readonly CUSTOMERS_OVERVIEW: string = "customers-overview";
  static readonly USER_GUIDE: string = "user-guide";
  static readonly FILE_DOWNLOAD: string = "file-download";
}
