
export abstract class CONSTANTS{
    
    static readonly MONTHS: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    static readonly ODATA_COUNT: string = "@odata.count";
    static readonly ODATA_CONTENT: string = "value";
    static readonly ODATA_DATE_TIME_FORMAT: string = "yyyy-MM-ddTHH:mm:ssZ";
    static readonly FILTER_DATE_TIME_FORMAT: string = "dd.MM.yyyy HH:mm:ss";
    static readonly MAX_FILE_DESCRIPTION_LENGTH: number = 5000;

    static DEPLOYMENT_TYPE = class {
        static readonly ON_PREMISE: string = "on-premise";
        static readonly CLOUD: string = "cloud";
    }

    static REGULAR_EXPRESSIONS = class {
      /**
       * RegExp for email validator borrowed from Angular implementation of EmailValidator
       */
      static readonly EMAIL: RegExp = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

      /**
       * RegExp for url validator borrowed from angularjs source code 
       */
      static readonly URL: RegExp = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;

      static readonly UPPERCASE_LETTERS: RegExp = /[A-Z]/g;

      static readonly LOWERCASE_LETTERS: RegExp = /[a-z]/g;

      static readonly DIGITS: RegExp = /[0-9]/g;

      static readonly SPECIAL_CHARACTERS: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    }

    /**
     * Language ISO 639-1 Codes for supported languages
     */
    static LANGUAGE_CODE = class{
        static readonly ENGLISH: string = "en";
        static readonly GERMAN: string = "de";
        static readonly FRENCH: string = "fr";
    }

    /**
     * Sidenav labels.
     */
    static SIDENAV = class{
        static readonly GENERAL_SEARCH: string = "general-search";
        static readonly ACCOUNT: string = "account";
        static readonly ACCOUNT_PROFILE: string = "account profile";
        static readonly ACCOUNT_SUPPORT_INFORMATION: string = "account support information";
        static readonly ACCOUNT_PRODUCTS: string = "account products";
        static readonly DASHBOARD: string = "dashboard";
        static readonly DASHBOARD_WELCOME: string = "welcome";
        static readonly DASHBOARD_CUSTOMER_OVERVIEW: string = "customer overview";
        static readonly LICENSE_AND_INSTALLATIONS: string = "license and installations";
        static readonly SOFTWARE: string = "software";
        static readonly SOFTWARE_RELEASES: string = "software releases";
        static readonly SOFTWARE_RELEASE_POLICY: string = "software release policy";
        static readonly SOFTWARE_COMPATIBILITY: string = "software compatibility";
        static readonly HELP_CENTER: string = "help center";
        static readonly HELP_CENTER_DOCUMENTATION: string = "documentation";
        static readonly HELP_CENTER_TRAINING_VIDEOS: string = "training videos";
        static readonly ADMNISTRATION: string = "administration";
        static readonly ADMNISTRATION_CONFIGURATION: string = "configuration";
        static readonly ADMNISTRATION_FILESTORAGE: string = "file storage";
        static readonly ADMNISTRATION_USER_MANAGEMENT: string = "user management";
        static readonly ADMINISTRATION_PUBLICATION_CATEGORIES: string = "publication categories";
        static readonly MARKETING_RESOURCES: string = "marketing resources";
        static readonly MARKETING_RESOURCES_DOCUMENTATION: string = "documentation";
        static readonly MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEOS: string = "marketing videos";
        static readonly AUDIT_TRAIL: string = "audit trail";
        static readonly ADMINISTRATIVE_NOTIFICATIONS: string = "administrative notifications";
        static readonly DATA_SYNCHRONIZATION: string = "data synchronization";
        static readonly CUSTOMERS_OVERVIEW: string = "customers overview";
        static readonly USER_GUIDE: string = "user guide";
    }

    /**
     * Permissions.
     */
    static PERMISSIONS = class{
      static readonly SUPPORT_INFO: string = "SUPPORT_INFO";
      static readonly SUPPORT_INFO_CUST: string = "SUPPORT_INFO_CUST";
      static readonly PRODUCT_RELEASE_INFO: string = "PRODUCT_RELEASE_INFO";
      static readonly PRODUCT_RELEASE_MANAGEMENT: string = "PRODUCT_RELEASE_MANAGEMENT";
      static readonly SOFTWARE_DOWNLOAD: string = "SOFTWARE_DOWNLOAD";
      static readonly PRODUCT_DOCS: string = "PRODUCT_DOCS";
      static readonly PRODUCT_DOCS_MANAGEMENT: string = "PRODUCT_DOCS_MANAGEMENT";
      static readonly SYSTEM_ACTIVITIES: string = "SYSTEM_ACTIVITIES";
      static readonly MARKETING_MATERIAL: string = "MARKETING_MATERIAL";
      static readonly ADMIN_NOTIFICATIONS: string = "ADMIN_NOTIFICATIONS";
      static readonly SYSTEM_SETTINGS: string = "SYSTEM_SETTINGS";
      static readonly AUDIT_TRAIL: string = "AUDIT_TRAIL";
      static readonly SYNC_COMPANIES: string = "SYNC_COMPANIES";
      static readonly SYNC_PRODUCT_DOCS: string = "SYNC_PRODUCT_DOCS";
      static readonly SYNC_USER_GROUPS: string = "SYNC_USER_GROUPS";
      static readonly FILE_STORAGE_MANAGEMENT: string = "FILE_STORAGE_MANAGEMENT";
      static readonly USER_MANAGEMENT: string = "USER_MANAGEMENT";
      static readonly USER_GROUP_MANAGEMENT: string = "USER_GROUP_MANAGEMENT";
      static readonly CUSTOMERS_OVERVIEW: string = "CUSTOMERS_OVERVIEW";
    }

    static ROLES = class{
      static readonly CUST_USER: string = "CUST_USER";
      static readonly CUST_ADMIN: string = "CUST_ADMIN";
      static readonly BUS_PARTNER_USER: string = "BUS_PARTNER_USER";
      static readonly INT_USER: string = "INT_USER";
      static readonly INT_CONSULTANT: string = "INT_CONSULTANT";
      static readonly INT_ADMIN: string = "INT_ADMIN";
      static readonly INT_SUPER_ADMIN: string = "INT_SUPER_ADMIN";
      static readonly INT_PRODUCT_MANAGER: string = "INT_PRODUCT_MANAGER";
      static readonly INTERESTED_PARTY_USER: string = "INTERESTED_PARTY_USER";
    }

    /**
     * Server error codes
     */
    static SERVER_ERROR_CODES = class {

        static readonly CONFIGURATION_ERROR: number = 1;
        static readonly UNKNOWN_PRODUCT_NAME: number = 2;
        static readonly INVALID_PRODUCT_RELEASE_STATUS: number = 3;
        static readonly EMAIL_SEND_FAILED: number = 4;
        static readonly CORE_DATA_SYNC_IN_PROGRESS: number = 5;
        static readonly CATEGORY_NOT_ELIGIBLE_FOR_DELETION: number = 6;
        static readonly DOCS_SYNC_IN_PROGRESS: number = 7;
        static readonly LOGIN_FAILED: number = 8;
        static readonly AUTHENTICATION_METHOD_TEMPORARELY_UNAVAILABLE: number = 9;
        static readonly TWO_STEP_VERIFICATION_CODE_EXPIRED_OR_INVALID: number = 10;
        static readonly EMAIL_NOT_CONFIRMED: number = 11;
        static readonly UNABLE_TO_CONFIRM_EMAIL: number = 12;
        static readonly EMAIL_ALREADY_CONFIRMED: number = 13;
        static readonly EMAIL_CONFIRMATION_LINK_INVAILD: number = 14;
        static readonly UNABLE_TO_REGISTER_USER: number = 15;
        static readonly USER_ALREADY_REGISTERED: number = 16;
        static readonly USER_REGISTRATION_CONFIRMATION_LINK_INVALID: number = 17;
        static readonly USER_REGISTRATION_ALREADY_CONFIRMED: number = 18;
        static readonly UNABLE_TO_CONFIRM_USER_REGISTRATION: number = 19;
        static readonly UNABLE_TO_GENERATE_AUTHENTICATOR_KEY: number = 20;
        static readonly UNABLE_TO_REGISTER_AUTHENTICATOR: number = 21;
        static readonly UNABLE_TO_SEND_2FA_RECOVERY_CODE: number = 22;
        static readonly TWO_FACTOR_AUTHENTICATION_CODE_INVALID: number = 23;
        static readonly AUTHENTICATION_METHOD_NOT_SUPPORTED: number = 24;
        static readonly WRONG_AUTHENTICATION_METHOD_SPECIFIED: number = 25;
        static readonly TWO_FACTOR_RECOVERY_CODE_INVALID: number = 26;
        static readonly TWO_FACTOR_RECOVERY_VALIDATION_FAILED: number = 27;
        static readonly UNABLE_TO_CREATE_FIDO2_CREDENTIAL_CREATION_OPTIONS: number = 28;
        static readonly UNABLE_TO_CREATE_FIDO2_CREDENTIALS: number = 29;
        static readonly UNABLE_TO_CREATE_FIDO2_CREDENTIAL_ASSERTION_OPTIONS: number = 30;
        static readonly FIDO2_CREDENTIAL_INVALID: number = 31;
        static readonly PASSWORD_TOO_WEAK: number = 32;
        static readonly USER_IS_DISABLED: number = 33;
        static readonly USER_IS_LOCKED: number = 34;
        static readonly INVALID_SECURITY_CONFIGURATION: number = 35;
        static readonly INVALID_PASSWORD_RESET_TOKEN: number = 36;
        static readonly PUBLICATION_CATEGORY_IS_PREDEFINED: number = 38;
        static readonly PUBLICATION_CATEGORY_IS_USED: number = 39;
        static readonly USER_REGISTRATION_UNKNOWN_USER: number = 40;
        static readonly TAG_NOT_ELIGIBLE_FOR_DELETION: number = 41;
        static readonly INVALID_CURRENT_PASSWORD: number = 42;
        static readonly PASSWORD_POLICY_NOT_MET: number = 43;
        static readonly FILE_NOT_ELIGIBLE_FOR_DELETION: number = 44;
        static readonly DIRECTORY_NOT_ELIGIBLE_FOR_DELETION: number = 45;
    }

    static SERVER_WARNING_CODES = class {
      
      static readonly RELEASE_VERSION_UPDATED: number = 1;
    }

    /**
     * Product release statuses
     */
    static PRODUCT_RELEASE = class {

      static readonly FEATURE_IMAGE_EDGE_MAX_PX: number = 1500;
      static readonly FEATURE_IMAGE_EDGE_MIN_PX: number = 20;

      static STATUSES = class {
        static readonly BETA: number = 1;
        static readonly RELEASE_CANDIDATE: number = 2;
        static readonly RELEASED: number = 3;
      }

      static PDF_RECREATION = class {

        static PRODUCT_AND_VERSION_OPTIONS = class {
          static readonly ALL: string = "All";
          static readonly LATEST: string = "Latest";
        }

        static PDF_RECREATION_TYPE = class {
          static readonly ALL_PRODUCTS_ALL_RELEASES: number = 0;
          static readonly ALL_PRODUCTS_LATEST_RELEASE: number = 1;
          static readonly SPECIFIC_PRODUCT_ALL_RELEASES: number = 2;
          static readonly SPECIFIC_PRODUCT_AND_RELEASE: number = 3;
        }
      }

      static INSTALLATIONS_STORAGE = class {

        static STORAGE_TYPES = class {
          static readonly SOFTWARE_DOWNLOADS: string = "softwaredownloads";
          static readonly THIRD_PARTY_PRODUCT_HOTFIXES: string = "thirdpartyproducthotfixes";
        }

        static PATH_SEGMENTS = class {
          static readonly BETA: string = "beta";
          static readonly RELEASE_CANDIDATE: string = "rc";
          static readonly RELEASE: string = "release";
        }
      }
    }

    /**
     * Names of application configuration parameters
     */
    static CONFIGURATION_PARAMETERS = class {

      static CORE_DATA_SYNC = class {
          static readonly IS_ENABLED: string = "CoreDataSync:IsEnabled";
          static readonly DAILY_START_TIME: string = "CoreDataSync:Daily:StartTime";
          static readonly CONNECTION_STRING: string = "CoreDataSync:DatabaseConnectionString";
      }

      static FILE_STORAGE = class {
          static readonly SHAREPOINT_SITE_URL: string = "FileStorage:SiteUrl";
          static readonly SITE_NAME: string = "FileStorage:SiteName";
          static readonly TENANT_ID: string = "FileStorage:TenantId";
          static readonly CLIENT_ID: string = "FileStorage:ClientId";
          static readonly CERTIFICATE_THUMBPRINT: string = "FileStorage:ClientCertificateThumbprint";
          static readonly ROOT_DIRECTORY: string = "FileStorage:RootDirectory";
          static readonly VIDEO_THUMBNAIL_TIME: string = "FileStorage:VideoThumbnailTime";
      }

      static SOFTWARE_DOWNLOAD = class {
          static readonly SHAREPOINT_SITE_URL: string = "SoftwareDownload:SiteUrl";
          static readonly SITE_NAME: string = "SoftwareDownload:SiteName";
          static readonly TENANT_ID: string = "SoftwareDownload:TenantId";
          static readonly CLIENT_ID: string = "SoftwareDownload:ClientId";
          static readonly CERTIFICATE_THUMBPRINT: string = "SoftwareDownload:ClientCertificateThumbprint";
      }

      static SMTP = class {
          static readonly SERVER_ADDRESS: string = "Smtp:ServerAddress";
          static readonly SERVER_PORT_NUMBER: string = "Smtp:ServerPortNumber";
          static readonly ACCOUNT_NAME: string = "Smtp:AccountName";
          static readonly ACCOUNT_PASSWORD: string = "Smtp:AccountPassword";
          static readonly SENDER_EMAIL: string = "Smtp:SenderEmailAddress";
      }

      static PRODUCT_DOCS = class {
        static readonly INTRO_VIDEO_URL: string = "ProductDocs:IntroVideoUrl";
      }

      static PRODUCT_DOCS_SYNC = class {
        static readonly IS_ENABLED: string = "ProductDocsSync:IsEnabled";
        static readonly DOCU_REPO_URL: string = "ProductDocsSync:DocuRepoUrl";
        static readonly DOCU_REPO_USERNAME: string = "ProductDocsSync:DocuRepoUsername";
        static readonly DOCU_REPO_USER_PASSWORD: string = "ProductDocsSync:DocuRepoUserPassword";
        static readonly DAILY_START_TIME: string = "ProductDocsSync:Daily:StartTime";
      }

      static SEARCH = class {
          static readonly SEARCH_INDEX_NAME: string = "Search:IndexName";
          static readonly SEARCH_APP_ID: string  = "Search:AppId";
          static readonly SEARCH_API_KEY: string = "Search:ApiKey";
      }

      static SECURITY = class {
        static readonly AUTH_COOKIE_LIFETIME: string = "Security:AuthCookieLifetime";
        static readonly EMAIL_CONFIRMATION_LINK_LIFETIME: string  = "Security:EmailConfirmationLinkLifetime";
        static readonly TWO_STEP_VERIFICATION_CODE_LIFETIME: string = "Security:TwoStepVerificationCodeLifetime";
        static readonly PASSWORD_RESET_LINK_LIFETIME: string = "Security:PasswordResetLinkLifetime";

        static ENCRYPTION = class {
          static readonly COOKIE_ENCRYPTION_KEY_INFO: string = "Security:Encryption:CookieEncryptionKeyInfo";
          static readonly ENCRYPTION_CERTIFICATE_INFO: string = "Security:Encryption:EncryptionCertificateInfo";
        }

        static PASSWORD_POLICY = class{
          static readonly MIN_LENGTH: string = "Security:PasswordPolicy:MinimalLength";
          static readonly MIN_NO_OF_UPPERCASE_LETTERS: string = "Security:PasswordPolicy:MinimalNumberOfUppercaseLetters";
          static readonly MIN_NO_OF_LOWERCASE_LETTERS: string = "Security:PasswordPolicy:MinimalNumberOfLowercaseLetters";
          static readonly MIN_NO_OF_DIGITS: string = "Security:PasswordPolicy:MinimalNumberOfDigits";
          static readonly MIN_NO_OF_SPECIAL_CHARS: string = "Security:PasswordPolicy:MinimalNumberOfSpecialCharacters";
        }

        static MICROSOFT_ENTRA_ID = class{
          static readonly TENANT_ID: string = "Security:MicrosoftEntraID:TenantId";
          static readonly CLIENT_ID: string = "Security:MicrosoftEntraID:ClientId";
          static readonly CLIENT_SECRET: string = "Security:MicrosoftEntraID:ClientSecret";
        }

        static USER_LOCKOUT = class {
          static readonly MAX_FAILED_ATTEMPTS: string = "Security:UserLockout:MaximalFailedLoginAttempts";
          static readonly LOCKOUT_DURATION: string = "Security:UserLockout:LockoutDuration";
        }

        static CRM_USERS_PASSWORD_UPDATE = class {
          static readonly AUTH_URL: string = "Security:CrmUserPasswordUpdate:AuthUrl";
          static readonly PASSWORD_UPDATE_URL: string = "Security:CrmUserPasswordUpdate:PasswordUpdateUrl";
          static readonly CLIENT_ID: string = "Security:CrmUserPasswordUpdate:ClientId";
          static readonly CLIENT_SECRET: string = "Security:CrmUserPasswordUpdate:ClientSecret";
        }

        static FIDO2 = class{
          static readonly SERVER_NAME: string = "Security:FIDO2:ServerName";
          static readonly SERVER_DOMAIN: string = "Security:FIDO2:ServerDomain";
          static readonly ORIGINS: string = "Security:FIDO2:Origins";
          static readonly TIMEOUT: string = "Security:FIDO2:Timeout";
          static readonly TIMESTAMP_DRIFT_TOLLERANCE: string = "Security:FIDO2:TimestampDriftTolerance";
          static readonly CHALENGE_SIZE: string = "Security:FIDO2:ChallengeSize";
          static readonly BACKUP_ELIGIBLE_CREDENTIAL_POLICY: string = "Security:FIDO2:BackupEligibleCredentialPolicy";
          static readonly BACKED_UP_CREDENTIAL_POLICY: string = "Security:FIDO2:BackedUpCredentialPolicy";
          
        }

        static CSP = class {
          static readonly DEFAULT_SOURCES: string = "Security:CSP:DefaultSources";
          static readonly STYLE_SOURCES: string = "Security:CSP:StyleSources";
          static readonly FONT_SOURCES: string = "Security:CSP:FontSources";
          static readonly IMAGE_SOURCES: string = "Security:CSP:ImageSources";
          static readonly OBJECT_SOURCES: string = "Security:CSP:ObjectSources";
          static readonly FRAME_SOURCES: string = "Security:CSP:FrameSources";
          static readonly CONNECT_SOURCES: string = "Security:CSP:ConnectSources";
          static readonly MEDIA_SOURCES: string = "Security:CSP:MediaSources";
          static readonly SCRIPT_SOURCES: string = "Security:CSP:ScriptSources";
        }
      }

      static USER_GROUPS_SYNC = class {
        static readonly SYNC_TYPE: string = "UserGroupsSync:SyncType";
        static readonly IS_ENABLED: string = "UserGroupsSync:IsEnabled";
        static readonly TENANT_ID: string = "UserGroupSync:MicrosoftEntraID:TenantId";
        static readonly CLIENT_ID: string = "UserGroupSync:MicrosoftEntraID:ClientId";
        static readonly CLIENT_CERTIFICATE_THUMBPRINT: string = "UserGroupSync:MicrosoftEntraID:ClientCertificateThumbprint";
        static readonly HOURLY_SYNC_INTERVAL: string = "UserGroupsSync:Hourly:Interval";
        static readonly DAILY_SYNC_START_TIME: string = "UserGroupsSync:Daily:StartTime";
        static readonly SPECIFIC_DAYS_SYNC_START_TIME: string = "UserGroupsSync:SpecificDays:StartTime";
        static readonly SPECIFIC_DAYS_SYNC_MONDAY_ON: string = "UserGroupsSync:SpecificDays:MondayOn";
        static readonly SPECIFIC_DAYS_SYNC_TUESDAY_ON: string = "UserGroupsSync:SpecificDays:TuesdayOn";
        static readonly SPECIFIC_DAYS_SYNC_WEDNESDAY_ON: string = "UserGroupsSync:SpecificDays:WednesdayOn";
        static readonly SPECIFIC_DAYS_SYNC_THURSDAY_ON: string = "UserGroupsSync:SpecificDays:ThursdayOn";
        static readonly SPECIFIC_DAYS_SYNC_FRIDAY_ON: string = "UserGroupsSync:SpecificDays:FridayOn";
        static readonly SPECIFIC_DAYS_SYNC_SATURDAY_ON: string = "UserGroupsSync:SpecificDays:SaturdayOn";
        static readonly SPECIFIC_DAYS_SYNC_SUNDAY_ON: string = "UserGroupsSync:SpecificDays:SundayOn";
      }

      static DATA_CLEANUP = class {

        static AUDIT_TRAILS = class {
          static readonly DAILY_START_TIME: string = "AuditTrailCleanup:Daily:StartTime";
          static readonly MAX_AGE: string = "AuditTrailCleanup:MaxAge";
        }

        static USER_ACTIVITIES = class {
          static readonly DAILY_START_TIME: string = "UserActivitiesCleanup:Daily:StartTime";
          static readonly MAX_AGE: string = "UserActivitiesCleanup:MaxAge";
        }

        static DATA_SYNC_LOGS = class {
          static readonly DAILY_START_TIME: string = "DataSyncLogsCleanup:Daily:StartTime";
          static readonly MAX_AGE: string = "DataSyncLogsCleanup:MaxAge";
        }
      }

      static COMMON = class {
        static SUBCONTRACT_GRACE_PERIOD: string = "Common:SubcontractGracePeriod";
        static SERVICE_MANAGED_IDENTITY_CLIENT_ID: string = "Common:ServiceManagedIdentityClientId";
        static PRODUCT_RELEASE_LEASE_DURATION: string = "Common:ProductReleaseLeaseDuration";
      }
    }

    /**
     * Configuration parameter values.
     */
    static CONFIGURATION_PARAMETERS_VALUES = class {
      static SECURITY = class {
        static FIDO2 = class {
          static BACKUP_ELIGIBLE_CREDENTIAL_POLICY_OPTIONS = class{
            static readonly ALLOWED: string = "allowed";
            static readonly REQUIRED: string = "required";
            static readonly DISALLOWED: string = "disallowed";
           }

           static BACKUP_UP_CREDENTIAL_POLICY_OPTIONS = class{
            static readonly ALLOWED: string = "allowed";
            static readonly REQUIRED: string = "required";
            static readonly DISALLOWED: string = "disallowed";
           }
        }
      }
    }

    /**
     * Document related properties.
     */
    static DOCUMENT = class {

        static readonly GUID_QUERY_PARAMETER: string = "?FileGuid=";

        static PUBLICATION_TYPES = class {
            static readonly HTML: string = "HTML5";
            static readonly PDF: string = "PDF";
        }
        
        static CATEGORIES = class {
            static readonly ADMINISTRATOR_GUIDE: string = "ADM";
            static readonly API_DOCUMENTATION: string = "API";
            static readonly CONFIGURATION_GUIDE: string = "CNF";
            static readonly DESIGNER_GUIDE: string = "DES";
            static readonly DEVELOPMENT_GUIDE: string = "DEV";
            static readonly HOW_TO: string = "HOW";
            static readonly INSTALATION_GUIDE: string = "INS";
            static readonly USER_GUIDE: string = "USG";
            static readonly WHATS_NEW: string = "NEW";
        }
    }

    /**
     * Topics for publisher subscriber communication.
     */
    static TOPICS = class {
        static readonly UPDATE_TOOLBAR: string = "UpdateToolbar";
        static readonly TOOLBAR_TITLE_CLICKED: string = "ToolbarTitleClicked";
        static readonly CHECK_FAVORITE: string = "CheckFavorite";
        static readonly UPDATE_SEARCH_FILTER_OPTION: string = "UpdateSearchFilterOption";
        static readonly ADDING_FAVORITE: string = "AddingFavorite";
        static readonly SHARING_PAGE: string = "SharingPage";
        static readonly CONFIRM_SHARE_PAGE: string = "ConfirmSharePage";
        static readonly CONFIRM_FAVORITE: string = "ConfirmFavorite";
        static readonly DELETE_FAVORITE: string = "DeleteFavorite";
        static readonly OPEN_FAVORITE_DOCUMENT: string = "OpenFavoriteDocument";
        static readonly SEND_SEARCH_TERM_WITH_FILTERS: string = "SendSearchTermWithFilters";
        static readonly DOCUMENT_PRODUCT_VERSION_CHANGED: string = "DocumentProductVersionChanged";
        static readonly UPDATE_DOCUMENT_PRODUCT_VERSION: string = "UpdateDocumentProductVersion";
        static readonly RATING_SUMMARY: string = "RatingSummary";
    }

    static QUERY_PARAMS = class {
      static readonly SEARCH_TERM: string = "searchTerm";
      static readonly SEARCH_AREA: string = "area";
      static readonly PRODUCT: string = "product";
      static readonly PRODUCT_VERSION: string = "productVersion";
      static readonly RELATIVE_PATH: string = "relativePath";
      // Currently docs only
      static readonly CATEGORY: string = "category";
      // Currently text, code snippet and video
      static readonly HELP_CENTER_CONTENT_TYPE: string = "helpCenterContentType";
      // Currently text and video
      static readonly MARKETING_RESOURCES_CONTENT_TYPE: string = "marketingResourceContentType";
      // Currently product releases only (bug fix, feature, migration, download, prerequisite, third party hot fix)
      static readonly CONTENT_SUB_TYPE: string = "contentSubType";
      static readonly LANGUAGE: string = "language";
      static readonly SKIP: string = "skip";
      static readonly FILE_CATEGORY: string = "fileCategory";
      static readonly FILE_FORMAT: string = "fileFormat";
      static readonly OPENED_DOCS_SEARCH: string = "openedDocsSearch";
      static readonly IGNORE: string = "ignore";
      static readonly DOWNLOAD_LINK_ID: string = "downloadLinkId";
      static readonly POST_LOGIN_ROUTE: string = "postLoginRoute";
      static readonly FILE_GUID: string = "fileGuid";
      static readonly VIDEO_TYPE: string = "videoType";
      
    }

    static QUERY_PARAM_VALUES = class {
      static readonly FILE_CATEGORY_GENERAL: string = "general";
      static readonly FILE_CATEGORY_MARKETING: string = "marketing";
    }

    static EXTERNAL_ACTIONS = class {
      static readonly DOWNLOAD_FILE: string = "downloadFile";
    }

    static SEARCH = class {
        static readonly SEARCH_TERM_MINIMUM_LENGTH: number = 3;
        static readonly ALL: string = "All";
        static readonly NONE: string = "none";
        static readonly COMMON: string = "Common";

        static PAGE_SIZES = class {
            static readonly GENERAL_SEARCH: number = 20;
            static readonly HELP_CENTER_DOCUMENTATION: number = 10;
            static readonly HELP_CENTER_TRAINING_VIDEOS: number = 20;
            static readonly MARKETING_DOCUMENTATION: number = 20;
            static readonly MARKETING_MATERIAL_VIDEOS: number = 20;
            static readonly RELATED_VIDEOS: number = 10;
        }

        static FILTERS = class {
            static readonly SEARCH_RECORD_TYPE: string = "searchRecordType:";
            static readonly SEARCH_RECORD_SUBTYPE: string = "searchRecordSubtype:";
            static readonly PRODUCT_NAME: string = "productName:";
            static readonly PRODUCT_SHORT_NAME: string = "productShortName:";
            static readonly PRODUCT_VERSION: string = "productVersion:";
            static readonly IS_LATEST: string = "isLatest:";
            static readonly DOCUMENT_CATEGORY: string = "documentCategory:";
            static readonly FILE_FORMAT: string = "fileFormat:";
            static readonly LANGUAGE: string = "language:";
            static readonly CONTENT_TYPE: string = "contentType:";
            static readonly HELP_CENTER_CONTENT_TYPE: string = "helpCenterContentType:";
            static readonly MARKETING_RESOURCE_CONTENT_TYPE: string = "marketingResourceContentType:";
            static readonly IS_EXTERNALLY_VISIBLE: string = "isExternallyVisible:";
        }

        static SEARCH_RESULT_LINK_TYPES = class {
            static readonly URL: number = 0;
            static readonly NAME: number = 1;
        }

        static SEARCH_RECORD_TYPES = class {
            static readonly HELP_CENTER: number = 0;
            static readonly PRODUCT_RELEASES: number = 1;
            static readonly MARKETING_RESOURCE: number = 2;
        }

        static SEARCH_RECORD_SUBTYPES = class {
            static readonly ALL: number = -1;
            static readonly BUG_FIX: number = 0;
            static readonly FEATURE: number = 1;
            static readonly MIGRATION: number = 2;
            static readonly DOWNLOAD: number = 3;
            static readonly PREREQUISITE: number = 4;
            static readonly THIRD_PARTY_HOT_FIX: number = 5;
            static readonly MARKETING_MATERIAL_VIDEO: number = 6;
            static readonly TRAINING_VIDEO: number = 7;
        }

        static SEARCH_RECORD_CONTENT_TYPES = class {
            static readonly TEXT: number = 0;
            static readonly TABLE: number = 1;
            static readonly CODE_SNIPPET: number = 2;
            static readonly VIDEO: number = 3;
        }

        static SEARCH_RECORD_CONTENT_OPTIONS = class {
            static readonly TEXT: string = "Text";
            static readonly CODE_SNIPPET: string = "Code Snippet";
            static readonly VIDEO: string = "Video";
        }

        static SEARCH_AREA_ID = class {
            static readonly ALL: number = 0;
            static readonly HELP_CENTER: number = 1;
            static readonly PRODUCT_RELEASES: number = 2
            static readonly MARKETING_RESOURCES: number = 3;
            static readonly HELP_CENTER_DOCUMENTATION: number = 4;
            static readonly HELP_CENTER_TRAINING_VIDEOS: number = 5;
            static readonly MARKETING_RESOURCES_DOCUMENTATION: number = 6;
            static readonly MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEOS: number = 7;
        }

        static VIDEO_CATEGORIES = class {
          static readonly ALL: string = "All";
          static readonly TRAINING_VIDEO: string = "Training Video";
          static readonly MARKETING_MATERIAL_VIDEO: string = "Marketing Material Video";
        }
    }

    static USER_FAVORITE_CATEGORIES = class {
        static readonly RELEASE_NOTES: string = "Release Notes";
        static readonly SOFTWARE_COMPATIBILITY: string = "Software Compatibility";
        static readonly PRODUCT_DOCUMENTATION: string = "Product Documentation";
        static readonly MARKETING_MATERIAL: string = "Marketing Material";
        static readonly VIDEO_FILE: string = "Video File";
    }

    /**
     * Language ISO 639-1 Codes of supported languages for marketing documents.
     */
    static FILE_LANGUAGE_CODES = class {
        static readonly ENGLISH: string = "en";
        static readonly GERMAN: string = "de";
        static readonly FRENCH: string = "fr";
        static readonly ITALIAN: string = "it";
    }

    static AUTHENTICATION_METHODS = class {
      static readonly PASSWORD: number = -1;
      static readonly DEFAULT: number = 0;
      static readonly TWO_STEP_VERIFICATION: number = 1;
      static readonly TWO_FACTOR_AUTHENTICATION: number = 2;
      static readonly FIDO2_AUTHENTICATION: number = 3;
    }

    static USER_ACTIVITIES = class {

      static API_FILTER_OPTIONS = class {
        static readonly ALL: string = "All";
      }

      static readonly DEFAULT_DATE_RANGE_IN_DAYS: number = 14;
      //static readonly ALL_OPTION: string = "All";

      static CATEGORIES = class {
        static readonly LOGIN: string = "Login";
        static readonly PRODUCT_RELEASE_DOWNLOAD: string = "ProductReleaseDownload";
        static readonly PRODUCT_RELEASE_NOTES: string = "ProductReleaseNotes";
        static readonly PRODUCT_DOCUMENTATION: string = "ProductDocumentation";
        static readonly MARKETING_MATERIAL_DOWNLOAD: string = "MarketingMaterialDownload";
        static readonly PRODUCT_DOCUMENTATION_SEARCH: string = "ProductDocumentationSearch";
        static readonly PRODUCT_RELEASE_NOTES_SEARCH: string = "ProductReleaseNotesSearch";
        static readonly MARKETING_MATERIAL_SEARCH: string = "MarketingMaterialSearch";
        static readonly HELP_CENTER_TRAINING_VIDEO: string = "HelpCenterTrainingVideo";
        static readonly MARKETING_MATERIAL_VIDEO: string = "MarketingMaterialVideo";
      }

      static CATEGORY_TYPES = class {
          static readonly NOT_PRODUCT_RELATED: number = 0;
          static readonly CONDITIONALLY_PRODUCT_RELATED: number = 1;
          static readonly PRODUCT_RELATED: number = 2;
      }

      static BUSINESS_PARTNER_SPECIFIC_OPTIONS = class {
        static readonly ALL: string = "All (Summarized)";
      }

      static CATEGORY_SPECIFIC_OPTIONS = class {
        static readonly ALL: string = "All";
      }

      static PRODUCT_SPECIFIC_OPTIONS = class {
          static readonly ALL: string = "AllOptions (Summarized)";
          static readonly ALL_PRODUCTS: string = "All Products";
          static readonly NOT_PRODUCT_RELATED: string = "Not Product Related";
      }

      static SELECTED_FILTER_OPTIONS = class {
        static readonly ALL: number = 0;
        static readonly ALL_CATEGORIES: number = 1;
        static readonly MULTIPLE_CATEGORIES: number = 2;
        static readonly SELECT_CATEGORY: number = 3;
        static readonly ALL_PRODUCTS: number = 4;
        static readonly MULTIPLE_PRODUCTS: number = 5;
        static readonly NOT_RELATED_TO_PRODUCT: number = 6;
        static readonly SELECT_PRODUCT: number = 7;
        static readonly MULTIPLE_OPTIONS: number = 8;
      }

      static CHART = class {
        // NOTE: New major unit value can be added when maximum count for some user activity greatly exceeds (more than 10x) maximum value in the array.
        static readonly AVAILABLE_MAJOR_UNITS_FOR_VALUE_AXIS: number[] = [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000];
        static readonly APPROXIMATE_NUMBER_OF_VALUE_AXIS_LABLES: number = 5;
      }
    }

    static SYNC_TYPES = class {
      static readonly HOURLY: number = 0;
      static readonly DAILY: number = 1;
      static readonly SPECIFIC_DAYS_OF_WEEK: number = 2;
    }

    static AUDIT_TRAIL = class {

      static readonly ACTION_TYPE_NAME: string = "actionTypeName";
      static readonly SYSTEM_CORE_DATA_SYNC: string = "System - Core Data Sync";

      static ACTION_TYPES = class {
        static readonly ALL: string = "All";
        static readonly USER_CHANGE: string = "UserChange";
        static readonly USER_GROUP_CHANGE: string = "UserGroupChange";
        static readonly USER_GROUP_IMPORT_CHANGE: string = "UserGroupImportChange";
      }

      static UPDATED_USER_PROPERTY_NAMES = class {
          static readonly FIRST_NAME: string = "First Name";
          static readonly LAST_NAME: string = "Last Name";
          static readonly USERNAME: string = "Username";
          static readonly EMAIL: string = "Email";
          static readonly PHONE_NUMBER: string = "Phone";
          static readonly DEFAULT_AUTH_METHOD: string = "Default Auth Method";
          static readonly IS_ENABLED: string = "Is Enabled";
          static readonly IS_LOCKED: string = "Is Locked";
          static readonly TWO_FA_ENABLED: string = "2FA Enabled";
          static readonly TWO_SV_ENABLED: string = "2SV Enabled";
          static readonly FIDO2_ENABLED: string = "FIDO2 Enabled";
      }

      static UPDATED_USER_PROPERTY_VALUES = class {
          static readonly DEFAULT: string = "default";
          static readonly TWO_FA: string = "2FA";
          static readonly TWO_SV: string = "2SV";
          static readonly FIDO2: string = "FIDO2";
          static readonly TRUE: string = "true";
          static readonly FALSE: string = "false";
      }

      static AUTHENTICATION_METHODS = class {
          static readonly DEFAULT: number = 0;
          static readonly TWO_STEP_VERIFICATION: number = 1;
          static readonly TWO_FACTOR_AUTHENTICATION: number = 2;
          static readonly FIDO2: number = 3;
      }

      static PERMISSIONS_ROLES_AND_USER_GROUPS_UPDATE_DESCRIPTORS = class {
          static readonly ADDED: string = "Added";
          static readonly REMOVED: string = "Removed";
      }
    }

    static CUSTOMERS_OVERVIEW = class {

      static SUPPORTED_PRODUCTS = class {

        static FILTER_OPTIONS = class {

          static PRODUCTS = class {
            static readonly ALL: string = "All";
          }
  
          static PRODUCT_CATEGORIES = class {
            static readonly ALL: string = "All";
          }
  
          static SLA_TYPES = class {
            static readonly ALL: string = "All";
          }
  
          static SUBCONTRACT_VALIDITY = class {
            static readonly ALL: string = "All";
            static readonly CURRENTLY_VALID: string = "Valid";
            static readonly CURRENTLY_NOT_VALID: string = "Not Valid";
          }
        }
  
        static SELECTED_FILTER_OPTIONS = class {
          static readonly ALL_PRODUCTS: number = 0;
          static readonly MULTIPLE_PRODUCTS: number = 1;
          static readonly SELECT_PRODUCT: number = 2;
          static readonly ALL_PRODUCTS_CATEGORIES: number = 3;
          static readonly MULTIPLE_PRODUCT_CATEGORIES: number = 4;
          static readonly SELECT_PRODUCT_CATEGORY: number = 5;
        }
      }
    }

    static ADMINISTRATIVE_NOTIFICATIONS = class {

      static readonly NOTIFICATION_TYPE_NAME: string = "notificationTypeName";
      static readonly NOTIFICATION_IS_CONFIRMED: string = "isConfirmed";
      static readonly NOTIFICATION_IS_PROCESSED: string = "isProcessed";

      static TYPES = class {
        static readonly ALL: string = "All";
        static readonly PERMISSIONS_CHANGE_REQUEST: string = "PermissionsChangeRequest";
        static readonly ROLES_CHANGE_REQUEST: string = "RolesChangeRequest";
        static readonly USER_REGISTRATION_REQUEST: string = "UserRegistrationRequest";
      }

      static IS_CONFIRMED_VALUES = class {
        static readonly ALL = undefined;
        static readonly CONFIRMED: boolean = true;
        static readonly DENIED: boolean = false;
        static readonly NOT_PROCESSED = null;
      }
    }

    static DATA_SYNCHRONIZATION = class {

      static readonly DATA_SYNC_RUN_ID: string = "dataSyncRunId";
      static readonly DATA_SYNC_RUN_STATUS_NAME: string = "dataSyncRunStatusName";
      static readonly DATA_SYNC_LOG_LEVEL_NAME: string = "dataSyncLogLevelName";

      static STATUSES = class {
        static readonly ALL: string = "All";
        static readonly INFO: string = "Info";
        static readonly WARNING: string = "Warning";
        static readonly ERROR: string = "Error";
      }

      static TYPES = class {
        static readonly PRODUCT_DOCUMENTATION_SYNC: string = "ProductDocumentationSync";
        static readonly CORE_DATA_SYNC: string = "CoreDataSync";
        static readonly USER_GROUPS_SYNC: string = "UserGroupsSync";
      }

      static TAB_INDEXES = class {
        static readonly PRODUCT_DOCUMENTATION: number = 0;
        static readonly CORE_DATA: number = 1;
        static readonly USER_GROUPS: number = 2;
      }
    }

    static USER_MANAGEMENT = class {
      static readonly IS_ADMIN: string = "isAdmin";
    }

    static BOOLEAN_FILTER_VALUES = class {
      static readonly ALL = undefined;
      static readonly YES: boolean = true;
      static readonly NO: boolean = false;
    }

    static FILE_SUBCATEGORIES = class {
      static readonly MARKETING_MATERIAL_VIDEO: string = "Marketing Material Video";
      static readonly TRAINING_VIDEO: string = "Training Video";
    }

    static LOGIN_PROVIDERS = class {
      static readonly MICROSOFT: string = "Microsoft";
    }

    static GRID = class {
      static readonly PAGE_SIZE: number = 100;
    }

    static PRIVACY_POLICY = class {

      static LINKS = class {
        static readonly ENGLISH: string = "https://opal-holding.com/en/data-protection/#pll_switcher";
        static readonly GERMAN: string = "https://opal-holding.com/datenschutz/";
        static readonly FRENCH: string = "https://opal-holding.com/fr/protection-des-donnees/";
      }
    }

    static USER_RATINGS = class {

      static CATEGORIES = class {
        static readonly HTML_PRODUCT_DOCUMENTATION: string = "HTML Product Documentation";
        static readonly MARKETING_DOCUMENTATION: string = "Marketing Documentation";
      }

      static RATING_DETAILS = class {
        static readonly PAGE_SIZE: number = 20;
      }
    }

    static TRANSLATION_PLACEHOLDERS = class {
      static readonly USER_FULL_NAME = "{user_full_name}";
    }
}
