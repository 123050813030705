import { Injectable } from "@angular/core";
import { PasswordPolicy } from "src/app/api/opal-partner-center/models";
import { CONSTANTS } from "../../constants";
import { PasswordValidationResult } from "../../model/password-validation-result";


/**
 * @description Password validation service.
 */
@Injectable({
  providedIn: 'root'
})
export class PasswordValidationService {

  //#region Public Methods

  /**
   * Validates the password.
   * @param password Password to validate.
   * @param passwordPolicy Password policy.
   * @returns Password validation result.
   */
  public validatePassword(password: string, passwordPolicy: PasswordPolicy): PasswordValidationResult{

    var result = new PasswordValidationResult();

    if(password){

      if(password.length >= passwordPolicy.minimalLength!){
        result.minimalPasswordLengthValid = true;
      }

      var uppercaseMatch = password.match(CONSTANTS.REGULAR_EXPRESSIONS.UPPERCASE_LETTERS);
      var lowercaseMatch = password.match(CONSTANTS.REGULAR_EXPRESSIONS.LOWERCASE_LETTERS);
      var digitMatch = password.match(CONSTANTS.REGULAR_EXPRESSIONS.DIGITS);
      var specialCharacterMatch = password.match(CONSTANTS.REGULAR_EXPRESSIONS.SPECIAL_CHARACTERS);

      if(uppercaseMatch != null && (uppercaseMatch.length >= passwordPolicy!.minimalNumberOfUppercaseLetters!)){
        result.minimalNumberOfUppercaseCharactersValid = true;
      }

      if(lowercaseMatch != null && (lowercaseMatch.length >= passwordPolicy!.minimalNumberOfLowercaseLetters!)){
        result.minimalNumberOfLowercaseCharactersValid = true;
      }

      if(digitMatch != null && (digitMatch.length >= passwordPolicy!.minimalNumberOfDigits!)){
        result.minimalNumberOfDigitsValid = true;
      }
      
      if(specialCharacterMatch != null && (specialCharacterMatch.length >= passwordPolicy!.minimalNumberOfSpecialCharacters!)){
        result.minimalNumberOfSpecialCharactersValid = true;
      }

    }

    return result;
  }

  //#endregion

}