import { ROOT_ROUTES } from "../app-routing.constants";

export abstract class SECURITY_ROUTES{  
  static readonly LOGIN: string = "login";
  static readonly EMAIL_CONFIRMATION: string = "email-confirmation";
  static readonly USER_REGISTRATION: string = "user-registration";
  static readonly RESET_PASSWORD: string = "password-reset";
  static readonly PRIVACY_POLICY: string = "privacy-policy";

  static readonly SECURITY_LOGIN = `/${ROOT_ROUTES.SECURITY}/${SECURITY_ROUTES.LOGIN}`;
  static readonly SECURITY_EMAIL_CONFIRMATION = `/${ROOT_ROUTES.SECURITY}/${SECURITY_ROUTES.EMAIL_CONFIRMATION}`;
  static readonly SECURITY_USER_REGISTRATION = `/${ROOT_ROUTES.SECURITY}/${SECURITY_ROUTES.USER_REGISTRATION}`;
  static readonly SECURITY_RESET_PASSWORD = `/${ROOT_ROUTES.SECURITY}/${SECURITY_ROUTES.RESET_PASSWORD}`;
  static readonly SECURITY_PRIVACY_POLICY = `/${ROOT_ROUTES.SECURITY}/${SECURITY_ROUTES.PRIVACY_POLICY}`;

}