import { NgForm } from "@angular/forms";

declare module "@angular/forms/" {

  interface NgForm {

    /**
     * Resets form validation.
     * @param this Form
     */
    resetValidation(this:Form) : void;
  
    /**
     * Validates form.
     * @param this Form
     */
    validate(this:Form) : void;
  }
}

/**
 * Reset form validation implementation.
 */
NgForm.prototype.resetValidation = function(this:NgForm){
    Object.keys(this.controls).forEach((key)=>{
        const control = this.controls[key];
        control.markAsPristine();
        control.markAsUntouched();
    })
}

/**
 * Validates form.
 * NOTE: Checkbox controls will be ignore.
 */
NgForm.prototype.validate = function(this:NgForm){
  Object.keys(this.controls).forEach((key)=>{
      const control = this.controls[key];

      if(typeof control.value !== "boolean")
      {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
        control.updateValueAndValidity();
      }
  })
}