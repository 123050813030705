/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetAppSettingsResponse } from '../models/get-app-settings-response';
import { BaseResponse } from '../models/base-response';
import { UpdateAppSettingsRequest } from '../models/update-app-settings-request';
import { GetAppSettingResponse } from '../models/get-app-setting-response';
@Injectable({
  providedIn: 'root',
})
class AppSettingsService extends __BaseService {
  static readonly getApiAppSettingsPath = '/api/appSettings';
  static readonly postApiAppSettingsPath = '/api/appSettings';
  static readonly getApiAppSettingPath = '/api/appSetting';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  getApiAppSettingsResponse(): __Observable<__StrictHttpResponse<GetAppSettingsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/appSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetAppSettingsResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiAppSettings(): __Observable<GetAppSettingsResponse> {
    return this.getApiAppSettingsResponse().pipe(
      __map(_r => _r.body as GetAppSettingsResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiAppSettingsResponse(body?: UpdateAppSettingsRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/appSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiAppSettings(body?: UpdateAppSettingsRequest): __Observable<BaseResponse> {
    return this.postApiAppSettingsResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param Name undefined
   * @return OK
   */
  getApiAppSettingResponse(Name: string): __Observable<__StrictHttpResponse<GetAppSettingResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Name != null) __params = __params.set('Name', Name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/appSetting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetAppSettingResponse>;
      })
    );
  }
  /**
   * @param Name undefined
   * @return OK
   */
  getApiAppSetting(Name: string): __Observable<GetAppSettingResponse> {
    return this.getApiAppSettingResponse(Name).pipe(
      __map(_r => _r.body as GetAppSettingResponse)
    );
  }
}

module AppSettingsService {
}

export { AppSettingsService }
