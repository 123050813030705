import { Directive, Input } from "@angular/core";
import { AbstractControl, ValidationErrors, Validator, NG_VALIDATORS } from "@angular/forms";

/**
 * Validates if the value of input field is not found in the array of forbidden names
 */
@Directive({
    selector: '[forbiddenNames]',
    providers: [{provide: NG_VALIDATORS, useExisting: ForbiddenNamesValidatorDirective, multi: true}]
})
export class ForbiddenNamesValidatorDirective implements Validator {

    @Input('forbiddenNames')
    public forbiddenNames?: Array<string>;

    validate(control: AbstractControl<any, any>): ValidationErrors | null {

        var input = control.value?.toLowerCase();
        
        if (!this.forbiddenNames || this.forbiddenNames.length == 0 || this.forbiddenNames.findIndex(x => x.toLowerCase() == input) < 0) {
            return null;
        } else {
            return {"forbiddenNames": true};
        }
    }

}