export class UpdateToolbarMessage {

    //#region Constructor

    constructor(title: string, subtitle: string, pageName: string, showDocumentOptions: boolean = false, showSearchBar: boolean = false, searchTerm: string = "") {
        this.title = title;
        this.subtitle = subtitle;
        this.pageName = pageName;
        this.showDocumentOptions = showDocumentOptions;
        this.showSearchBar = showSearchBar;
        if(searchTerm){
            this.searchTerm = searchTerm;
        } else {
            this.searchTerm = "";
        }
    }

    //#endregion

    //#region Properties

    /**
     * Toolbar title.
     */
    title: string;

    /**
     * Toolbar subtitle (currently used only within Help Center page to store product name).
     */
    subtitle: string;

    /**
     * Page name.
     */
    pageName: string;

    /**
     * Determines if document options should be shown in toolbar.
     */
    showDocumentOptions: boolean;

    /**
     * Determines if search bar should be shown in toolbar.
     */
    showSearchBar: boolean;

    /**
     * Search term.
     */
    searchTerm: string;

    //#endregion
}
