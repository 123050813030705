<kendo-dialog [minHeight]="500" [minWidth]="350" [maxWidth]="350" [animation]="false">
  <!-- Header -->
  <div class="d-flex flex-column bg-opal-green-rad-gradient align-items-center p-3">
    
    <!-- Logo -->
    <img class="login-image" src="../../../assets/img/OPAL_Logo.jpg"/>

    <!-- Title -->
    <div class="opal-white fs-4 mt-3">{{ "SECURITY.LOGIN.PRIVACY_POLICY.TITLE" | translate }}</div>
  </div>

  <!-- Privacy Policy Body -->
  
    <div class="dialog-body flex-fill align-self-stretch d-flex flex-column pt-4">
      <div class="text-center">
        {{ 'SECURITY.LOGIN.PRIVACY_POLICY.ACCEPTANCE_REQUIREMENT_START' | translate }}
        <a class="cursor-pointer" (click)="onNavigateToPrivacyPolicyPage()">{{ 'SECURITY.LOGIN.PRIVACY_POLICY.DATA_PROTECTION' | translate }}</a>
        {{ 'SECURITY.LOGIN.PRIVACY_POLICY.ACCEPTANCE_REQUIREMENT_END' | translate }}
      </div>
    </div>

    <!-- Button Bar -->
    <div class="dialog-footer d-flex align-items-center">

      <!-- Deny Privacy Policy Button -->
      <button
        kendoButton
        (click)="denyPrivacyPolicy()"
        class="flex-grow-1 me-2"
        [themeColor]="'primary'"
        rounded="none">
        {{ "SECURITY.LOGIN.PRIVACY_POLICY.BUTTONS.DENY" | translate }}
      </button>

      <!-- Accept Privacy Policy Button -->
      <button
        kendoButton
        (click)="acceptPrivacyPolicy()"
        class="flex-grow-1 ms-2"
        [themeColor]="'primary'"
        rounded="none">
        {{ "SECURITY.LOGIN.PRIVACY_POLICY.BUTTONS.ACCEPT" | translate }}
      </button>
    
  </div>



</kendo-dialog>