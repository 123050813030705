export class ConfirmSharePageMessage {
  //#region Constructor

  constructor(pageLink: string, pageTitle: string){
    this.pageLink = pageLink;
    this.pageTitle = pageTitle;
}

//#endregion

//#region Properties

public pageLink?: string;

public pageTitle?: string;

//#endregion
}