import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { filter, map, Observable } from "rxjs";
import { ODataResponse } from "../common/model/odata-response";
import { UserInfoBase } from "../opal-partner-center/models";
import { StrictHttpResponse } from "../opal-partner-center/strict-http-response";
import { OpalPartnerCenterODataApiConfiguration } from "./opal-partner-center-odata-api-configuration";

@Injectable({
    providedIn: 'root'
})
export class UsersODataService{

  //#region Constructors

  constructor(private _httpClient: HttpClient, private _config: OpalPartnerCenterODataApiConfiguration){

  }

  //#endregion

  //#region API

  getUsersResponse(query: string): Observable<StrictHttpResponse<ODataResponse<UserInfoBase>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this._config.rootUrl + `/api/users` + query,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this._httpClient.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map((_r) => {
        return _r as StrictHttpResponse<ODataResponse<UserInfoBase>>;
      })
    );
  }
  
  getApiUsers(query: string): Observable<ODataResponse<UserInfoBase>> {
    return this.getUsersResponse(query).pipe(
      map(_r => _r.body as ODataResponse<UserInfoBase>)
    );
  }

  //#endregion

  //#region Private methods

  private newParams(): HttpParams {
    return new HttpParams({
      encoder: new ParameterCodec()
    });
  }

  //#endregion
}

class ParameterCodec implements HttpParameterCodec {

  //#region HttpParameterCodec 

  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }

  //#region 
}
