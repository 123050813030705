/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetUserInfoResponse } from '../models/get-user-info-response';
import { BaseResponse } from '../models/base-response';
import { UpdateUserRequest } from '../models/update-user-request';
import { ResetUserPasswordRequest } from '../models/reset-user-password-request';
import { SyncUserPasswordRequest } from '../models/sync-user-password-request';
@Injectable({
  providedIn: 'root',
})
class UsersManagementService extends __BaseService {
  static readonly getApiUsersManagementPath = '/api/usersManagement';
  static readonly putApiUsersManagementPath = '/api/usersManagement';
  static readonly putApiResetUserPasswordPath = '/api/resetUserPassword';
  static readonly putApiSyncUserPasswordPath = '/api/syncUserPassword';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param UserName undefined
   * @return OK
   */
  getApiUsersManagementResponse(UserName: string): __Observable<__StrictHttpResponse<GetUserInfoResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (UserName != null) __params = __params.set('UserName', UserName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/usersManagement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetUserInfoResponse>;
      })
    );
  }
  /**
   * @param UserName undefined
   * @return OK
   */
  getApiUsersManagement(UserName: string): __Observable<GetUserInfoResponse> {
    return this.getApiUsersManagementResponse(UserName).pipe(
      __map(_r => _r.body as GetUserInfoResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  putApiUsersManagementResponse(body?: UpdateUserRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/usersManagement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  putApiUsersManagement(body?: UpdateUserRequest): __Observable<BaseResponse> {
    return this.putApiUsersManagementResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  putApiResetUserPasswordResponse(body?: ResetUserPasswordRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/resetUserPassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  putApiResetUserPassword(body?: ResetUserPasswordRequest): __Observable<BaseResponse> {
    return this.putApiResetUserPasswordResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  putApiSyncUserPasswordResponse(body?: SyncUserPasswordRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/syncUserPassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  putApiSyncUserPassword(body?: SyncUserPasswordRequest): __Observable<BaseResponse> {
    return this.putApiSyncUserPasswordResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }
}

module UsersManagementService {
}

export { UsersManagementService }
