/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { OpalPartnerCenterODataApiConfigurationInterface, OpalPartnerCenterODataApiConfiguration } from './opal-partner-center-odata-api-configuration';
import { FileStorageOdataService } from './file-storage-odata.service';
import { UsersODataService } from './users-odata.service';
import { UserGroupsODataService } from './user-groups-odata.service';



@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    OpalPartnerCenterODataApiConfiguration,
    FileStorageOdataService,
    UsersODataService,
    UserGroupsODataService
  ],
})
export class OpalPartnerCenterODataApiModule {
  static forRoot(customParams: OpalPartnerCenterODataApiConfigurationInterface): ModuleWithProviders<OpalPartnerCenterODataApiModule> {
    return {
      ngModule: OpalPartnerCenterODataApiModule,
      providers: [
        {
          provide: OpalPartnerCenterODataApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
