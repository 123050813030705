<div class="d-flex flex-column flex-fill confirmation-dialog">

 <!-- Header -->
 <kendo-dialog-titlebar class="k-window-actions-custom d-flex dialog-header justify-content-center align-items-center">

  <!-- Icon -->
  <div>
      <i class="fa-solid fa-lg fa-question opal-dark-green-text"></i>
  </div>

  <!-- Title -->
  <div class="display-9 ms-3">{{ 'CONFIRMATION_DIALOG.TITLE' | translate}}</div>

  <!-- Close -->
  <div class="ms-auto">
      <i class="fa-light fa-xmark fa-sm opal-grey-text cursor-pointer" (click)="onCancel()"></i>
  </div>

</kendo-dialog-titlebar>

  <!-- Message -->
  <div class="d-flex flex-row flex-grow-1 align-items-center justify-content-center">
      <h6 class="text-center text-wrap px-3">{{message}}</h6>
  </div>
  
  <!-- Button Bar -->
  <div class="dialog-footer d-flex align-items-center">

      <!-- Cancel button -->
      <button kendoButton [primary]="true" class="k-state-focused" (click)="onCancel()">
        <i class="fa-solid fa-md fa-times me-2"></i>
        {{'CONFIRMATION_DIALOG.NO' | translate}}
    </button>

      <!-- Confirm button -->
      <button kendoButton [primary]="true" class="ms-auto" (click)="onConfirm()">
        <i class="fa-solid fa-md fa-check me-2"></i>
        {{'CONFIRMATION_DIALOG.YES' | translate}}
    </button>
      
  </div>

</div>


