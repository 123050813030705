import { Routes } from "@angular/router";
import { EmailConfirmationComponent } from "./email-confirmation/email-confirmation.component";
import { LoginComponent } from "./login/login.component";
import { RegisterUserComponent } from "./register-user/register-user.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { SECURITY_ROUTES } from "./security.routing.constants";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";

export const SecurityRoutes: Routes = [
  {
    path: SECURITY_ROUTES.LOGIN, component: LoginComponent
  },
  {
    path: SECURITY_ROUTES.RESET_PASSWORD, component: ResetPasswordComponent
  },
  {
    path: SECURITY_ROUTES.USER_REGISTRATION, component: RegisterUserComponent
  },
  {
    path: SECURITY_ROUTES.EMAIL_CONFIRMATION, component: EmailConfirmationComponent
  },
  {
    path: SECURITY_ROUTES.PRIVACY_POLICY, component: PrivacyPolicyComponent
  },
];