export class AuthenticationMethod
{
  constructor(id: number, name:string)
  {
    this.id = id;
    this.name = name;
  }

  public id: number;

  public name:string;
}