import { UserRatingSummaryInfo } from "src/app/api/opal-partner-center/models";

export class UserRatingSummary {

    //#region Constructor

    constructor(userRatingSummaryInfo: UserRatingSummaryInfo | undefined | null) {

        if (userRatingSummaryInfo) {
            this.categoryName = userRatingSummaryInfo.categoryName!;
            this.ratedItemKey = userRatingSummaryInfo.ratedItemKey!;

            if (userRatingSummaryInfo.averageRating) {
                // NOTE: Average rating is rounded to one decimal place.
                this.averageRating = Math.round(userRatingSummaryInfo.averageRating * 10) / 10;
                this.ratingCount = userRatingSummaryInfo.ratingCount;
                
            } else {
                this.averageRating = undefined;
                this.ratingCount = undefined;
            }
            
        } else {
            this.categoryName = "";
            this.ratedItemKey = "";
        }
    }

    //#endregion

    //#region Properties

    /**
     * User rating category name.
     */
    public categoryName: string;

    /**
     * Unique identifier of rated item.
     */
    public ratedItemKey: string;

    /**
     * Average rating.
     */
    public averageRating: number | undefined;

    /**
     * Rating count.
     */
    public ratingCount: number | undefined;

    //#endregion

}