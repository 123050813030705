import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { UsersService } from "src/app/api/opal-partner-center/services";
import { LAYOUT_ROUTES } from "src/app/layout/layout.routing.constants";
import { CONSTANTS } from "../../constants";
import { UserInfoService } from "../user-info-service/user-info-service";

/**
 * Router guard to protect navigating to routes not allowed for user.
 * @param route Activated route snapshot.
 * @param state Router state snapshot.
 * @returns If route can be activated.
 */
export const RouteGuard: CanActivateFn = async (route, state) => {

  const userInfoService = inject(UserInfoService);
  const usersService = inject(UsersService);

  let canActivate: boolean = false;

  var url = state.url.substring(1);
  if(userInfoService.userInfo){

    canActivate = canActivateRoute(url, userInfoService);

  } else {

    try{
      var userInfoResult = await firstValueFrom(usersService.getApiUserInfo());

      if(userInfoResult){
        userInfoService.userInfo = userInfoResult.userInfo;
        canActivate = canActivateRoute(url, userInfoService);
      }
    }catch(e){ 
    }
  }

  return canActivate;
}

/**
 * Determines if route can be activated according to user permissions.
 * @param route Route to check.
 * @param userInfoService User info service.
 * @returns True if route can be activated, false otherwise.
 */
function canActivateRoute(route: string, userInfoService: UserInfoService): boolean {

  let canActivateRoute: boolean = false;
  route = route.split('?')[0];
  switch(route){
    case LAYOUT_ROUTES.GENERAL_SEARCH:
      canActivateRoute = userInfoService.hasAnyOfPermissions(CONSTANTS.PERMISSIONS.PRODUCT_RELEASE_INFO, 
                                                             CONSTANTS.PERMISSIONS.PRODUCT_RELEASE_MANAGEMENT,
                                                             CONSTANTS.PERMISSIONS.PRODUCT_DOCS,
                                                             CONSTANTS.PERMISSIONS.PRODUCT_DOCS_MANAGEMENT,
                                                             CONSTANTS.PERMISSIONS.MARKETING_MATERIAL);
      break;

    case LAYOUT_ROUTES.DASHBOARD_WELCOME:
    case LAYOUT_ROUTES.ACCOUNT_PROFILE:
      canActivateRoute = true;
      break;

    case LAYOUT_ROUTES.ACCOUNT_SUPPORT_INFORMATION:
      canActivateRoute = userInfoService.hasAnyOfPermissions(CONSTANTS.PERMISSIONS.SUPPORT_INFO, 
                                                             CONSTANTS.PERMISSIONS.SUPPORT_INFO_CUST);
      break;

    case LAYOUT_ROUTES.SOFTWARE_RELEASES:
    case LAYOUT_ROUTES.SOFTWARE_COMPATIBILITY:
    case LAYOUT_ROUTES.SOFTWARE_RELEASE_POLICY:
      canActivateRoute = userInfoService.hasAnyOfPermissions(CONSTANTS.PERMISSIONS.PRODUCT_RELEASE_INFO,
                                                             CONSTANTS.PERMISSIONS.PRODUCT_RELEASE_MANAGEMENT)
      break;

    case LAYOUT_ROUTES.HELP_CENTER_DOCUMENTATION:
    case LAYOUT_ROUTES.HELP_CENTER_TRAINING_VIDEOS:
    case LAYOUT_ROUTES.HELP_CENTER_TRAINING_VIDEO:
      canActivateRoute = userInfoService.hasAnyOfPermissions(CONSTANTS.PERMISSIONS.PRODUCT_DOCS, 
                                                             CONSTANTS.PERMISSIONS.PRODUCT_DOCS_MANAGEMENT);
      break;

    case LAYOUT_ROUTES.MARKETING_RESOURCES_DOCUMENTATION:
    case LAYOUT_ROUTES.MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEOS:
    case LAYOUT_ROUTES.MARKETING_RESOURCES_MARKETING_MATERIAL_VIDEO:
      canActivateRoute = userInfoService.hasPermission(CONSTANTS.PERMISSIONS.MARKETING_MATERIAL);
      break;

      case LAYOUT_ROUTES.ADMINISTRATION_CONFIGURATION:
          canActivateRoute = userInfoService.hasPermission(CONSTANTS.PERMISSIONS.SYSTEM_SETTINGS);
      break;

    case LAYOUT_ROUTES.ADMINISTRATION_FILESTORAGE:
      canActivateRoute = userInfoService.hasPermission(CONSTANTS.PERMISSIONS.FILE_STORAGE_MANAGEMENT);
      break;

    case LAYOUT_ROUTES.ADMINISTRATION_USER_MANAGEMENT:
      canActivateRoute = userInfoService.hasAnyOfPermissions(CONSTANTS.PERMISSIONS.USER_GROUP_MANAGEMENT, 
                                                             CONSTANTS.PERMISSIONS.USER_MANAGEMENT);
      break;

    case LAYOUT_ROUTES.ADMINISTRATION_PUBLICATION_CATEGORIES:
      canActivateRoute = userInfoService.hasPermission(CONSTANTS.PERMISSIONS.PRODUCT_DOCS_MANAGEMENT);
      break;

    case LAYOUT_ROUTES.ADMINISTRATION_DATA_SYNCHRONIZATION:
      canActivateRoute = userInfoService.hasAnyOfPermissions(CONSTANTS.PERMISSIONS.SYNC_COMPANIES, 
                                                             CONSTANTS.PERMISSIONS.SYNC_PRODUCT_DOCS, 
                                                             CONSTANTS.PERMISSIONS.SYNC_USER_GROUPS);
      break;

    case LAYOUT_ROUTES.AUDIT_TRAIL:
      canActivateRoute = userInfoService.hasPermission(CONSTANTS.PERMISSIONS.AUDIT_TRAIL);
      break;

    case LAYOUT_ROUTES.ADMINISTRATIVE_NOTIFICATIONS:
      canActivateRoute = userInfoService.hasPermission(CONSTANTS.PERMISSIONS.ADMIN_NOTIFICATIONS);
      break;
    case LAYOUT_ROUTES.CUSTOMERS_OVERVIEW:
      canActivateRoute = userInfoService.hasPermission(CONSTANTS.PERMISSIONS.SYSTEM_SETTINGS);
      break;
    case LAYOUT_ROUTES.USER_GUIDE:
      canActivateRoute = true;
      break;
  }

  return canActivateRoute;
}


