export class Language {

    //#region Constructor

    /**
     * Initializes new instance of Language.
     * @param languageCode Language code
     * @param languageName Language name
     */
    constructor(languageCode: string, languageName: string){
        this.languageName = languageName;
        this.languageCode = languageCode;
    }

    //#endregion

    //#region Public Properties

    /**
     * Language name.
     */
    public languageName: string;

    /**
     * Language code.
     */
    public languageCode: string;

    //#endregion


}