<div>

    <!-- Close button -->
    <i class="fa-solid fa-lg fa fa-xmark snack-close-button" (click)="snackBarRef.dismiss()"></i>

    <!-- Snackbar Content -->
    <div class="d-flex flex-column">

        <!-- Snackbar Header -->
        <div class="d-flex mb-1 align-items-center" [ngSwitch]="snackType">

            <!-- Icon -->
            <i class="fa-solid fa-2xl" [ngClass]="{'snack-success fa-circle-check': snackType === 'Success', 'snack-info fa-circle-info': snackType == 'Info', 
            'snack-warning fa-triangle-exclamation': snackType == 'Warning', 'snack-error fa-circle-exclamation': snackType == 'Error'}" ></i>

            <!-- Title -->
            <span class="snack-title ms-2" *ngSwitchCase="'Success'">{{'NOTIFICATION.SUCCESS' | translate}}</span>
            <span class="snack-title ms-2" *ngSwitchCase="'Warning'">{{'NOTIFICATION.WARNING' | translate}}</span>
            <span class="snack-title ms-2" *ngSwitchCase="'Error'">{{'NOTIFICATION.ERROR' | translate}}</span>
            <span class="snack-title ms-2" *ngSwitchCase="'Info'">{{'NOTIFICATION.INFO' | translate}}</span>

        </div>
      
        <!-- Snackbar Message -->
        <span  class="snack-message">{{data.message | translate}}</span>
    </div>
</div>