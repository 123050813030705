import { AuthMethod, UserAuthenticationSettings } from "src/app/api/opal-partner-center/models";
import { IChildChangedReceiver } from "../interfaces/i-child-changed-receiver";

export class UserAuthenticationSettingsInfo {

  //#region Private Fields

  /**
   * Determines if two step authentication is enabled.
   */
  private _twoStepEnabled : boolean;

  /**
   * Original two step authentication setting.
   */
  private _twoStepEnabledOrig : boolean;

  /**
   * Determines if two factor authentication is enabled.
   */
  private _twoFactorEnabled : boolean;

  /**
   * Original two factor authentication setting.
   */
  private _twoFactorEnabledOrig : boolean;

  /**
   * Determines if FIDO2 authentication is enabled.
   */
  private _fido2Enabled : boolean;

  /**
   * Original FIDO2 authentication setting.
   */
  private _fido2EnabledOrig : boolean;

  /**
   * Default authentication method.
   */
  private _defaultAuthMethod: AuthMethod;

  /**
   * Original default authentication method.
   */
  private _defaultAuthMethodOrig: AuthMethod;

  /**
   * Child changed receiver
   */
  private _childChangedReceiver?: IChildChangedReceiver;
  
  //#endregion

  //#region Constructor

  constructor(userAuthenticationSettings: UserAuthenticationSettings, childChangedReceiver?: IChildChangedReceiver){

    this._twoStepEnabled = userAuthenticationSettings.twoStepEnabled!;
    this._twoStepEnabledOrig = userAuthenticationSettings.twoStepEnabled!;
    this.twoStepConfigured = userAuthenticationSettings.twoStepConfigured!;
    this.twoStepEnabledChanged = false;

    this._twoFactorEnabled = userAuthenticationSettings.twoFactorEnabled!;
    this._twoFactorEnabledOrig = userAuthenticationSettings.twoFactorEnabled!;
    this.twoFactorConfigured = userAuthenticationSettings.twoFactorConfigured!;
    this.twoFactorEnabledChanged = false;

    this._fido2Enabled = userAuthenticationSettings.fido2Enabled!;
    this._fido2EnabledOrig = userAuthenticationSettings.fido2Enabled!;
    this.fido2Configured = userAuthenticationSettings.fido2Configured!;
    this.fido2EnabledChanged = false;

    this._defaultAuthMethod = userAuthenticationSettings.defaultAuthMethod!;
    this._defaultAuthMethodOrig = userAuthenticationSettings.defaultAuthMethod!;
    this.defaultAuthMethodChanged = false;

    this._childChangedReceiver = childChangedReceiver;
    this.isChanged = false;
  }

  //#endregion

  //#region Public Properties

  /**
   * Gets if two step authentication is enabled.
   */
  public get twoStepEnabled() : boolean {
    return this._twoStepEnabled;
  }

  /**
   * Sets if two step authentication is enabled.
   */
  public set twoStepEnabled(twoStepEnabled : boolean) {
    this._twoStepEnabled = twoStepEnabled;
    this.checkChanges();
  }

  /**
   * Sets if two step authentication is changed.
   */
  public twoStepEnabledChanged: boolean;
  
  /**
   * Gets if two factor authentication is enabled.
   */
  public get twoFactorEnabled() : boolean {
    return this._twoFactorEnabled;
  }

  /**
   * Sets if two factor authentication is enabled.
   */
  public set twoFactorEnabled(twoFactorEnabled : boolean) {
    this._twoFactorEnabled = twoFactorEnabled;
    this.checkChanges();
  }

  /**
   * Sets if two factor authentication is changed.
   */
  public twoFactorEnabledChanged: boolean;
  
  /**
   * Gets if FIDO2 authentication is enabled.
   */
  public get fido2Enabled() : boolean {
    return this._fido2Enabled;
  }

  /**
   * Sets if FIDO2 authentication is enabled.
   */
  public set fido2Enabled(fido2Enabled : boolean) {
    this._fido2Enabled = fido2Enabled;
    this.checkChanges();
  }

  /**
   * Sets if FIDO2 authentication is changed.
   */
  public fido2EnabledChanged: boolean;

  /**
   * Gets default authentication method.
   */
  public get defaultAuthMethod() : AuthMethod{
    return this._defaultAuthMethod;
  }

  /**
   * Sets default authentication method.
   */
  public set defaultAuthMethod(defaultAuthMethod : AuthMethod) {
    this._defaultAuthMethod = defaultAuthMethod;
    this.checkChanges();
  }

  /**
   * Sets if default authentication method is changed.
   */
  public defaultAuthMethodChanged: boolean;

  /**
   * Determines if two step authentication is configured.
   */
  public twoStepConfigured: boolean;

  /**
   * Determines if two factor authentication is configured.
   */
  public twoFactorConfigured: boolean;

  /**
   * Determines if FIDO2 authentication is configured.
   */
  public fido2Configured: boolean;

  /**
   * Determines if user security settings have changed.
   */
  public isChanged: boolean;

  //#endregion

  //#region Public Methods

  /**
   * Resets changes.
   */
  public undoChanges(): void {
    this.twoStepEnabled = this._twoStepEnabledOrig;
    this.twoFactorEnabled = this._twoFactorEnabledOrig;
    this.fido2Enabled = this._fido2EnabledOrig;
    this.defaultAuthMethod = this._defaultAuthMethodOrig;
  }

  //#endregion

  //#region Private Methods

  /**
   * Checks if any of the properties have changed.
   */
  private checkChanges(): void {
    this.isChanged = this._twoStepEnabled !== this._twoStepEnabledOrig ||
                     this._twoFactorEnabled !== this._twoFactorEnabledOrig ||
                     this._fido2Enabled !== this._fido2EnabledOrig ||
                     this._defaultAuthMethod !== this._defaultAuthMethodOrig;

    if(this._childChangedReceiver){
      this._childChangedReceiver.notifyChildChanged();
    }
  }

  //#endregion
}