/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetBusinessPartnersResponse } from '../models/get-business-partners-response';
import { GetMainContractsResponse } from '../models/get-main-contracts-response';
import { GetMainContractDetailsResponse } from '../models/get-main-contract-details-response';
import { QueryCustomersProductsResponse } from '../models/query-customers-products-response';
import { DownloadFileResponse } from '../models/download-file-response';
import { QueryCustomersUsersResponse } from '../models/query-customers-users-response';
import { GetCompaniesCountriesResponse } from '../models/get-companies-countries-response';
import { GetSalesRepresentativesResponse } from '../models/get-sales-representatives-response';
import { GetServiceLevelAgreementTypesResponse } from '../models/get-service-level-agreement-types-response';
@Injectable({
  providedIn: 'root',
})
class CompaniesService extends __BaseService {
  static readonly getApiCompaniesPath = '/api/companies';
  static readonly getApiMainContractsPath = '/api/mainContracts';
  static readonly getApiAvailableMainContractsPath = '/api/availableMainContracts';
  static readonly getApiMainContractPath = '/api/mainContract';
  static readonly getApiQueryCustomersPoductsPath = '/api/queryCustomersPoducts';
  static readonly getApiExportCustomersPoductsPath = '/api/exportCustomersPoducts';
  static readonly getApiQueryCustomersUsersPath = '/api/queryCustomersUsers';
  static readonly getApiExportCustomersUsersPath = '/api/exportCustomersUsers';
  static readonly getApiCompaniesCountriesPath = '/api/companiesCountries';
  static readonly getApiSalesRepresentativesPath = '/api/salesRepresentatives';
  static readonly getApiServiceLevelAgreementTypesPath = '/api/serviceLevelAgreementTypes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  getApiCompaniesResponse(): __Observable<__StrictHttpResponse<GetBusinessPartnersResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetBusinessPartnersResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiCompanies(): __Observable<GetBusinessPartnersResponse> {
    return this.getApiCompaniesResponse().pipe(
      __map(_r => _r.body as GetBusinessPartnersResponse)
    );
  }

  /**
   * @param BusinessPartnerNumber undefined
   * @return OK
   */
  getApiMainContractsResponse(BusinessPartnerNumber: string): __Observable<__StrictHttpResponse<GetMainContractsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (BusinessPartnerNumber != null) __params = __params.set('BusinessPartnerNumber', BusinessPartnerNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/mainContracts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetMainContractsResponse>;
      })
    );
  }
  /**
   * @param BusinessPartnerNumber undefined
   * @return OK
   */
  getApiMainContracts(BusinessPartnerNumber: string): __Observable<GetMainContractsResponse> {
    return this.getApiMainContractsResponse(BusinessPartnerNumber).pipe(
      __map(_r => _r.body as GetMainContractsResponse)
    );
  }

  /**
   * @return OK
   */
  getApiAvailableMainContractsResponse(): __Observable<__StrictHttpResponse<GetMainContractsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/availableMainContracts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetMainContractsResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiAvailableMainContracts(): __Observable<GetMainContractsResponse> {
    return this.getApiAvailableMainContractsResponse().pipe(
      __map(_r => _r.body as GetMainContractsResponse)
    );
  }

  /**
   * @param ContractNumber undefined
   * @return OK
   */
  getApiMainContractResponse(ContractNumber: string): __Observable<__StrictHttpResponse<GetMainContractDetailsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (ContractNumber != null) __params = __params.set('ContractNumber', ContractNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/mainContract`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetMainContractDetailsResponse>;
      })
    );
  }
  /**
   * @param ContractNumber undefined
   * @return OK
   */
  getApiMainContract(ContractNumber: string): __Observable<GetMainContractDetailsResponse> {
    return this.getApiMainContractResponse(ContractNumber).pipe(
      __map(_r => _r.body as GetMainContractDetailsResponse)
    );
  }

  /**
   * @param params The `CompaniesService.GetApiQueryCustomersPoductsParams` containing the following parameters:
   *
   * - `Filters.Take`:
   *
   * - `Filters.SubcontractValidity`:
   *
   * - `Filters.SubcontractValidStartDateAsText`:
   *
   * - `Filters.SubcontractValidEndDateAsText`:
   *
   * - `Filters.SubcontractPurchaseStartDateAsText`:
   *
   * - `Filters.SubcontractPurchaseEndDateAsText`:
   *
   * - `Filters.Skip`:
   *
   * - `Filters.ServiceLevelAgreementType`:
   *
   * - `Filters.Products`:
   *
   * - `Filters.ProductCategories`:
   *
   * - `Filters.CustomerZip`:
   *
   * - `Filters.CustomerSalesRepresentative`:
   *
   * - `Filters.CustomerInfo`:
   *
   * - `Filters.CustomerCountry`:
   *
   * - `Filters.CustomerCity`:
   *
   * - `Filters.ContractInfo`:
   *
   * @return OK
   */
  getApiQueryCustomersPoductsResponse(params: CompaniesService.GetApiQueryCustomersPoductsParams): __Observable<__StrictHttpResponse<QueryCustomersProductsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.FiltersTake != null) __params = __params.set('Filters.Take', params.FiltersTake.toString());
    if (params.FiltersSubcontractValidity != null) __params = __params.set('Filters.SubcontractValidity', params.FiltersSubcontractValidity.toString());
    if (params.FiltersSubcontractValidStartDateAsText != null) __params = __params.set('Filters.SubcontractValidStartDateAsText', params.FiltersSubcontractValidStartDateAsText.toString());
    if (params.FiltersSubcontractValidEndDateAsText != null) __params = __params.set('Filters.SubcontractValidEndDateAsText', params.FiltersSubcontractValidEndDateAsText.toString());
    if (params.FiltersSubcontractPurchaseStartDateAsText != null) __params = __params.set('Filters.SubcontractPurchaseStartDateAsText', params.FiltersSubcontractPurchaseStartDateAsText.toString());
    if (params.FiltersSubcontractPurchaseEndDateAsText != null) __params = __params.set('Filters.SubcontractPurchaseEndDateAsText', params.FiltersSubcontractPurchaseEndDateAsText.toString());
    if (params.FiltersSkip != null) __params = __params.set('Filters.Skip', params.FiltersSkip.toString());
    if (params.FiltersServiceLevelAgreementType != null) __params = __params.set('Filters.ServiceLevelAgreementType', params.FiltersServiceLevelAgreementType.toString());
    (params.FiltersProducts || []).forEach(val => {if (val != null) __params = __params.append('Filters.Products', val.toString())});
    (params.FiltersProductCategories || []).forEach(val => {if (val != null) __params = __params.append('Filters.ProductCategories', val.toString())});
    if (params.FiltersCustomerZip != null) __params = __params.set('Filters.CustomerZip', params.FiltersCustomerZip.toString());
    if (params.FiltersCustomerSalesRepresentative != null) __params = __params.set('Filters.CustomerSalesRepresentative', params.FiltersCustomerSalesRepresentative.toString());
    if (params.FiltersCustomerInfo != null) __params = __params.set('Filters.CustomerInfo', params.FiltersCustomerInfo.toString());
    if (params.FiltersCustomerCountry != null) __params = __params.set('Filters.CustomerCountry', params.FiltersCustomerCountry.toString());
    if (params.FiltersCustomerCity != null) __params = __params.set('Filters.CustomerCity', params.FiltersCustomerCity.toString());
    if (params.FiltersContractInfo != null) __params = __params.set('Filters.ContractInfo', params.FiltersContractInfo.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/queryCustomersPoducts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryCustomersProductsResponse>;
      })
    );
  }
  /**
   * @param params The `CompaniesService.GetApiQueryCustomersPoductsParams` containing the following parameters:
   *
   * - `Filters.Take`:
   *
   * - `Filters.SubcontractValidity`:
   *
   * - `Filters.SubcontractValidStartDateAsText`:
   *
   * - `Filters.SubcontractValidEndDateAsText`:
   *
   * - `Filters.SubcontractPurchaseStartDateAsText`:
   *
   * - `Filters.SubcontractPurchaseEndDateAsText`:
   *
   * - `Filters.Skip`:
   *
   * - `Filters.ServiceLevelAgreementType`:
   *
   * - `Filters.Products`:
   *
   * - `Filters.ProductCategories`:
   *
   * - `Filters.CustomerZip`:
   *
   * - `Filters.CustomerSalesRepresentative`:
   *
   * - `Filters.CustomerInfo`:
   *
   * - `Filters.CustomerCountry`:
   *
   * - `Filters.CustomerCity`:
   *
   * - `Filters.ContractInfo`:
   *
   * @return OK
   */
  getApiQueryCustomersPoducts(params: CompaniesService.GetApiQueryCustomersPoductsParams): __Observable<QueryCustomersProductsResponse> {
    return this.getApiQueryCustomersPoductsResponse(params).pipe(
      __map(_r => _r.body as QueryCustomersProductsResponse)
    );
  }

  /**
   * @param params The `CompaniesService.GetApiExportCustomersPoductsParams` containing the following parameters:
   *
   * - `Filters.Take`:
   *
   * - `Filters.SubcontractValidity`:
   *
   * - `Filters.SubcontractValidStartDateAsText`:
   *
   * - `Filters.SubcontractValidEndDateAsText`:
   *
   * - `Filters.SubcontractPurchaseStartDateAsText`:
   *
   * - `Filters.SubcontractPurchaseEndDateAsText`:
   *
   * - `Filters.Skip`:
   *
   * - `Filters.ServiceLevelAgreementType`:
   *
   * - `Filters.Products`:
   *
   * - `Filters.ProductCategories`:
   *
   * - `Filters.CustomerZip`:
   *
   * - `Filters.CustomerSalesRepresentative`:
   *
   * - `Filters.CustomerInfo`:
   *
   * - `Filters.CustomerCountry`:
   *
   * - `Filters.CustomerCity`:
   *
   * - `Filters.ContractInfo`:
   *
   * @return OK
   */
  getApiExportCustomersPoductsResponse(params: CompaniesService.GetApiExportCustomersPoductsParams): __Observable<__StrictHttpResponse<DownloadFileResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.FiltersTake != null) __params = __params.set('Filters.Take', params.FiltersTake.toString());
    if (params.FiltersSubcontractValidity != null) __params = __params.set('Filters.SubcontractValidity', params.FiltersSubcontractValidity.toString());
    if (params.FiltersSubcontractValidStartDateAsText != null) __params = __params.set('Filters.SubcontractValidStartDateAsText', params.FiltersSubcontractValidStartDateAsText.toString());
    if (params.FiltersSubcontractValidEndDateAsText != null) __params = __params.set('Filters.SubcontractValidEndDateAsText', params.FiltersSubcontractValidEndDateAsText.toString());
    if (params.FiltersSubcontractPurchaseStartDateAsText != null) __params = __params.set('Filters.SubcontractPurchaseStartDateAsText', params.FiltersSubcontractPurchaseStartDateAsText.toString());
    if (params.FiltersSubcontractPurchaseEndDateAsText != null) __params = __params.set('Filters.SubcontractPurchaseEndDateAsText', params.FiltersSubcontractPurchaseEndDateAsText.toString());
    if (params.FiltersSkip != null) __params = __params.set('Filters.Skip', params.FiltersSkip.toString());
    if (params.FiltersServiceLevelAgreementType != null) __params = __params.set('Filters.ServiceLevelAgreementType', params.FiltersServiceLevelAgreementType.toString());
    (params.FiltersProducts || []).forEach(val => {if (val != null) __params = __params.append('Filters.Products', val.toString())});
    (params.FiltersProductCategories || []).forEach(val => {if (val != null) __params = __params.append('Filters.ProductCategories', val.toString())});
    if (params.FiltersCustomerZip != null) __params = __params.set('Filters.CustomerZip', params.FiltersCustomerZip.toString());
    if (params.FiltersCustomerSalesRepresentative != null) __params = __params.set('Filters.CustomerSalesRepresentative', params.FiltersCustomerSalesRepresentative.toString());
    if (params.FiltersCustomerInfo != null) __params = __params.set('Filters.CustomerInfo', params.FiltersCustomerInfo.toString());
    if (params.FiltersCustomerCountry != null) __params = __params.set('Filters.CustomerCountry', params.FiltersCustomerCountry.toString());
    if (params.FiltersCustomerCity != null) __params = __params.set('Filters.CustomerCity', params.FiltersCustomerCity.toString());
    if (params.FiltersContractInfo != null) __params = __params.set('Filters.ContractInfo', params.FiltersContractInfo.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/exportCustomersPoducts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DownloadFileResponse>;
      })
    );
  }
  /**
   * @param params The `CompaniesService.GetApiExportCustomersPoductsParams` containing the following parameters:
   *
   * - `Filters.Take`:
   *
   * - `Filters.SubcontractValidity`:
   *
   * - `Filters.SubcontractValidStartDateAsText`:
   *
   * - `Filters.SubcontractValidEndDateAsText`:
   *
   * - `Filters.SubcontractPurchaseStartDateAsText`:
   *
   * - `Filters.SubcontractPurchaseEndDateAsText`:
   *
   * - `Filters.Skip`:
   *
   * - `Filters.ServiceLevelAgreementType`:
   *
   * - `Filters.Products`:
   *
   * - `Filters.ProductCategories`:
   *
   * - `Filters.CustomerZip`:
   *
   * - `Filters.CustomerSalesRepresentative`:
   *
   * - `Filters.CustomerInfo`:
   *
   * - `Filters.CustomerCountry`:
   *
   * - `Filters.CustomerCity`:
   *
   * - `Filters.ContractInfo`:
   *
   * @return OK
   */
  getApiExportCustomersPoducts(params: CompaniesService.GetApiExportCustomersPoductsParams): __Observable<DownloadFileResponse> {
    return this.getApiExportCustomersPoductsResponse(params).pipe(
      __map(_r => _r.body as DownloadFileResponse)
    );
  }

  /**
   * @param params The `CompaniesService.GetApiQueryCustomersUsersParams` containing the following parameters:
   *
   * - `Filters.Take`:
   *
   * - `Filters.Skip`:
   *
   * - `Filters.LastName`:
   *
   * - `Filters.IsAdmin`:
   *
   * - `Filters.FirstName`:
   *
   * - `Filters.Email`:
   *
   * - `Filters.CustomerInfo`:
   *
   * - `Filters.ContractInfo`:
   *
   * @return OK
   */
  getApiQueryCustomersUsersResponse(params: CompaniesService.GetApiQueryCustomersUsersParams): __Observable<__StrictHttpResponse<QueryCustomersUsersResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.FiltersTake != null) __params = __params.set('Filters.Take', params.FiltersTake.toString());
    if (params.FiltersSkip != null) __params = __params.set('Filters.Skip', params.FiltersSkip.toString());
    if (params.FiltersLastName != null) __params = __params.set('Filters.LastName', params.FiltersLastName.toString());
    if (params.FiltersIsAdmin != null) __params = __params.set('Filters.IsAdmin', params.FiltersIsAdmin.toString());
    if (params.FiltersFirstName != null) __params = __params.set('Filters.FirstName', params.FiltersFirstName.toString());
    if (params.FiltersEmail != null) __params = __params.set('Filters.Email', params.FiltersEmail.toString());
    if (params.FiltersCustomerInfo != null) __params = __params.set('Filters.CustomerInfo', params.FiltersCustomerInfo.toString());
    if (params.FiltersContractInfo != null) __params = __params.set('Filters.ContractInfo', params.FiltersContractInfo.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/queryCustomersUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryCustomersUsersResponse>;
      })
    );
  }
  /**
   * @param params The `CompaniesService.GetApiQueryCustomersUsersParams` containing the following parameters:
   *
   * - `Filters.Take`:
   *
   * - `Filters.Skip`:
   *
   * - `Filters.LastName`:
   *
   * - `Filters.IsAdmin`:
   *
   * - `Filters.FirstName`:
   *
   * - `Filters.Email`:
   *
   * - `Filters.CustomerInfo`:
   *
   * - `Filters.ContractInfo`:
   *
   * @return OK
   */
  getApiQueryCustomersUsers(params: CompaniesService.GetApiQueryCustomersUsersParams): __Observable<QueryCustomersUsersResponse> {
    return this.getApiQueryCustomersUsersResponse(params).pipe(
      __map(_r => _r.body as QueryCustomersUsersResponse)
    );
  }

  /**
   * @param params The `CompaniesService.GetApiExportCustomersUsersParams` containing the following parameters:
   *
   * - `Filters.Take`:
   *
   * - `Filters.Skip`:
   *
   * - `Filters.LastName`:
   *
   * - `Filters.IsAdmin`:
   *
   * - `Filters.FirstName`:
   *
   * - `Filters.Email`:
   *
   * - `Filters.CustomerInfo`:
   *
   * - `Filters.ContractInfo`:
   *
   * @return OK
   */
  getApiExportCustomersUsersResponse(params: CompaniesService.GetApiExportCustomersUsersParams): __Observable<__StrictHttpResponse<DownloadFileResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.FiltersTake != null) __params = __params.set('Filters.Take', params.FiltersTake.toString());
    if (params.FiltersSkip != null) __params = __params.set('Filters.Skip', params.FiltersSkip.toString());
    if (params.FiltersLastName != null) __params = __params.set('Filters.LastName', params.FiltersLastName.toString());
    if (params.FiltersIsAdmin != null) __params = __params.set('Filters.IsAdmin', params.FiltersIsAdmin.toString());
    if (params.FiltersFirstName != null) __params = __params.set('Filters.FirstName', params.FiltersFirstName.toString());
    if (params.FiltersEmail != null) __params = __params.set('Filters.Email', params.FiltersEmail.toString());
    if (params.FiltersCustomerInfo != null) __params = __params.set('Filters.CustomerInfo', params.FiltersCustomerInfo.toString());
    if (params.FiltersContractInfo != null) __params = __params.set('Filters.ContractInfo', params.FiltersContractInfo.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/exportCustomersUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DownloadFileResponse>;
      })
    );
  }
  /**
   * @param params The `CompaniesService.GetApiExportCustomersUsersParams` containing the following parameters:
   *
   * - `Filters.Take`:
   *
   * - `Filters.Skip`:
   *
   * - `Filters.LastName`:
   *
   * - `Filters.IsAdmin`:
   *
   * - `Filters.FirstName`:
   *
   * - `Filters.Email`:
   *
   * - `Filters.CustomerInfo`:
   *
   * - `Filters.ContractInfo`:
   *
   * @return OK
   */
  getApiExportCustomersUsers(params: CompaniesService.GetApiExportCustomersUsersParams): __Observable<DownloadFileResponse> {
    return this.getApiExportCustomersUsersResponse(params).pipe(
      __map(_r => _r.body as DownloadFileResponse)
    );
  }

  /**
   * @return OK
   */
  getApiCompaniesCountriesResponse(): __Observable<__StrictHttpResponse<GetCompaniesCountriesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/companiesCountries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetCompaniesCountriesResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiCompaniesCountries(): __Observable<GetCompaniesCountriesResponse> {
    return this.getApiCompaniesCountriesResponse().pipe(
      __map(_r => _r.body as GetCompaniesCountriesResponse)
    );
  }

  /**
   * @return OK
   */
  getApiSalesRepresentativesResponse(): __Observable<__StrictHttpResponse<GetSalesRepresentativesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/salesRepresentatives`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetSalesRepresentativesResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiSalesRepresentatives(): __Observable<GetSalesRepresentativesResponse> {
    return this.getApiSalesRepresentativesResponse().pipe(
      __map(_r => _r.body as GetSalesRepresentativesResponse)
    );
  }

  /**
   * @return OK
   */
  getApiServiceLevelAgreementTypesResponse(): __Observable<__StrictHttpResponse<GetServiceLevelAgreementTypesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/serviceLevelAgreementTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetServiceLevelAgreementTypesResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiServiceLevelAgreementTypes(): __Observable<GetServiceLevelAgreementTypesResponse> {
    return this.getApiServiceLevelAgreementTypesResponse().pipe(
      __map(_r => _r.body as GetServiceLevelAgreementTypesResponse)
    );
  }
}

module CompaniesService {

  /**
   * Parameters for getApiQueryCustomersPoducts
   */
  export interface GetApiQueryCustomersPoductsParams {
    FiltersTake?: number;
    FiltersSubcontractValidity?: string;
    FiltersSubcontractValidStartDateAsText?: string;
    FiltersSubcontractValidEndDateAsText?: string;
    FiltersSubcontractPurchaseStartDateAsText?: string;
    FiltersSubcontractPurchaseEndDateAsText?: string;
    FiltersSkip?: number;
    FiltersServiceLevelAgreementType?: string;
    FiltersProducts?: Array<string>;
    FiltersProductCategories?: Array<string>;
    FiltersCustomerZip?: string;
    FiltersCustomerSalesRepresentative?: string;
    FiltersCustomerInfo?: string;
    FiltersCustomerCountry?: string;
    FiltersCustomerCity?: string;
    FiltersContractInfo?: string;
  }

  /**
   * Parameters for getApiExportCustomersPoducts
   */
  export interface GetApiExportCustomersPoductsParams {
    FiltersTake?: number;
    FiltersSubcontractValidity?: string;
    FiltersSubcontractValidStartDateAsText?: string;
    FiltersSubcontractValidEndDateAsText?: string;
    FiltersSubcontractPurchaseStartDateAsText?: string;
    FiltersSubcontractPurchaseEndDateAsText?: string;
    FiltersSkip?: number;
    FiltersServiceLevelAgreementType?: string;
    FiltersProducts?: Array<string>;
    FiltersProductCategories?: Array<string>;
    FiltersCustomerZip?: string;
    FiltersCustomerSalesRepresentative?: string;
    FiltersCustomerInfo?: string;
    FiltersCustomerCountry?: string;
    FiltersCustomerCity?: string;
    FiltersContractInfo?: string;
  }

  /**
   * Parameters for getApiQueryCustomersUsers
   */
  export interface GetApiQueryCustomersUsersParams {
    FiltersTake?: number;
    FiltersSkip?: number;
    FiltersLastName?: string;
    FiltersIsAdmin?: boolean;
    FiltersFirstName?: string;
    FiltersEmail?: string;
    FiltersCustomerInfo?: string;
    FiltersContractInfo?: string;
  }

  /**
   * Parameters for getApiExportCustomersUsers
   */
  export interface GetApiExportCustomersUsersParams {
    FiltersTake?: number;
    FiltersSkip?: number;
    FiltersLastName?: string;
    FiltersIsAdmin?: boolean;
    FiltersFirstName?: string;
    FiltersEmail?: string;
    FiltersCustomerInfo?: string;
    FiltersContractInfo?: string;
  }
}

export { CompaniesService }
