import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { PasswordPolicy } from "src/app/api/opal-partner-center/models";
import { LOCALIZE_CONSTANTS } from "../localize.constants";
import { PasswordValidationService } from "../services/password-validation-service/password-validation.service";

@Directive({
  selector: '[passwordPolicyValidator]',
  providers:[{provide: NG_VALIDATORS, useExisting: PasswordPolicyValidatorDirective, multi: true}]  
})
export class PasswordPolicyValidatorDirective implements Validator{

  //#region Private Fields

  private _messagePasswordMustBeAtLeast?: string;

  private _messageCharactersLong?: string;

  private _messagePasswordMustHaveAtLeast?: string;

  private _messageUppercaseLetters?: string;

  private _messageLowercaseLetters?: string;

  private _messageDigits?: string;

  private _messageSpecialCharacters?: string;

  //#endregion

  //#region Public Properties

  @Input('passwordPolicy')
  public passwordPolicy?: PasswordPolicy;

  //#endregion

  //#region Constructor

  constructor(private _translateService: TranslateService, private _passwordValidationService: PasswordValidationService) {
    this._translateService.get([LOCALIZE_CONSTANTS.ERROR_MESSAGE.PASSWORD_POLICY_PASSWORD_MUST_BE_AT_LEAST,
                                LOCALIZE_CONSTANTS.ERROR_MESSAGE.PASSWORD_POLICY_CHARACTERS_LONG,
                                LOCALIZE_CONSTANTS.ERROR_MESSAGE.PASSWORD_POLICY_PASSWORD_MUST_HAVE_AT_LEAST,
                                LOCALIZE_CONSTANTS.ERROR_MESSAGE.PASSWORD_POLICY_UPPERCASE_LETTERS,
                                LOCALIZE_CONSTANTS.ERROR_MESSAGE.PASSWORD_POLICY_LOWERCASE_LETTERS,
                                LOCALIZE_CONSTANTS.ERROR_MESSAGE.PASSWORD_POLICY_DIGITS,
                                LOCALIZE_CONSTANTS.ERROR_MESSAGE.PASSWORD_POLICY_SPECIAL_CHARACTERS]).subscribe(translations=>{

                                  this._messagePasswordMustBeAtLeast = translations[LOCALIZE_CONSTANTS.ERROR_MESSAGE.PASSWORD_POLICY_PASSWORD_MUST_BE_AT_LEAST];
                                  this._messageCharactersLong = translations[LOCALIZE_CONSTANTS.ERROR_MESSAGE.PASSWORD_POLICY_CHARACTERS_LONG];
                                  this._messagePasswordMustHaveAtLeast = translations[LOCALIZE_CONSTANTS.ERROR_MESSAGE.PASSWORD_POLICY_PASSWORD_MUST_HAVE_AT_LEAST];
                                  this._messageUppercaseLetters = translations[LOCALIZE_CONSTANTS.ERROR_MESSAGE.PASSWORD_POLICY_UPPERCASE_LETTERS];
                                  this._messageLowercaseLetters = translations[LOCALIZE_CONSTANTS.ERROR_MESSAGE.PASSWORD_POLICY_LOWERCASE_LETTERS];
                                  this._messageDigits = translations[LOCALIZE_CONSTANTS.ERROR_MESSAGE.PASSWORD_POLICY_DIGITS];
                                  this._messageSpecialCharacters = translations[LOCALIZE_CONSTANTS.ERROR_MESSAGE.PASSWORD_POLICY_SPECIAL_CHARACTERS];

                                })
  }

  //#endregion

  //#region Validator

  validate(control: AbstractControl): ValidationErrors | null   {

    if(!this.passwordPolicy){
      return null;
    }
    var passwordValidationResult = this._passwordValidationService.validatePassword(control.value, this.passwordPolicy!);

    if(!passwordValidationResult.minimalPasswordLengthValid){
      
      return {
        "passwordPolicy": false,
        "passwordPolicyError": this._messagePasswordMustBeAtLeast + " " + this.passwordPolicy!.minimalLength + " " + this._messageCharactersLong
      };

    } else if(!passwordValidationResult.minimalNumberOfUppercaseCharactersValid){

      return {
        "passwordPolicy": false,
        "passwordPolicyError": this._messagePasswordMustHaveAtLeast + " " + this.passwordPolicy!.minimalNumberOfUppercaseLetters + " " + this._messageUppercaseLetters
      };

    } else if(!passwordValidationResult.minimalNumberOfLowercaseCharactersValid){

      return {
        "passwordPolicy": false,
        "passwordPolicyError": this._messagePasswordMustHaveAtLeast + " " + this.passwordPolicy!.minimalNumberOfLowercaseLetters + " " + this._messageLowercaseLetters
      };

    } else if(!passwordValidationResult.minimalNumberOfDigitsValid){
        
        return {
          "passwordPolicy": false,
          "passwordPolicyError": this._messagePasswordMustHaveAtLeast + " " + this.passwordPolicy!.minimalNumberOfDigits + " " + this._messageDigits
        };
    } else if(!passwordValidationResult.minimalNumberOfSpecialCharactersValid){
      return {
        "passwordPolicy": false,
        "passwordPolicyError": this._messagePasswordMustHaveAtLeast + " " + this.passwordPolicy!.minimalNumberOfSpecialCharacters + " " + this._messageSpecialCharacters
      };
    } else {
      return null
    }
  }

  //#endregion

}