/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OpalPartnerCenterApiConfiguration as __Configuration } from '../opal-partner-center-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetProductsInfosResponse } from '../models/get-products-infos-response';
import { GetProductsDocumentationSummaryResponse } from '../models/get-products-documentation-summary-response';
import { GetDocumentPublicationDetailsResponse } from '../models/get-document-publication-details-response';
import { DownloadFileResponse } from '../models/download-file-response';
import { GetFileLinkResponse } from '../models/get-file-link-response';
import { GetPublicationCategoriesResponse } from '../models/get-publication-categories-response';
import { BaseResponse } from '../models/base-response';
import { AddPublicationCategoryRequest } from '../models/add-publication-category-request';
import { DeletePublicationCategoryRequest } from '../models/delete-publication-category-request';
import { UpdatePublicationCategoryRequest } from '../models/update-publication-category-request';
@Injectable({
  providedIn: 'root',
})
class ProductsDocumentationService extends __BaseService {
  static readonly getApiProductsDocumentationProductsPath = '/api/productsDocumentationProducts';
  static readonly getApiProductsTrainingVideosProductsPath = '/api/productsTrainingVideosProducts';
  static readonly getApiProductsDocumentationSummaryPath = '/api/productsDocumentationSummary';
  static readonly getApiDocumentPublicationDetailsPath = '/api/documentPublicationDetails';
  static readonly getApiDocumentPublicationDownloadPath = '/api/documentPublicationDownload';
  static readonly getApiDocumentPublicationDownloadLinkPath = '/api/documentPublicationDownloadLink';
  static readonly getApiPublicationCategoriesPath = '/api/publicationCategories';
  static readonly postApiPublicationCategoriesPath = '/api/publicationCategories';
  static readonly deleteApiPublicationCategoriesPath = '/api/publicationCategories';
  static readonly putApiPublicationCategoriesPath = '/api/publicationCategories';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  getApiProductsDocumentationProductsResponse(): __Observable<__StrictHttpResponse<GetProductsInfosResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/productsDocumentationProducts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductsInfosResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiProductsDocumentationProducts(): __Observable<GetProductsInfosResponse> {
    return this.getApiProductsDocumentationProductsResponse().pipe(
      __map(_r => _r.body as GetProductsInfosResponse)
    );
  }

  /**
   * @return OK
   */
  getApiProductsTrainingVideosProductsResponse(): __Observable<__StrictHttpResponse<GetProductsInfosResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/productsTrainingVideosProducts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductsInfosResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiProductsTrainingVideosProducts(): __Observable<GetProductsInfosResponse> {
    return this.getApiProductsTrainingVideosProductsResponse().pipe(
      __map(_r => _r.body as GetProductsInfosResponse)
    );
  }

  /**
   * @return OK
   */
  getApiProductsDocumentationSummaryResponse(): __Observable<__StrictHttpResponse<GetProductsDocumentationSummaryResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/productsDocumentationSummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductsDocumentationSummaryResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiProductsDocumentationSummary(): __Observable<GetProductsDocumentationSummaryResponse> {
    return this.getApiProductsDocumentationSummaryResponse().pipe(
      __map(_r => _r.body as GetProductsDocumentationSummaryResponse)
    );
  }

  /**
   * @param params The `ProductsDocumentationService.GetApiDocumentPublicationDetailsParams` containing the following parameters:
   *
   * - `publicationFormat`:
   *
   * - `publicationCategory`:
   *
   * - `productShortName`:
   *
   * - `productVersion`:
   *
   * @return OK
   */
  getApiDocumentPublicationDetailsResponse(params: ProductsDocumentationService.GetApiDocumentPublicationDetailsParams): __Observable<__StrictHttpResponse<GetDocumentPublicationDetailsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.publicationFormat != null) __params = __params.set('publicationFormat', params.publicationFormat.toString());
    if (params.publicationCategory != null) __params = __params.set('publicationCategory', params.publicationCategory.toString());
    if (params.productShortName != null) __params = __params.set('productShortName', params.productShortName.toString());
    if (params.productVersion != null) __params = __params.set('productVersion', params.productVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/documentPublicationDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetDocumentPublicationDetailsResponse>;
      })
    );
  }
  /**
   * @param params The `ProductsDocumentationService.GetApiDocumentPublicationDetailsParams` containing the following parameters:
   *
   * - `publicationFormat`:
   *
   * - `publicationCategory`:
   *
   * - `productShortName`:
   *
   * - `productVersion`:
   *
   * @return OK
   */
  getApiDocumentPublicationDetails(params: ProductsDocumentationService.GetApiDocumentPublicationDetailsParams): __Observable<GetDocumentPublicationDetailsResponse> {
    return this.getApiDocumentPublicationDetailsResponse(params).pipe(
      __map(_r => _r.body as GetDocumentPublicationDetailsResponse)
    );
  }

  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiDocumentPublicationDownloadResponse(FileGuid: string): __Observable<__StrictHttpResponse<DownloadFileResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (FileGuid != null) __params = __params.set('FileGuid', FileGuid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/documentPublicationDownload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DownloadFileResponse>;
      })
    );
  }
  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiDocumentPublicationDownload(FileGuid: string): __Observable<DownloadFileResponse> {
    return this.getApiDocumentPublicationDownloadResponse(FileGuid).pipe(
      __map(_r => _r.body as DownloadFileResponse)
    );
  }

  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiDocumentPublicationDownloadLinkResponse(FileGuid: string): __Observable<__StrictHttpResponse<GetFileLinkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (FileGuid != null) __params = __params.set('FileGuid', FileGuid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/documentPublicationDownloadLink`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetFileLinkResponse>;
      })
    );
  }
  /**
   * @param FileGuid undefined
   * @return OK
   */
  getApiDocumentPublicationDownloadLink(FileGuid: string): __Observable<GetFileLinkResponse> {
    return this.getApiDocumentPublicationDownloadLinkResponse(FileGuid).pipe(
      __map(_r => _r.body as GetFileLinkResponse)
    );
  }

  /**
   * @return OK
   */
  getApiPublicationCategoriesResponse(): __Observable<__StrictHttpResponse<GetPublicationCategoriesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/publicationCategories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetPublicationCategoriesResponse>;
      })
    );
  }
  /**
   * @return OK
   */
  getApiPublicationCategories(): __Observable<GetPublicationCategoriesResponse> {
    return this.getApiPublicationCategoriesResponse().pipe(
      __map(_r => _r.body as GetPublicationCategoriesResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  postApiPublicationCategoriesResponse(body?: AddPublicationCategoryRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/publicationCategories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  postApiPublicationCategories(body?: AddPublicationCategoryRequest): __Observable<BaseResponse> {
    return this.postApiPublicationCategoriesResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  deleteApiPublicationCategoriesResponse(body?: DeletePublicationCategoryRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/publicationCategories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  deleteApiPublicationCategories(body?: DeletePublicationCategoryRequest): __Observable<BaseResponse> {
    return this.deleteApiPublicationCategoriesResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }

  /**
   * @param body undefined
   * @return OK
   */
  putApiPublicationCategoriesResponse(body?: UpdatePublicationCategoryRequest): __Observable<__StrictHttpResponse<BaseResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/publicationCategories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return OK
   */
  putApiPublicationCategories(body?: UpdatePublicationCategoryRequest): __Observable<BaseResponse> {
    return this.putApiPublicationCategoriesResponse(body).pipe(
      __map(_r => _r.body as BaseResponse)
    );
  }
}

module ProductsDocumentationService {

  /**
   * Parameters for getApiDocumentPublicationDetails
   */
  export interface GetApiDocumentPublicationDetailsParams {
    publicationFormat: string;
    publicationCategory: string;
    productShortName: string;
    productVersion?: string;
  }
}

export { ProductsDocumentationService }
